import { EQPAPI } from "../../api/api";

const PICS_LOAD = "picReducer/SUCCESS_LOAD_1";
const ERROR_LOAD = "picReducer/ERROR_LOAD";
const SET_PIC = 'picReducer/SET_PIC';
const SET_ERROR = "picReducer/ERROR";
const SET_LOADER = 'picReducer/SET_LOADER';


const initialState = {
    pics: null,
    error: null,
    pic: null,
    loader: false,
    
  };


  export const picReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PIC:
        return {
            ...state,
            pic: action.pic
        }
      case SET_LOADER:
        return {
              ...state,
              loader: action.loader
            }              

        case PICS_LOAD:
        return {
          ...state,
          pics: action.pics,
        };
        case ERROR_LOAD:
        return {
            ...state,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }

        default:
            return state;
    }
};


export const getPics = (state) => state.picReducer; 
export const getPic = (state) => state.picReducer.pic; 
export const getError = state => state.picReducer.error
export const getPicLoader = state => state.picReducer.loader

export const setPic = (pic) => ({type: SET_PIC, pic})
export const setError = (error) => ({type: SET_ERROR, error})
export const setPicLoader = (loader) => ({type: SET_LOADER, loader})
export const setPics = (pics) => ({
    type: PICS_LOAD,
    pics,
  });

export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadPic = (id) => async dispatch => {
  try {
      
      const response = await EQPAPI.getPic(id)
      
      if (response.status === 200 && response.data.length !== 0) {
          const result = response.data
          dispatch(setPic(result))
      }


  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}


export const loadPics = () => async (dispatch) => {
    const response = await EQPAPI.getPicList();
    if (response.status === 200 && response.data.length !== 0) {
      dispatch(setPics(response.data));

    } else {
      dispatch(errorLoad());
    }

  };

export const newPic = (pic) => async dispatch => {

    let error = {iserror: false}

    if ((pic?.NAME?.toString() == null) ||(pic?.NAME.toString() == ''))  error = {...error,  picNAME:   {text: 'Не указано наименование'}, iserror: true}
    
    if (error.iserror )
    dispatch(setError(error))
    else
    try {
      dispatch(setPicLoader(true))
        const response = await EQPAPI.newPic(pic)
        
        if (response.status === 200 && response.data.length !== 0) {
            dispatch(setPicLoader(false))
            const result = response.data
            dispatch(setPic(result))
            dispatch(setError(null))
        }
    }
    catch (error) {
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const updatePic = (pic) => async dispatch => {

  let error = {iserror: false}

  if ((pic?.NAME?.toString() == null) ||(pic?.NAME.toString() == ''))  error = {...error,  picNAME:   {text: 'Не указано наименование'}, iserror: true}
  
  if (error.iserror )
  dispatch(setError(error))
  else
  try {
    dispatch(setPicLoader(true))
      const response = await EQPAPI.updatePic(pic)
      
      if (response.status === 200 && response.data.length !== 0) {
          dispatch(setPicLoader(false))
          const result = response.data
          dispatch(setPic(result))
          dispatch(setError(null))
      }
  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}

export const deletePic = (pic) => async dispatch => {
  dispatch(setPicLoader(true))
  const response = await EQPAPI.deletePic(pic)
  
  if (response.status === 200 && response.data.length !== 0) {
      const result = response.data
      dispatch(setPics(result));
      dispatch(setPicLoader(false))
    }
}
