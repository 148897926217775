import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import  Loader  from '../components/Loader/Loader';
import { createSearchParams, useSearchParams, Link } from "react-router-dom";
import {getProfile, loadProfileData,Ю} from "../redux/reducers/user-profile-personal-reducer";
import {ProfileAPI} from "../api/api";
import {Navigate} from "react-router-dom";
import {
    loadActivation, getError, successLoad,
    getCode,
    getActivated,
    getId, setCode, setId
} from "../redux/reducers/activation-reducer";

import {
    getLoggedIn,
    getLogin,
} from "../redux/reducers/login-reducer";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: ''
    },
    loginRight: {
        width: '100%',
        backgroundColor: '#fff',
        paddingBottom: '50px',
        minHeight: 'inherit',
        ['@media (max-width:900px)']: { 
            width: '100%',
            paddingBottom: '50px',
        },
    },
    chapterName: {
        fontSize:'40px', 
        textAlign: 'center',
        fontWeight: 800
    },        
    button: {
        color: '#fff',
        backgroundColor: '#054C74',
        '&:hover': {
            backgroundColor: '#E6E6E6',
            color: '#1A1A1A',
        },        
    },
    activationBox: {
        padding: '10px 50px 20px 50px',
        ['@media (max-width:767px)']: { 
            padding: '10px 10px 50px 10px',
            }          

    },        

}))

const setActivationState = async (code, id) => {
    let response = await ProfileAPI.activateProfile(code, id)
    return response
}

export const ActivationPage = () => {

    const [noteVisible, setNoteVisible] = useState('');
    const [counterVisible, setCounterVisible] = useState('hidden');
    const [counter, setCounter] = useState(60);

    const isLoggedIn = useSelector(getLoggedIn)
    const classes = useStyles()
    const dispatch = useDispatch()
    const login = useSelector(getLogin)



    const {user} = useSelector(getProfile)
    const [searchParams, setSearchParams] = useSearchParams();
    const [activation, setActivation] = useState()

    const code = useSelector(getCode)
    const id = useSelector(getId)
    const error = useSelector(getError)
    const is_activated = useSelector(getActivated)

    const [eqpMeta, setEqpMeta] = useState({title:'Активация - Импортозамещение и подбор нефтегазового оборудования', description:'Активация в Каталоге нефтегазового оборудования, ориентированном на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    useEffect(() => {
        dispatch(loadProfileData)
    }, [activation])  

    const [submitLoader, setsubmitLoader] = useState(true);
    const submitUpdate =() => setsubmitLoader(!submitLoader);

    useEffect(() => {
       setsubmitLoader(!submitLoader)
      },[error]);

      
    const onCodeChange = e => {
        
        dispatch(setCode(e.target.value))
        dispatch(setId(user.id))
    }

    const submit = () => {
        submitUpdate();
        dispatch(loadActivation(code, id))
    }   

    const sendNoteAction = async (id) => {
        let response = await ProfileAPI.sendNoteAction(id)
        return response
    }    

    const sendNote = () => {
        setCounter(60)
        setNoteVisible('hidden')
        setCounterVisible('');
        sendNoteAction(user.id)
    }

    React.useEffect(() => {
        if (counter === 0) {
            setNoteVisible('')
            setCounterVisible('hidden');
        }

        const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
      }, [counter]);
    
      
    if ((is_activated) &&  (!activation))
    setActivation(true)
    
    if (!isLoggedIn)
        return <Navigate to={'/login'}/>
    
            
    if (user != null) {
        if (!user.is_activated) {

            if ((searchParams.get("code") != null) && (searchParams.get("id") != null)) {
            
                setActivationState(searchParams.get("code"), searchParams.get("id")).then((resolve) =>{
                    if (resolve.status === 200)
                        setActivation(true)

                }) 
                }
            else
            return  <HomePrivateLayout checkprivate="true">
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>                
            <div className={classes.paper}>
            <div className={classes.chapterName}>Активация</div>
            <div className={classes.activationBox}>Укажите код активации, ранее направленный на электронную почту, указанную при регистрации
                <form>
                <TextField
                    id={'outlined-basic'}
                    label={'Код активации'}
                    style = {{width: 300}}
                    margin={'normal'}
                    variant={'outlined'}
                    error={!!error}
                    helperText={error?.code?.text}
                    value={code}
                    onChange={onCodeChange}
                    onKeyDown={(e) => {
                    if (e.key === 'Enter') submit()
                    }}                                        
                    />
                    <div>
                        <p><a href='#' className={"link headerlink "+noteVisible} onClick={sendNote}>Отправить код повторно</a><span className={counterVisible}>Через {counter} код можно получить повторно</span></p>
                    <Button variant="contained" disabled={submitLoader} onClick={submit} size={'large'} className={classes.button}>
                        Активировать
                    </Button>
                    </div>                    
                </form>
                </div>                
            </div>                  
        </HomePrivateLayout>
        }

        if (user.is_activated)     
        return  <HomePrivateLayout checkprivate="true">
            
                    <div className={classes.paper}>
                    <div className={classes.chapterName}>Активация</div>    
                    <p>Ваш профиль успешно активирован<br/>
                    Спасибо за регистрацию!</p>
                    <p>Настроить<Link to="/profile" className="link headerlink">профиль пользователя</Link></p>
                    </div>                  
                </HomePrivateLayout>
                   
                
        }
    
        return  <HomePrivateLayout checkprivate="true">
        <div className={classes.paper}>
        <Loader/>
        </div>                  
        </HomePrivateLayout>

}