import { EQPAPI } from "../../api/api";

const SUCCESS_LOAD_1 = "okReducer/SUCCESS_LOAD_1";
const SUCCESS_LOAD_2 = "okReducer/SUCCESS_LOAD_2";
const SET_OKITEM = "okReducer/SET_OKITEM";
const ERROR_LOAD = "okReducer/ERROR_LOAD";


const initialState = {
    ok1: null,
    ok2: null,
    okitem: null,
    loading: true,
    error: null,
  };


  export const okReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OKITEM:
        return {
          ...state,
          okitem: action.okitem,
          loading: false,
        };
        case SUCCESS_LOAD_1:
        return {
          ...state,
          ok1: action.ok,
          loading: false,
        };        
        case SUCCESS_LOAD_2:
        return {
          ...state,
          ok2: action.ok,
          loading: false,
        };        
        case ERROR_LOAD:
        return {
            ...state,
            loading: false,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };        
        default:
            return state;
    }
};


export const getOk = (state) => state.okReducer; 
export const getOkitem = state => state.okReducer.okitem
export const setOkitem = (okitem) => ({type: SET_OKITEM, okitem})


export const successLoad1 = (ok) => ({
    type: SUCCESS_LOAD_1,
    ok,
  });

  export const successLoad2 = (ok) => ({
    type: SUCCESS_LOAD_2,
    ok,
  });  
export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadOk = (owner) => async (dispatch) => {
    const response = await EQPAPI.getOkList(owner);
    if (response.status === 200 && response.data.length !== 0) {
      const new_data = response.data;
    if(owner == 1)
      dispatch(successLoad1(new_data));
    if(owner == 2)
      dispatch(successLoad2(new_data));

    } else {
      dispatch(errorLoad());
    }

  };

  export const loadOkitem = (ok) => async dispatch => {

    const response = await EQPAPI.getOkItem(ok)
    
    if (response.status === 200 && response.data.length !== 0) {
        const result = response.data
        dispatch(setOkitem(result));

    }
  }


  export const newOk = (ok) => async dispatch => {
    if (ok.NAME =='')
        ok.NAME ='Без названия'
        const response = await EQPAPI.newOk(ok)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            if(ok.ID_OWNER == 1)
            dispatch(successLoad1(result));
            if(ok.ID_OWNER == 2)
            dispatch(successLoad2(result));
        }
}  

  export const updateOk = (ok) => async dispatch => {
        const response = await EQPAPI.updateOk(ok)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            if(ok.ID_OWNER == 1)
            dispatch(successLoad1(result));
            if(ok.ID_OWNER == 2)
            dispatch(successLoad2(result));
        }
}

  export const deleteOk = (ok) => async dispatch => {
    const response = await EQPAPI.deleteOk(ok)
    
    if (response.status === 200 && response.data.length !== 0) {
        const result = response.data
        if(ok.ID_OWNER == 1)
        dispatch(successLoad1(result));
        if(ok.ID_OWNER == 2)
        dispatch(successLoad2(result));
    }
  }
