import React from 'react';
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Skeleton from "@material-ui/lab/Skeleton";
import {makeStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: '0',
        width: '25px',
        height: '25px',
        backgroundColor: '#fff',
        border: '2px solid #054C74',
        fontSize: 24,
        color: '#054C74',        
        "&:hover": {
            cursor: 'pointer'
        },

    },
    uname: {
        ['@media (max-width:420px)']: { 
            display:'none'
        },
    }
}))


export const ProfileMenu = ({firstName, lastName, onLogOutButtonClick, loading, photo=null}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }
    if (loading) {
        return <div style={{padding:'5px'}} className="right"><Skeleton variant="circle" width={30} height={30}  /></div>
    }
    return <>
    <div style={{padding:'5px', display:'inline-flex', color: '#054C74'}} className="right">
    <div className={classes.uname} style={{padding:'5px', cursor: 'pointer'}} onClick={handleClick}>{firstName} {lastName}</div>
        <Avatar className={classes.avatar}
                onClick={handleClick}
                src={photo}>
             {firstName.slice(0, 1)}
        </Avatar>
        
        <Menu anchorOrigin={{vertical: "bottom", horizontal: "center"}}
              transformOrigin={{vertical: "top", horizontal: "center"}}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{}}
        >
            <MenuItem component={Link} to={'/profile'}>
                <ListItemText className="headerlinkImageText" primary={"Профиль"}/>
            </MenuItem>
            <MenuItem onClick={onLogOutButtonClick}>
                <ListItemText className="headerlinkImageText" primary="Выйти"/>
            </MenuItem>
        </Menu>
        </div>
    </>
}   