import { EQPAPI } from "../../api/api";

const REGIONS_LOAD = "regionsReducer/REGIONS_LOAD";
const ERROR_LOAD = "regionsReducer/ERROR_LOAD";


const initialState = {
    regions: null,
    loading: true,
    error: null,
  };


  export const regionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGIONS_LOAD:
        return {
          ...state,
          regions: action.regions,
          loading: false,
        };
        case ERROR_LOAD:
        return {
            ...state,
            loading: false,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };        
        default:
            return state;
    }
};


export const getRegions = (state) => state.regionsReducer.regions; 


export const regionsLoad = (regions) => ({
    type: REGIONS_LOAD,
    regions,
  });


export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadRegions = () => async (dispatch) => {

    const response = await EQPAPI.getRegionsList();
    if (response.status === 200 && response.data.length !== 0) {

      const new_data = response.data;
      dispatch(regionsLoad(new_data));

    } else {
      dispatch(errorLoad());
    }

  };

  export const newRegion = (region) => async dispatch => {
    if (region.NAME =='')
      region.NAME ='Без названия'
        const response = await EQPAPI.newRegion(region)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(regionsLoad(result));
        }
}  

  export const updateRegion = (region) => async dispatch => {
        const response = await EQPAPI.updateRegion(region)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(regionsLoad(result));
        }
}

  export const deleteRegion = (region) => async dispatch => {
    const response = await EQPAPI.deleteRegion(region)
    
    if (response.status === 200 && response.data.length !== 0) {
        const result = response.data
        dispatch(regionsLoad(result));
    }
  }