let API_SERVER

switch (process.env.NODE_ENV) {
    case 'development':
        API_SERVER = 'http://localhost:8000'
        break
    case 'production':
        API_SERVER = process.env.REACT_APP_API_SERVER
        break
    default:
        API_SERVER = 'http://176.99.11.132'
        break
}

export const API_SERVER_VAL = API_SERVER