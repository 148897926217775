



export function RuIcon() {

return (
    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.462695 0H23.7V6.66664H0.662695V0Z" fill="white" stroke="#868686"/>
<rect x="0.262695" y="6.66626" width="24" height="6.66664" fill="#0062BD" />
<rect x="0.262695" y="6.66626" width="24" height="6.66664" />
<rect x="0.262695" y="13.3337" width="24" height="6.66664" fill="#E60000"/>
<rect x="0.262695" y="13.3337" width="24" height="6.66664" />
</svg>
)

}