import Alert from "@material-ui/lab/Alert";
import { EQPAPI } from "../../api/api";

const SET_SEARCH_RESULT = "searchReducer/SET_SEARCH_RESULT";
const SET_BTP = "searchReducer/SET_BTP";
const SET_SEARCH_OK = "searchReducer/SET_SEARCH_OK";
const SET_LOADING_RESULT = "searchReducer/SET_LOADING_RESULT";
const SET_LOADING_OK = "searchReducer/SET_LOADING_OK";
const SET_LOAD_BM = "searchReducer/SET_LOAD_BM";
const SET_LOAD_CM = "searchReducer/SET_LOAD_CM";
const ERROR = "searchReducer/ERROR";


const initialState = {
    search_result:null,
    search_ok:null,
    btp:null,
    loading_result: false,
    loading_ok: true,
    load_bm:false,
    error: null,
  };


//export const getPromo = (state) => state.promoReducer;  

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SEARCH_RESULT:
        return {
            ...state,
            search_result: action.search_result
        }
      case SET_BTP:
        return {
            ...state,
            btp: action.btp
        }        
      case SET_SEARCH_OK:
        return {
            ...state,
            search_ok: action.search_ok
        }      
      case SET_LOADING_RESULT:
        return {
            ...state,
            loading_result: action.loading_result
        }      
      case SET_LOADING_OK:
        return {
            ...state,
            loading_ok: action.loading_ok
        }
      case SET_LOAD_BM:
        return {
            ...state,
            load_bm: action.load_bm
        }             
      case SET_LOAD_CM:
        return {
            ...state,
            load_cm: action.load_cm
        }             

      case ERROR:
        return {
            ...state,
            loading: false,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };        
        default:
            return state;
    }
};

export const errorLoad = () => ({ type: ERROR });  

export const getSearchResult = state => state.searchReducer.search_result
export const getSearchOk = state => state.searchReducer.search_ok
export const getSearchBTP = state => state.searchReducer.btp
export const getLoadingResult = state => state.searchReducer.loading_result
export const getLoadingOk = state => state.searchReducer.loading_ok
export const getError = state => state.searchReducer.error
export const getLoadBm = state => state.searchReducer.load_bm
export const getLoadCm = state => state.searchReducer.load_cm


export const setSearchResult = (search_result) => ({type: SET_SEARCH_RESULT, search_result})
export const setSearchOk = (search_ok) => ({type: SET_SEARCH_OK, search_ok})
export const setSearchBTP = (btp) => ({type: SET_BTP, btp})
export const setLoadingResult = (loading_result) => ({type: SET_LOADING_RESULT, loading_result})
export const setLoadingOk = (loading_ok) => ({type: SET_LOADING_OK, loading_ok})
export const setLoadBm = (load_bm) => ({type: SET_LOAD_BM, load_bm})
export const setLoadCm = (load_cm) => ({type: SET_LOAD_CM, load_cm})
export const setError = (error) => ({type: ERROR, error})

export const setBookmarkStatus = (eqid) => async (dispatch) => {
  const response = await EQPAPI.setBookmark(eqid);
  if (response.status === 200 && response.data.length !== 0) {
      const result = response.data;
      dispatch(setLoadBm(true))
  }

}

export const setCompareStatus = (eqid) => async (dispatch) => {
  const response = await EQPAPI.setCompare(eqid);
  if (response.status === 200 && response.data.length !== 0) {
      const result = response.data;
      dispatch(setLoadCm(true))
  }

}


export const loadSearch = (query=null, page=null, ok=null, eq=null, params=null, chap=null) => async (dispatch) => {

      const response = await EQPAPI.getSearch({query:query,p:page,ok:ok,eq:eq,params:params,chap:chap});
      if (response.status === 200 && response.data.length !== 0) {
        const result = response.data;
      
        dispatch(setSearchResult(result));

      } else {
        dispatch(errorLoad());
      }

  }

  export const loadASearch = (conditions) => async (dispatch) =>  {
    const response = await EQPAPI.getASearch({p:conditions.page, ok:conditions.aok, childok:((conditions.childok)?conditions.childok:false), aname:conditions.aname, ashifr:conditions.ashifr, acompany:conditions.acompany, aregion:conditions.aregion, params:conditions.params});
    if (response.status === 200 && response.data.length !== 0) {
      const result = response.data;
    
      dispatch(setSearchResult(result));

    } else {
      dispatch(errorLoad());
    }

  }  

  export const loadFSearch = (conditions) => async (dispatch) =>  {
    const response = await EQPAPI.getFSearch({p:conditions.page, ok:conditions?.ok, params:conditions.params});
    if (response.status === 200 && response.data.length !== 0) {
      const result = response.data;
    
      dispatch(setSearchResult(result));

    } else {
      dispatch(errorLoad());
    }

  }  

  export const loadCSearch = (conditions) => async (dispatch) =>  {
    const response = await EQPAPI.getCSearch({p:conditions.page, ok:conditions?.ok, params:conditions.params});
    if (response.status === 200 && response.data.length !== 0) {
      const result = response.data;
    
      dispatch(setSearchResult(result));

    } else {
      dispatch(errorLoad());
    }

  }  

  export const loadOk = (id, ok_sub=null) => async (dispatch) => {

    if (id != null) {
    const response = await EQPAPI.getOk({id:id, ok_sub:ok_sub});
    if (response.status === 200 && response.data.length !== 0) {
      const result = response.data;
    
      dispatch(setSearchOk(result));

    } else {
      dispatch(errorLoad());
    }
    }
    else
    dispatch(setSearchOk('not found'));
}

export const loadBTP = (id, query = null) => async (dispatch) => {

  if (id != null) {
  const response = await EQPAPI.getBTP(id, query);
  if (response.status === 200 && response.data.length !== 0) {
    const result = response.data;

    result.forEach( function callback (resultItem) {
        resultItem.forks.forEach( function callback (forkItem) {
          forkItem.maxval = forkItem.maxval.toFixed(2)
          forkItem.minval = forkItem.minval.toFixed(2)
        })
    })

    dispatch(setSearchBTP(result));

  } else {
    dispatch(errorLoad());
  }
  }
  else
  dispatch(setSearchBTP('not found'));
}