import React, { createRef, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn, getTariff} from "../../redux/reducers/login-reducer";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import { Tooltip } from "@material-ui/core";


const changeStatus = (item) => {
item.bookmark = !item.bookmark
}


const Bookmark = (props) => {

    const isTariff = useSelector(getTariff)
    let out = []

    if ((!isTariff) )
        out.push(
        <Tooltip title={<span style={{ fontSize:'14px'}}>Добавить в избранное</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
            <StarOutlineIcon onClick={()=>{props.action()}} style={{color:'#054C74', cursor:'pointer'}}/>
        </Tooltip>)
    else if (props.status)
        out.push(
        <Tooltip title={<span style={{ fontSize:'14px'}}>Удалить из избранного</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
            <StarIcon onClick={()=>{props.onClick()}} style={{color:'#bb0000', cursor:'pointer', animation: `spin 4s linear infinite`}}/>
        </Tooltip>)
        else
        out.push(
        <Tooltip title={<span style={{ fontSize:'14px'}}>Добавить в избранное</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
            <StarOutlineIcon onClick={()=>{props.onClick()}} style={{color:'#054C74', cursor:'pointer'}}/>
        </Tooltip>)
    
    return out
}
export default Bookmark;