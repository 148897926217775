import React, {createRef, useEffect, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import { Helmet } from 'react-helmet';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";
import {getProfile} from "../redux/reducers/user-profile-personal-reducer";
import { getError, getResult, sendFeedback } from '../redux/reducers/feedback-reducer';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    orderMenu: { minWidth:'300px',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    },     
    orderArea: {
        backgroundColor: '#fff',
        boxShadow: '0 1px 4px #bfbfbf',
        borderRadius: '12px',
        width:'500px',
        padding: '15px',
        marginTop:'15px',
        ['@media (max-width:520px)']: { 
            width: '-webkit-fill-available',
            marginRight: '5px',
            marginLeft: '5px',
            marginBottom:'20px',
            height:'auto'
        },  
    }         
    
}))
export const ContactsPage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)


    const [eqpMeta, setEqpMeta] = useState({title:'Контакты - Импортозамещение и подбор нефтегазового оборудования', description:'Контакты разработчиков каталога нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    if (!isLoggedIn) 
    return <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/contacts"} rel="canonical"></link>
    </Helmet>         
                    <div className={classes.paper}>
                     <ContactsContent/>
                    </div>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout>
        <Helmet>
            <title>{eqpMeta.title}</title>
            <meta name="description" content={eqpMeta.description}/>
            <meta name="keywords" content={eqpMeta.keywords}/>
        </Helmet> 
                    <div className={classes.paper}>
                    <ContactsContent/>
                    </div>                  
                </HomePrivateLayout>         
}

const ContactsContent = () => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const error = useSelector(getError)
    const result = useSelector(getResult)

    const [feedBack, setFeedBack] = useState({
        feedBackSubject:'Предложение о сотрудничестве',
        feedBackText:'',
        feedBackEmail:'',
        feedBackCapcha:'',
    })

    const user = useSelector(getProfile)

    const feedBackCapcha = createRef();

    const setFeedbackValue = (name, value) => {
        let feedBackCopy = {... feedBack}
        feedBackCopy[name] = value
        setFeedBack(feedBackCopy)
    }

    const submit = () => {

        setFeedbackValue('feedBackCapcha', feedBackCapcha.current.getValue())
        dispatch(sendFeedback({...feedBack, feedBackCapcha:feedBackCapcha.current.getValue()}))
        

    }

    return  <div className="App-page">
                <h1 className="App-h1">КОНТАКТЫ</h1>
                
                <p>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «НАУЧНО-ПРОЕКТНАЯ ФИРМА «ПРОЕКТИРОВАНИЕ И КОНСАЛТИНГ» (ООО «НПФ «ПРОКОН»)</p>
                <p><span style={{fontWeight:'bold', color:"rgb(92 92 92)"}}>АДРЕС КОМПАНИИ:</span> г.Москва, 3-й проезд Марьиной Рощи, д.40, стр.1, этаж 5</p>
                <p><span style={{fontWeight:'bold', color:"rgb(92 92 92)"}}>ПОЧТОВЫЙ АДРЕС:</span> 119330, г. Москва, а/я 703</p>
                <p><span style={{fontWeight:'bold', color:"rgb(92 92 92)"}}>ЭЛЕКТРОННАЯ ПОЧТА:</span> info@importzamena.ru</p>
                <div className={classes.orderArea}>
                    <div style={{paddingBottom:"20px"}}><span style={{fontWeight:'bold'}}>ФОРМА ОБРАТНОЙ СВЯЗИ</span> </div>
                    {((result?.capcha?.success)&&(!result?.feedback?.iserror))?
                    <div style={{padding:"20px"}} >Сообщение отправлено</div>
                    :<div>
                        <div style={{paddingBottom:"10px"}}>
                            <TextField
                                id="feedBackSubject"
                                label={'Тема'}
                                value={feedBack['feedBackSubject']}
                                onChange={(e)=> setFeedbackValue('feedBackSubject', e.target.value)}
                                select
                                className={classes.orderMenu}
                            >
                                <MenuItem value="Предложение о сотрудничестве">Предложение о сотрудничестве</MenuItem>
                                <MenuItem value="Технический вопрос">Технический вопрос</MenuItem>
                                <MenuItem value="Пожаловаться на ошибку">Пожаловаться на ошибку</MenuItem>
                                <MenuItem value="Запрос на удаление регистрации">Запрос на удаление регистрации</MenuItem>
                                <MenuItem value="Другой вопрос">Другой вопрос</MenuItem>
                            </TextField>
                        </div>
                        <div style={{paddingBottom:"20px"}}>
                            <TextField
                                margin="dense"
                                padding="none"
                                id="feedText"
                                label="Адрес электронной почты"
                                fullWidth 
                                helperText={error?.feedBackEmail?.text}
                                error={!!error?.feedBackEmail}
                                value={feedBack['feedBackEmail']}
                                onChange={(e)=> setFeedbackValue('feedBackEmail', e.target.value)}
                                >
                            </TextField>     
                        </div>                    
                        <div style={{paddingBottom:"20px"}}>
                            <TextField
                                helperText={error?.feedBackText?.text}
                                error={!!error?.feedBackText}
                                margin="dense"
                                padding="none"
                                id="feedBackText"
                                label="Сообщение"
                                multiline
                                rows={10}
                                fullWidth 
                                value={feedBack['feedBackText']}
                                onChange={(e)=> setFeedbackValue('feedBackText', e.target.value)}

                                >
                            </TextField>     
                        </div>
                        <div style={{paddingBottom:"5px"}}>
                            <ReCAPTCHA
                            sitekey={"6LfGZeMpAAAAAHbNdeOfOgUrYcz8NvKA55LSuoZp"}
                            ref={feedBackCapcha}
                            />
                        </div>
                        <div style={{color:'red', paddingBottom:"20px"}}>{error?.feedBackCapcha?.text}</div>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                {
                                <Button variant="contained" 
                                    //disabled={submitLoader} 
                                    onClick={submit}  
                                    style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                                    size={'large'} 
                                >
                                    Отправить
                                </Button>
                                
                                }
                            </Grid>
                        </Grid>     
                    </div>
                    }
                </div>
            </div>

}