import React from 'react';
import {    Link  } from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Menu from '@material-ui/icons/Menu';
import Bookmark from '@material-ui/icons/BookmarksOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Star from '@material-ui/icons/StarOutline';
import {useDialog} from "../../hooks/useDialog.js";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: 40,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderBottom: '2px solid #054C74',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        overflow: 'hidden',
        padding: '0',
        margin: '0'
    },
    grow: {
        width: '40px',
        flexGrow: 1
    },


}))



export const HeaderPublic = ({children}) => {

    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()

    const classes = useStyles()
    return <AppBar position="fixed" elevation={0} className={classes.appBar} style={{minWidth:'375px'}}>
            <PleaseRegisterDialog
                open={pleaseRegisterDialogOpen}
                onClose={pleaseRegisterDialogHandleClose}
            />        
        <div className="toolbar">
            <Link to="/" className="menu left"><img src="/images/importzamena.svg"/></Link>
            <Link to="/about" className="link headerlink left notablet">О нас</Link>
            <Link to="/tariffs" className="link headerlink left notablet">Тарифы</Link>
            <Link to="/contacts" className="link headerlink left notablet">Контакты</Link>

            
            <Link to="/registration" className="link headerlink right">Зарегистрироваться</Link>
            <Link to="/login" className="link headerlink right">Войти</Link>
            <div style={{'width':'20px', 'height':'24px', 'float':'right'}}></div>
            <a onClick={pleaseRegisterDialogHandleOpen} className="link headerlinkImage right">
                <Tooltip title={<span style={{ fontSize:'14px'}}>Избранное</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>                
                <Star style={{color:'#868686'}}/>
                </Tooltip>
                <div className="headerlinkImageText nomobile" style={{lineHeight:"30px", float:"right", position: "relative", top: "-3px"}}>Избранное</div></a>
            <a onClick={pleaseRegisterDialogHandleOpen} className="link headerlinkImage right">
                <Tooltip title={<span style={{ fontSize:'14px'}}>Cравнить</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
                    <Bookmark style={{color:'#868686'}}/>
                </Tooltip>
            <div className="headerlinkImageText nomobile" style={{lineHeight:"30px", float:"right", position: "relative", top: "-3px"}}>Сравнить</div></a>
            <Link to="/help" className="link headerlinkImage right">
                <Tooltip title={<span style={{ fontSize:'14px'}}>Помощь</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
                    <HelpOutlineIcon style={{color:'#868686'}}/>
                </Tooltip>
                <div className="headerlinkImageText nomobile" style={{lineHeight:"30px", float:"right", position: "relative", top: "-3px"}}>Помощь</div>
            </Link>


            <div className={classes.grow} variant="dense"/>
            {children}
        </div>
    </AppBar>
}