import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn, getTariff} from "../../redux/reducers/login-reducer";
import {getOrder, getOrderLoader, loadOrder} from "../../redux/reducers/keeper-reducer";
import {getUser} from "../../redux/reducers/user-profile-personal-reducer";
import { createSearchParams, useSearchParams, Link, useParams } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useRedirect} from "../../hooks/useRedirect";
import  Loader  from '../Loader/Loader';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '24px',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
        width: '80%',
        ['@media (max-width:1200px)']: { 
            width: '-webkit-fill-available',
            paddingRight: '5px',
            paddingLeft: '5px',
            paddingBottom:'20px',
            height:'auto'
        },
    
    },
    orderArea: {
        backgroundColor: '#fff',
        boxShadow: '0 1px 4px #bfbfbf',
        borderRadius: '12px',
        width:'500px',
        padding: '15px',
        ['@media (max-width:520px)']: { 
            width: '-webkit-fill-available',
            marginRight: '5px',
            marginLeft: '5px',
            marginBottom:'20px',
            height:'auto'
        },        

    },
    chapterName: {
        fontSize:'30px', 
        textAlign: 'center',
        fontWeight: 400,
        marginBottom:'20px'
    },
    headitemname : {
        display: 'flex', 
        paddingTop: '10px',
        paddingLeft: '5px',
        fontSize:'16px',
        
        color: '#929292'
    },
    headitemvalue : {
        display: 'flex', 
        fontSize:'15px',
        paddingLeft: '5px',
    },    
    payOrderButton: {
        color: '#fff', 

        marginTop:'30px',
        backgroundColor: 'rgb(214, 0, 0)', 
        marginBottom:'30px', 
        width:"100%"
    }
}))


const PayOrder = (props) => {

    const statuses = {
        new: 'ожидайте выставления счета',
        sent: 'ожидает оплаты',
        paid: 'оплачен',
        expired: 'оплата просрочена'
      } 
      const types = {
        individual: 'Для физического лица ',
        entity: 'Для юридического лица ',
      }  
    const {replace} = useRedirect()

    const classes = useStyles()
    const dispatch = useDispatch();

    const order = useSelector(getOrder)
    const orderLoader = useSelector(getOrderLoader)
    const user = useSelector(getUser)

    const {id} = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const [files, setFiles] = useState([])

    useEffect(() => {
        
        if (id == 'new')
            dispatch(loadOrder(id, searchParams.get("tariff")))
        else
            dispatch(loadOrder(id))
        
    }, [])

    useEffect(() => {
        
        if ((order)&&(id == 'new'))
            replace('/payorder/'+order?.id)
        if (order?.invoice_file)
            setFiles (JSON.parse(order?.invoice_file?.replaceAll("'", '"')))
        
    }, [order])    
    //
    
    const eqDateTime = (dtString) => {
        const eqDT = {};
        eqDT['Y'] = dtString.split('T')[0].split('-')[0];
        eqDT['M'] = dtString.split('T')[0].split('-')[1];
        eqDT['D'] = dtString.split('T')[0].split('-')[2];

        eqDT['H'] = dtString.split('T')[1].split(':')[0];
        eqDT['I'] = dtString.split('T')[1].split(':')[1];
        eqDT['S'] = dtString.split('T')[1].split(':')[2].split('.')[0];
        return eqDT['D']+'.'+eqDT['M']+'.'+ eqDT['Y']+' '+ eqDT['H']+':'+ eqDT['I']+':'+ eqDT['S']
    }

    

    return (!orderLoader)?
            <div className={classes.orderArea}>
                <div><h1 className={classes.chapterName}>ЗАКАЗ №{order?.id}<br/>{statuses[order?.status]}
                {
                 ((user?.role == 'individual') && (order?.status == 'sent'))?
                 <span style={{fontSize:'16px'}}><br/>до {new Date(Date.parse(order?.datecreate)+1000*1800).toLocaleDateString() +' '+new Date(Date.parse(order?.datecreate)+1000*1800).toLocaleTimeString()}</span>
                 :null
                }
                </h1></div>
                <div>
                    <div className={classes.headitemname}>Дата заказа</div>
                    <div className={classes.headitemvalue}>{eqDateTime(order?.datecreate)} </div>
                </div>                    
                <div>
                    <div className={classes.headitemname}>Тариф</div>
                    <div className={classes.headitemvalue}>{types[order?.tariffinfo?.TYP]+' '+order?.tariffinfo?.DURATION+' дней'} </div>
                </div>                    
                <div>
                    <div className={classes.headitemname}>Стоимость</div>
                    <div className={classes.headitemvalue}>{order?.tariffinfo?.PRICE} р.</div>
                </div>                    
                {
                ((order?.tariffinfo?.TYP == 'entity') && (order?.status == 'sent'))?
                <div>
                    <div className={classes.headitemname}><a href={files[0]?.data} className="headerlinkImageText" style={{cursor:'pointer'}}  download={files[0]?.file?.name}>Скачать счет</a></div>
                </div>  
                :null
                }                 
                
                <div className={classes.headitemname} style={{paddingBottom:'20px'}}>
                    {
                    (order?.status == 'expired')?
                    <span
                          className="headerlinkImageText"
                          style={{cursor:"pointer"}}
                          onClick={() => window.open("/tariffs", "_self")} 
                        >
                          Оформить новый заказ
                    </span>
                    :((order?.status == 'sent')&&(user?.role == 'individual'))?
                    <Button variant="contained" 
                    className={classes.payOrderButton}
                    //disabled={submitLoader} 
                    onClick={()=>window.open('https://importzamena.server.paykeeper.ru/bill/'+order.invoice_id)}  
                    
                    size={'large'} 
                >
                    ОПЛАТИТЬ
                    </Button>
                    :null


                    }   
                </div>
            </div>
            :
            <div style={{padding:'100px'}}><Loader/></div> 
            }
export default PayOrder;