import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getRegions, loadRegions } from "../../redux/reducers/regions-reducer";
import {useDispatch, useSelector} from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import LocationCityIcon from '@mui/icons-material/LocationCity';
import {OkPanelSkeleton} from "../../components/SearchSkeleton/SearchSkeleton.jsx";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
}))

let treeAction = null
let treeLocality = false

const getTreeItemsFromData = (treeItems, locality) => {

    return treeItems.map(treeItemData => {

      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children, locality);
      }
      return (
        (treeItemData.children.length==0)?
        <TreeItem
             key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          onClick={()=> {treeAction(treeItemData.id,treeItemData.name)}}
        />:
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          onLabelClick={()=> {if(!treeLocality)treeAction(treeItemData.id,treeItemData.name)}}
        />        
      );
    });
  };

const DataTreeView = ({ treeItems, action, locality }, ) => {

  treeAction = action
  treeLocality = locality
  return (
    (treeItems!=null)?
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultEndIcon={<LocationCityIcon/>}
    >
      {getTreeItemsFromData(treeItems, locality)}
    </TreeView>
    :''
  );
};  

export const RegionsPanel = (props) => {

    const classes = useStyles()
    const regions = useSelector(getRegions);    

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(loadRegions());
      }, [dispatch]);        

      
    return  <div className={classes.paper} style={{float:'left'}}>
        <div className={(!regions)?'':'hidden'}><OkPanelSkeleton/></div>
        <DataTreeView treeItems={regions} locality={props.locality?props.locality:false} action={props.action?props.action:null} />
            </div>

}