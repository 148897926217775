export const RouteNames = {
    HOME: '/',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    ABOUT: '/about',
    TARIFFS: '/tariffs',
    PAYORDER: '/payorder/:id',
    CONTACTS: '/contacts',
    POLICY: '/policy',
    ADVANCED: '/advanced',
    LOSTPASSWORD: '/lostpassword',
    USERPROFILE: '/profile',
    FAVORITE: '/favorite',
    COMPARE: '/compare',
    ACTIVATE: '/activate',
    RECOVERY: '/recovery',
    SEARCH: '/eqpsearch',
    OSEARCH: '/eqpsearch/:option',    
    OPERATOR: '/operator',
    OFERTA: '/oferta',
    PM: '/payment-methods',
    EQ: '/eq/:id',    
    EQEDIT: '/eqedit/:id',    
    FIRMEDIT: '/firmedit/:id',    
    USEREDIT: '/useredit/:id',    
    PARSESTUDY: '/parsestudy/:id',
    HELP: '/help',
    NOT_FOUND: '*',

}