import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setBookmarkStatusEq,setCompareStatusEq, getLoadBmEq, setLoadBmEq, getLoadCmEq, setLoadCmEq, loadEQEdit, getEQ, setEQ, getBTP, setBTP, loadBTP, getEIList, 
        setEIList, loadEIList, getManufactures, setManufactures, loadManufactures, getEQSave, setEQSave, saveEQEdit, loadEQ, getEQFromUrl, setLoadUrlEq, getLoadUrlEq, loadFromUrl, getBTPRefresh, loadBTPRefresh, setBTPRefresh} from "../../redux/reducers/eq-reducer";
import {getFirm, getFirmError, getFirmLoader, setFirm, setFirmLoader, setFirmError, loadFirm} from "../../redux/reducers/firm-reducer";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import {SearchBar} from "../SearchBar/SearchBar";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import {useDialog} from "../../hooks/useDialog.js";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";  
import  Loader  from '../Loader/Loader';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {API_SERVER_VAL} from "../../config";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Lightbox  from 'react-image-lightbox';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {OkPanel} from "../../containers/OkPanel/OkPanel";
import {PicPanel} from "../../containers/PicPanel/PicPanel";
import {useRedirect} from "../../hooks/useRedirect";
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import 'react-image-lightbox/style.css';

import TextField from '@mui/material/TextField';

const baseUrl = `${API_SERVER_VAL}/api/eqp`

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px',
        width: '100%',
    },
    orderMenu: { minWidth:'100px',
    width:'100%',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    }, 
    deletepic: {
        position: 'absolute',
        textAlign: 'center',
        width: 'inherit',
        height: '160px',
        background: '#fff',
        opacity: '0.7',
        display: 'table-cell',
        verticalAlign: 'bottom',
        lineHeight: '160px',    

        cursor:'pointer',  color:'#054C74',border: '1px solid',

    },
    SearchManufacture: {
        marginTop: '20px',
        border: '1px solid #868686',
        
        width: '250px',
        height: '40px',
        borderRadius: '5px 5px 5px 5px',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        paddingLeft: '10px',
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '350px',
              borderRight: '2px solid #868686',
              borderRadius: '5px',
          },
          ['@media (max-width:360px)']: { 
            width: '300px',
          }
      },
    
    manufactureItem: { 
        cursor:'pointer', 
        color:'#054C74',
        borderBottom: '1px dashed', 
        lineHeight: '25px',
    },  

    mainDialog: {
        "& div.MuiPaper-root": {
            margin: '5px',
            height: 'calc(100% - 5px)',
            minWidth: '60%'
            
          },
        "& .MuiTypography-root": {
          padding: '5px',
          margin: 'auto'      
        },
        "& .MuiDialogContent-root": {
            padding: '0',
            
        },
        "& .MuiTreeItem-label": {
            color: '#1A1A1A'
        }

    },
    picItem: {
        float: 'left', 
        width: '160px',
        height: '160px',  
        margin:'10px', 
        border: '1px solid #868686',
        display:'block',
        
    },
    carousel: {
        margin:'auto',
        cursor:'pointer'
    },
    headitemname : {
        display: 'flex', 
        paddingTop: '10px',
        paddingLeft: '5px',
        fontSize:'16px',
        
        color: '#929292'
    },
    headitemvalue : {
        display: 'flex', 
        fontSize:'15px',
        paddingLeft: '5px',
    },
    headitemvalueFirm: {
        marginLeft: '5px',
        cursor:'pointer', 
        color:'#054C74', 
        display:'block',
        width: 'fit-content',
        textDecoration: 'underline', 
        textDecorationStyle: 'dashed', 
        textDecorationThickness:'1px', 
        paddingTop:'3px', 
        lineHeight:'20px'
    },
    pic: {
        minWidth:'300px',
        height: '300px',
        display:'flex',
        float: 'left',
        paddingRight: '10px',
        ['@media (max-width:520px)']: { 
            width: '-webkit-fill-available',
            paddingRight: '0',
            paddingBottom:'20px',
            height:'auto'
        },
        "& .carousel-root": {
            margin: "auto",
          },
        "& img, & div": {
            maxHeight: "300px",
            maxWidth: "300px",
          }, 
    },
    eqName: {
        fontSize: '25px',
        
        verticalAlign: 'middle',
        display: 'table-cell',
    },

    EQMain: {paddingTop: '70px', paddingBottom: '30px', width:'1024px', 
    ['@media (max-width:860px)']: { 
        width: '-webkit-fill-available',
        margin: '0 5px'
    },   
    ['@media (max-width:500px)']: { 
        width: '-webkit-fill-available',
    }
    },    
    Breadcrumbs: {
        "& li.MuiBreadcrumbs-separator": {
            marginLeft: '0',
            marginRight: '0',
        },   
        ['@media (max-width:500px)']: { 
            display: 'none',
        }        
    },
    
    customBadge: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '10px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },
      customBadge2: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '4px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },

      button: {
        color: '#054C74',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#eeeeee',
            color: '#054C74',
        },        
    },

}))



export const EQEdit = (av) => {
    const {id} = useParams()

    const classes = useStyles()
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getLoggedIn)
    const loadBm = useSelector(getLoadBmEq)
    const loadCm = useSelector(getLoadCmEq)
    const manufactures = useSelector(getManufactures)
    const eqSave = useSelector(getEQSave)


    const [showFullScreenImage, setShowFullScreenImage] = useState({open:false, images:null, name:null});
    const [dataPreparation, setDataPreparation] =  useState(false);

    const [firmDetails, setFirmDetails] =  useState([]);
    const [owner, setOwner] =  useState(1);
    const [searchManufacture, setSearchManufacture] = useState('');
    const [getFromUrl, setGetFromUrl] = useState({url:'',display:false});
    const [dialogClassifier, setDialogClassifier] = useState(null);
    const [delPic, setDelPic] = useState({type:'',id:-1,content:
        <div className={classes.deletepic}>Удалить</div>
    });
    const сloseDialogClassifier = () => {
        setDialogClassifier(null);
      };
//console.log(firmDetails)
    
    let eqItem = useSelector(getEQ)
    let btp = useSelector(getBTP)
    let btpRefresh = useSelector(getBTPRefresh)
    const eiList = useSelector(getEIList)
    const firm = useSelector(getFirm)  
    const firmLoader = useSelector(getFirmLoader)  
    const {replace} = useRedirect()

    useEffect(() => {
        if (eqItem != null) {
             setFirmDetails(
                eqItem?.firms?.map(firmItem=>({"id":firmItem.ID, "content":null}))
            )
            if (manufactures == null)
            dispatch(loadManufactures());            
            
            
            if (btp == null)
                dispatch(loadBTP((eqItem?.ok!='')?eqItem?.ok.map((okItem=>okItem.ID)):'-1'))
            
            if (eqItem.eq.ID != null)
                replace('/eqedit/'+eqItem.eq.ID)

            if (eqItem?.filterOk) {
                filterBtp()
            }
                
        }
    }, [eqItem]);
    

    
useEffect(() =>{
        if (btpRefresh != null) {
            
            let btpCopy = {... btp}
            btpCopy = Object.values(btpCopy)
            btpRefresh?.forEach( function callback (btpRefreshItem) {
            btpCopy = btpCopy?.filter(btpItem=>btpItem.btp_id!=btpRefreshItem.btp_id)
            })
        console.log("+++++++++++");
        console.log(btpRefresh);
        console.log(btp);
        console.log(btpCopy);
        dispatch(setBTP(btpCopy))
        dispatch(setBTPRefresh(null))
        }
    },[btpRefresh])

    const filterBtp = (filterOk) => {
        let eqItemCopy = {... eqItem}
    
        
        dispatch(loadBTPRefresh(eqItemCopy.filterOk))
        delete eqItemCopy.filterOk
        dispatch(setEQ(eqItemCopy))

    }

    useEffect(() => {
        if (eqSave?.status == 'presaving') {
           
            setDataPreparation(false)
            dispatch(setEQ(null))
            dispatch(loadEQEdit(eqSave?.id))
            dispatch(setEQSave('ok'))
            

        }

    }, [eqSave])
    useEffect(() => {
        
        if ((!dataPreparation) && (btp != null) &&(eqItem!=null)) {

            dispatch(setEQ ({... eqItem, prm:eqItem?.prm?.map( (prmItem) => {
                prmItem['disabled'] = false
                return prmItem
            }), ok: eqItem?.ok?.map( (okItem) => {
                if((eqItem?.eq?.ID == null) &&(!okItem?.status ) )
                    okItem['status'] = 'new'
                else
                if (!okItem?.status ) 
                    okItem['status'] = 'old'
                return okItem
            }), firms: eqItem?.firms?.map( (firmItem) => {
                if (!firmItem['status'])
                firmItem['status'] = 'old'
                return firmItem
            })
            }))

            dispatch( setBTP (btp?.map( (btpItem) => {
                btpItem['disabled'] = true
                btpItem['V1'] = null
                btpItem['V2'] = null
                btpItem['VAL1'] = null
                btpItem['VAL2'] = null
                btpItem['LEVELS'] = null
                btpItem['EI'] = null
                return btpItem
            }) ) )
            
            setDataPreparation(true)
        }
    }, [btp, eqItem]);    

    useEffect(() => {


        if(firm != null)
        setFirmDetails(firmDetails.map((o, i) => {
            if (o.id === firm.id) {
                o.content = firm 
                return o; // stop searching
            }
            else return o
        }))
    },[firm])


    useEffect(() => {
        dispatch(loadEIList());
        if (id) 
        dispatch(loadEQEdit(id));
    }, []);
 

  
    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()



    const ManufacturesPanel = (props) => {
        return (manufactures)? 
            manufactures.filter(el => el.NAME.toUpperCase().indexOf(searchManufacture.toUpperCase().trim()) != -1).map((option) => (
            <div style={{paddingLeft: '5px'}} 
            onClick={()=>{
                addManufacture(option.ID, option.NAME)
                
            }}> <span className={classes.manufactureItem}>{option.NAME}</span></div>
            ))
            :null
        
    }




    const ok = () => {
        let content = []

        if(eqItem?.ok?.length > 0)
        eqItem?.ok?.forEach( function callback (okItem) {
            if (okItem?.status != 'deleted')
            content.push(<div className={classes.headitemvalue} style={{paddingTop:'3px'}}>{okItem.NAME}
                                <IconButton style={{padding:'0', top:'-2px'}} onClick={()=>{
                                    //let preOkFilter = eqItem?.ok?.filter((okItemFilter=>okItemFilter.ID!=okItem.ID))
                                    //dispatch(loadBTP(eqItem?.ok?.concat([{ID:id, NAME:name, status:'new'}])?.map((okItem=>okItem.ID))))

                                    //dispatch(loadBTPRefresh((eqItem?.ok!='')?eqItem?.ok?.filter((okItemFilter=>okItemFilter.ID!=okItem.ID)):'-1'))
                                    //let preOkFilter = eqItem?.ok?.filter((okItemFilter=>okItemFilter.ID!=okItem.ID))
                                    
                                    //?.map((okItemM=>okItemM.ID)</div>
                                    dispatch(loadBTP(eqItem?.ok?.filter(option=>((option.ID != okItem.ID)&&(option.status!="deleted")) )?.map((okItem=>okItem.ID))));

                                    (okItem?.status == 'new')?

                                        dispatch(setEQ({... eqItem, ok:eqItem?.ok?.filter(option=>option.ID != okItem.ID )}))

                                    :
                                    dispatch(setEQ({... eqItem, ok:eqItem?.ok?.map(option=>{
                                        if (option.ID == okItem.ID)
                                            option['status'] = 'deleted'
                                        return option
                                    }
                                         )}))
                                    }} >
                                    <CloseIcon />
                                </IconButton> 
                        </div>)
        })

        return content
    }


    const firms = () => {
        let content = []

        if(eqItem?.firms?.length > 0)
        eqItem?.firms?.forEach( function callback (firmItem) {
            if (firmItem?.status != 'deleted')
            content.push(<div className={classes.headitemvalue} style={{paddingTop:'3px'}}>{firmItem.NAME}
                                <IconButton style={{padding:'0', top:'-2px'}} onClick={()=>{
                                    (firmItem?.status == 'new')?
                                    dispatch(setEQ({... eqItem, firms:eqItem?.firms?.filter(option=>option.ID != firmItem.ID )}))
                                    :
                                    dispatch(setEQ({... eqItem, firms:eqItem?.firms?.map(option=>{
                                        if (option.ID == firmItem.ID)
                                            option['status'] = 'deleted'
                                        return option
                                    }
                                         )}))
                                    }} >
                                    <CloseIcon />
                                </IconButton> 
                        </div>)
        })
        
        return <div>{content}</div>
    }    

    const renderFirmDetail = (firmId) => {
        var firmItem = firmDetails?.find(o => parseInt(o.id) == parseInt(firmId))
        if (firmItem?.content == 'loader')
            return <Loader type="linear"/>
        else
        if (firmItem?.content == null)
        return <div></div>
        else
        return <div style={{paddingLeft:'5px'}}>
            {(firmItem?.content?.status != '')&&(firmItem?.content?.status)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Статус:</span> <span style={(firmItem?.content?.status == "Действует")?{color:"darkgreen"}:(firmItem?.content?.status == "Ликвидирована")?{color:"darkred"}:null}>{firmItem?.content?.status}</span></div>:null}
            {(firmItem?.content?.date_update != '')&&(firmItem?.content?.date_update)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Дата обновления:</span> {firmItem?.content?.date_update}</div>:null}
            {(firmItem?.content?.innkpp != '')&&(firmItem?.content?.innkpp)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>ИНН/КПП:</span> {firmItem?.content?.innkpp}</div>:null}
            {(firmItem?.content?.ogrn != '')&&(firmItem?.content?.ogrn)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>ОГРН:</span> {firmItem?.content?.ogrn}</div>:null}
            {(firmItem?.content?.opfname != '')&&(firmItem?.content?.opfname)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Организационно-правовая форма:</span> {firmItem?.content?.opfname}</div>:null}
            {(firmItem?.content?.full != '')&&(firmItem?.content?.full)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Полное наименование:</span> {firmItem?.content?.full}</div>:null}
            {(firmItem?.content?.manager != '')&&(firmItem?.content?.manager)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Руководитель:</span> {firmItem?.content?.manager}</div>:null}
            {(firmItem?.content?.region != '')&&(firmItem?.content?.region)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Регион:</span> {firmItem?.content?.region}</div>:null}
            {(firmItem?.content?.addressline != '')&&(firmItem?.content?.addressline)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Адрес местонахождения:</span> {firmItem?.content?.addressline}</div>:null}
            {(firmItem?.content?.site != '')&&(firmItem?.content?.site)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Сайт:</span> <span className="breadcrumbslink" style={{cursor:'pointer'}} onClick={()=>window.open(firmItem?.content?.site,"_blank")}>{firmItem?.content?.site}</span></div>:null}
            </div>
    } 

    const onFirmClick = (id) => {

        setFirmDetails(firmDetails.map((o, i) => {
            if (o.id === id) {
                if ((o.content == null) && (firmLoader==null)) {
                    o.content = 'loader' 
                    dispatch(loadFirm(id))
                }
                return o; // stop searching
            }
            else return o
        }))

        

    }
    
   

    let images = []
    let imagesView = []
    
    if (eqItem?.old > 0)
    for(let i = 0; i <= eqItem.old-1; i++) {
        images.push(<div><img src={baseUrl+'/pic/?eid='+eqItem?.eq?.ID+'&count='+i} /></div>)
        imagesView.push(baseUrl+'/pic/?eid='+eqItem?.eq?.ID+'&count='+i)
    }
    else
    if ((eqItem?.pics?.length > 0) && (imagesView.length == 0))
        eqItem.pics.forEach( function callback (picsItem) { 
            images.push(<div><img src={baseUrl+'/pic/?id='+picsItem} /></div>)
            imagesView.push(baseUrl+'/pic/?id='+picsItem)
        })

        else {
        images.push(<div><img src={baseUrl+'/pic/?eid='+eqItem?.eq?.ID} /></div>)
        imagesView.push(baseUrl+'/pic/?eid='+eqItem?.eq?.ID)
        }

        const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);
        
        const gotoNext = () =>
        currentImageIndex + 1 < showFullScreenImage.images.length &&
        setCurrentIndex(currentImageIndex + 1);
        
        const [currentImageIndex, setCurrentIndex] = useState(0);

        function scrollElement(id) {
            var element = document.getElementById(id);
            element.scrollIntoView();
           }        
           const firmsList = useRef(null)
           const regionsList = useRef(null)
           
const setEqValue = (name, value) => {
    let eqItemCopy = {... eqItem}
    eqItemCopy.eq[name] = value
    dispatch(setEQ(eqItemCopy))
}

const setActivePrm = (id, value, name, type) => {
    if (type == 'prm')
    dispatch(setEQ({... eqItem, prm:eqItem?.prm?.map(prmItem => {
        if (prmItem.ID == id)
            prmItem[name] = value
        return prmItem
    } )})) 
    if (type == 'btp')
    dispatch(setBTP(btp?.map(btpItem => {
        if (btpItem.btp_id == id)
            btpItem[name] = value
        return btpItem
    } ))) 
    
}   

const ttx = () => {
    let content = []

    if( (eqItem?.prm?.length > 0) && (eiList))
        eqItem?.prm?.forEach( function callback (prmItem) {
            let prmbtp_id = btp?.filter(btpItem=>btpItem.btp_id==prmItem.PRM.ID)
            if(prmbtp_id!='')
            content.push(
            <div className={classes.headitemvalue} style={{paddingTop:'3px'}}>
                <div>
                    <Checkbox 
                        checked={!prmItem.disabled} 
                        //key = {BTPCount}
                        onClick={(e, checked) => setActivePrm(prmItem.ID, !e.target.checked, 'disabled', 'prm')}
                        style={{float:'left', marginTop:'4px'}}
                        color="#054C74"
                    />
                </div>
                <div style={{width:'200px', alignContent:'center', display:'grid'}}>{prmItem.PRM.NAME}</div>
                <div>
                    <TextField
                    style={{paddingLeft:'10px', width:'80px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    key={"standard-techSpec-value"}
                    label="от"
                    value={prmItem.VAL1}
                    disabled = {prmItem.disabled}
                    onChange={(e)=>setActivePrm(prmItem.ID, e.target.value, 'VAL1', 'prm')}
//                    onBlur={(e)=> setTechSpecTmp ({...techSpecTmp, value:e.target.value})  }
                    >
                    </TextField>   
                </div>
                <div>
                    <TextField
                    style={{paddingLeft:'10px', width:'80px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    key={"standard-techSpec-value"}
                    label="до"
                    value={prmItem.VAL2}
                    disabled = {prmItem.disabled}
                    onChange={(e)=>setActivePrm(prmItem.ID, e.target.value, 'VAL2', 'prm')}
//                    onChange={(e)=>setTsValue(e.target.value)}
//                    onBlur={(e)=> setTechSpecTmp ({...techSpecTmp, value:e.target.value})  }
                    >
                    </TextField>   
                </div>
                <div>
                    <TextField
                    style={{paddingLeft:'10px', width:'180px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    key={"standard-techSpec-showvalue"}
                    label="Отображать"
                    value={prmItem.LEVELS}
                    disabled = {prmItem.disabled}
                    onChange={(e)=>setActivePrm(prmItem.ID, e.target.value, 'LEVELS', 'prm')}
//                    onChange={(e)=>setTsValue(e.target.value)}
//                    onBlur={(e)=> setTechSpecTmp ({...techSpecTmp, value:e.target.value})  }
                    >
                    </TextField>   
                </div>     
                <div>
                    <TextField
                    size="small"
                    style={{paddingLeft:'10px', width:'180px'}}
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-techSpec"
                    select
                    label="Единица измерения"
                    defaultValue={prmItem.EI}
                    value={prmItem.EI}
                    disabled = {prmItem.disabled}
                    onChange={(e)=>setActivePrm(prmItem.ID, e.target.value, 'EI', 'prm')}
//                    value={techSpecTmp.id}
//                    onChange={setTechSpec}
                    >
                    {
                        
                        eiList?.filter(eiItem => eiItem.PRM == prmItem.PRM.EI).map((option)=> (
                        <MenuItem key={option.NAME} value={option.ID}>
                        {option.NAME}
                        </MenuItem>
                    ))
                    }
                    </TextField>                           
                </div>
            </div>
            )
        })

/////////////////////////////////////////////
    //if( (eqItem?.prm?.length > 0) && ())
        btp?.forEach( function callback (btpItem) {
            
            if (!eqItem?.prm?.find(({PRM}) => PRM.ID == btpItem.btp_id) )
            content.push(
            <div className={classes.headitemvalue} style={{paddingTop:'3px'}}>
                <div>
                    <Checkbox 
                        checked={!btpItem.disabled} 
                        //key = {BTPCount}
                        onClick={(e, checked) => setActivePrm(btpItem.btp_id, !e.target.checked, 'disabled', 'btp')}
                        style={{float:'left', marginTop:'4px'}}
                        color="#054C74"
                    />
                </div>                
                <div style={{width:'200px', alignContent:'center', display:'grid'}}>{btpItem.btp_name}</div>
                <div>
                    <TextField
                    style={{paddingLeft:'10px', width:'80px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    key={"standard-techSpec-value"}
                    label="от"
                    value={btpItem.VAL1}
                    disabled = {btpItem.disabled}
                    onChange={(e)=>setActivePrm(btpItem.btp_id, e.target.value, 'VAL1', 'btp')}
//                    onBlur={(e)=> setTechSpecTmp ({...techSpecTmp, value:e.target.value})  }
                    >
                    </TextField>   
                </div>
                <div>
                    <TextField
                    style={{paddingLeft:'10px', width:'80px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    key={"standard-techSpec-value"}
                    label="до"
                    value={btpItem.VAL2}
                    disabled = {btpItem.disabled}
                    onChange={(e)=>setActivePrm(btpItem.btp_id, e.target.value, 'VAL2', 'btp')}
//                    onChange={(e)=>setTsValue(e.target.value)}
//                    onBlur={(e)=> setTechSpecTmp ({...techSpecTmp, value:e.target.value})  }
                    >
                    </TextField>   
                </div>
                <div>
                    <TextField
                    style={{paddingLeft:'10px', width:'180px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    key={"standard-techSpec-showvalue"}
                    label="Отображать"
                    value={btpItem.LEVELS}
                    disabled = {btpItem.disabled}
                    onChange={(e)=>setActivePrm(btpItem.btp_id, e.target.value, 'LEVELS', 'btp')}
//                    onChange={(e)=>setTsValue(e.target.value)}
//                    onBlur={(e)=> setTechSpecTmp ({...techSpecTmp, value:e.target.value})  }
                    >
                    </TextField>   
                </div>     
                <div>
                    <TextField
                    size="small"
                    style={{paddingLeft:'10px', width:'180px'}}
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-techSpec"
                    select
                    label="Единица измерения"
                    defaultValue={btpItem.ei[0]}
                    value={btpItem.EI}
                    disabled = {btpItem.disabled}
                    onChange={(e)=>setActivePrm(btpItem.btp_id, e.target.value, 'EI', 'btp')}
//                    value={techSpecTmp.id}
//                    onChange={setTechSpec}
                    >
                    {
                        
                        btpItem.ei.map((option)=> (
                        <MenuItem key={option.NAME} value={option.ID}>
                        {option.NAME}
                        </MenuItem>
                    ))
                    }
                    </TextField>                           
                </div>
            </div>
            )
        })        




    return content    
}

const pictures = () => {

    let content = []

    if (eqItem?.old > 0)
    for(let i = 0; i <= eqItem.old-1; i++) {
        content.push(<div className={classes.picItem} onClick={()=>{
            
            if (delPic.type == 'old') {
                let picsArr = eqItem?.eq?.PIC_OLD.split(';')
                picsArr = picsArr.filter((option,key)=>key != delPic.id )?.join(';')
                
                dispatch(setEQ({... eqItem, old:(eqItem.old-1), eq:{... eqItem.eq, PIC_OLD:picsArr}}))
                
            }

            if (delPic.type == 'new') {
                dispatch(setEQ({... eqItem, pics:eqItem?.pics?.filter((option)=>option != delPic.id )}))
            }            

        }} onMouseOver={()=>{setDelPic({ ... delPic, id: i, type:'old'})}} onMouseLeave={()=>{setDelPic({ ... delPic, id: -1, type:''})}}>{((delPic.id == i)&&(delPic.type='old'))?delPic.content:null}<img style={{maxWidth:'160px',maxHeight:'160px'}} src={baseUrl+'/pic/?eid='+eqItem?.eq?.ID+'&count='+i}/></div>)
    }

    if (eqItem?.pics?.length > 0)
        eqItem.pics.map(picItem => {
            content.push(<div className={classes.picItem} onClick={()=>{
            
                if (delPic.type == 'old') {
                    let picsArr = eqItem?.eq?.PIC_OLD.split(',')
                    picsArr = picsArr.filter((option,key)=>key != delPic.id )?.join(',')
                    
                    dispatch(setEQ({... eqItem, old:(eqItem.old-1), eq:{... eqItem.eq, PIC_OLD:picsArr}}))
                    
                }
    
                if (delPic.type == 'new') {
                    dispatch(setEQ({... eqItem, pics:eqItem?.pics?.filter((option)=>option != delPic.id )}))
                }            
    
            }} onMouseOver={()=>{setDelPic({ ... delPic, id: picItem, type:'new'})}} onMouseLeave={()=>{setDelPic({ ... delPic, id: -1, type:''})}}>{((delPic.id == picItem)&&(delPic.type='new'))?delPic.content:null}<img style={{maxWidth:'160px',maxHeight:'160px'}} src={baseUrl+'/pic/?id='+picItem} /></div>)
        }) 


    return content
}
const addManufacture = (id, name) => {
    dispatch(setEQ({... eqItem, firms:eqItem?.firms?.concat([{ID:id, NAME:name, status:'new'}])}))
    сloseDialogClassifier()
}
        
const addClassifier = (id, name) => {
    dispatch(setEQ({... eqItem, ok:eqItem?.ok?.concat([{ID:id, NAME:name, status:'new'}])}))
    dispatch(setBTP(null))
    dispatch(loadBTP(eqItem?.ok?.concat([{ID:id, NAME:name, status:'new'}])?.map((okItem=>okItem.ID))))
    setDataPreparation(false)

    сloseDialogClassifier()
}

const addPic = (id) => {
    dispatch(setEQ({... eqItem, pics:eqItem?.pics?.concat([id])}))
    сloseDialogClassifier()
}

const submit = () => {
dispatch(setEQSave('saving'))
setDataPreparation(false)
eqItem['btp'] = btp
dispatch(saveEQEdit(eqItem?.eq?.ID, eqItem))
}

// Тут правим        
return  <div className={classes.paper}>

<Dialog
            className={classes.mainDialog}
        open={(dialogClassifier == null)?false:true}
        onClose={сloseDialogClassifier}
        handleClose={сloseDialogClassifier}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
      >
        <DialogTitle id="scroll-dialog-title">
        
        {(dialogClassifier == 'ok')?
        <TextField
                    size="small"
                    className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-sort"
                    select
                    label="Тип оборудования"
                    defaultValue="1"
                    value={owner}
                    onChange={(event, value) => setOwner(value.props.value)}
                    >
                    
                    <MenuItem key="production" value="1">Оборудование для добычи нефти и газа</MenuItem>
                    <MenuItem key="drilling" value="2">Буровое оборудование</MenuItem>
        </TextField>
        :(dialogClassifier == 'manufacture')?
        <input 
            type="text" 
            //onKeyDown={(e) => { if (e.key === 'Enter') search()}} 
            autocomplete="off" 
            onChange={e=>setSearchManufacture(e.target.value)} 
            className={classes.SearchManufacture} 
            id="search-manufacture" 
            placeholder="Найти производителя" 
            value={searchManufacture}
        /> 
        :(dialogClassifier == 'pictures')?
        <div>ВЫБОР ИЗОБРАЖЕНИЯ</div>
        :null
        }
        </DialogTitle>
        <DialogContent dividers="paper">
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          >

            {(dialogClassifier == 'ok')?
            <OkPanel owner={owner} action={addClassifier}/>
            :(dialogClassifier == 'manufacture')?
            <ManufacturesPanel manufactures={manufactures}/>
            :(dialogClassifier == 'pictures')?
            <PicPanel owner={owner} action={addPic}/>
            :null
            }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={сloseDialogClassifier}>Закрыть</Button>
        </DialogActions>
      </Dialog>      
               {(showFullScreenImage.images != null)? <Lightbox

                    mainSrc={showFullScreenImage.images[currentImageIndex]}
                    nextSrc={showFullScreenImage.images[(currentImageIndex + 1) % showFullScreenImage.images.length]}
                    prevSrc={showFullScreenImage.images[(currentImageIndex + showFullScreenImage.images.length - 1) % showFullScreenImage.images.length]}
                    onCloseRequest={() => {setShowFullScreenImage({open:false, images:null, name:null}); setCurrentIndex(0)}}
                    isOpen={showFullScreenImage.open}
                    onMovePrevRequest={gotoPrevious}
                    onMoveNextRequest={gotoNext}
                    images={showFullScreenImage.images}
                    currentIndex={currentImageIndex}

                    />:null}
            <PleaseRegisterDialog
                open={pleaseRegisterDialogOpen}
                onClose={pleaseRegisterDialogHandleClose}
            />      
        <SearchBar/>
        {(dataPreparation && eqItem!=null)?        
        <div className={classes.EQMain}>
            <div style={{display: 'inline-block', width: '100%'}}>     
                <div className={classes.headitemname} style={{paddingBottom:'10px', float:'left'}}>Изменен: {eqItem?.eq?.CHANGED}</div>
                {(id == 'new')?
                <div>
                    <IconButton style={{padding:'0', top:'-2px', color:'#054C74', float: 'right'}}
                    onClick={()=>setGetFromUrl({...getFromUrl, display:true})}
                    >
                        <SmartButtonIcon fontSize="large"/>
                    </IconButton>
                </div>
                :null}
            </div>             

            {(getFromUrl.display)?
                <div style={{display:"inline-flex"}}>
                <TextField
                            size="small"
                            style={{ width:'400px', marginRight:"10px"}}
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-url"
                            label="Ссылка на сайт производителя"
                            value={getFromUrl.url}
                            onChange={(e)=> setGetFromUrl({...getFromUrl, url:e.target.value})}
                            >
                            

                </TextField>
                <Button variant="contained" 
                        startIcon={<CloudDownloadIcon />}
                                                                                                                                                                            
                                                //disabled={submitLoader} 
                        
                        onClick={()=>{setDataPreparation(false); dispatch(setBTP(null)); dispatch(setLoadUrlEq(true));dispatch(loadFromUrl(getFromUrl.url))}}
                        style={{color: '#054C74', backgroundColor: '#fff', margin:'10px 0 30px 0'}}
                        size={'small'} 
                >
                    открыть
            </Button>                
            </div>
            :null}            
            <div>
                <TextField
                            size="small"
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-shifr"
                            label="Шифр"
                            value={eqItem.eq.SHIFR}
                            onChange={(e)=> setEqValue('SHIFR', e.target.value)}
                            >
                            

                </TextField>
            </div>        
            <div>
                <TextField
                        size="small"
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-name"
                        label="Наименование"
                        value={eqItem.eq.NAME}
                        onChange={(e)=> setEqValue('NAME', e.target.value)}
                            //onChange={setOwnerItem}
                        >
                            

                </TextField>        
            </div>
            <div>     
                <div className={classes.headitemname}>Отраслевой классификатор</div>
                {ok()}
                <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline', marginLeft:'5px'}} onClick={() => setDialogClassifier('ok')} >Добавить позицию классификатора</div>
            </div> 
            <div>
                <div className={classes.headitemname} ref={firmsList}>Изготовители</div>
                {firms()}
                <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline', marginLeft:'5px'}} onClick={() => setDialogClassifier('manufacture')}>Добавить изготовителя</div>
            </div>       
            <div>     
                <div className={classes.headitemname}>Технические характеристики</div>
                
                {ttx()}
            </div>    
                     
            <div style={{paddingTop:'10px'}}>
                <TextField
                        size="small"
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-name"
                        label="Технические характеристики в свободной форме"
                        value={eqItem.eq.DESCRIPTION}
                        multiline
                        rows={10}
                        onChange={(e)=> setEqValue('DESCRIPTION', e.target.value)}
                            //onChange={setOwnerItem}
                        >
                            

                </TextField>        
            </div>     

            <div style={{paddingTop:'10px'}}>
                <TextField
                        size="small"
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-name"
                        label="Описание"
                        value={eqItem.eq.COMMENT}
                        multiline
                        rows={10}
                        onChange={(e)=> setEqValue('COMMENT', e.target.value)}  
                            //onChange={setOwnerItem}
                        >
                            

                </TextField>        
            </div>     
            <div style={{paddingBottom:'20px'}}>     
                <div className={classes.headitemname}>Изображения</div>
                
                {pictures()}
                <div style={{clear:'both'}}><div style={{ cursor:'pointer',  color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline', marginLeft:'5px'}} onClick={() => setDialogClassifier('pictures')}>Добавить изображение</div></div>
            </div>  
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    {
                    (eqSave != 'saving')?
                    <Button variant="contained" 
                        //disabled={submitLoader} 
                        onClick={submit}  
                        style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                        size={'large'} 
                    >
                        Сохранить
                    </Button>
                    :        <div style={{padding:'16px'}}><Loader/></div> 
                    }
                </Grid>
            </Grid>              

        </div>:
        <div style={{padding:'100px'}}><Loader/></div> }

        </div>

}