import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import {getProfile, getAllTariffs, loadAllTariffs} from "../../redux/reducers/user-profile-personal-reducer";
import CheckIcon from '@mui/icons-material/Check';
import Grid from "@material-ui/core/Grid";
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Helmet } from "react-helmet";
import PleaseLoginDialog from "../PleaseLoginDialog/PleaseLoginDialog.jsx";  
import {useDialogLogin} from "../../hooks/useDialogLogin.js";

const useStyles = makeStyles((theme) => ({
  paper: {
      marginTop: '54px',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 'auto',
      width: '80%',
      ['@media (max-width:1200px)']: { 
          width: '-webkit-fill-available',
          paddingRight: '5px',
          paddingLeft: '5px',
          paddingBottom:'20px',
          height:'auto'
      },
  
  },
  chapterName: {
      fontSize:'30px', 
      textAlign: 'center',
      fontWeight: 400,
      marginBottom:'20px'
  },
  top_tabs: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '50px',
      "& :last-child": {
          borderRadius: '0 3px 3px 0'
        },
        "& :first-child": {
          borderRadius: '3px 0 0 3px'
        },          
  },
  top_tabs_item: {
      width: '50%',
      height: '48px',
      boxSizing: 'border-box',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',

      border: '1px solid #eff0f4',
      cursor: 'pointer',
  },
  div_table: {
      display: 'table',
      width: '-webkit-fill-available',
      backgroundColor: '#fafafa',
      border: '1px solid #666666',
      borderSpacing: '5px',
      marginLeft:'200px'
    },
  div_table_row: {
      display: 'table-row',
      width: 'auto',
      clear: 'both',
      fontWeight: 400,
      
    },
  div_table_col: {
      fontSize:'20px',
      float: 'left',
      display: 'table-column',
      width: '33%',
      padding:'10px 0 10px 0'
      
    },

  lengthPanelSelectedItem: {
      textAlign: 'center', 
      background: '#ba0000',
      color:'#fff', 
      margin:'10px', 
      padding:'10px',
      cursor: 'pointer',
      border: '1px solid #fff',
  },

  lengthPanelItem: {
      textAlign: 'center', 
      color:'#666', 
      margin:'10px', 
      padding:'10px',
      border: '1px solid #666',
      cursor: 'pointer'
  },      
  first_price_block: {
      fontSize:'20px',
      float: 'left',

      
      padding:'10px 0 10px 20px',
      marginLeft:'220px',
      fontWeight: 400,
      ['@media (max-width:1000px)']: { 
          marginLeft: '0px',
          float:'none'
      },        
      ['@media (max-width:470px)']: { 
          padding:'10px 0 10px 10px',
      },        
    },
    price_block: {
      fontSize:'20px',
      float: 'left',
      

      padding:'10px 0 10px 20px',
      
      fontWeight: 400,
      ['@media (max-width:1000px)']: { 
          
          float:'none'
      },   
      ['@media (max-width:470px)']: { 
          padding:'10px 0 10px 20px',
      },                
    },
  price_name: {
      fontWeight: 400,
      lineHeight:'45px',
      fontSize:'20px',
      ['@media (max-width:800px)']: { 
          fontSize:'initial',
      },        
      ['@media (max-width:600px)']: { 
          fontSize:'12px',
      },   
      ['@media (max-width:470px)']: { 
          fontSize:'12px',
      },               
  },
  price_value: {
    fontWeight: 400,
    lineHeight:'45px',
    fontSize:'20px',
    ['@media (max-width:800px)']: { 
        fontSize:'initial',
    },        
    ['@media (max-width:600px)']: { 
        fontSize:'16px',
    },   
    ['@media (max-width:470px)']: { 
        fontSize:'16px',
    },               
},  
  lengthPanel:{
      width:'200px', 
      float:'left', 
      position:'absolute', 
      paddingTop:'60px',
      ['@media (max-width:1000px)']: { 
          float:'none',
          position:'unset',
          paddingTop:'0px',
          width:'auto'            
      },        
      ['@media (max-width:600px)']: { 

      },         
  },
  priceMobile: {
      ['@media (max-width:440px)']: { 
          display:'none'
      },  
      ['@media (max-width:600px)']: { 
          fontSize:'12px',
      },         
  },
  button: {
      color: '#fff',
      backgroundColor: '#054C74',
      '&:hover': {
          backgroundColor: '#E6E6E6',
          color: '#1A1A1A',
      },        
  },    



}))


export const TariffsPanel = ({tariffsList, selectedTab, ...otherProps}) => {
  const classes = useStyles()
  const [selectedTarif, setSelectedTarif] = useState(null);
  const [lengthPanel, setLengthPanel] = useState([]);
  const userProfile = useSelector(getProfile)

  

  useEffect(() => {
      if ((tariffsList != null) && (selectedTarif == null))
      setSelectedTarif(tariffsList?.filter(tarifItem=>tarifItem.TYP == selectedTab)?.sort((a, b) => a.DURATION - b.DURATION)[0])

      if ((tariffsList != null)) 
      setSelectedTarif(tariffsList?.filter(tarifItem=>tarifItem.TYP == selectedTab)?.sort((a, b) => a.DURATION - b.DURATION)[0])
      

  }, [selectedTab, tariffsList])


  useEffect(() => {
    if ((tariffsList != null)) 
      setLengthPanel(tariffsList?.filter(tarifItem=>tarifItem.TYP == selectedTab).sort((a, b) => a.DURATION - b.DURATION).map(tarifItem=><div className={(tarifItem == selectedTarif)?classes.lengthPanelSelectedItem:classes.lengthPanelItem} onClick={()=>setSelectedTarif(tarifItem)}>{tarifItem.DURATION} дней</div>))      
  
    }, [selectedTarif])

  var thousandSeparator = function(str) {
      var parts = (str + '').split('.'),
          main = parts[0],
          len = main.length,
          output = '',
          i = len - 1;
      
      while(i >= 0) {
          output = main.charAt(i) + output;
          if ((len - i) % 3 === 0 && i > 0) {
              output = ' ' + output;
          }
          --i;
      }
  
      if (parts.length > 1) {
          output += '.' + parts[1];
      }
      return output;
  };    


  

  
  const {
      open: pleaseLoginDialogOpen,
      handleOpen: pleaseLoginDialogHandleOpen,
      handleClose: pleaseLoginDialogHandleClose
  } = useDialogLogin()
  return  (tariffsList != null)
          ?<div>
              <PleaseLoginDialog
              open={pleaseLoginDialogOpen}
              onClose={pleaseLoginDialogHandleClose}
              />   
              <div className={classes.lengthPanel}>
              {lengthPanel}
              </div>
              <div style={{display:'-webkit-box'}}>
                  <div className={classes.first_price_block}  >
                     <div className={classes.price_name}><br /></div>
                     <div className={classes.price_name} >Стоимость</div>
                     <div className={classes.price_name}>Поиск оборудования</div>
                     <div className={classes.price_name}>Поиск по параметрам</div>
                     <div className={classes.price_name}>Подбор аналогов</div>
                     <div className={classes.price_name}>Подбор отечественных аналогов</div>
                     <div className={classes.price_name}>Контакты производителей</div>
                  </div>
                  <div   className={classes.price_block} >
                       <div className={classes.price_name} style={{fontWeight:'bold'}}>Бесплатно</div>
                       <div className={classes.price_value} style={{fontWeight:'bold'}}>0 ₽ </div>
                       <div className={classes.price_name}><CheckIcon sx={{color: "green"}}/></div>
                       <div className={classes.price_name}>–</div>
                       <div className={classes.price_name}>–</div>
                       <div className={classes.price_name}>–</div>  
                       <div className={classes.price_name}>–</div>  
                  </div>
                  <div  className={classes.price_block}>
                  <div className={classes.price_name} style={{fontWeight:'bold'}}>Платно</div>
                  {(selectedTarif!=null)?
                      <div className={classes.price_value} style={{fontWeight:'bold'}}>{thousandSeparator(selectedTarif.PRICE)} ₽<span className={classes.priceMobile}> ({(selectedTarif.PRICE/selectedTarif.DURATION).toFixed(2)} ₽ | день)</span></div>
                      :<div className={classes.price_name} style={{fontWeight:'bold'}}></div>
                      }
                      <div className={classes.price_name}><CheckIcon sx={{color: "green"}}/></div>
                      <div className={classes.price_name}><CheckIcon sx={{color: "green"}}/></div>
                      <div className={classes.price_name}><CheckIcon sx={{color: "green"}}/></div>
                      <div className={classes.price_name}><CheckIcon sx={{color: "green"}}/></div>
                      <div className={classes.price_name}><CheckIcon sx={{color: "green"}}/></div>
                  </div>     
              </div>
              <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                      <IconButton variant="contained" 
                       //onClick={submit} 
                       disabled={((userProfile?.user?.role == 'individual') && (selectedTab == 'entity'))?true:(((userProfile?.user?.role == 'entity')||(userProfile?.user?.role == 'supplier')) && (selectedTab == 'individual'))?true:false}
                       sx={{
                          color: '#fff',
                          backgroundColor: '#054C74',
                          borderRadius: 3,
                          fontSize:'16px',
                          border: "1px solid",
                          '&:hover': {
                              backgroundColor: '#E6E6E6',
                              color: '#1A1A1A',
                          }
                          
                      }}
                      onClick={()=>(userProfile?.user)?window.open('/payorder/new/?tariff='+selectedTarif.ID,"_self"):pleaseLoginDialogHandleOpen()}
                      size={'large'} className={classes.button}>
                          <ShoppingCartIcon/>
                          <span style={{marginLeft:'10px'}} >Купить</span>
                      </IconButton>
                  </Grid>
              </Grid>

          </div>
          :<div></div>
          
}