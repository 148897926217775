import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";
import {HeaderPrivate} from "../components/HeaderPrivate/HeaderPrivate";
import {FooterPublic} from "../components/FooterPublic/FooterPublic";
import {Container} from "../components/Container/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    main: {
        minHeight: 'calc(100vh - 272px)',
        paddingLeft:'0px',
        paddingRight:'0px',
        marginLeft:'unset',
        marginRight:'unset',
        maxWidth:'unset'        
    }
}));




export const HomePrivateLayout = ({children, ...otherProps}) => {
    const classes = useStyles()



//alert(children.props.toString());
    return <Box>
        <HeaderPrivate {...otherProps}>
            <div></div>
        </HeaderPrivate>
        <Container component={'main'} className={classes.main} {...otherProps}>
            {children}
        </Container>
        <FooterPublic>
            <div></div>
        </FooterPublic>
    </Box>
}
