import React, {useEffect, useState} from 'react';

import {Navigate , Route, Routes} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn} from "../../redux/reducers/login-reducer";

import {adminRoutes, notFoundRoute, privateRoutes, publicRoutes} from "../../router";
import {RouteNames} from "../../router/consts";
import {getProfile, loadProfileData} from "../../redux/reducers/user-profile-personal-reducer";


const getPublicRouteKey = (id) => `public-route-${id}`
const getPrivateRouteKey = (id) => `private-route-${id}`
const getAdminRouteKey = (id) => `admin-route-${id}`

export const AuthRouter = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        if((!user)&&(isLoggedIn))
            dispatch(loadProfileData)
    }, [])
    const isLoggedIn = useSelector(getLoggedIn)
    const {user} = useSelector(getProfile)


//    alert (window.location.pathname)
//    privateRoutes.map((route, id) => console.log(route))
//    console.log(publicRoutes.map((route, id) => <Route key={getPublicRouteKey(id)} {...route}/>))
   // if (user != null)
   // if (!user.is_activated) return <Navigate to={'/activate'}/>
//   {(isLoggedIn && user?.tf && user?.checkcode && window.location.pathname!='/login' && window.location.pathname!='/profile' && window.location.pathname!='/profile/')?
//   <Route path="/redirect" element={ <Navigate to={RouteNames.LOGIN} /> }/>:null }


    return <Routes>



        {publicRoutes.map((route, id) => <Route key={getPublicRouteKey(id)} {...route}/>)}


        {isLoggedIn ? privateRoutes.map((route, id) => <Route key={getPrivateRouteKey(id)} {...route}/>)
            : <Route path="/redirect" element={ <Navigate to={RouteNames.LOGIN} /> }/>
        }

        {isLoggedIn  ?
            adminRoutes.map((route, id) => (<Route key={getAdminRouteKey(id)} {...route}/>)) :
            null}        

        <Route {...notFoundRoute}/>
        <Route render={() => <Navigate to={RouteNames.NOT_FOUND}/>}/>

    </Routes>
}