import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import AdvancedSearch from "../components/AdvancedSearch/AdvancedSearch"
import {getProfile, loadProfileData} from "../redux/reducers/user-profile-personal-reducer";
import Operator from "../components/Operator/Operator";
import Firms from "../components/Firms/Firms";
import {Tabs} from "../components/TabsOperator/TabsOperator";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import {OkEdit} from "../containers/OkEdit/OkEdit"
import {GeoEdit} from "../containers/GeoEdit/GeoEdit"
import {IVEdit} from "../containers/IVEdit/IVEdit"
import { SpecPanel } from '../containers/SpecPanel/SpecPanel';
import { PicEdit } from '../containers/PicEdit/PicEdit';
import { TariffEdit } from '../containers/TariffEdit/TariffEdit';
import Users from '../components/Users/Users';
import Purchases from '../components/Purchases/Purchases';
import StatisticsQuery from '../components/StatisticsQuery/StatisticsQuery';
import StatisticsUser from '../components/StatisticsUser/StatisticsUser';


const useStyles = makeStyles((theme) => ({
    paper: {
        margin: '24px 5px 20px 10px',
 //       display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginBottom: ''
    },
    chapterName: {
        fontSize:'30px', 
        textAlign: 'center',
        fontWeight: 400
    },

}))
export const OperatorPage = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)
    let {user, loading} = useSelector(getProfile)




    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        
    }
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
        
    }

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
    }

    const tabsConfig = [
        {value: 'Equipment', label: 'Оборудование', onclick:null, component: <Operator/>},
        {value: 'Manufacturing', label: 'Производители', onclick:null, component: <Firms/>},
        {value: 'Users', label: 'Пользователи', onclick:null, component: <Users/>},
        {value: 'Reference', label: 'Справочники', onclick:handleClick, menuComponent:{okp:<OkEdit ok1={true} owner={1}/>, geo:<GeoEdit />, iv:<IVEdit/>, spec:<SpecPanel/>,pics:<PicEdit/>,tariffs:<TariffEdit/>}},
        {value: 'Purchases', label: 'Заказы', onclick:null, component: <Purchases/>},
        {value: 'Statistics', label: 'Статистика', onclick:handleClick2, menuComponent:{users:<StatisticsUser />, querys:<StatisticsQuery/>}},
        //{value: 'Users', label: 'Gjk', onclick:null, component: <Firms/>},
    ]
    
    if (isLoggedIn && user?.role == 'operator') 
    return <HomePrivateLayout>
                    <div className={classes.paper}>
                        
                        <Tabs tabs={tabsConfig} />

                        <Menu anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                            transformOrigin={{vertical: "top", horizontal: "center"}}
                            getContentAnchorEl={null}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{}}
                        >
                            <MenuItem onClick={handleClose} component={Link} to={'/operator/?tab=Reference&component=okp'}>
                                <ListItemText className="headerlinkImageText" primary={"Отраслевой классификатор"}/>
                            </MenuItem>
                            <MenuItem component={Link} onClick={handleClose}  to={'/operator/?tab=Reference&component=geo'}>
                                <ListItemText className="headerlinkImageText" primary={"Географический справочник"}/>
                            </MenuItem>                            
                            <MenuItem component={Link} onClick={handleClose}  to={'/operator/?tab=Reference&component=iv'}>
                                <ListItemText className="headerlinkImageText" primary={"Измеряемые величины"}/>
                            </MenuItem>                            
                            <MenuItem component={Link} onClick={handleClose}  to={'/operator/?tab=Reference&component=spec'}>
                                <ListItemText className="headerlinkImageText" primary={"Технические характеристики"}/>
                            </MenuItem>                            
                            <MenuItem component={Link} onClick={handleClose}  to={'/operator/?tab=Reference&component=pics'}>
                                <ListItemText className="headerlinkImageText" primary={"Изображения"}/>
                            </MenuItem>                            
                            <MenuItem component={Link} onClick={handleClose}  to={'/operator/?tab=Reference&component=tariffs'}>
                                <ListItemText className="headerlinkImageText" primary={"Тарифы"}/>
                            </MenuItem>                            

                        </Menu>
                        <Menu anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                            transformOrigin={{vertical: "top", horizontal: "center"}}
                            getContentAnchorEl={null}
                            anchorEl={anchorEl2}
                            open={Boolean(anchorEl2)}
                            onClose={handleClose}
                            style={{}}
                        >
                            <MenuItem  component={Link}  onClick={handleClose} to={'/operator/?tab=Statistics&component=users'}>
                                <ListItemText className="headerlinkImageText" primary={"Пользователи"}/>
                            </MenuItem>
                            <MenuItem component={Link} onClick={handleClose}  to={'/operator/?tab=Statistics&component=querys'}>
                                <ListItemText className="headerlinkImageText" primary={"Запросы"}/>
                            </MenuItem>                            

                        </Menu>

                    </div>
        </HomePrivateLayout>
        else
        return  null  
}