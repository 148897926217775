import React, { useEffect, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import { Helmet } from 'react-helmet';
import PayOrder from '../components/PayOrder/PayOrder';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: ''
    },

}))

export const PayOrderPage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)
    const [eqpMeta, setEqpMeta] = useState({title:'Оплата', description:'Оплата доступа к каталогу нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. Это принципиально новый инструментарий, позволяющий находить нефтегазовое оборудование, в том числе по основным эксплуатационным характеристикам, и осуществлять автоматический подбор однотипного нефтегазового оборудования для конкретной позиции Каталога', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    if (!isLoggedIn) 
    return <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet> 
                    <div className={classes.paper}>
                     Оплата тарифа доступна после авторизации
                    </div>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout>
                <Helmet>
                    <title>{eqpMeta.title}</title>
                    <meta name="description" content={eqpMeta.description}/>
                    <meta name="keywords" content={eqpMeta.keywords}/>
                </Helmet> 
                    <div className={classes.paper}>
                     <PayOrder/>
                    </div>                  
                </HomePrivateLayout>    
}

