import { EQPAPI } from "../../api/api";

const SET_RESULT = 'feedbackReducer/SET_RESULT';
const SET_ERROR = "feedbackReducer/SET_ERROR";
const SET_LOADER = 'feedbackReducer/SET_LOADER';


const initialState = {
    result: null,
    error: null,
    loader: false,
  };


  export const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_RESULT:
        return {
            ...state,
            result: action.result
        }
      case SET_LOADER:
        return {
              ...state,
              loader: action.loader
            }              

        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }

        default:
            return state;
    }
};


export const getResult = (state) => state.feedbackReducer.result; 
export const getError = (state) => state.feedbackReducer.error; 
export const getLoader = state => state.feedbackReducer.loader

export const setResult = (result) => ({type: SET_RESULT, result})
export const setError = (error) => ({type: SET_ERROR, error})
export const setLoader = (loader) => ({type: SET_LOADER, loader})

export const sendFeedback = (feedBack) => async dispatch => {
  console.log(feedBack);
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    let error = {}
    if (!feedBack['feedBackEmail']) {
        error = {...error, feedBackEmail: {text: 'Укажите адрес электронной почты'}}
        error = {...error, iserror: true}
    }
    if (!feedBack['feedBackSubject']) {
        error = {...error, feedBackSubject: {text: 'Укажите тему обращения'}}
        error = {...error, iserror: true}
    }
    if (!feedBack['feedBackText']) {
      error = {...error, feedBackText: {text: 'Укажите текст обращения'}}
      error = {...error, iserror: true}
    }
    if(reg.test(feedBack['feedBackEmail']) == false)  {
      error = {...error, feedBackEmail: {text: 'Укажите корректный e-mail'}}
      error = {...error, iserror: true}
    }
    if (!feedBack['feedBackCapcha']) {
      error = {...error, feedBackCapcha: {text: 'Подтвердите, что вы не робот'}}
      error = {...error, iserror: true}
    }
    dispatch(setError(error))
    console.log(error);
    if (!error?.iserror) {
      try {
          const response = await EQPAPI.sendFeedback(feedBack)
          
          if (response.status === 200 && response.data.length !== 0) {
              const result = response.data
              dispatch(setResult(result))
          }


      }
      catch (error) {
          error = {...error, userCode: {text: 'Что-то пошло не так...'}}
          error = {...error, iserror: true}
      }    
    } 
}
