import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DataGrid,
  ruRU,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';


export const CompareTable = ({ data, columns }) => {
   // const { redirect } = useRedirect();
  
    //const onChartClick = async (e) => {
    //  const chartId = e.row.id;
    //  redirect(RouteNames.CHART_DETAIL, { chartId });
    //};

    return (

  
      <Paper  style={{ height: "100%", width: "100%", paddingTop:'20px' }}>
        <DataGrid
          columns={columns}
          rows={data}
          
          density={"standart"}
          autoHeight={true}
          //headerHeight={123}
          localeText={
            ruRU?.props?.MuiDataGrid?.localeText ||
            ruRU?.components?.MuiDataGrid?.defaultProps?.localeText
          }
          disableSelectionOnClick
          disableColumnMenu
          cell--textRight
        />
      </Paper>

    );
  };