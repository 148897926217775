import React, { useEffect, useMemo, useState } from "react";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {getLoggedIn, getTariff} from "../redux/reducers/login-reducer";
import {Promo} from "../components/Promo/Promo";
import {PromoOkPanel} from "../components/PromoOkPanel/PromoOkPanel";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";

import Loader from "@material-ui/core/CircularProgress";



export const HomePage = () => {



  const isLoggedIn = useSelector(getLoggedIn)
  const tariff = useSelector(getTariff)
  
  const [eqpMeta, setEqpMeta] = useState({title:'Импортозамещение и подбор нефтегазового оборудования', description:'Это Каталог нефтегазового оборудования, ориентированный на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. Это принципиально новый инструментарий, позволяющий находить нефтегазовое оборудование, в том числе по основным эксплуатационным характеристикам, и осуществлять автоматический подбор однотипного нефтегазового оборудования для конкретной позиции Каталога', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});

    if (!isLoggedIn)        
    return  <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>   
                 
              <Promo/>
              <PromoOkPanel/>
            </HomePublicLayout>
    else
    return  <HomePrivateLayout>
          <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>
              <Promo/>
              <PromoOkPanel/>
            </HomePrivateLayout>

        
}