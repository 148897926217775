import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import {    Link  } from "react-router-dom";
import {ProfileMenu} from "../../components/ProfileMenu/ProfileMenu";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Menu from '@material-ui/icons/Menu';
import Bookmark from '@material-ui/icons/BookmarksOutlined';
import Star from '@material-ui/icons/StarOutline';
import {useDialog} from "../../hooks/useDialog.js";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";
import {useDispatch, useSelector} from "react-redux";
import {getProfile, loadProfileData} from "../../redux/reducers/user-profile-personal-reducer";
import {logout, getLoggedIn, getTariff, setTariff} from "../../redux/reducers/login-reducer";
import { useLocation } from 'react-router-dom'
import Badge from "@material-ui/core/Badge";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { Tooltip } from "@material-ui/core";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: 40,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderBottom: '2px solid #054C74',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        overflow: 'hidden',
        padding: '0',
        margin: '0'
    },
    grow: {
        width: '40px',
        flexGrow: 1
    },
    customBadge: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '-9px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },
 


}))



export const HeaderPrivate = ({children, av} ) => {


    let {user, loading} = useSelector(getProfile)
    const isLoggedIn = useSelector(getLoggedIn)
    const isTariff = useSelector(getTariff)
    const dispatch = useDispatch()
    
    useEffect(() => {

    if (av!=undefined)
    //alert(av)
    dispatch(loadProfileData)

    }, [av])  

    

    

    useEffect(() => {
            if ((!isTariff) &&(user?.tariff == 1))
                dispatch(setTariff(true))
            if ((isTariff) &&(user?.tariff == 0))
                dispatch(setTariff(false))

        }, [isTariff, user])  


    

    useEffect(() => {
        // if(!user)
        // dispatch(loadProfileData)
    }, [])

    const logOut = () => {
        dispatch(logout())
    }

    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()

    const classes = useStyles()

    const location = useLocation();
    
    if (location.pathname != '/activate')
    if (user != null)
    if (!user.is_activated) return <Navigate to={'/activate'}/>


    const openFavorite = () => {
              window.open('/eqpsearch/favorite/',"_self");
      }

      const openCompare = () => {
        window.open('/compare/',"_self");
}      

    const openOperator = () => {
        window.open('/operator/',"_self");
    }  
    if (isLoggedIn && user?.tf && user?.checkcode && window.location.pathname!='/login' && window.location.pathname!='/profile' && window.location.pathname!='/profile/')
        return <Navigate to={'/login'}/>


    return <AppBar position="fixed" elevation={0} className={classes.appBar} style={{minWidth:'375px'}} >
            <PleaseRegisterDialog
                open={pleaseRegisterDialogOpen}
                onClose={pleaseRegisterDialogHandleClose}
            />        
        <div className="toolbar">
            <Link to="/" className="menu left"><img src="/images/importzamena.svg"/></Link>
            <Link to="/about" className="link headerlink left notablet">О нас</Link>
            <Link to="/tariffs" className="link headerlink left notablet">Тарифы</Link>
            <Link to="/contacts" className="link headerlink left notablet">Контакты</Link>

            <ProfileMenu firstName={user?.first_name} lastName={user?.last_name} onLogOutButtonClick={logOut} loading={loading} photo={user?.photo} />
            <div style={{'width':'20px', 'height':'24px', 'float':'right'}}></div>
            <a onClick={()=>(!isTariff)?pleaseRegisterDialogHandleOpen():openFavorite()} className="link headerlinkImage right">
                <Badge badgeContent={user?.favorite} className={classes.customBadge} style={{marginLeft:'10px', marginRight:'10px',}}>
                    <Star style={{color:'#868686'}}/>
                    <div className="headerlinkImageText nomobile" style={{lineHeight:"30px", float:"right", position: "relative", top: "-3px"}}>Избранное</div>
                </Badge>
            </a>
            <a onClick={()=>(!isTariff)?pleaseRegisterDialogHandleOpen():openCompare()} className="link headerlinkImage right">
                <Badge badgeContent={user?.comparing} className={classes.customBadge} style={{marginLeft:'10px', marginRight:'10px',}}>
                    <Tooltip title={<span style={{ fontSize:'14px'}}>Добавить в сравнение</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
                    <Bookmark style={{color:'#868686'}}/>
                    </Tooltip>
                    <div className="headerlinkImageText nomobile" style={{lineHeight:"30px", float:"right", position: "relative", top: "-3px"}}>Сравнить</div>
                </Badge>
            </a>
            <Link to="/help" className="link headerlinkImage right">
                <Tooltip title={<span style={{ fontSize:'14px'}}>Помощь</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
                    <HelpOutlineIcon style={{color:'#868686'}}/>
                </Tooltip>
                <div className="headerlinkImageText nomobile" style={{lineHeight:"30px", float:"right", position: "relative", top: "-3px"}}>Помощь</div>
            </Link>
            {(user?.role == 'operator') ?
            <a onClick={()=>openOperator()} className="link headerlinkImage right">
                    <MiscellaneousServicesIcon style={{color:'#868686', marginRight:'2px'}}/>
                    <div className="headerlinkImageText nomobile" style={{lineHeight:"30px", float:"right", position: "relative", top: "-3px"}}>Администрирование</div>
            </a>
            :null
            }


            <div className={classes.grow} variant="dense"/>
            {children}
        </div>
    </AppBar>
}