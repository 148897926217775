import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export const Notification = ({open, message, severity, onClose}) => {
    return <Snackbar open={Boolean(open)} autoHideDuration={6000} onClose={onClose}>
        <Alert onClose={onClose} severity={severity}>
            {
                Array.isArray(message) ? message.map(el => <>{el}<br/></>) :
                    typeof message === 'object' ? Object.values(message).map(el => <>{el}<br/></>) :
                        message
            }
        </Alert>
    </Snackbar>
}