import {AuthAPI} from "../../api/api";

const SET_LOGIN = 'recoveryReducer/SET_LOGIN';
const SET_CODE = 'recoveryReducer/SET_CODE';
const SET_RECOVER = 'recoveryReducer/SET_RECOVER';
const SET_ERROR = 'recoveryReducer/SET_ERROR';
const SET_SENDMAIL= 'recoveryReducer/SET_SENDMAIL';
const SET_PASSWORD= 'recoveryReducer/SET_PASSWORD';
const SET_COMPLEET= 'recoveryReducer/SET_COMPLEET';


const initialState = {
    login: '',
    password: '',
    code: '',
    isRecovery: false,
    isSendmail: false,
    error: null,
    isCompleet: false
}

export const recoveryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RECOVER:
            return {
                ...state,
                isRecovery: action.isRecovery
            }
        case SET_SENDMAIL:
            return {
                ...state,
                isSendmail: action.isSendmail
            }            
        case SET_LOGIN:
            return {
                ...state,
                login: action.login
            }
        case SET_COMPLEET:
            return {
                ...state,
                isCompleet: action.isCompleet
            }

        case SET_PASSWORD:
            return {
                ...state,
                password: action.password
            }            
        case SET_CODE:
            return {
                ...state,
                code: action.code
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }

        default:
            return state;
    }
}

export const getLogin = state => state.recoveryReducer.login
export const getPassword = state => state.recoveryReducer.password
export const getCode = state => state.recoveryReducer.code
export const getRecovery = state => state.recoveryReducer.isRecovery
export const getError = state => state.recoveryReducer.error
export const getSendMail = state => state.recoveryReducer.isSendmail
export const getCompleet = state => state.recoveryReducer.isCompleet



export const setLogin = (login) => ({type: SET_LOGIN, login})
export const setPassword = (password) => ({type: SET_PASSWORD, password})
export const setCode = (code) => ({type: SET_CODE, code})
export const setRecovery = (isRecovery) => ({type: SET_RECOVER, isRecovery})
export const setError = (error) => ({type: SET_ERROR, error})
export const setSendmail = (isSendmail) => ({type: SET_SENDMAIL, isSendmail})
export const setCompleet = (isCompleet) => ({type: SET_COMPLEET, isCompleet})

export const recoveryChangePassword = (code, password) => async dispatch => {
    const response = await AuthAPI.recoveryChangePassword(code, password)
    if (response.status === 201) {
        dispatch(setPassword(password))
        dispatch(setCompleet(true))
    } else {
        //dispatch(setPassword('-1'))
        dispatch(setError({password: {text: response?.data?.errormessage}}))
    }
}



export const recoveryCheckCode = (code) => async dispatch => {
    const response = await AuthAPI.recoveryCheckCode(code)
    if (response.status === 200) {
        dispatch(setCode(code))
        dispatch(setSendmail(true))
    } else {
        dispatch(setSendmail(true))
        dispatch(setCode('-1'))
        dispatch(setError({message: {text: 'Эта ссылка для восстановления пароля недействительна'}}))
    }
}


export const recoveryCheckLogin = (username) => async dispatch => {
    try {
        let error = {}
        if (!username) {
        error = {...error, login: {text: 'Укажите адрес электронной почты'}}
        dispatch(setError(error))
        dispatch(setPassword(''))
        }
        else {
        const response = await AuthAPI.recoveryCheckLogin(username)
        if (response.status === 200) {
            dispatch(setSendmail(true))
        } else {
            dispatch(setError({login: {text: 'Пользователь с таким адресом не найден'}}))
            }
        }

        } catch (error) {
            dispatch(setError({password: {text: error?.response?.data?.detail}}))
        }
}
    

