import React, { useEffect, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',


    },

    cardImg: {
        ['@media (max-width:550px)']: { 
            width: '-webkit-fill-available',
            padding: '5px'
            }           

    }

}))

export const PMPage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)
    const [eqpMeta, setEqpMeta] = useState({title:'Способы и правила оплаты - Импортозамещение и подбор нефтегазового оборудования', description:'Это Каталог нефтегазового оборудования, ориентированный на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. Это принципиально новый инструментарий, позволяющий находить нефтегазовое оборудование, в том числе по основным эксплуатационным характеристикам, и осуществлять автоматический подбор однотипного нефтегазового оборудования для конкретной позиции Каталога', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    if (!isLoggedIn) 
    return <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet> 
                    <div className={classes.paper}>
                    <PMContent/>
                    </div>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout>
                <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/payment-methods"} rel="canonical"></link>
    </Helmet> 
                    <div className={classes.paper}>
                     <PMContent/>
                    </div>                  
                </HomePrivateLayout>    
}

const PMContent = () => {
    const classes = useStyles()


    return  <div className="App-page">
                <h1 className="App-h1">СПОСОБЫ И ПРАВИЛА ОПЛАТЫ</h1>
                    <div className="App-page-paragraph-name">Правила оплаты</div>
                    <p>К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide, НСПК МИР.</p>
                    <p>Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите способ оплаты: банковской картой.</p>
                    <p>При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты:</p>
                    <ul>
                        <li>1.	тип карты</li>
                        <li>2.	номер карты</li> 
                        <li>3.	срок действия карты (указан на лицевой стороне карты)</li>
                        <li>4.	Имя держателя карты (латинскими буквами, точно также как указано на карте)</li>
                        <li>5.	CVC2/CVV2 код</li>
                    </ul>
                    <img src="/images/card.png"  alt="Оплата тарифа картой" className={classes.cardImg}/>
                    <p>Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.</p>
                    <p>Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным сертификатом безопасности PCI DSS. Передача информации происходит с применением технологии шифрования SSL.  Эта информация недоступна посторонним лицам.</p>
                    <div className="App-page-paragraph-name">Отказ от услуги</div>
                    <p>Право потребителя на расторжение договора об оказании услуги регламентируется статьей 32 федерального закона «О защите прав потребителей»</p>
                    <ul>
                        <li>Потребитель вправе расторгнуть договор об оказании услуги в любое время, уплатив исполнителю часть цены пропорционально части оказанной услуги до получения извещения о расторжении указанного договора и возместив исполнителю расходы, произведенные им до этого момента в целях исполнения договора, если они не входят в указанную часть цены услуги;</li>
                        <li>Потребитель при обнаружении недостатков оказанной услуги вправе по своему выбору потребовать:</li>
                        <ul>
                            <li>безвозмездного устранения недостатков;</li>
                            <li>соответствующего уменьшения цены;</li>
                            <li>возмещения понесенных им расходов по устранению недостатков своими силами или третьими лицами;</li>
                        </ul>
                        <li>Потребитель вправе предъявлять требования, связанные с недостатками оказанной услуги, если они обнаружены в течение гарантийного срока, а при его отсутствии в разумный срок, в пределах двух лет со дня принятия оказанной услуги;</li>
                        <li>Исполнитель отвечает за недостатки услуги, на которую не установлен гарантийный срок, если потребитель докажет, что они возникли до ее принятия им или по причинам, возникшим до этого момента;</li>
                        <li>Возврат денежных средств при оплате банковской картой осуществляется на ту банковскую карту, с которой был сделан платеж. Срок возврата от 1 до 30 рабочих дней.</li>
                    </ul>

                    <div className="App-page-paragraph-name">Как сделать заказ на подключение</div>
                    
                    <p>Для того, чтобы подключить тариф, Вам необходимо совершить несколько простых действий:</p>
                    <p>1. Выбираем тариф<br/>
                    Последовательно выберите тип и интересующий Вас тариф.</p>

                    <p>2. Оформляем заказ<br/>
                    Выбрав интересующий Вас тариф, нажмите кнопку «Купить». Создастся заказ и ему бедет присвоен номер.</p>

                    <p>3. Оплачиваем заказ<br/>
                    В зависимости от типа тарифа Вам будет предложена форма для оплаты.</p>
 
             </div>
}
