import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getFirm, loadFirmEdit} from "../../redux/reducers/firm-reducer";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import TextField from '@mui/material/TextField';
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getHtml, getHtmlLoader, getSaveLoader, loadHtml, saveParseSettings, setHtml, setHtmlLoader, setSaveLoader } from "../../redux/reducers/parse-reducer";
import { Parser } from "html-to-react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Tooltip } from "@material-ui/core";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Grid from "@material-ui/core/Grid";
import Loader from "../Loader/Loader";

const useStyles = makeStyles((theme) => ({

    hrGradient: {
        margin: "20px 0",
        padding: "0",
        height: "6px",
        border: "none",
        background: "linear-gradient(45deg, #054C74, #ddd)"
    }

}))


export const ParseStudy = (av) => {
    const dispatch = useDispatch();
    const classes = useStyles()
    const htmlParser = new Parser();

    const {id} = useParams()
    const firmItem = useSelector(getFirm)
    const htmlItem = useSelector(getHtml)
    const htmlLoader= useSelector(getHtmlLoader)
    const saveLoader= useSelector(getSaveLoader)


    const [dialogClassifier, setDialogClassifier] = useState(null);
    const [currentStudyItem, setCurrentStudyItem] = useState(null);

    const [currentStudy, setCurrentStudy] = useState(null);
    const [parseItem, setParseItem] = useState({url:null});
    

    useEffect(() => {
        dispatch(loadFirmEdit(id))

    }, []);


    useEffect(() => {
        if(firmItem)
             if (firmItem?.parsesettings != ""){
                //console.log(firmItem?.parsesettings.replaceAll("'",'"').replaceAll("False",'false').replaceAll("True",'true').replaceAll("\\xa0",''));
                let parseItemObj = JSON.parse(firmItem?.parsesettings.replaceAll("'",'"').replaceAll("False",'false').replaceAll("True",'true').replaceAll("\\xa0",''))
                setParseItem (parseItemObj)
                dispatch(setHtmlLoader(true))

                dispatch(loadHtml(parseItemObj))
}

    }, [firmItem]);


    useEffect(() => {
        if (!htmlItem) {
            setParseItemValue("firm", id)
        } else {

            let paramNames = ['name', 'shifr', 'description', 'pics', 'tth']
            let parseItemObj = {... parseItem}
                
            paramNames.forEach((paramName) => parseItemObj[paramName]?.chain?.forEach((chainItem, key) => {
                let querystring = genQueryString(chainItem, key)

                if (key == 0) {
                    parseItemObj[paramName]['event'] = true
                    let parseElement = [document.getElementById("parseblock"+parseItemObj[paramName]['block'])]
                    chainItem['target'] = parseElement[0]?.querySelector(querystring.trim())
                } else {
                    chainItem['target'] = parseItemObj[paramName]['chain'][key-1]['target']?.parentElement
                }
                

            })) 
            //console.log(parseItemObj);
            setParseItem(parseItemObj)           

        }
    }, [htmlItem]);    



    const setParseItemValue = (name, value) => {
        let parseItemCopy = {... parseItem}
        parseItemCopy[name] = value
        
        setParseItem(parseItemCopy)
    }
    const disableBlock = (key) => {
        
        dispatch(setHtml({...htmlItem, blocks:htmlItem?.blocks?.map((blockItem,blockKey) => (key == blockKey)? {...blockItem, usage: false}:blockItem)}))

    }

    const prepareForSelect = (e) => {
        
        // e.currentTarget?.childNodes?.forEach((ch, key) => {
        //     ch.style.padding = "5px"
        // })

        e.target.style.backgroundColor = 'salmon';
        
        e.target.style.cursor = 'pointer';
       

    }

    const blockApart = (block) => {
        var outBlock = htmlParser.parse(block)
        outBlock.childNodes?.forEach((ch, key) => {
                ch.style.padding = "5px"
                ch.style.margin = "5px"
            })

            return outBlock
    }

    const сloseDialogClassifier = () => {
        setDialogClassifier(null);
      };    

    const setStudyItemValue = (name, value) => {
        let studyItemCopy = {... currentStudyItem}
        studyItemCopy[name] = value

        setCurrentStudyItem(studyItemCopy)
    }  

    const setStudyItemParamValue = (name, key, value) => {
        let studyItemCopy = {... currentStudyItem}
        studyItemCopy["chain"].toReversed()[key]["params"][name] = value

        setCurrentStudyItem(studyItemCopy)
    } 

    const genQueryString = (chainItem, key, querystring = "") =>{



        if ((key > 0) &&(querystring!="") && ((chainItem?.params?.name) || (chainItem?.params?.class) ||(chainItem?.params?.id)))
        querystring += " > "
        if (chainItem?.params?.name)
            querystring+=chainItem?.params?.name+""
            if (chainItem?.params?.class)
            querystring+="."+chainItem?.params?.class 

            if (chainItem?.params?.id)
            querystring+=" #"+chainItem?.params?.id 

        return querystring
    }

    const preParseElement = () => {
        if (currentStudyItem) {
        let parseElement = [document.getElementById("parseblock"+currentStudyItem?.block)]
        let querystring =""
        currentStudyItem?.chain?.toReversed()?.map((chainItem, key) => {
            querystring = genQueryString(chainItem, key, querystring)
        })

            if ((querystring != "")&&(currentStudyItem?.condition == "first"))
            parseElement = [parseElement[0]?.querySelector(querystring.trim())]
            if ((querystring != "")&&(currentStudyItem?.condition == "all"))
            parseElement = Array.from(parseElement[0]?.querySelectorAll(querystring.trim()))

//        console.log(querystring);
        let out = []

        if ((currentStudyItem?.name == 'name') || (currentStudyItem?.name == 'description')|| (currentStudyItem?.name == 'tth')) {
        parseElement.forEach((parseElementItem, key)=>{
            out.push(<span style={{whiteSpace:'pre'}}> {((currentStudy?.shifr?.result)&&(currentStudyItem?.name == 'name'))?parseElementItem?.innerText.replace(currentStudyItem?.filter, '').replace(currentStudy?.shifr?.result, '')+"\n" :parseElementItem?.innerText.replace(currentStudyItem?.filter, '')+"\n"}</span>)
        })
        }

        if ((currentStudyItem?.name == 'shifr')) {
            let shifr = ""
            parseElement.forEach((parseElementItem, key)=>{
                shifr += parseElementItem?.innerText.replace(currentStudyItem?.filter, '')+" "

            })
        if (currentStudyItem.magicshifr){
            var magicshifr = ''
            shifr.split(' ').forEach((shifrItem, key)=>{
                
                if ((key > 0) && (shifrItem !== shifrItem.toLowerCase()))
                    magicshifr+=shifrItem+' '
            })
            out.push(magicshifr)
        }
        else
        out.push(shifr)
        }

        if ((currentStudyItem?.name == 'pics')) {
            parseElement.forEach((parseElementItem, key)=>{
                var newsrc = ""
                // var srcName = parseElementItem?.attributes?.forEach(attrName=>{
                //     if (attrName.includes(".jpg") || attrName.includes(".jpeg") || attrName.includes(".gif") || attrName.includes(".png"))  
                //         return attrName
                // })
                var attrName = (currentStudyItem.attr)?currentStudyItem.attr:"src"
                

                if (parseElementItem?.hasAttribute(attrName)) {
                
                var newsrc = ""
                if (parseElementItem?.getAttribute(attrName)?.includes("http")) {
                    var u = new URL(parseElementItem?.getAttribute(attrName))
                    newsrc = firmItem.WEBSITE+((firmItem.WEBSITE.slice(-1) == '/')?"":"/")+parseElementItem?.src.replace(u.protocol+"//"+u.hostname+":"+u.port,"").replace(u.protocol+"//"+u.hostname,"")
                    }
                    else 
                    newsrc = firmItem.WEBSITE+ parseElementItem?.getAttribute(attrName)
                }
                if(!newsrc.includes("http"))
                    newsrc = htmlItem?.parts[0]+"://"+newsrc.replace('//','/')

                out.push(<img style={{maxWidth:'200px',maxHeight:'100px', margin:"5px", border:"solid 1px #ccc"}} src={newsrc}></img>)
                
            })
        }

        
        return {out:out, querystring:querystring}

    }


    }

    const captureDialog = () => {

        //присваеваем значение



        if (currentStudyItem?.event != null){
        // console.log(currentStudyItem?.event?.target);
        // if (currentStudyItem?.event?.target?.id != '')
        // console.log(currentStudyItem?.event?.target?.id);
        // console.log(currentStudyItem?.event?.target?.className);
        // var parseBlockObject = document.getElementById("parseplock"+currentStudyItem?.block)
        // console.log(parseBlockObject);
        
        //console.log(currentTarget?.target?.parentElement?.parentElement?.parentElement?.id);
        // console.log(currentTarget?.target?.parentElement);
        // console.log(currentTarget?.target?.parentElement?.parentElement);
        //document.querySelector('#foo .bar');
        return  <div>
                    <div>
                        <TextField
                            size="small"
                            style={{ width:'220px'}}
                            margin="dense"
                            padding="none"
                            id="standard-select-status"
                            select
                            label="Захватываемый параметр"
                            defaultValue="active"
                            value={currentStudyItem?.name}
                            
                            onChange={(e)=>setCurrentStudyItem({...currentStudyItem, name:e.target.value})}
                            >
                                <MenuItem key="active" value="name">Наименование</MenuItem>
                                <MenuItem key="disabled" value="shifr">Шифр</MenuItem>
                                <MenuItem key="disabled" value="description">Описание</MenuItem>
                                <MenuItem key="disabled" value="pics">Изображения</MenuItem>
                                <MenuItem key="disabled" value="tth">Технические характеристики</MenuItem>
                        </TextField>                           
                    </div>  
                    <div>
                        {
                            ((currentStudyItem?.name == 'name') || (currentStudyItem?.name == 'shifr') || (currentStudyItem?.name == 'description')|| (currentStudyItem?.name == 'pics')|| (currentStudyItem?.name == 'tth'))?
                            <div>
                                {
                                ((currentStudyItem?.name != 'pics') )?
                                <div>
                                    <TextField
                                        style={{ width:'500px'}}
                                        size="small"
                                        className={classes.orderMenu}
                                        margin="dense"
                                        padding="none"
                                        id="standard-value"
                                        label="Значение выбранного параметра"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        multiline
                                        rows={3}
                                        value={currentStudyItem?.value}

                                        >
                                    </TextField>  

                                </div>
                                :null
                                }
                                <div style={{paddingTop:"10px"}}>
                                    Условия привязки к блокам
                                    {currentStudyItem?.chain?.toReversed()?.map((chainItem, key) => 
                                        
                                    <div style={{backgroundColor:'#fbf9f9'}}>{(key+1)+'. '}
                                    {
                                        (key == 0)?
                                        <Tooltip title="Перейти на блок выше" style={{marginRight:"20px"}}>
                                            <IconButton 
                                            onClick={()=>setStudyItemValue('chain',currentStudyItem?.chain?.concat([{
                                                target:chainItem?.target?.parentElement, 
                                                params:{name:chainItem?.target?.parentElement?.localName,id:false,class:false}
                                            }]))}
                                            >
                                                <DriveFolderUploadIcon style={{ color: 'darkgreen' }}/>
                                            </IconButton>
                                        </Tooltip>
                                        :null
                                    }
                                        <Checkbox
                                            checked={(chainItem?.params?.name)?true:false} 
                                            onClick={(e)=>setStudyItemParamValue('name',key, (e.target.checked)?chainItem?.target?.localName:false)} 
                                            style={{ color: '#054C74' }}
                                        />
                                        <span style={{paddingRight:'20px'}}>{chainItem?.target?.localName}</span>

                                        {   (chainItem?.target?.id != '')?
                                            <checkbkock>
                                                <Checkbox
                                                checked={(chainItem?.params?.id)?true:false} 
                                                onClick={(e)=>setStudyItemParamValue('id',key, (e.target.checked)?chainItem?.target?.id:false)} 
                                                style={{ color: '#054C74' }}
                                                
                                                />
                                                <span style={{paddingRight:'20px'}}>id</span>
                                            </checkbkock>
                                            :null
                                        }
                                        {   (chainItem?.target?.className != '')?
                                            <checkbkock>
                                        <Checkbox
                                            checked={(chainItem?.params?.class)?true:false} 
                                            onClick={(e)=>setStudyItemParamValue('class',key, (e.target.checked)?chainItem?.target?.className:false)} 
                                            style={{ color: '#054C74' }}
                                        />
                                        <span style={{paddingRight:'20px'}}>class</span>
                                        </checkbkock>
                                            :null
                                        }
                                        
                                                                             

                                    </div>

                                    )
                                        
                                    }
                                    <div>
                                        <TextField
                                            size="small"
                                            style={{ width:'220px'}}
                                            margin="dense"
                                            padding="none"
                                            id="standard-select-status"
                                            select
                                            label="Условия парсинга"
                                            defaultValue="first"
                                            value={currentStudyItem?.condition}
                                            onChange={(e) => {setStudyItemValue('condition', e.target.value)}}
                                            >
                                                <MenuItem key="active" value="first">Первый найденный</MenuItem>
                                                <MenuItem key="disabled" value="all">Все повторы</MenuItem>
                                        </TextField>                                         
                                    </div>
                                    {((currentStudyItem?.name != 'pics') )?
                                    <div>
                                    <TextField
                                        style={{ width:'500px'}}
                                        size="small"
                                        className={classes.orderMenu}
                                        margin="dense"
                                        padding="none"
                                        id="standard-filter"
                                        label="Удалять из результата"
                                        value={currentStudyItem?.filter}
                                        onChange={(e) => {setStudyItemValue('filter', e.target.value)}}

                                        >
                                    </TextField>  

                                    </div> 
                                    :<div>
                                    <TextField
                                        style={{ width:'250px'}}
                                        size="small"
                                        className={classes.orderMenu}
                                        margin="dense"
                                        padding="none"
                                        id="standard-spec-src"
                                        label="Аттрибут содержащий ссылку"
                                        value={currentStudyItem?.attr}
                                        onChange={(e) => {setStudyItemValue('attr', e.target.value)}}

                                        >
                                    </TextField>  

                                    </div> 
                                    }

                                    {
                                    ((currentStudyItem?.name == 'shifr') )?
                                    <div>
                                        <Checkbox
                                            checked={currentStudyItem?.magicshifr} 
                                            
                                            onClick={(e)=>setStudyItemValue('magicshifr', e.target.checked)}

                                            style={{ color: '#054C74' }}
                                        />
                                        Найти шифр
                                    </div>
                                    :null
                                    }

                                </div>
                            </div>
                            :null
                        }
                            
                            <div style={{paddingTop:"20px"}}>
                                    Результат парсинга
                                <div style={{minWidth:'600px', width:'fit-content', minHeight:'200px', marginTop:'20px', border:'solid 1px #ccc'}}>
                                {
                                    preParseElement()["out"]
                                }
                                </div>
                            </div>
                    </div>
                </div>
    }
    else
        return <div></div>

    }

    const setCurrentStudyValue = (name, value) => {
        let studyCopy = {... currentStudy}
        studyCopy[name] = value

        setCurrentStudy(studyCopy)
    }
    

const acceptStudyItem = () => {       
    
    setParseItemValue(currentStudyItem.name,{...currentStudyItem, querystring:preParseElement()["querystring"], result: preParseElement()?.toString()});setDialogClassifier(null);
}


const saveCurrentStudy = () =>{
    dispatch(setSaveLoader(true));
    dispatch(saveParseSettings(parseItem))
}

const openUrl = () => {
    dispatch(setHtmlLoader(true))
    setCurrentStudyItem(null)

    dispatch(loadHtml({url:parseItem?.url, firm:parseItem?.firm}))
    dispatch(setHtml(null))

    setParseItem({url:parseItem.url, firm: id})    
//    console.log("+++"+htmlLoader);

}

    
    return <div style={{paddingTop:"20px", width:"100%"}}>


        <Dialog 
        disableEnforceFocus
        open={(dialogClassifier == null)?false:true}
        fullWidth="true"
        maxWidth="true"
        onClose={сloseDialogClassifier}
        handleClose={сloseDialogClassifier}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
      >
        <DialogTitle id="scroll-dialog-title" style={{width:'800px'}}>
            Настройка захвата параметра
        </DialogTitle>
        <DialogContent dividers="paper" >
        {captureDialog()}
        </DialogContent>
        <DialogActions>
          
        <Button 
        //style={{color: '#fff', backgroundColor: '#054C74'}}
        //disabled={!currentStudyItem?.coincidence}
        onClick={()=>acceptStudyItem()}
    >
        
        применить
    </Button>
          <Button 
          onClick={сloseDialogClassifier}
          >Закрыть</Button>


        </DialogActions>
        </Dialog>  


        <div style={{width:"100%", textAlign:"center"}}>Настройка парсинга карточки оборудования</div>
        
        {(firmItem)?
        <div>
            <div style={{width:"100%", textAlign:"center"}}>{(firmItem?.inn)?firmItem?.shortwithopf:firmItem?.NAME}</div>
            <div style={{width:"100%", textAlign:"center"}}>{firmItem?.WEBSITE}</div>
        </div>
        :<div style={{marginLeft:"50%"}}><Loader/></div>
        }        
        <div style={{display:"flex"}}>
            
            <div style={{padding:"5px"}}>

            <div style={{display:"inline-flex"}}>
            <TextField
                            style={{ width:'400px', marginRight:"10px"}}
                            size="small"
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-url"
                            label="Ссылка на сайт производителя"
                            value={parseItem?.url}
                            onChange={(e)=> setParseItemValue('url', e.target.value)}
                            >
            </TextField>      
            
            {(!htmlLoader)?
            <Button variant="contained" 
                        startIcon={<CloudDownloadIcon />}

                                                //disabled={submitLoader} 
                        onClick={openUrl}
                        //onClick={()=>{dispatch(setFirmLoader(true));dispatch(loadFirmSync(firmItem))}}
                        style={{color: '#054C74', backgroundColor: '#fff', margin:'10px 0 30px 0'}}
                        size={'small'} 
                >
                    открыть
            </Button>                  
            :<Loader/>
            }           
        </div>  
            {(htmlItem)?
            <div style={{marginBottom:"20px"}}>
                <Chip  style={{margin:'3px'}}
                onClick={()=>{if(parseItem.name){setCurrentStudyItem(parseItem.name);setDialogClassifier('select')}}}
                disabled={!parseItem?.name}
                label="Наименование" 
                variant="outlined" />
                <Chip  style={{margin:'3px'}}
                onClick={()=>{if(parseItem.shifr){setCurrentStudyItem(parseItem.shifr);setDialogClassifier('select')}}}
                disabled={!parseItem?.shifr}
                label="Шифр" 
                variant="outlined" />
                <Chip  style={{margin:'3px'}}
                onClick={()=>{if(parseItem.description){setCurrentStudyItem(parseItem.description);setDialogClassifier('select')}}}
                disabled={!parseItem?.description}
                label="Описание" 
                variant="outlined" />
                <Chip  style={{margin:'3px', cursor:"pointer"}}
                onClick={()=>{if(parseItem.pics){setCurrentStudyItem(parseItem.pics);setDialogClassifier('select')}}}
                disabled={!parseItem?.pics}
                label="Изображения" 
                variant="outlined" />
                <Chip  style={{margin:'3px'}}
                onClick={()=>{if(parseItem.tth){setCurrentStudyItem(parseItem.tth);setDialogClassifier('select')}}}
                disabled={!parseItem?.tth}
                label="Технические характеристики" 
                variant="outlined" />                                                                

                    {(!saveLoader)?
                    <Button variant="contained" 
                        //disabled={submitLoader} 
                        onClick={saveCurrentStudy}  
                        style={{color: '#fff', backgroundColor: '#054C74', marginLeft: '20px'}}
                        size={'small'} 
                    >
                        Сохранить
                    </Button>
                    :<div style={{marginBottom:'30px'}}><Loader/></div>
                    }

            </div>      
            :null      
            }
            <div id="presult">
                {
                    htmlItem?.blocks?.map((htmlItemBlock,key)=>(htmlItemBlock?.usage)?<div>
                        <div  style={{fontSize:"18px"}}>Блок {key+1} 
                        <IconButton style={{padding:'0'}} 
                        onClick={()=>disableBlock(key)}
                        >
                            <CloseIcon />
                        </IconButton> 
                        </div>
                        <div id={"parseblock"+key} onMouseOver={(e)=>{
                            prepareForSelect(e)
                        }}
                        
                        onClick={(e)=>{

                            e.preventDefault()
                            setCurrentStudyItem({event:e, block:key, name:'name', condition:"first", filter:"", attr:"", value: e?.target?.innerText, chain:[{target:e.target, params:{name:e?.target?.localName,id:false,class:false,}}]})
                            setDialogClassifier('select')

                            
                        }}

                        onMouseOut={(e)=>{
                            e.target.style = {};
                            
                        }}

                        >
                        {blockApart(htmlItemBlock.block)}
                        </div>
                        <div >
                            <hr className={classes.hrGradient}/>
                        </div>
                        </div>:<div></div>)
                }
                
            </div>
            </div>



        </div>

    </div>

}