import React from 'react';
import {    Link  } from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";


export const PleaseLoginDialog = (props) => {
    const {open, onClose, submitCallback} = props

    const handleCloseDialog = () => {
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleCloseDialog} maxWidth={"xs"} fullWidth>
            <DialogTitle>ВНИМАНИЕ</DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                Для приобретения доступа к каталогу необходимо <Link to="/login" className="link headerlink" Style={'padding:0px'}>войти</Link> или <Link to="/registration" className="link headerlink" Style={'padding:0px'}>зарегистрироваться</Link>!<br/>
                
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>
                    <span className="link">Ok</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default PleaseLoginDialog;