import {AuthAPI, UserAPI} from "../../api/api";
import { v4 as uuidv4 } from "uuid";

const SET_LOGIN = 'registrationReducer/SET_LOGIN';
const SET_PASSWORD = 'registrationReducer/SET_PASSWORD';
const SET_FIRSTNAME = 'registrationReducer/SET_FIRSTNAME';
const SET_LASTNAME = 'registrationReducer/SET_LASTNAME';
const SET_INN = 'registrationReducer/SET_INN';
const SET_KPP = 'registrationReducer/SET_KPP';
const SET_IP = 'registrationReducer/SET_IP';
const SET_SUPPLIER = 'registrationReducer/SET_SUPPLIER';
const SET_ROLE = 'registrationReducer/SET_ROLE';
const SET_REGISTERED = 'registrationReducer/SET_REGISTERED';
const SET_ERROR = 'registrationReducer/SET_ERROR';
const SET_STEP1 = 'registrationReducer/SET_STEP1';
const SET_STEP2 = 'registrationReducer/SET_STEP2';
const SET_ORG = 'registrationReducer/SET_ORG';
//uuidv4()

const initialState = {
    login: '',
    password: '',
    firstname: '',
    lastname: '',
    inn: '',
    kpp: '',
    role: '',
    ip: false,
    supplier:false,
    isRegistered: false,
    error: null,
    step1Visible:true,
    step2Visible:false,
    org: null
}

export const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STEP1:
            return {
                ...state,
                step1Visible: action.step1Visible
            }
        case SET_STEP2:
            return {
                ...state,
                step2Visible: action.step2Visible
            }
        case SET_LOGIN:
            return {
                ...state,
                login: action.login
            }
        case SET_PASSWORD:
            return {
                ...state,
                password: action.password
            }
        case SET_INN:
            return {
                ...state,
                inn: action.inn
            }
        case SET_KPP:
            return {
                ...state,
                kpp: action.kpp
            }
        case SET_IP:
            return {
                ...state,
                ip: action.ip
            }
        case SET_SUPPLIER:
            return {
                ...state,
                supplier: action.supplier
            }            
            case SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.firstname
            }
        case SET_LASTNAME:
            return {
                ...state,
                lastname: action.lastname
            }
        case SET_ROLE:
            return {
                ...state,
                role: action.role
            }                        
        case SET_REGISTERED:
            return {
                ...state,
                isRegistered: action.isRegistered
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_ORG:
            return {
                ...state,
                org: action.org
            }

        default:
            return state;
    }
}

export const getLogin = state => state.registrationReducer.login
export const getFirstName = state => state.registrationReducer.firstname
export const getLastName = state => state.registrationReducer.lastname
export const getRole = state => state.registrationReducer.role
export const getPassword = state => state.registrationReducer.password
export const getINN = state => state.registrationReducer.inn
export const getKPP = state => state.registrationReducer.kpp
export const getIP = state => state.registrationReducer.ip
export const getRegistered = state => state.registrationReducer.isRegistered
export const getError = state => state.registrationReducer.error
export const getStep1 = state => state.registrationReducer.step1Visible
export const getStep2 = state => state.registrationReducer.step2Visible
export const getOrg = state => state.registrationReducer.org
export const getSupplier = state => state.registrationReducer.supplier

export const setLogin = (login) => ({type: SET_LOGIN, login})
export const setFirstName = (firstname) => ({type: SET_FIRSTNAME, firstname})
export const setLastName = (lastname) => ({type: SET_LASTNAME, lastname})
export const setRole = (role) => ({type: SET_ROLE, role})
export const setINN = (inn) => ({type: SET_INN, inn})
export const setKPP = (kpp) => ({type: SET_KPP, kpp})
export const setIP = (ip) => ({type: SET_IP, ip})
export const setPassword = (password) => ({type: SET_PASSWORD, password})
export const setRegistered = (isRegistered) => ({type: SET_REGISTERED, isRegistered})
export const setError = (error) => ({type: SET_ERROR, error})
export const setStep1 = (step1Visible) => ({type: SET_STEP1, step1Visible})
export const setStep2 = (step2Visible) => ({type: SET_STEP2, step2Visible})
export const setOrg = (org) => ({type: SET_ORG, org})
export const setSupplier = (supplier) => ({type: SET_SUPPLIER, supplier})


export const registrationLegalStep2 = (username, password, role, first_name, last_name, org = null) => async dispatch => {
    try {

        const response = await UserAPI.createLegalUser({username:username, email:username, password:password, role:role, first_name:first_name, last_name:last_name, mid_name:uuidv4(), is_activated:false, org:org})
        
        if (response.status === 201) {
            dispatch(setLogin(''))
            dispatch(setPassword(''))
            dispatch(setFirstName(''))
            dispatch(setLastName(''))
            dispatch(setRole(''))
            dispatch(setINN(''))
            dispatch(setRegistered(true))
            dispatch(setError(null))
        }
    } catch (error) {

        // if (error?.response?.data?.detail === 'No active account found with the given credentials') {
             dispatch(setError({password: {text: error?.response?.data?.detail}}))
         //}
     }
    
}

export const registrationLegal = (username, password, role, first_name, last_name, inn_num, kpp_num, ip = false, org = null) => async dispatch => {
    try {

        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let error = {}
            if (!username) {
                error = {...error, login: {text: 'Укажите адрес электронной почты'}}
                error = {...error, iserror: true}
            }
            if (password.length < 8) {
                error = {...error, password: {text: 'Убедитесь, что это значение содержит не менее 8 символов'}}
                error = {...error, iserror: true}
            }
            if (!password) {
                error = {...error, password: {text: 'Укажите пароль'}}
                error = {...error, iserror: true}
            }
            if (!first_name) {
                error = {...error, firstname: {text: 'Укажите имя'}}
                error = {...error, iserror: true}
            }
            if (!last_name) {
                error = {...error, lastname: {text: 'Укажите фамилию'}}
                error = {...error, iserror: true}
            }    
            if(reg.test(username) == false)  {
                error = {...error, login: {text: 'Укажите корректный e-mail'}}
                error = {...error, iserror: true}
            }

            if ((ip) && (validateInn(inn_num).error != false)) {
                error = {...error, inn: {text: validateInn(inn_num).error}}
                error = {...error, iserror: true}
            }

            if (!ip) {
                if (validateInn(inn_num).error != false) {
                error = {...error, inn: {text: validateInn(inn_num).error}}
                error = {...error, iserror: true}
                }
                if (validateKpp(kpp_num).error != false) {
                    error = {...error, kpp: {text: validateKpp(kpp_num).error}}
                    error = {...error, iserror: true}
                }
            }
            

        if (!error.iserror) {
            const response = await AuthAPI.CheckEmailView(username)
            if (response.status === 200) {
                const responseinn = await AuthAPI.CheckINNView(inn_num, kpp_num)

                    if (!responseinn?.data?.suggestions[0]) {
                        error = {...error, inn: {text: 'Юридическое лицо не найдено'}}
                        error = {...error, iserror: true}        
                    }
                    if (responseinn?.data?.suggestions[0]?.data?.state?.status == 'LIQUIDATED') {
                        error = {...error, inn: {text: 'Юридическое лицо ликвидировано'}}
                        error = {...error, iserror: true}        
                    }                    

                    if (!error.iserror) {
                        dispatch(setOrg(responseinn.data))
                        dispatch(setStep1(false))  
                        dispatch(setStep2(true))

                    }
                    
            }
            else {
                error = {...error, login: {text: 'Пользователь с таким адресом уже существует'}}
                error = {...error, iserror: true}
            }
        }

        if (error.iserror)
        dispatch(setError(error))
        else
        dispatch(setError(null))    

    } catch (error) {

       // if (error?.response?.data?.detail === 'No active account found with the given credentials') {
            dispatch(setError({password: {text: error?.response?.data?.detail}}))
        //}
    }

}



export const registrationIndividual = (username, password, role, first_name, last_name) => async dispatch => {
    try {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let error = {}
        if (username == "" || password == ""|| first_name == "" || last_name == "" || (reg.test(username) == false)) {
            
            if (!username) {
                error = {...error, login: {text: 'Укажите адрес электронной почты'}}
                error = {...error, iserror: true}
            }
            if (!password) {
                error = {...error, password: {text: 'Укажите пароль'}}
                error = {...error, iserror: true}
            }
            if (!first_name) {
                error = {...error, firstname: {text: 'Укажите имя'}}
                error = {...error, iserror: true}
            }
            if (!last_name) {
                error = {...error, lastname: {text: 'Укажите фамилию'}}
                error = {...error, iserror: true}
            }    
            if(reg.test(username) == false)  {
                error = {...error, login: {text: 'Укажите корректный e-mail'}}
                error = {...error, iserror: true}
            }

            
            dispatch(setError(error))
        }
        if (!error.iserror) {
            const response = await UserAPI.createIndividualUser({username:username, email:username, password:password, role:role, first_name:first_name, last_name:last_name, mid_name:uuidv4(), is_activated:false})

            if (response.status === 201) {
                dispatch(setLogin(''))
                dispatch(setPassword(''))
                dispatch(setFirstName(''))
                dispatch(setLastName(''))
                dispatch(setRole(''))
                dispatch(setINN(''))
                dispatch(setRegistered(true))
                dispatch(setError(null))
            } else {
                if (response?.data?.username)
                    dispatch(setError({login: {text: response?.data?.username}}))
                if (response?.data?.password)
                    dispatch(setError({password: {text: response?.data?.password}}))
                dispatch(setPassword(''))
            }
        }
    } catch (error) {

       // if (error?.response?.data?.detail === 'No active account found with the given credentials') {
            dispatch(setError({password: {text: error?.response?.data?.detail}}))
        //}
    }

}

export const logout = () => async (dispatch) => {
    document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
    try {
        await AuthAPI.logout()
    } finally {
        dispatch(clearCredentials())
    }
}

export const clearCredentials = () => async (dispatch) => {
    clearCredentials()
    dispatch(getRegistered(false));
}


const validateInn = (inn_num) => {
	var result = {result:false, error:false}
	if (typeof inn_num === 'number') {
		inn_num = inn_num.toString();
	} else if (typeof inn_num !== 'string') {
		inn_num = '';
	}
	if (!inn_num.length) {
		result.error = 'ИНН пуст'
	} else if (/[^0-9]/.test(inn_num)) {
        result.error =  'ИНН может состоять только из цифр'
	} else if ([10, 12].indexOf(inn_num.length) === -1) {
        result.error = 'ИНН может состоять только из 10 или 12 цифр'
	} else {
		var checkDigit = function (inn_num, coefficients) {
			var n = 0;
			for (var i in coefficients) {
				n += coefficients[i] * inn_num[i];
			}
			return parseInt(n % 11 % 10);
		};
		switch (inn_num.length) {
			case 10:
				var n10 = checkDigit(inn_num, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
				if (n10 === parseInt(inn_num[9])) {
					result.result = true;
				}
				break;
			case 12:
				var n11 = checkDigit(inn_num, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
				var n12 = checkDigit(inn_num, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
				if ((n11 === parseInt(inn_num[10])) && (n12 === parseInt(inn_num[11]))) {
					result.result = true;
				}
				break;
		}
		if (!result) {
            result.error = 'Неправильное контрольное число'
		}
	}
	return result;
}


const validateKpp = (kpp_num) => {
	var result = {result:false, error:false}
	if (typeof kpp_num === 'number') {
		kpp_num = kpp_num.toString();
	} else if (typeof kpp_num !== 'string') {
		kpp_num = '';
	}
	if (!kpp_num.length) {
        result.error = 'КПП пуст'
	} else if (kpp_num.length !== 9) {
        result.error = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z'
	} else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp_num)) {
		result.error = 'Неправильный формат КПП'
	} else {
		result.result = true;
	}
	return result;
}