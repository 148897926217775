import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import {loadSpecs, getSpecs, getSpecLoader} from "../../redux/reducers/spec-reducer";
import  Loader  from '../../components/Loader/Loader';
import { SpecsTable } from "../../components/SpecsTable/SpecsTable";
import {GridCellExpand} from "../../components/GridCellExpand/GridCellExpand.jsx";
import {useQuery} from "../../hooks/useQuery";
import { SpecEdit } from "../../components/SpecEdit/SpecEdit";



const useStyles = makeStyles((theme) => ({

  
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },

}))


export  function SpecPanel() {
    const classes = useStyles()
    const query = useQuery()
    const id = query.get('id')
    

    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getLoggedIn)
    const  specs  = useSelector(getSpecs);    
    const  specLoader  = useSelector(getSpecLoader);    

    useEffect(() => {

        dispatch(loadSpecs());
      }, []);        

      const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              link = {'/operator/?tab=Reference&component=spec&id='+params.id}
             // clickHandle = {props.action}
          />
        )
      }        

      const genColumns = () => {
        let compareColumns = [
            { 
            field: "id", 
            headerName: "ID", 
            minWidth: 15, 
            
//            renderCell: RenderDescription
            }, 
            { 
            field: "NAME", 
            headerName: "Наименование", 
            minWidth: 300, flex: 1, 
            renderCell: RenderDescription
            },
            { 
                field: "TYP", 
                headerName: "Тип", 
                width: 100, flex: 1, 
                renderCell:RenderDescription, 
            },                  
            { 
              field: "COMMENT", 
              headerName: "Обозначение", 
              width: 100, flex: 1, 
              renderCell:RenderDescription, 
            }, 

            ]

        return compareColumns
    }      

    const genRows = () => {
        let rowData = []
        specs?.forEach( function callback (firmItem) {
            let tmpRow = {id:firmItem.ID,NAME:firmItem.NAME,COMMENT:firmItem.COMMENT,TYP:
            (firmItem.TYP == 0)? "Числовой"
            :(firmItem.TYP == 1)?"Списочный"
            :"Не указан"}
            rowData.push(tmpRow)
        })
        return rowData

    }  
        
    return (
        (specs)?<div >
            {(id == null)?
            <SpecsTable style={{height:'auto'}} data={genRows()} columns={genColumns()}/>
            :<SpecEdit/>
            }

           

        </div>
        :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table'}}><Loader/></div>       
          
    )

}