import {ProfileAPI, EQPAPI} from "../../api/api";
import {createErrorNotification} from "./notification-reducer";
import {logout} from "./login-reducer.js";

const SUCCESS_LOADED_PROFILE = 'userProfileEdit/SUCCESS_LOADED_PROFILE'
const SET_PROFILE = 'userProfileEdit/SET_PROFILE'
const SET_ORG = 'userProfileEdit/SET_ORG'
const SET_FIRSTNAME = 'userProfileEdit/SET_FIRSTNAME';
const SET_LASTNAME = 'userProfileEdit/SET_LASTNAME';
const SET_MIDNAME = 'userProfileEdit/SET_MIDNAME';
const SET_KPP = 'userProfileEdit/SET_KPP';
const SET_ERROR = 'userProfileEdit/SET_ERROR';
const SET_PHOTO = 'userProfileEdit/SET_PHOTO';
const SET_LOADER = 'userProfileEdit/SET_LOADER';
const SET_MESSAGE = 'userProfileEdit/SET_MESSAGE';
const SET_ALERTS = 'userProfileEdit/SET_ALERTS';
const SET_NEWS = 'userProfileEdit/SET_NEWS';
const SET_TF = 'userProfileEdit/SET_TF';
const SET_TFCODE = 'userProfileEdit/SET_TFCODE';
const SET_PURCHASES = 'userProfileEdit/SET_PURCHASES';
const SET_ALLTARIFFS = 'userProfileEdit/SET_ALLTARIFFS';


const initialState = {
    user: null,
    org: null,
    loading: true,
    firstname: '',
    lastname: '',
    midname: '',
    kpp: '',
    photo: '',
    loader: false,
    message: '',
    alerts: false,
    news: false,
    tf:false,
    tfcode:false,
    purchases: false,
    alltariffs: null

}

export const userProfileEdit = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_LOADED_PROFILE:
            return {...state, user: action.user, loading: false}
        case SET_PROFILE:
            return {...state, user: action.user}
        case SET_ORG:
            return {...state, org: action.org} 
        case SET_PURCHASES:
            return {...state, purchases: action.purchases}             
        case SET_KPP:
            return {
                ...state,
                kpp: action.kpp
            }
        case SET_MESSAGE:
            return {
                ...state,
                message: action.message
            }            
        case SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.firstname
            }
        case SET_LASTNAME:
            return {
                ...state,
                lastname: action.lastname
            }
        case SET_MIDNAME:
            return {
                ...state,
                midname: action.midname
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_PHOTO:
            return {
                ...state,
                photo: action.photo
            }
        case SET_ALERTS:
            return {
                ...state,
                alerts: action.alerts, loading: false
            }            
        case SET_NEWS:
            return {
                ...state,
                news: action.news, loading: false
            }       
        case SET_ALLTARIFFS:
            return {
                ...state,
                alltariffs: action.alltariffs
            }                 

        case SET_TF:
            return {
                ...state,
                tf: action.tf, loading: false
            }                 
        case SET_TFCODE:
            return {
                ...state,
                tfcode: action.tfcode, loading: false
            }                              
        case SET_LOADER:
            return {
                ...state,
                loader: action.loader
            }            

        default:
            return state
    }
}
export const getUser = (state) => state.userProfileEdit.user
export const getPurchases = (state) => state.userProfileEdit.purchases
export const getProfile = (state) => state.userProfileEdit
export const getFirstName = state => state.userProfileEdit.firstname
export const getLastName = state => state.userProfileEdit.lastname
export const getMidName = state => state.userProfileEdit.midname
export const getKPP = state => state.userProfileEdit.kpp
export const getError = state => state.userProfileEdit.error
export const getPhoto = state => state.userProfileEdit.photo
export const getLoader = state => state.userProfileEdit.loader
export const getLoading = state => state.userProfileEdit.loading
export const getMessage = state => state.userProfileEdit.message
export const getNews = state => state.userProfileEdit.news
export const getAlerts = state => state.userProfileEdit.alerts
export const getTf = state => state.userProfileEdit.tf
export const getTfCode = state => state.userProfileEdit.tfcode
export const getAllTariffs = state => state.userProfileEdit.alltariffs

//export const getOrg = state => state.userProfile.org


export const successLoadedProfile = user => ({type: SUCCESS_LOADED_PROFILE, user})

export const setProfile = user => ({type: SET_PROFILE, user})
export const setPurchases = purchases => ({type: SET_PURCHASES, purchases})
export const setOrg = org => ({type: SET_ORG, org})

export const setFirstName = (firstname) => ({type: SET_FIRSTNAME, firstname})
export const setLastName = (lastname) => ({type: SET_LASTNAME, lastname})
export const setMidName = (midname) => ({type: SET_MIDNAME, midname})
export const setKPP = (kpp) => ({type: SET_KPP, kpp})
export const setError = (error) => ({type: SET_ERROR, error})
export const setPhoto = (photo) => ({type: SET_PHOTO, photo})
export const setLoader = (loader) => ({type: SET_LOADER, loader})
export const setMessage = (message) => ({type: SET_MESSAGE, message})
export const setAlerts = (alerts) => ({type: SET_ALERTS, alerts})
export const setNews = (news) => ({type: SET_NEWS, news})
export const setTf = (tf) => ({type: SET_TF, tf})
export const setTfCode = (tfcode) => ({type: SET_TFCODE, tfcode})
export const setAllTariffs = (alltariffs) => ({type: SET_ALLTARIFFS, alltariffs})

export const savePersonalData = (photo = '', first_name, last_name, mid_name, kpp_num = null, org = null) => async dispatch => {
    try {
        let error = {}
        if (!first_name) {
            error = {...error, firstname: {text: 'Укажите имя'}}
            error = {...error, iserror: true}
        }
        if (!last_name) {
            error = {...error, lastname: {text: 'Укажите фамилию'}}
            error = {...error, iserror: true}
        }  

        if (org !== null)
        if (org?.typename !== 'INDIVIDUAL') 
        if (validateKpp(kpp_num).error != false) {
            error = {...error, kpp: {text: validateKpp(kpp_num).error}}
            error = {...error, iserror: true}
        }
    
    
        if (!error.iserror) {
    
            const response = await ProfileAPI.savePersonalData({photo:photo, first_name:first_name, last_name:last_name, mid_name:mid_name, kpp_num:kpp_num, photo:photo, org:org })
            if (response.status === 200) {
                dispatch(setLoader(false))
                dispatch(setError(null))                
            }
            else {
                error = {...error, firstname: {text: "Что-то пошло не так..."}}
                error = {...error, iserror: true}
            }

        }

        if (error.iserror)
        dispatch(setError(error))
        else
        dispatch(setError(null))          
    }
    catch (error) {
        dispatch(setLoader(false))
        if (error?.response?.data?.detail != null)
             dispatch(setError({firstname: {text: error?.response?.data?.detail}}))
             else
             dispatch(setError({firstname: {text: "Что-то пошло не так..."}}))

     }    
}


export const saveSubscriptions = (news, alerts) => async dispatch => {
    try {
        let error = {}
        const response = await ProfileAPI.saveSubscriptions({news:news, alerts:alerts})
        if (response.status === 200) {
            dispatch(setLoader(false))
            dispatch(setError(null))                
            dispatch(setMessage("Изменения сохранены"))
        }
        else {
            dispatch(setMessage("Что-то пошло не так..."))
            error = {...error, iserror: true}
        }  
        
        if (error.iserror)
        dispatch(setError(error))
        else
        dispatch(setError(null)) 
    }
    catch (error) {
        dispatch(setLoader(false))
        dispatch(setMessage("Что-то пошло не так..."))
        error = {...error, iserror: true}

    }        

}

export const saveProtect = (tf, tfcode = null) => async dispatch => {
    try {
        let error = {}
        if ((tfcode != null) && (tfcode == '')) {
            error = {...error, userCode: {text: 'Укажите код'}}
            error = {...error, iserror: true}
            dispatch(setError(error))
            dispatch(setLoader(false))
        }
        else {
        const response = await ProfileAPI.saveProtect({tf:tf, tfcode:tfcode})
        if (response.status === 200) {
            dispatch(setLoader(false))
            dispatch(setError(null))   
//            alert(tf+' '+response.data.tfcode)             
//            if (response.data.tfcode == -1) {
                dispatch(setTfCode(response.data.tfcode))
//                error = {...error, userCode: {text: 'Указан неверный код'}}
 
//            }

                
            if (response.data.tfcode)
                dispatch(setTfCode(response.data.tfcode))
            else {
                dispatch(setMessage("Изменения сохранены"))
            }

        }
        else if (response.status === 203) {
            dispatch(setTfCode(true))
            error = {...error, userCode: {text: 'Указан неверный код'}}
            error = {...error, iserror: true}
            dispatch(setLoader(false))
        }

        else {
            dispatch(setLoader(false))
            dispatch(setMessage("Что-то пошло не так..."))
            error = {...error, iserror: true}
        }  
        
        if (error.iserror)
        dispatch(setError(error))
        else
        dispatch(setError(null)) 
    }
    }
    catch (error) {
        dispatch(setLoader(false))
        dispatch(setMessage("Что-то пошло не так..."))
        error = {...error, iserror: true}

    }        

}

export const loadPurchases = async dispatch => {
    const response = await ProfileAPI.getPurchases()

    if (response.status === 200) {

        dispatch(setPurchases(response.data))
        
    }
}

export const loadAllTariffs = async dispatch => {
    const response = await EQPAPI.getTariffs()

    if (response.status === 200) {

        dispatch(setAllTariffs(response.data))
        
    }
}


export const loadProfileData = async dispatch => {
    const response = await ProfileAPI.getProfile()

    if (response.status === 200) {

        dispatch(setProfile(response.data))
        dispatch(setFirstName(response.data.first_name))
        dispatch(setLastName(response.data.last_name))
        dispatch(setMidName(response.data.mid_name))
        dispatch(setPhoto(response.data.photo))
        dispatch(setAlerts(response.data.alerts))
        dispatch(setNews(response.data.news))        
        dispatch(setTf(response.data.tf))        
        dispatch(setTfCode(response.data.tfcode))        
        if ((response.data.role === 'entity') || (response.data.role === 'supplier')) {
            const orgResponse = await ProfileAPI.getOrg()
            if (orgResponse.status === 200) {
                dispatch(setOrg(orgResponse.data))
                dispatch(setKPP(orgResponse.data.kpp))

            }
        }
        dispatch(successLoadedProfile(response.data))
        
    }
    if (response.status === 205) {
        dispatch(logout())

    }    
}

export const updateProfileData = (values) => async (dispatch) => {
    try {
        await ProfileAPI.updateProfile(values)

    } catch (e) {
        dispatch(createErrorNotification(Object.values(e.response.data)[0]))
    }
}

export const updatePassword = (values) => async (dispatch) => {
    try {
        let error = {}
        if (!values?.old_password) {
            error = {...error, old_password: {text: 'Укажите старый пароль'}}
            error = {...error, iserror: true}
        }
        if (!values?.password) {
            error = {...error, password: {text: 'Укажите новый пароль'}}
            error = {...error, iserror: true}
        }  
        if ((values?.password) && (values?.old_password) && (values?.password == values?.old_password)) {
            error = {...error, password: {text: 'Пароли одинаковые'}}
            error = {...error, old_password: {text: 'Пароли одинаковые'}}
            error = {...error, iserror: true}
        }          

        if (!error.iserror) {
            const response = await ProfileAPI.updatePassword(values)
            if (response.status === 201) {
                dispatch(setError(null))
                dispatch(setLoader(false))
                dispatch(setMessage('Пароль успешно изменен'))
            }
            else {
                if (response?.data?.password) {
                    error = {...error, password: {text: response?.data?.password}}
                    error = {...error, iserror: true}
        
                }
                if (response?.data?.old_password) {
                    error = {...error, old_password: {text: response?.data?.old_password}}
                    error = {...error, iserror: true}
        
                }

            }

        }

        if (error.iserror)
        {
        dispatch(setError(error))
        dispatch(setLoader(false))
        }
        else
        dispatch(setError(null))    




//        onSuccess()
//        dispatch(logout())
    } catch (e) {
//        dispatch(createErrorNotification(Object.values(e.response.data)[0]))
    }
}


const validateKpp = (kpp_num) => {
	var result = {result:false, error:false}
	if (typeof kpp_num === 'number') {
		kpp_num = kpp_num.toString();
	} else if (typeof kpp_num !== 'string') {
		kpp_num = '';
	}
	if (!kpp_num.length) {
        result.error = 'КПП пуст'
	} else if (kpp_num.length !== 9) {
        result.error = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z'
	} else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp_num)) {
		result.error = 'Неправильный формат КПП'
	} else {
		result.result = true;
	}
	return result;
}