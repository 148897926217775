import React, {useState}  from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import {EQ} from "../components/EQ/EQ";
import { EQEdit } from '../components/EQEdit/EQEdit';


const useStyles = makeStyles((theme) => ({
    paper: {

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: ''
    },

}))

export const EQPEditPage = () => {
    const classes = useStyles()

    const isLoggedIn = useSelector(getLoggedIn)
    const [av, setAv] = useState(false);
    const ssetAv = (avе) => {
        
        setAv(avе)
    }

    if (isLoggedIn) 
        return  <HomePrivateLayout av={av} setAv={setAv}>
                    <div className={classes.paper}>
                    <EQEdit av={{av, ssetAv}}/>
                    </div>                  
                </HomePrivateLayout>          

        
}