import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {OkPanel} from "../../containers/OkPanel/OkPanel";


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '10px 0 10px 0'
    },
    promoOk: {float:'left', display:'flex',
    ['@media (max-width:860px)']: { 
        display:'block'
    }
    },
    promoOkName: {fontWeight: '700', fontSize: '20px', lineHeight: '24px',padding:'0 5px 0 5px'},
}))

const OkPanelAction = (id, name) => {
    window.open('/eqpsearch/?ok='+id+'&okname='+name,"_self");
}

export const PromoOkPanel = () => {
    
    const classes = useStyles()

    return  <div className={classes.paper}>
                <div className={classes.promoOk}>
                    <div>
                        <div>
                            <div className={classes.promoOkName}>Оборудование для добычи нефти и газа</div>
                        </div>
                        <OkPanel owner="1"/>
                    </div>
                    <div>
                        <div>
                            <div className={classes.promoOkName}>Буровое оборудование</div>
                        </div>
                        <OkPanel owner="2" action={OkPanelAction}/>
                    </div>
                </div>
            </div>

}