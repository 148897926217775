import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import ruLocale from "date-fns/locale/ru";



const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: theme.palette.common.white,
    width: "75%",
    borderRadius: "5px",
    "& .MuiInputBase-root": {
      height: '40px',
    }
  },
}));

function DatePicker({ time, handleChange }) {
  const handleDateTimeChange = (date) => {
    date = moment(date).format("YYYY-MM-DD")
    handleDateChange(date)
    handleChange(date)
  }
  const [selectedDate, handleDateChange] = useState(time);
  useEffect(() => selectedDate, [selectedDate])
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}  libInstance={moment}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="yyyy-MM-dd"
          value={
            selectedDate
          }
          InputAdornmentProps={{ position: "start" }}
          onChange={(date) => handleDateTimeChange(date)}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
          classes={{
            root: classes.formControl,
          }}
        />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;