import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import {useDispatch, useSelector} from "react-redux";
import Tune from '@material-ui/icons/Tune';
import Search from '@material-ui/icons/Search';
import { Link } from "react-router-dom";
import { getPromo, loadingPromo } from "../../redux/reducers/promo-reducer";
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        paddingLeft: '0px'
    },

    promoPanelAdv: {
        cursor: 'pointer',
        position: 'absolute',
        right: '125px',
        top: '28px',
        ['@media (max-width:500px)']: { 
            display: 'none'
            }  
    },    

    promoPanelSearch: {
        position: 'absolute',
        right: '5px',
        top: '28px',
        display: 'none',
        ['@media (max-width:500px)']: { 
            display: 'block'
            }  
    },      

    promo: {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/mainbanner-l.jpg)',
        width: '100%',
        height: '475px',
        backgroundPositionY: '50%',
        backgroundPositionX: '50%',
        backgroundSize: 'cover',
        ['@media (max-width:767px)']: { 
            height: '250px',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/mainbanner-m.jpg)',
            backgroundPositionY: '50%',
            backgroundPositionX: '50%',
            backgroundSize: 'cover',
          },
        ['@media (max-width:500px)']: { 
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/mainbanner-s.jpg)',
            backgroundPositionY: '50%',
            backgroundPositionX: '50%',
            backgroundSize: 'cover',
              }          
      },

      promoPanel: {
        width: '760px',
        height: '100%',
        margin: 'auto',
        ['@media (max-width:767px)']: { 
            width: '480px'
          },
        ['@media (max-width:500px)']: { 
            width: '350px'
            }            
      },
      
      promoPanelSlogan: {
        fontSize: '32px',
        fontWeight: '800',
        color: 'white',
        paddingTop: '50px',
        ['@media (max-width:767px)']: { 
            fontSize: '24px',
            paddingTop: '10px',
          },
        ['@media (max-width:500px)']: { 
              fontSize: '17px',
              paddingTop: '10px',
            }           
      },
      
      promoPanelSearchTerms: {
        marginTop: '20px',
        width: '635px',
        height: '40px',
        borderRadius: '5px 0px 0px 5px',
        border: '0',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        paddingLeft: '10px',
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '350px',
              borderRadius: '5px',
          }  
      },
      
      promoPanelSearchButon: {
        background: '#054C74',
        borderRadius: '0px 5px 5px 0px',
        width: '115px',
        height: '40px',
        outline: 'none',
        border: '0',
        color: 'white',
        cursor: 'pointer',
        ['@media (max-width:500px)']: { 
            display: 'none'
            }           
      },

      promoPanelCount: {
        float:'left',
        color: 'white',
        paddingTop: '180px',
        paddingRight: '30px',
        fontWeight:'bold',
        letterSpacing:'2px',
        ['@media (max-width:767px)']: { 
            paddingTop: '100px',
            fontWeight:'unset',
            letterSpacing:'0px',
            paddingRight: '10px',
          } ,
          ['@media (max-width:500px)']: { 
            fontSize: '10px',
            }  
      },
      
      promoPanelCountNum: {  
        fontSize: '32px',
        fontWeight:'bold',
        ['@media (max-width:767px)']: { 
            fontSize: '24px',
        }
      }    
}))


export const Promo = () => {

const classes = useStyles()
const { counters } = useSelector(getPromo);    

const isLoggedIn = useSelector(getLoggedIn)

const dispatch = useDispatch();


const [query, setQuery] = useState('');

useEffect(() => {

    dispatch(loadingPromo());
  }, [dispatch]);

  
  const onSearchChange = e => {
    setQuery(e.target.value)
}

const search = () => {
  if (query != '')
  window.open('/eqpsearch/?query='+query,"_self");
}

  return <div className={classes.paper}>
            <div style={{padding:'20px 5px 20px 5px'}}>
              Система работает в тестовом режиме. Стоимость на период тестирования снижена.
            </div>
            <div className={classes.promo}>
                <div className={classes.promoPanel}>
                    <div className={classes.promoPanelSlogan}>ИМПОРТОЗАМЕЩЕНИЕ<br/>
                        Поиск и подбор аналогов оборудования
                    </div>
                    <div Style={'position:absolute'}>
                        <input type="text" autocomplete="off" onChange={onSearchChange} onKeyDown={(e) => { if (e.key === 'Enter') search()}}  className={classes.promoPanelSearchTerms} id="search-terms" placeholder="Наименование или шифр оборудования" value={query}/>
                        <button onClick={search} className={classes.promoPanelSearchButon} >Найти</button>
                        <Link to="/advanced" className="link headerlinkImageText">
                          <Tooltip title={<span style={{ fontSize:'14px'}}>Расширенный поиск</span>} arrow PopperProps={{ style: { marginTop: -12 } }} >                
                            <Tune className={classes.promoPanelAdv}/>
                          </Tooltip>
                        </Link>
                        <Search onClick={search} className={classes.promoPanelSearch}/>
                    </div>
                    <div className={classes.promoPanelCount}>
                    <span className={classes.promoPanelCountNum}>{counters.prod_count}</span><br />
                    оборудование для добычи
                    </div>
                    <div className={classes.promoPanelCount}>
                    <span className={classes.promoPanelCountNum}>{counters.drill_count}</span><br />
                    буровое оборудование
                    </div>
                    <div className={classes.promoPanelCount}>
                    <span className={classes.promoPanelCountNum}>{counters.company_count}</span><br />
                    изготовители
                    </div>                                                        
                </div>


            </div>
        </div>


}