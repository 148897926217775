import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
//import { deleteTariff, getError, getTariff, getTariffLoader, getTariffs, loadTariff, loadTariffs, newTariff, setError, setTariff, setTariffs, updateTariff } from "../../redux/reducers/tariff-reducer";
import { deleteTariff, getTariffError, getTariff, getTariffLoader, getTariffs, loadTariff, loadTariffs, newTariff, setError, setTariff, setTariffs, updateTariff } from "../../redux/reducers/tariff-reducer";
import {useDispatch, useSelector} from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ConstructionIcon  from '@mui/icons-material/Construction';
import {OkPanelSkeleton} from "../../components/SearchSkeleton/SearchSkeleton.jsx";
import {GridCellExpand} from "../../components/GridCellExpand/GridCellExpand.jsx";
import { TariffsTable } from "../../components/TariffsTable/TariffsTable";
import  Loader  from '../../components/Loader/Loader';
import {API_SERVER_VAL} from "../../config";
import {useQuery} from "../../hooks/useQuery";
import TextField from '@mui/material/TextField';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useRedirect} from "../../hooks/useRedirect";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
    paper: {
      flexDirection: 'column',

                alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
    orderMenu: { minWidth:'100px',
    width:'100%',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    },     
    headitemname : {
      display: 'flex', 
      paddingTop: '10px',
      paddingLeft: '5px',
      fontSize:'16px',
      
      color: '#929292'
  },
  avatar: {
    margin: '0',
    width: '150px',
    height: '150px',
    backgroundColor: '#fff',
    border: '1px solid #868686',
    padding:'25px',
    fontSize: 24,
    color: '#868686',        
    "&:hover": {
        cursor: 'pointer'
    },
  },
  
  formAreaAvatar: {
    float:'left',
    marginTop:'auto', 
    marginBottom:'auto'   , 
    ['@media (max-width:767px)']: { 
      float: 'none',
      marginTop:'unset', 
      marginBottom:'unset',
      display: 'table',
      margin: 'auto',
      }   
  },  

}))

const tariffTranslate = {
  typ: {entity: 'Юридическое лицо', individual: 'Физическое лицо'},
  status: {active: 'Активный', disabled: 'Не активный'},
}

const baseUrl = `${API_SERVER_VAL}/api/eqp`

export const TariffEdit = (props) => {

    const query = useQuery()
    const id = query.get('id')
 
    const classes = useStyles()
    const tariffs = useSelector(getTariffs);    
    const tariffItem = useSelector(getTariff)
    const tariffLoader = useSelector(getTariffLoader)

    const error = useSelector(getTariffError)
    const {replace} = useRedirect()

    const [dialogClassifier, setDialogClassifier] = useState(null);


    const dispatch = useDispatch();
    useEffect(() => {
        if((tariffs == null)&&(!tariffLoader))
        dispatch(loadTariffs());
        if ((id) &&(!tariffItem)&&(tariffs)) {
          dispatch(loadTariff(id))
      }      
      }, [tariffs,id]);        


    useEffect(() => {
    if ((id != null) &&(id != 'new'))
    if (id == null)
      dispatch(setTariff(null))
    }, [id]);        

    useEffect(() => {
      if (tariffItem?.ID != null)
          replace('/operator/?tab=Reference&component=tariffs&id='+tariffItem?.ID)
      }, [tariffItem]);        
  
  
    const сloseDialogClassifier = () => {
      setDialogClassifier(null);
    };
      const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              link = {'/operator/?tab=Reference&component=tariffs&id='+params.id}
          />
        )
      }      

      const genColumns = () => {
        let compareColumns = [
            { 
            field: "id", 
            headerName: "ID", 
            minWidth: 15, 
            
//            renderCell: RenderDescription
            }, 
            { 
              field: "TYP", 
              headerName: "Тип клиета", 
              width: 150, flex: 1, 
              renderCell: RenderDescription
          },            
            { 
            field: "DURATION", 
            headerName: "Продолжительность, дни", 
            minWidth: 180, flex: 1, 
            renderCell: RenderDescription
            }, 
            { 
              field: "PRICE", 
              headerName: "Стоимость, руб.", 
              width: 150, flex: 1, 
              renderCell:RenderDescription, 
            }, 

            { 
              field: "STATUS", 
              headerName: "Статус", 
              width: 150, flex: 1, 
              renderCell: RenderDescription
          },            
            ]

        return compareColumns
    }

    const genRows = () => {
        let rowData = []
        tariffs?.forEach( function callback (tariffItem) {
            let tmpRow = {id:tariffItem.ID,DURATION:tariffItem.DURATION,PRICE:tariffItem.PRICE,TYP:tariffTranslate.typ[tariffItem.TYP],STATUS:tariffTranslate.status[tariffItem.STATUS],} 
            rowData.push(tmpRow)
        })
        return rowData

    }      
      
    const setTariffValue = (name, value) => {
      let tariffItemCopy = {... tariffItem}
      tariffItemCopy[name] = value
      dispatch(setTariff(tariffItemCopy))
  }
 
  const closeTariffEdit = () => {
    dispatch(setTariff(null))
    dispatch(setTariffs(null))
    replace('/operator/?tab=Reference&component=tariffs')
  }

  const deleteTariffEdit = () => {
    dispatch(setTariff(null))
    dispatch(setTariffs(null))
    dispatch(deleteTariff(tariffItem))
    replace('/operator/?tab=Reference&component=tariffs')
  }

  console.log(tariffItem)

    return <div className={classes.paper}>  
        <Dialog
            className={(dialogClassifier != 'delete')?classes.mainDialog:null}
        open={(dialogClassifier == null)?false:true}
        onClose={сloseDialogClassifier}
        handleClose={сloseDialogClassifier}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
      >
        <DialogTitle id="scroll-dialog-title">
        
        {(dialogClassifier == 'delete')?
        <DialogContentText
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
        Вы действительно хотите удалить «{tariffItem.NAME}»?

        </DialogContentText>
        :null
        }
        </DialogTitle>
        <DialogContent dividers="paper">
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          >
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button 
          onClick={сloseDialogClassifier}
          >Закрыть</Button>
        {dialogClassifier == 'delete'?
        <Button style={{color:'red'}} onClick={()=>{сloseDialogClassifier(); deleteTariffEdit()}}>Удалить</Button>
        :null}          
        </DialogActions>
        </Dialog>      

          {
          ((id)&&(tariffItem)&&(!tariffLoader))?<div style={{minWidth:'380px',maxWidth:'680px', margin:'auto'}}>
          {
          (tariffItem?.DATATIME)?
            <div>     
                <div className={classes.headitemname} style={{paddingBottom:'10px'}}>Изменен: {tariffItem?.DATATIME}</div>
            </div>          
          :<div>     
                <div className={classes.headitemname} style={{paddingBottom:'10px'}}>Новый тариф</div>
            </div>          

          }
          {
          (tariffItem != null)?  
            <div>
            <div>
                    <TextField
                    size="small"
                    style={{ width:'220px'}}
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-status"
                    select
                    label="Статус"
                    defaultValue="active"
                    value={tariffItem.STATUS}
                    
                    onChange={(e)=>setTariffValue('STATUS', e.target.value)}
//                    onChange={setTechSpec}
                    >
                        <MenuItem key="active" value="active">Активный</MenuItem>
                        <MenuItem key="disabled" value="disabled">Не активный</MenuItem>
                    </TextField>                           
            </div>           
            <div>
                    <TextField
                    size="small"
                    style={{ width:'220px'}}
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-TYP"
                    select
                    label="Тип клиента"
                    defaultValue="entity"
                    value={tariffItem.TYP}
                    
                    onChange={(e)=>setTariffValue('TYP', e.target.value)}

                    >
                        <MenuItem key="entity" value="entity">Юридическое лицо</MenuItem>
                        <MenuItem key="individual" value="individual">Физическое лицо</MenuItem>
                    </TextField>                           
            </div>  
            <div>
                <TextField
                            size="small"
                            style={{ width:'300px'}}
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-DURATION"
                            label="Продолжительность, дни"
                            value={tariffItem.DURATION}
                            error={!!error?.tariffDURATION}
                            helperText={error?.tariffDURATION?.text}                                                   
                            onChange={(e)=> setTariffValue('DURATION', e.target.value)}
                            >
                </TextField>
            </div>
            <div>
                <TextField
                            size="small"
                            style={{ width:'300px'}}
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-PRICE"
                            label="Стоимость, рубли"
                            value={tariffItem.PRICE}
                            error={!!error?.tariffPRICE}
                            helperText={error?.tariffPRICE?.text}                                                   

                            onChange={(e)=> setTariffValue('PRICE', e.target.value)}
                            >
                </TextField>
            </div>

           <Grid container spacing={2} justifyContent="center" style={{padding:"20px 0"}}>
                    <Grid item>
                        <Button variant="contained" 
                            //disabled={submitLoader} ]
                            onClick={() => {


                                //setFirmLoaded(false)
                                //dispatch(setGeo(null))                        
                                
                                if (id == 'new') {
                                    dispatch(newTariff(tariffItem))
                                    
                                    
                                }
                                else if (id != null){
                                    
                                    dispatch(updateTariff(tariffItem))
                                    
                                }
                                    
                            }}  
                            style={{color: '#fff', backgroundColor: 'darkgreen', marginBottom:'30px'}}
                            size={'large'} 
                        >
                            Сохранить
                        </Button>
                    </Grid>


                    <Grid item>
                        <Button 
                        style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                        size={'large'} 
                        onClick={()=>closeTariffEdit()}
                        >Закрыть</Button>                    
                    </Grid>                    
            </Grid>              
            </div>
            :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table'}}><Loader/></div>
            }
          </div>  
          :((tariffs)&&(!id)&&(!tariffLoader))?<div >
          <TariffsTable style={{height:'auto'}} add={true} data={genRows()} columns={genColumns()} action={props.action}/>
            </div>
          :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table'}}><Loader/></div>} 
          </div>
}