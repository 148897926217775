import { EQPAPI } from "../../api/api";


const SET_SPEC = 'specReducer/SET_SPEC';
const SET_SPECS = 'specReducer/SET_SPECS';
const SET_OK = 'specReducer/SET_OK';
const SET_LOADER = 'specReducer/SET_LOADER';
const SET_ERROR = "specReducer/ERROR";
const SET_SAVED = "specReducer/SAVED";
const SET_PRM = "specReducer/SET_PRM";


const initialState = {
    spec: null,
    spcs: null,
    ok: null,
    prm: null,
    error: null,
    loader: false,
    saved: false
}

export const specReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SPEC:
            return {
                ...state,
                spec: action.spec
            }
        case SET_SPECS:
            return {
                ...state,
                specs: action.specs
            }    
        case SET_PRM:
            return {
                ...state,
                prm: action.prm
            }                     
        case SET_OK:
            return {
                ...state,
                ok: action.ok
            } 
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_LOADER:
            return {
                ...state,
                loader: action.loader
            }              
        case SET_SAVED:
            return {
                ...state,
                saved: action.saved
            }

        default:
            return state;
    }
}


export const getSpec = state => state.specReducer.spec
export const getPrm = state => state.specReducer.prm
export const getSaved = state => state.specReducer.saved
export const getSpecs = state => state.specReducer.specs
export const getOk = state => state.specReducer.ok
export const getError = state => state.specReducer.error
export const getSpecLoader = state => state.specReducer.loader

export const setSpec = (spec) => ({type: SET_SPEC, spec})
export const setPrm = (prm) => ({type: SET_PRM, prm})
export const setSaved = (saved) => ({type: SET_SAVED, saved})
export const setSpecs = (specs) => ({type: SET_SPECS, specs})
export const setOk = (ok) => ({type: SET_OK, ok})
export const setSpecLoader = (loader) => ({type: SET_LOADER, loader})
export const setError = (error) => ({type: SET_ERROR, error})

export const loadPrm = () => async dispatch => {
    dispatch(setSpecLoader(true))
    try {
        const response = await EQPAPI.getPrm()
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setPrm(result))
            dispatch(setSpecLoader(false))
        }
    }
    catch (error) {
        dispatch(setSpecLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const deleteSpec = (spec) => async dispatch => {
    dispatch(setSpecLoader(true))
    const response = await EQPAPI.deleteSpec(spec)
    
    if (response.status === 200 && response.data.length !== 0) {
        const result = response.data
        dispatch(setSpecs(result));
        dispatch(setSaved(true))
    }
  }


export const loadSpecs = () => async dispatch => {
    dispatch(setSpecLoader(true))
    try {
        const response = await EQPAPI.getSpecs()
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setSpecs(result))
            dispatch(setSpecLoader(false))
        }
    }
    catch (error) {
        dispatch(setSpecLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const loadSpec = (id) => async dispatch => {
    dispatch(setSpecLoader(true))
    try {
        
        const response = await EQPAPI.getSpec(id)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setSpecs(result))
        }


    }
    catch (error) {
        dispatch(setSpecLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}




export const newSpec = (spec) => async dispatch => {

    let error = {iserror: false}

    if ((spec?.NAME?.toString() == null) ||(spec?.NAME.toString() == ''))  error = {...error,  specNAME:   {text: 'Не указано наименование'}, iserror: true}
    if ((spec?.COMMENT?.toString() == null) ||(spec?.COMMENT.toString() == '')) error = {...error,  specCOMMENT:   {text: 'Не указано обозначение'}, iserror: true}
    if ((spec?.TYP?.toString() == null) ||(spec?.TYP.toString() == ''))  error = {...error,  specTYP:   {text: 'Не указан тип'}, iserror: true}
    if ((spec?.EI?.toString() == null) ||(spec?.EI.toString() == ''))  error = {...error,  specEI:   {text: 'Не указана измеряемая величина'}, iserror: true}
    
    if (error.iserror )
    dispatch(setError(error))
    else
    try {
        dispatch(setSpecLoader(true))
        const response = await EQPAPI.newSpec(spec)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setSpecs(result))
            dispatch(setSpecLoader(false))
            dispatch(setError(null))
            dispatch(setSaved(true))
        }
    }
    catch (error) {
        dispatch(setSpecLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const updateSpec = (id, spec) => async dispatch => {
    
    let error = {iserror: false}

    if ((spec?.NAME?.toString() == null) ||(spec?.NAME.toString() == ''))  error = {...error,  specNAME:   {text: 'Не указано наименование'}, iserror: true}
    if ((spec?.COMMENT?.toString() == null) ||(spec?.COMMENT.toString() == '')) error = {...error,  specCOMMENT:   {text: 'Не указано обозначение'}, iserror: true}
    if ((spec?.TYP?.toString() == null) ||(spec?.TYP.toString() == ''))  error = {...error,  specTYP:   {text: 'Не указан тип'}, iserror: true}
    if ((spec?.EI?.toString() == null) ||(spec?.EI.toString() == ''))  error = {...error,  specEI:   {text: 'Не указана измеряемая величина'}, iserror: true}
    
    if (error.iserror )
    dispatch(setError(error))
    else
    
    try {
        dispatch(setSpecLoader(true))

        const response = await EQPAPI.updateSpec(id, spec)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setSpecs(result))
            dispatch(setSpecLoader(false))
            dispatch(setError(null))
            dispatch(setSaved(true))
            
        }
    }
    catch (error) {
        dispatch(setSpecLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}



export const loadSpecEdit = (id) => async dispatch => {
    dispatch(setSpecLoader(true))
    try {
        const response = await EQPAPI.getSpec(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setSpec(result))

        }
        const response2 = await EQPAPI.getPrm()        
        
        if (response2.status === 200 && response2.data.length !== 0) {
            const result = response2.data
            dispatch(setPrm(result))
            dispatch(setSpecLoader(false))
        }

    }
    catch (error) {
        dispatch(setSpecLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const loadAllSpecs = (id) => async dispatch => {
    dispatch(setSpecLoader(true))
    try {
        const response = await EQPAPI.getAllSpecs()
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setSpecs(result))
            dispatch(setSpecLoader(false))
        }
    }
    catch (error) {
        dispatch(setSpecLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}