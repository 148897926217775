import React, {useEffect, useState}  from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import PersonalProfile from "../components/PersonalProfile/PersonalProfile";
import ChangepasswordProfile from "../components/ChangepasswordProfile/ChangepasswordProfile";
import SubscriptionsProfile from "../components/SubscriptionsProfile/SubscriptionsProfile";
import ProtectionProfile from "../components/ProtectionProfile/ProtectionProfile";
import {Tabs} from "../components/TabsProfile/TabsProfile";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: ''
    },
    profile: {
        
        width:'700px',
        ['@media (max-width:700px)']: { 
            width:'100%',
            }  
    },


}))




export const UserProfilePage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)
    const [av, setAv] = useState(false);
    const ssetAv = (av) => {
        setAv(!av)
    }

    const [eqpMeta, setEqpMeta] = useState({title:'Личный кабинет - Импортозамещение и подбор нефтегазового оборудования', 
    description:'Личный кабинет в каталоге нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    
    const tabsConfig = [
        {value: 'personal', label: 'Личные', component: <PersonalProfile av={{av, ssetAv}}/>},
        {value: 'changepassword', label: 'Пароль', component: <ChangepasswordProfile/>},
        {value: 'subscriptions', label: 'Подписки', component: <SubscriptionsProfile/>},
        {value: 'protection', label: 'Защита', component: <ProtectionProfile/>},
    ]

    if (isLoggedIn) 
         return  <HomePrivateLayout av={av} setAv={setAv}>
    <Helmet >
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>                    
                     <div className={classes.paper}>
                        <div className={classes.profile}>
                            <Tabs tabs={tabsConfig} av={av}/>
                        </div>
                     </div>                  
                 </HomePrivateLayout> 
}