import { EQPAPI, ProfileAPI } from "../../api/api";

const SET_TAIFFS = "purchasesReducer/SET_TAIFFS";
const ERROR_LOAD = "purchasesReducer/ERROR_LOAD";
const SET_PURCHASE = 'purchasesReducer/SET_PURCHASE';
const SET_ORG = 'purchasesReducer/SET_ORG';
const SET_ERROR = "purchasesReducer/ERROR";
const SET_LOADER = 'purchasesReducer/SET_LOADER';


const initialState = {
    purchases: null,
    org: null,
    error: null,
    purchase: null,
    loader: false,
    
  };


  export const purchasesReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PURCHASE:
        return {
            ...state,
            purchase: action.purchase
        }
      case SET_ORG:
        return {
            ...state,
            org: action.org
        }

      case SET_LOADER:
        return {
              ...state,
              loader: action.loader
            }              

        case SET_TAIFFS:
        return {
          ...state,
          purchases: action.purchases,
        };
        case ERROR_LOAD:
        return {
            ...state,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }

        default:
            return state;
    }
};


export const getPurchases = (state) => state.purchasesReducer.purchases; 
export const getOrg = (state) => state.purchasesReducer.org; 
export const getPurchase = (state) => state.purchasesReducer.purchase; 
export const getPurchasesError = state => state.purchasesReducer.error
export const getPurchasesLoader = state => state.purchasesReducer.loader

export const setPurchase = (purchase) => ({type: SET_PURCHASE, purchase})
export const setOrg = (org) => ({type: SET_ORG, org})
export const setPurchaseError = (error) => ({type: SET_ERROR, error})
export const setPurchasesLoader = (loader) => ({type: SET_LOADER, loader})
export const setPurchases = (purchases) => ({ type: SET_TAIFFS, purchases});

export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadPurchase = (id) => async dispatch => {
  try {
      
      const response = await EQPAPI.getPurchase(id)
      
      if (response.status === 200 && response.data.length !== 0) {
          const result = response.data
          dispatch(setPurchase(result))
      }


  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}

export const savePurchaseInvoiceFile = (id, invoice_file) => async dispatch => {
  try {
      
      const response = await EQPAPI.uploadPurchaseInvoiceFile(id, invoice_file)
      
      if (response.status === 200 && response.data.length !== 0) {
          const result = response.data
          dispatch(setPurchase(result))
      }


  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}

export const setStatusPaid = (id, setpaid) => async dispatch => {
  try {
      
      const response = await EQPAPI.setPurchaseStatusPaid(id, setpaid)
      
      if (response.status === 200 && response.data.length !== 0) {
          const result = response.data
          dispatch(setPurchase(result))
      }


  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}


export const loadOrg = (id) => async dispatch => {
  try {
      
      const response = await ProfileAPI.getPurchaseOrg(id)
      
      if (response.status === 200 && response.data.length !== 0) {
          const result = response.data
          dispatch(setOrg(result))
      }


  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}


export const loadPurchases = () => async (dispatch) => {
    const response = await EQPAPI.getPurchasesEdit();
    if (response.status === 200 && response.data.length !== 0) {
      dispatch(setPurchases(response.data));

    } else {
      dispatch(errorLoad());
    }

  };

export const newPurchase = (purchase) => async dispatch => {

    let error = {iserror: false}

    if ((purchase?.DURATION?.toString() == null) ||(purchase?.DURATION.toString() == ''))  error = {...error,  purchaseDURATION:   {text: 'Не указан срок действия тарифа в днях'}, iserror: true}
    if ((purchase?.PRICE?.toString() == null) ||(purchase?.PRICE.toString() == ''))  error = {...error,  purchasePRICE:   {text: 'Не указана стоимость'}, iserror: true}
    
    if (error.iserror )
    dispatch(setPurchaseError(error))
    else
    try {
      dispatch(setPurchasesLoader(true))
        const response = await EQPAPI.newPurchase(purchase)
        
        if (response.status === 200 && response.data.length !== 0) {
            dispatch(setPurchasesLoader(false))
            const result = response.data
            dispatch(setPurchase(result))
            dispatch(setPurchaseError(null))
        }
        const response2 = await EQPAPI.getPurchasesEdit();
        if (response2.status === 200 && response2.data.length !== 0) {
          dispatch(setPurchases(response2.data));
    
        }        
    }
    catch (error) {
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const updatePurchase = (purchase) => async dispatch => {

  let error = {iserror: false}

  if ((purchase?.DURATION?.toString() == null) ||(purchase?.DURATION.toString() == ''))  error = {...error,  purchaseDURATION:   {text: 'Не указан срок действия тарифа в днях'}, iserror: true}
  if ((purchase?.PRICE?.toString() == null) ||(purchase?.PRICE.toString() == ''))  error = {...error,  purchasePRICE:   {text: 'Не указана стоимость'}, iserror: true}

  
  if (error.iserror )
  dispatch(setPurchaseError(error))
  else
  try {
    dispatch(setPurchasesLoader(true))
      const response = await EQPAPI.updatePurchase(purchase)
      
      if (response.status === 200 && response.data.length !== 0) {
          dispatch(setPurchasesLoader(false))
          const result = response.data
          dispatch(setPurchase(result))
          dispatch(setPurchaseError(null))
      }
      const response2 = await EQPAPI.getPurchasesEdit();
      if (response2.status === 200 && response2.data.length !== 0) {
        dispatch(setPurchases(response2.data));
  
      }      
  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}

export const deletePurchase = (pic) => async dispatch => {
  dispatch(setPurchasesLoader(true))
  const response = await EQPAPI.deletePic(pic)
  
  if (response.status === 200 && response.data.length !== 0) {
      const result = response.data
      dispatch(setPurchases(result));
      dispatch(setPurchasesLoader(false))
    }
}
