import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import {store} from "./redux/store"
import {setLoggedIn, setTariff} from "./redux/reducers/login-reducer";
import './css/index.css';
import {isTokenExpired} from "./utils/auth";


const refreshToken = localStorage.getItem('refresh_token')

if (refreshToken) {
    if (!isTokenExpired(refreshToken)){
        store.dispatch(setLoggedIn(true))
        store.dispatch(setTariff((localStorage.getItem('tariff') === 'true')?true:false))
    }
} else {
    store.dispatch(setLoggedIn(false))
}


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

//     <App />
// );

ReactDOM.render(
    <App/>,
  document.getElementById('root')
)
