import React, { createRef, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn, getTariff} from "../../redux/reducers/login-reducer";
import BookmarkAddedIcon  from '@mui/icons-material/BookmarkAdded';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Tooltip } from "@material-ui/core";


const changeStatus = (item) => {
item.bookmark = !item.bookmark
}


const Compare = (props) => {

    const isTariff = useSelector(getTariff)
    let out = []

    if ((!isTariff) )
        out.push(
        <Tooltip title={<span style={{ fontSize:'14px'}}>Добавить в сравнение</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
            <BookmarkBorderIcon onClick={()=>{props.action()}} style={{color:'#054C74', cursor:'pointer'}}/>
        </Tooltip>)
    else if (props.status)
        out.push(
        <Tooltip title={<span style={{ fontSize:'14px'}}>Удалить из сравнения</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
            <BookmarkAddedIcon onClick={()=>{props.onClick()}} style={{color:'#bb0000', cursor:'pointer',}}/>
        </Tooltip>
        )
        else
        out.push(
        <Tooltip title={<span style={{ fontSize:'14px'}}>Добавить в сравнение</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
            <BookmarkBorderIcon onClick={()=>{props.onClick()}} style={{color:'#054C74', cursor:'pointer'}}/>
        </Tooltip>)
    
    return out
}
export default Compare;