import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import  Loader  from '../Loader/Loader.jsx';
import Container from '@mui/material/Container';
import Paper from "@material-ui/core/Paper";
import {useQuery} from "../../hooks/useQuery.js";
import {useRedirect} from "../../hooks/useRedirect.js";

const useStyles = makeStyles((theme) => ({

}))


export default function StatisticsQuery() {

    const query = useQuery()
    const {replace} = useRedirect()
    const classes = useStyles()
    const dispatch = useDispatch();

  useEffect(() => {

  }, []);        


   
    return (
        <div >query
        </div>
    )

}