const CREATE_ERROR_NOTIFICATION = 'notificationReducer/CREATE_ERROR_NOTIFICATION'
const CREATE_WARNING_NOTIFICATION = 'notificationReducer/CREATE_WARNING_NOTIFICATION'
const CREATE_INFO_NOTIFICATION = 'notificationReducer/CREATE_INFO_NOTIFICATION'
const CREATE_SUCCESS_NOTIFICATION = 'notificationReducer/CREATE_SUCCESS_NOTIFICATION'
const CLOSE = 'notificationReducer/CLOSE'

const initState = {
    open: false,
    message: '',
    severity: 'info'
}

export const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case CREATE_ERROR_NOTIFICATION:
            return {...state, open: true, message: action.message, severity: 'error'}
        case CREATE_WARNING_NOTIFICATION:
            return {...state, open: true, message: action.message, severity: 'warning'}
        case CREATE_INFO_NOTIFICATION:
            return {...state, open: true, message: action.message, severity: 'info'}
        case CREATE_SUCCESS_NOTIFICATION:
            return {...state, open: true, message: action.message, severity: 'success'}
        case CLOSE:
            return {...state, open: false, message: '', severity: 'info'}
        default:
            return state
    }
}


export const getNotification = (state) => state.notificationReducer


export const createErrorNotification = message => ({type: CREATE_ERROR_NOTIFICATION, message})
export const createWarningNotification = message => ({type: CREATE_WARNING_NOTIFICATION, message})
export const createInfoNotification = message => ({type: CREATE_INFO_NOTIFICATION, message})
export const createSuccessNotification = message => ({type: CREATE_SUCCESS_NOTIFICATION, message})
export const closeNotification = () => ({type: CLOSE})
