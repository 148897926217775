import React, {useRef, useState, useCallback, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import {
    getLogin, getFirstName, getLastName,
    getRole,
    getPassword, getError,
    setLogin,
    setFirstName,
    setLastName, setRole, setINN, setPassword, setLoggedIn, setError, registrationIndividual, getRegistered
} from "../../redux/reducers/registration-reducer";
import {Navigate} from "react-router-dom";
import  Loader  from '../../components/Loader/Loader';


const useStyles = makeStyles((theme) => ({
    loginBox: {
        padding: '10px 50px 20px 50px',
        ['@media (max-width:767px)']: { 
            padding: '10px 10px 50px 10px',
            }          

    },
    button: {
        color: '#fff',
        backgroundColor: '#054C74',
        '&:hover': {
            backgroundColor: '#E6E6E6',
            color: '#1A1A1A',
        },        
    },    
}));

export const RegistrationIndividual = () => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const classes = useStyles()

    const [loaderVisible, setLoaderVisible] = useState('hidden');
    const [buttonVisible, setButtonVisible] = useState('');
    

    const login = useSelector(getLogin)
    const password = useSelector(getPassword)
    const firstname = useSelector(getFirstName)
    const lastname = useSelector(getLastName)
    const role = useSelector(getRole)
    const error = useSelector(getError)
    const isRegistered = useSelector(getRegistered)

    const captchaRef = useRef(null)

    const dispatch = useDispatch()

    
    const [submitLoader, setsubmitLoader] = useState(!false);
    const submitUpdate = useCallback(() => setsubmitLoader(!submitLoader), []);
    

      const [agreeChecked, setAgreeChecked] = useState(false);
    
      const agreeCheckClick = (event) => {
          setAgreeChecked(event.target.checked)
          setsubmitLoader(!event.target.checked);

      }      

    //   useEffect(() => {
    //     submitUpdate();
    //   });

    const onLoginChange = e => {
        dispatch(setLogin(e.target.value))
    }
    const onPasswordChange = e => {
        dispatch(setPassword(e.target.value))
    }

    const onFirstnameChange = e => {
        dispatch(setFirstName(e.target.value))
    }

    const onLastnameChange = e => {
        dispatch(setLastName(e.target.value))
    }




    const submit = () => {
        setLoaderVisible('')
        setButtonVisible('hidden')
        submitUpdate();
        dispatch(registrationIndividual(login, password, 'individual', firstname, lastname))
    }

    useEffect(() => {
        
        if (error != null) {
            setLoaderVisible('hidden')
            setButtonVisible('')
        }
        }, [error])
    if (isRegistered)
    return <Navigate to={'/login'}/>
    
    return <div className={classes.loginBox}>
                <form>
                    <TextField
                    id={'outlined-basic'}
                    label={'Имя'}
                    fullWidth={true}
                    margin={'normal'}
                    autoComplete={'firstname'}
                    variant={'outlined'}
                    type={'string'}
                    error={!!error?.firstname}
                    helperText={error?.firstname?.text}
                    value={firstname}
                    onChange={onFirstnameChange}
//                    onKeyDown={(e) => {
//                    if (e.key === 'Enter') submit()
//                    }}                                        
                    />
                    <TextField
                    id={'outlined-basic'}
                    label={'Фамилия'}
                    fullWidth={true}
                    margin={'normal'}
                    autoComplete={'lastname'}
                    variant={'outlined'}
                    type={'string'}
                    error={!!error?.lastname}
                    helperText={error?.lastname?.text}
                    value={lastname}
                    onChange={onLastnameChange}
//                    onKeyDown={(e) => {
//                    if (e.key === 'Enter') submit()
//                    }}                                        
                    />
                    <TextField
                    id={'outlined-basic'}
                    label={'Электронная почта'}
                    fullWidth={true}
                    margin={'normal'}
                    autoComplete={'username'}
                    variant={'outlined'}
                    type={'email'}
                    error={!!error?.login}
                    helperText={error?.login?.text}
                    value={login}
                    onChange={onLoginChange}
//                    onKeyDown={(e) => {
//                    if (e.key === 'Enter') submit()
//                    }}                                        
                    />
                    <TextField
                    id={'outlined-basic'}
                    label={'Пароль'}
                    fullWidth={true}
                    margin={'normal'}
                    autoComplete={'username'}
                    variant={'outlined'}
                    type={showPassword ? "text" : "password"}
                    error={!!error?.password}
                    helperText={error?.password?.text}
                    value={password}
                    onChange={onPasswordChange}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
//                    onKeyDown={(e) => {
//                    if (e.key === 'Enter') submit()
//                    }}                                        
                    />     
                    <Checkbox checked={agreeChecked} onClick={agreeCheckClick} color="#054C74"/>я даю согласие на обработку своих<Link to="/policy" className="link headerlink">персональных данных</Link>
                    <Grid container spacing={2} justifyContent="center" style={{padding:'20px'}}>
                        <Grid item>
                            <div className={buttonVisible}>
                            <Button variant="contained" disabled={submitLoader}  size={'large'} onClick={submit}  className={classes.button}>
                                Зарегистрироваться
                            </Button>
                            </div>
                            <span className={loaderVisible}><Loader/></span>
                        </Grid>
                    </Grid>                    
                </form>
    </div>
    
}