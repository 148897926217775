import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import {getProfile, getAllTariffs, loadAllTariffs} from "../redux/reducers/user-profile-personal-reducer";
import CheckIcon from '@mui/icons-material/Check';
import { EQPAPI } from "../api/api";
import Grid from "@material-ui/core/Grid";
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Helmet } from "react-helmet";
import {TariffsPanel} from "../components/TariffsPanel/TariffsPanel";  



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '54px',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
        width: '80%',
        ['@media (max-width:1200px)']: { 
            width: '-webkit-fill-available',
            paddingRight: '5px',
            paddingLeft: '5px',
            paddingBottom:'20px',
            height:'auto'
        },
    
    },
    chapterName: {
        fontSize:'30px', 
        textAlign: 'center',
        fontWeight: 400,
        marginBottom:'20px'
    },
    top_tabs: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '50px',
        "& :last-child": {
            borderRadius: '0 3px 3px 0'
          },
          "& :first-child": {
            borderRadius: '3px 0 0 3px'
          },          
    },
    top_tabs_item: {
        width: '50%',
        height: '48px',
        boxSizing: 'border-box',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',

        border: '1px solid #eff0f4',
        cursor: 'pointer',
    },
    div_table: {
        display: 'table',
        width: '-webkit-fill-available',
        backgroundColor: '#fafafa',
        border: '1px solid #666666',
        borderSpacing: '5px',
        marginLeft:'200px'
      },
    div_table_row: {
        display: 'table-row',
        width: 'auto',
        clear: 'both',
        fontWeight: 400,
        
      },
    div_table_col: {
        fontSize:'20px',
        float: 'left',
        display: 'table-column',
        width: '33%',
        padding:'10px 0 10px 0'
        
      },

    lengthPanelSelectedItem: {
        textAlign: 'center', 
        background: '#ba0000',
        color:'#fff', 
        margin:'10px', 
        padding:'10px',
        cursor: 'pointer',
        border: '1px solid #fff',
    },

    lengthPanelItem: {
        textAlign: 'center', 
        color:'#666', 
        margin:'10px', 
        padding:'10px',
        border: '1px solid #666',
        cursor: 'pointer'
    },      
    first_price_block: {
        fontSize:'20px',
        float: 'left',

        
        padding:'10px 0 10px 20px',
        marginLeft:'220px',
        fontWeight: 400,
        ['@media (max-width:1000px)']: { 
            marginLeft: '0px',
            float:'none'
        },        
        ['@media (max-width:470px)']: { 
            padding:'10px 0 10px 10px',
        },        
      },
      price_block: {
        fontSize:'20px',
        float: 'left',
        

        padding:'10px 0 10px 20px',
        
        fontWeight: 400,
        ['@media (max-width:1000px)']: { 
            
            float:'none'
        },   
        ['@media (max-width:470px)']: { 
            padding:'10px 0 10px 20px',
        },                
      },
    price_name: {
        fontWeight: 400,
        lineHeight:'45px',
        fontSize:'20px',
        ['@media (max-width:800px)']: { 
            fontSize:'initial',
        },        
        ['@media (max-width:600px)']: { 
            fontSize:'12px',
        },   
        ['@media (max-width:470px)']: { 
            fontSize:'12px',
        },               
    },
    lengthPanel:{
        width:'200px', 
        float:'left', 
        position:'absolute', 
        paddingTop:'60px',
        ['@media (max-width:1000px)']: { 
            float:'none',
            position:'unset',
            paddingTop:'0px',
            width:'auto'            
        },        
        ['@media (max-width:600px)']: { 

        },         
    },
    priceMobile: {
        ['@media (max-width:440px)']: { 
            display:'none'
        },  
        ['@media (max-width:600px)']: { 
            fontSize:'12px',
        },         
    },
    button: {
        color: '#fff',
        backgroundColor: '#054C74',
        '&:hover': {
            backgroundColor: '#E6E6E6',
            color: '#1A1A1A',
        },        
    },    



}))



export const TariffsPage = () => {

    const isLoggedIn = useSelector(getLoggedIn)
    
    

    if (!isLoggedIn) 
    return <HomePublicLayout>
        <Tariffs/>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout>
            <Tariffs/>
                </HomePrivateLayout>          

     
}



const Tariffs =  () => {
    
    const userProfile = useSelector(getProfile)

    const [selectedTab, setSelectedTab] = useState('individual');
    const tariffsList = useSelector(getAllTariffs)

    const [eqpMeta, setEqpMeta] = useState({title:'Тарифы - Импортозамещение и подбор нефтегазового оборудования', description:'Тарифы на подключение к каталогу нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});

    const dispatch = useDispatch()

    useEffect(() => {
            dispatch(loadAllTariffs)
            // EQPAPI.getTariffs()
            // .then((res) => setTariffsList(res?.data))

        }, [])

    const classes = useStyles()
    const  selectedTabStyle = {color: '#fff',  backgroundColor: '#ba0000', border: '1px solid #fff'}

    
    return <div className={classes.paper}>
    
    <Helmet >
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/tariffs"} rel="canonical"></link>
    </Helmet>        
        <div style={{flex: '0 0 100%', maxWidth: '100%'}}>
        <div className={classes.chapterName}>Тарифы</div>
        
        <div class={classes.top_tabs}>
            <div className={classes.top_tabs_item} style={selectedTab=='individual'?selectedTabStyle:null} onClick = {() => setSelectedTab('individual')}>Физическое лицо</div>
            <div className={classes.top_tabs_item} style={selectedTab=='entity'?selectedTabStyle:null} onClick = {() =>setSelectedTab('entity')}>Юридическое лицо</div>
        </div>
        <TariffsPanel tariffsList={tariffsList} selectedTab={selectedTab}/>
        </div>
                     
    </div>

}

