import React from 'react';
import {Provider} from "react-redux";
import {persistor, store} from "../redux/store";
import {BrowserRouter} from "react-router-dom";
import {AuthRouter} from "../containers/AuthRouter/AuthRouter";
import { PersistGate } from 'redux-persist/integration/react'
import {useSelector} from "react-redux";
import {getProfile} from "../redux/reducers/user-profile-personal-reducer";
import {Redirect, Route, Switch} from "react-router-dom";

import './../css/App.css';
import './../css/media.css';

function App() {


  return <Provider store={store}>
      <BrowserRouter>
        <AuthRouter/>
      </BrowserRouter>    
    </Provider>
    // <div className="App">
    //   <div className="App-header">

    //     <div className="App-header-box">

    //     </div>


    //   </div>

    //   <div className="App-footer">

    //     <div className="App-footer-box">

    //     </div>


    //   </div>

    // </div>
}

export default App;
