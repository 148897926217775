import React from 'react';
import {    Link  } from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";

export const PleaseRegisterDialog = (props) => {
    const {open, onClose, submitCallback} = props

    const handleCloseDialog = () => {
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleCloseDialog} maxWidth={"xs"} fullWidth>
            <DialogTitle>Платный функционал</DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                Эта функция платная!<br/>
                Подберите для себя <Link to="/tariffs" className="link headerlink" Style={'padding:0px'}>подходящий тариф</Link>.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>
                    <span className="link">Ok</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PleaseRegisterDialog;