import React, { createRef, useState, useEffect, useCallback } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SearchIcon from '@material-ui/icons/Search';
import Tune from '@material-ui/icons/Tune';
import { createSearchParams, useSearchParams, Link } from "react-router-dom";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";  
import {useDialog} from "../../hooks/useDialog.js";

const useStyles = makeStyles((theme) => ({

    promoPanelSearchTerms: {
        marginTop: '20px',
        borderLeft: '2px solid #868686',
        borderTop: '2px solid #868686',
        borderBottom: '2px solid #868686',
        borderRight:0,
        
        width: '635px',
        height: '40px',
        borderRadius: '5px 0px 0px 5px',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        paddingLeft: '10px',
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '350px',
              borderRight: '2px solid #868686',
              borderRadius: '5px',
          },
          ['@media (max-width:360px)']: { 
            width: '300px',
          }
      },
      promoPanelSearchButon: {
        background: '#054C74',
        borderRadius: '0px 5px 5px 0px',
        width: '115px',
        height: '40px',
        outline: 'none',
        border: '0',
        color: 'white',
        cursor: 'pointer',
        ['@media (max-width:500px)']: { 
            display: 'none'
            }           
      },
      promoPanelAdv: {
        cursor: 'pointer',
        position: 'absolute',
        right: '125px',
        top: '28px',
        ['@media (max-width:500px)']: { 
            display: 'none'
            }  
    },  
    promoPanelSearch: {
        position: 'absolute',
        right: '5px',
        top: '28px',
        display: 'none',
        ['@media (max-width:500px)']: { 
            display: 'block'
            }  
    },     
}))





export const SearchBar = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState(searchParams.get("query")?searchParams.get("query"):'');    
    const classes = useStyles()    

    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()

    const onSearchChange = e => {
        setQuery(e.target.value)
    }    

    const search = () => {
        if (query != '') {
              window.open('/eqpsearch/?query='+query,"_self");
    
        }
      }    
return <div style={{position:'absolute'}}>
        <PleaseRegisterDialog
        open={pleaseRegisterDialogOpen}
        onClose={pleaseRegisterDialogHandleClose}
        />           
        
            <input type="text" onKeyDown={(e) => { if (e.key === 'Enter') search()}} autocomplete="off" onChange={onSearchChange} className={classes.promoPanelSearchTerms} id="search-terms" placeholder="Наименование или шифр оборудования" value={query}/>
            <button onClick={search} className={classes.promoPanelSearchButon} >Найти</button>
            <Link to="/advanced" className="link headerlinkImageText"><Tune className={classes.promoPanelAdv}/></Link>
            <SearchIcon onClick={search} className={classes.promoPanelSearch}/>
        </div>


    
}