import { EQPAPI } from "../../api/api";
import Loader from "@material-ui/core/CircularProgress";
import Counter from "../../utils/Counter";

const SUCCESS_LOAD = "promoReducer/SUCCESS_LOAD";
const ERROR_LOAD = "promoReducer/ERROR_LOAD";

const initialState = {
    counters: {
      prod_count:<Counter />,
      drill_count:<Counter />,
      company_count:<Counter />,
    },
    loading: true,
    error: null,
  };


export const getPromo = (state) => state.promoReducer;  

export const promoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_LOAD:
        return {
          ...state,
          counters: action.counters,
          loading: false,
        };
        case ERROR_LOAD:
        return {
            ...state,
            loading: false,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };        
        default:
            return state;
    }
};

export const successLoad = (counters) => ({
    type: SUCCESS_LOAD,
    counters,
  });
  
export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadingPromo = () => async (dispatch) => {
      setTimeout( async () => {
      const response = await EQPAPI.getPromo();
      if (response.status === 200 && response.data.length !== 0) {
        const new_data = response.data;
      
        dispatch(successLoad(new_data));

      } else {
        dispatch(errorLoad());
      }
    }, 1000);
    };

