import { ProfileAPI } from "../../api/api";
import Loader from "@material-ui/core/CircularProgress";
import Counter from "../../utils/Counter";

const SUCCESS_LOAD = "activationReducer/SUCCESS_LOAD";
const ERROR_LOAD = "activationReducer/ERROR_LOAD";
const SET_CODE = "activationReducer/SET_CODE";
const SET_ID = "activationReducer/SET_ID";
const GET_CODE = "activationReducer/GET_CODE";
const GET_ID = "activationReducer/GET_ID";
const SET_ERROR = 'activationReducer/SET_ERROR';


const initialState = {
    code: "",
    id: null,
    isActivated: false,
    error: null,
  };


export const getCode = (state) => state.activationReducer.code;  
export const getId = (state) => state.activationReducer.id; 

export const setCode = (code) => ({type: SET_CODE, code})
export const setId = (id) => ({type: SET_ID, id})

export const setError = (error) => ({type: SET_ERROR, error})
export const getError = state => state.activationReducer.error

export const setActivated = (isActivated) => ({type: SUCCESS_LOAD, isActivated})
export const getActivated = (state) => {

  return state.activationReducer.isActivated
}  


export const activationReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_CODE:
        return {
          ...state,
          code: action.code,
        };
        case SET_ID:
        return {
          ...state,
          id: action.id,
        };
      case SUCCESS_LOAD:
        return {
          ...state,
          isActivated: action.isActivated,
        };
        case ERROR_LOAD:
        return {
            ...state,
            error: "Проверочный код не подошел",
        };  
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        default:
            return state;
    }
};

export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadActivation = (code, id) => async (dispatch) => {

      if (!code) {
        let error = {}
        error = {...error, code: {text: 'Укажите код активации'}}
        dispatch(setError(error))

      }
      else {

      const response = await ProfileAPI.activateProfile(code, id);
      if (response.status === 200) {
        dispatch(setActivated(true))
        dispatch(setCode(''))
        dispatch(setId(''))
        dispatch(setError(null))
      } else {
        let error = {}
        error = {...error, code: {text: 'Проверочный код не подошел'}}
        dispatch(setError(error))
      }
    }
    };

