import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getIV, setIV, loadIV, newIV, updateIV, deleteIV, loadEI, getEI, setEI, newEI,updateEI, getError, getLoading, setLoading, setError, deleteEI } from "../../redux/reducers/iv-reducer";
import {useDispatch, useSelector} from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ConstructionIcon  from '@mui/icons-material/Construction';
import {OkPanelSkeleton} from "../../components/SearchSkeleton/SearchSkeleton.jsx";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {Button, ButtonGroup, Divider, Tooltip, Typography} from "@material-ui/core";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';




const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
}))

let treeAction = null

const getTreeItemsFromData = (treeItems, firstLAyer = false) => {

    return treeItems.map(treeItemData => {

      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }
      return (
        ((treeItemData.children.length==0)&&(firstLAyer))?
        <TreeItem
             key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          endIcon = {null}
          onClick={()=> {treeAction(treeItemData.id,treeItemData.name,firstLAyer)}}
        />:
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          endIcon = {<ConstructionIcon/>}
          onClick={()=> {treeAction(treeItemData.id,treeItemData.name,firstLAyer)}}
        />        
      );
    });
  };

const DataTreeView = ({ treeItems, action }, ) => {

  treeAction = action
  return (
    (treeItems!=null)?
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      
    >
      {getTreeItemsFromData(treeItems, true)}
    </TreeView>
    :''
  );
};  

export const IVEdit = (props) => {

    const classes = useStyles()
    const iv = useSelector(getIV);    
    const ei = useSelector(getEI);    
    const error = useSelector(getError)
    const eiLoading = useSelector(getLoading)
    
  

    const [selectedItem, setSelectedItem] = useState(null);
    //const [selectedEIItem, setSelectedEIItem] = useState(null);
    const [dialogClassifier, setDialogClassifier] = useState(null);
    const [ivName, setIvName] = useState(null);

    const сloseDialogClassifier = () => {
      setDialogClassifier(null);
    };

    const selectItem = (id, name) => {
      setSelectedItem({id:id, name:name})
      dispatch(setEI(null))
    }


    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(loadIV());
      }, []);        

    useEffect(()=>{
      if (selectedItem?.id < 1000000000)
        dispatch(loadEI(selectedItem?.id))

    },[selectedItem])

    useEffect(() => {
      
      if ((eiLoading == false)&&((dialogClassifier == 'add')||(dialogClassifier == 'edit')))
        сloseDialogClassifier()
      
    }, [eiLoading]);        


    const saveNew = () => {
      dispatch(setLoading(true))
      if (selectedItem==null)
      dispatch(newIV({NAME:ivName}))
      else{

      dispatch(newEI(ei,selectedItem?.id))
      }
      setIvName(null)

      
    }

    const updateEdited = () => {
      if (ei==null)
      dispatch(updateIV({ID:selectedItem.id, NAME:ivName}))
      else{
        dispatch(setLoading(true))
        dispatch(updateEI(ei))
        }      
      setIvName(null)
      сloseDialogClassifier()
    }    

    const deleteEdited = () => {
      if ((selectedItem!=null)&&(selectedItem?.id>1000000000))
      dispatch(deleteIV({ID:selectedItem.id}))
      else{
        dispatch(setLoading(true))
        dispatch(deleteEI(ei))
        }      
      setSelectedItem(null)
      setIvName(null)
      сloseDialogClassifier()
    }    

    const setEiValue = (name, value) => {
      let eiCopy = {... ei}
      eiCopy[name] = value
      dispatch(setEI(eiCopy))
  }

  
    const okDialog = (props) => {
      
      return  <Dialog
      className={classes.mainDialog}
      open={(dialogClassifier == null)?false:true}
      onClose={сloseDialogClassifier}
      handleClose={сloseDialogClassifier}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      style = {{minWidth: 500}}
      >

      <DialogTitle id="scroll-dialog-title">
        {
        (dialogClassifier == 'add')?
          <h2>Добавить новую {(selectedItem == null)?' измеряемую величину':' единицу измерения'}{(selectedItem?.id > 1000000000)?' в "'+selectedItem?.name+'"':null}</h2>
        :(dialogClassifier == 'edit')?
          <h2>Изменить {(selectedItem?.id >1000000000)?'измеряемую величину':'единицу измерения'}</h2>
        :(dialogClassifier == 'delete')?
          <h2>Удалить {(selectedItem?.id >1000000000)?'измеряемую величину':'единицу измерения'}</h2>

        :null
        }
        
      </DialogTitle>
      <DialogContent dividers="paper">
      {(dialogClassifier == 'delete')?
        <DialogContentText
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
        Вы действительно хотите удалить «{selectedItem.name}»

        </DialogContentText>
        :
        ( (selectedItem==null)||( (selectedItem?.id>1000000000) && (dialogClassifier == 'edit') ) )?
        <DialogContentText
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
           <TextField
                        
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-add-okp"
                        label="Наименование"
                        value={ivName}
                        style = {{width: 400}}
                        onChange={(e)=> setIvName(e.target.value)}
                        //onChange={setOwnerItem}
                        ></TextField>
          
        </DialogContentText>
        :
        <DialogContentText
        id="scroll-dialog-description"
        //ref={descriptionElementRef}
        tabIndex={-1}
        >             {(ei != null)||((dialogClassifier == 'add'))?
                  <div>
                     <TextField
                        
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-add-einame"
                        label="Наименование"
                        value={ei?.NAME}
                        style = {{width: 400}}
                        error={!!error?.eiNAME}
                        helperText={error?.eiNAME?.text}                        
                        onChange={(e)=> setEiValue('NAME', e.target.value)}
                        //onChange={setOwnerItem}
                        ></TextField>
                     <TextField
                        
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-add-eirusl"
                        label="Сокращенное наименование"
                        value={ei?.R_USL}
                        style = {{width: 400}}
                        error={!!error?.eiR_USL}
                        helperText={error?.eiR_USL?.text}                        

                        onChange={(e)=> setEiValue('R_USL', e.target.value)}
                        //onChange={(e)=> setIvName(e.target.value)}
                        //onChange={setOwnerItem}
                        ></TextField>    
                     <TextField
                        
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-add-eirk"
                        label="Расчетный коэффициент"
                        value={ei?.CALC}
                        style = {{width: 400}}
                        onChange={(e)=> setEiValue('CALC', e.target.value)}
                        error={!!error?.eiCALC}
                        helperText={error?.eiCALC?.text}                              
                        //onChange={(e)=> setIvName(e.target.value)}
                        //onChange={setOwnerItem}
                        ></TextField>  
                        </div>          
                        :<OkPanelSkeleton/>}                                 

        </DialogContentText>

    }
      </DialogContent>
      <DialogActions>
        <Button style={{color:'#054C74'}} onClick={сloseDialogClassifier}>Отмена</Button>
        {dialogClassifier == 'delete'?
        <Button style={{color:'red'}} onClick={()=>deleteEdited()}>Удалить</Button>
        :<Button style={{color:'#054C74'}} onClick={()=>(dialogClassifier == 'add')?saveNew():updateEdited()}>Сохранить</Button>
        }
      </DialogActions>
    </Dialog> 

    }
  

    if (dialogClassifier == 'close')
      сloseDialogClassifier()
  
    return  <div className={classes.paper} style={{float:'left'}}>
            {okDialog()}
            <div style={{width:'100%'}}>

              <ButtonGroup style={{padding:'10px'}} size={'small'} variant={'text'} hidden >
                <Button 
                  disabled={selectedItem?.id<1000000000}
                  onClick={()=>{dispatch(setEI(null));dispatch(setError(null));setDialogClassifier('add')}}
                ><AddOutlinedIcon/></Button>
                <Button 
                  disabled={!selectedItem?.id} 
                  onClick={()=>{setIvName(selectedItem.name);setDialogClassifier('edit')}}
                  //</ButtonGroup>onClick={onEdit}
                >
                    <EditOutlinedIcon/></Button>
                <Button 
                  disabled={!selectedItem?.id} 
                  onClick={()=>{setIvName(selectedItem.name);setDialogClassifier('delete')}}
                  ///onClick={onDelete}
                ><DeleteOutlinedIcon/></Button>
              </ButtonGroup>
            </div>

            <div className={(!iv)?'':'hidden'}><OkPanelSkeleton/></div>
            <DataTreeView treeItems={iv} action={selectItem} />
            </div>

}