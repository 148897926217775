import React, {useState, useEffect }from 'react';
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getAlerts, getError, getTf, setTf, getTfCode, setTfCode, getLoader,getProfile, loadProfileData, getLoading, setLoader, getMessage, saveProtect, setMessage
} from "../../redux/reducers/user-profile-personal-reducer";
import TextField from "@material-ui/core/TextField";
import Loader from "../Loader/Loader.jsx";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  subscriptionsArea: {
      paddingTop: '10px',
  },
  personalArea: {
    paddingTop: '10px',
    display: 'flex',
    ['@media (max-width:767px)']: { 
      display: 'block',
      }         
  },
  button: {
    color: '#fff',
    backgroundColor: '#054C74',
    '&:hover': {
        backgroundColor: '#E6E6E6',
        color: '#1A1A1A',
    },        
  },  
  formArea: {
    float:'left',
    ['@media (max-width:767px)']: { 
      float: 'none',
      }   
  },
  formlineLeftItem: {
    float:'left', 
    minWidth:'150px', 
    textAlign:'right', 
    paddingRight: '10px', 
    lineHeight:'85px'
  },
  formlineRightItem: {
    float:"left", 
    width: "350px"
  }
}))

export default function ProtectionProfile() {
  const classes = useStyles()
  const [buttonVisible, setButtonVisible] = useState(true);
  const [buttonVisible2, setButtonVisible2] = useState(true);
  const [userCode, setUserCode] = useState('');

  const profile = useSelector(getProfile)
  const tf = useSelector(getTf)
  const tfcode = useSelector(getTfCode)
  const saveLoader = useSelector(getLoader)
  const loading = useSelector(getLoading)
  const message = useSelector(getMessage)
  const error = useSelector(getError)

  const dispatch = useDispatch()

  useEffect(() => {
   // dispatch(loadProfileData)
  }, [])  

  const saveProtectionAction = () => {
    dispatch(setMessage(''))
    dispatch(setLoader(true))
    setButtonVisible(false)
    dispatch(saveProtect(tf))
  }

  const saveProtectionAction2= () => {
    dispatch(setMessage(''))
    dispatch(setLoader(true))
    setButtonVisible2(false)
    dispatch(saveProtect(tf, userCode))
  }


  const onCodeChange = e => {
    setUserCode(e.target.value)    
}


  const mailTfClick = (event) => {
    dispatch(setTf(event.target.checked))
  } 

  if (loading)
  return ( <div style={{display: 'table', paddingTop:'100px', margin: 'auto'}}><Loader/></div>
    )
else
    return (
      <div className={classes.personalArea}>
        <div className={classes.formArea}>
          {(!tfcode)? 
            <div>
                <div>
                    <Checkbox checked={tf} onClick={mailTfClick} color="#054C74"/>Двухфакторная аутентификация по электронной почте: {profile.user.email}
                </div>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                    <div className={!saveLoader?'':'hidden'} style={{width:'100%', paddingTop:'40px'}}>
                        <Button variant="contained"  size={'large'} onClick={saveProtectionAction}  className={classes.button}>
                        Сохранить изменения
                        </Button>
                    </div>
                    <span className={saveLoader?'':'hidden'}><Loader/></span>
                    </Grid>
                </Grid> 
            </div>
            :
            <div className={classes.activationBox}>Для подтверждения действия, укажите код, направленный на адрес: {profile.user.email}

            <TextField
                        id={'outlined-basic'}
                        label={'Код'}
                        style = {{width: 300}}
                        margin={'normal'}
                        variant={'outlined'}
                        error={!!error}
                        helperText={error?.userCode?.text}
                        value={userCode}
                        onChange={onCodeChange}
                        onKeyDown={(e) => {
                       if (e.key === 'Enter') saveProtectionAction2()
                        }}                                        
                        />
            <span  className={!saveLoader?'':'hidden'}>                        
            <Button 
                variant="contained" 
                
                //disabled={submitLoader}
                onClick={saveProtectionAction2}
                size={'large'}
                style={{margin: '20px 0 0 10px'}}
                className={classes.button}>
                    Отправить
            </Button>
            </span>
            
                <span className={saveLoader?'':'hidden'}><div style={{margin: '20px 0 0 10px', display: 'inline-flex'}}><Loader/></div></span>
            
            </div>          
            }
          <div style={{textAlign:'center', paddingTop:'20px'}}>{message}</div>
        </div>
      </div>
    );
  }