import React from 'react';
import {generatePath, useNavigate} from "react-router-dom";

export const useRedirect = () => {
    const history = useNavigate()

    const redirect = (to, params = {}) => {
        history(generatePath(to, params))
    }

    const replace = (to, params = {}) => {
        history(generatePath(to, params), { replace: true })
    }

    return {redirect, replace, history}
}