import React, { createRef, useState, useEffect, useCallback } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getLoggedIn, getTariff} from "../../redux/reducers/login-reducer";
import {loadProfileData} from "../../redux/reducers/user-profile-personal-reducer";
import {setBookmarkStatusEq,setCompareStatusEq, getLoadBmEq, setLoadBmEq, getLoadCmEq, setLoadCmEq, loadEQ, getEQ, setEQ} from "../../redux/reducers/eq-reducer";
import {setCompareStatus, setBookmarkStatus,getLoadCm,setLoadCm, getLoadBm, setLoadBm, loadASearch, loadFSearch, loadSearch, getSearchResult, setSearchResult, getSearchOk, getLoadingResult, getLoadingOk, getError, loadOk, setSearchOk, getSearchBTP, loadBTP, setSearchBTP} from "../../redux/reducers/search-reducer";
import {getEqFirm, setEqFirm, getFirm, getFirmError, getFirmLoader, setFirm, setFirmLoader, setFirmError, loadFirm} from "../../redux/reducers/firm-reducer";
import { createSearchParams, useSearchParams, Link, useParams } from "react-router-dom";
import Tune from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import {useDispatch, useSelector} from "react-redux";
import {SearchSkeleton, OkSkeleton} from "../SearchSkeleton/SearchSkeleton.jsx";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Checkbox from "@material-ui/core/Checkbox";
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {RuIcon} from '../RuIcon/RuIcon';
import {useRedirect} from "../../hooks/useRedirect";
import Tooltip from '@material-ui/core/Tooltip';
import {useDialog} from "../../hooks/useDialog.js";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";  
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {useWindowSize} from '../../hooks/useWindowSize.js';
import Slider from '@material-ui/core/Slider';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {API_SERVER_VAL} from "../../config";
import Lightbox  from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Bookmark from "../Bookmark/Bookmark"
import Compare from "../Compare/Compare"
import { AnalogMenu } from "../AnalogMenu/AnalogMenu";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import  Loader  from '../Loader/Loader';
import {getProfile} from "../../redux/reducers/user-profile-personal-reducer";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import IconButton from '@mui/material/IconButton';
import { Helmet } from "react-helmet";


const baseUrl = `${API_SERVER_VAL}/api/eqp`

const useStyles = makeStyles((theme) => ({
    headitemname : {
        color: '#929292'
    },
    headitemvalueFirm: {
        cursor:'pointer', 
        color:'#054C74', 
        display:'block',
        width: 'fit-content',
        textDecoration: 'underline', 
        textDecorationStyle: 'dashed', 
        textDecorationThickness:'1px', 
        paddingTop:'3px', 
        lineHeight:'20px'
    },
    headitemvalueFirmNotActive: {
        cursor:'pointer', 
        color:'darkred', 
        display:'block',
        width: 'fit-content',
        textDecoration: 'underline', 
        textDecorationStyle: 'dashed', 
        textDecorationThickness:'1px', 
        paddingTop:'3px', 
        lineHeight:'20px'
    },

    forksExtr: {float: 'right',margin:'10px 0',
        ['@media (max-width:600px)']: { 
            float:'left'
        }
    
    },

    filterButton: {float:"left", display:"flex", cursor:"pointer", padding:'3px',},

    pagination: {display:'flex', padding: '15px',
    ['@media (max-width:500px)']: { 
    display: 'none'
    }
    },
    orderMenu: { minWidth:'100px',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    }, 
    forksMenu: {
        "& .MuiInputLabel-root, fieldset": {
            fontSize: "1.1rem",
            color:'black',
            fontWeight:'500',

          }
    },
    imageGallery: {
        "& .carousel-root": {
            margin: "auto",
          },
        "& img": {
            maxHeight: "160px",
          },

    },    
    searchResultItemName: {fontSize:'18px', maxWidth: '650px',height:'auto', paddingLeft: '0', color: '#054C74', paddingTop:'5px', paddingBottom:'10px',
    ['@media (max-width:860px)']: { 
        maxWidth: 'fit-content',
        margin: '0 5px'
    },   

    ['@media (max-width:500px)']: { 
        maxWidth: 'none',

        width: 'auto',
        paddingLeft:'5px'
    }
    },
    searchResultItemOk: { float: 'none', maxWidth: '650px',
    ['@media (max-width:500px)']: { 
        
        paddingLeft:'5px'
    }
    },    
    searchResultFooter: {width:'100%', textAlign:'center', paddingTop: '20px', display: 'flow-root',
    ['@media (max-width:500px)']: { 
        width: 'auto',
      
    }    
    },
    searchResultMain: {paddingTop: '10px', paddingBottom: '0px', width:'860px', 
    ['@media (max-width:860px)']: { 
        width: '-webkit-fill-available',
        margin: '0 5px'
    },   
    ['@media (max-width:500px)']: { 
        width: '-webkit-fill-available',
    }
    },
    searchHeaderOk: {width:'860px', paddingTop:'10px',
    ['@media (max-width:860px)']: { 
        width: 'auto',
        margin: '0 5px'
    },       
    ['@media (max-width:500px)']: { 
        width: 'auto',
        '& .MuiChip-label': { fontSize: '14px' }, 
        
    }
    },
    searchHeaderTextLine: {fontWeight: '400', fontSize: '26px', lineHeight: '32px', width: '860px',
    ['@media (max-width:860px)']: { 
        width: 'auto',
        margin: '0 10px'
    },       
    ['@media (max-width:500px)']: { 
        width: 'auto',
        fontSize: '17px',
        lineHeight: '20px',
    }  
    },
    customBadge: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '10px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },
      customBadge2: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '4px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },

    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px'
    },
    button: {
        color: '#054C74',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#eeeeee',
            color: '#054C74',
        },        
    },

    promoPanelSearchTerms: {
        marginTop: '20px',
        borderLeft: '2px solid #868686',
        borderTop: '2px solid #868686',
        borderBottom: '2px solid #868686',
        borderRight:0,
        
        width: '635px',
        height: '40px',
        borderRadius: '5px 0px 0px 5px',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        paddingLeft: '10px',
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '350px',
              borderRight: '2px solid #868686',
              borderRadius: '5px',
          },
          ['@media (max-width:360px)']: { 
            width: '300px',
          }
      },
      promoPanelSearchButon: {
        background: '#054C74',
        borderRadius: '0px 5px 5px 0px',
        width: '115px',
        height: '40px',
        outline: 'none',
        border: '0',
        color: 'white',
        cursor: 'pointer',
        ['@media (max-width:500px)']: { 
            display: 'none'
            }           
      },
      promoPanelAdv: {
        cursor: 'pointer',
        position: 'absolute',
        right: '125px',
        top: '28px',
        ['@media (max-width:500px)']: { 
            display: 'none'
            }  
    },               
    promoPanelSearch: {
        position: 'absolute',
        right: '5px',
        top: '28px',
        display: 'none',
        ['@media (max-width:500px)']: { 
            display: 'block'
            }  
    },  
    searchResultStyle: {float:'none', display:'flex', width:'860px', minHeight:'185px',marginBottom: '20px', border: '1px solid #868686', borderRadius: '5px',  margin:'auto',
    ['@media (max-width:860px)']: { 
        width: '-webkit-fill-available',
        display:'table',
        margin: '10px 5px'
    },       
    ['@media (max-width:500px)']: { 
        width: 'auto',
        fontSize: '17px',
        lineHeight: '20px',
    } 
    },
    ascStyle:{transform: 'scaleY(-1)'},
    descStyle:{transform: 'none'},

    searchResultStyleGrid: {float:'left', width:'260px', minHeight:'550px', border: '1px solid #868686', borderRadius: '5px',  margin:'5px 5px 20px 5px', padding: '5px', 
    ['@media (max-width:860px)']: { 
        width: '255px',
        margin: '10px 5px'
    },       
    ['@media (max-width:500px)']: { 
        width: '260px',
        fontSize: '17px',
        lineHeight: '20px',
    } 
    },    
    selectedFormat: {backgroundColor:'#E6E6E6'},


 
}))


export const Search = (av = null) => {

    

    const {option} = useParams()
    const windowSize = useWindowSize()

    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()

    let clickSlider = false
    

    const isTariff = useSelector(getTariff)
    let searchResult = useSelector(getSearchResult)
    const loadBm = useSelector(getLoadBm)
    const loadCm = useSelector(getLoadCm)
    let {user} = useSelector(getProfile)

    const searchOk = useSelector(getSearchOk)
    const searchBTP = useSelector(getSearchBTP)
    const searchLoadingResult = useSelector(getLoadingResult)
    const searchLoadingOk = useSelector(getLoadingOk)
    const searchError = useSelector(getError)
    
    const eqItem = useSelector(getEQ)

    const firm = useSelector(getFirm)
    const eqFirm = useSelector(getEqFirm)  

    const dispatch = useDispatch();
    const classes = useStyles()
    const {replace} = useRedirect()

    const [searchParams, setSearchParams] = useSearchParams();

    const [query, setQuery] = useState(searchParams.get("query")?searchParams.get("query"):'');
    const [ok, setOk] = useState(searchParams.get("ok"));
    const [eq, setEq] = useState(searchParams.get("eq"));
    const [chap, setChap] = useState(searchParams.get("chap"));

    const [aname, setAname] = useState(searchParams.get("aname"));
    const [ashifr, setAshifr] = useState(searchParams.get("ashifr"));
    const [aok, setAok] = useState(searchParams.get("aok"));
    const [aokname, setAokName] = useState(searchParams.get("aokname"));
    const [acompany, setAcompany] = useState(searchParams.get("acompany"));
    const [aregion, setAregion] = useState(searchParams.get("aregion"));
    const [advanced, setAdvanced] = useState((option=='advanced'?true:false));
    const [analogues, setAnalogues] = useState((option=='analogues'?true:false));
    const [ruanalogues, setRuAnalogues] = useState((option=='ruanalogues'?true:false));
    const [favorite, setFavorite] = useState((option=='favorite'?true:false));
    const [compare, setComparer] = useState((option=='compare'?true:false));
    const [searchAnalogsArr, setSearchAnalogsArr] = useState([])
    const [target, setTarget] = useState(option=='analogues'?'analogues':option=='ruanalogues'?'ruanalogues':'none')
    const [firmDetails, setFirmDetails] =  useState(null);

    const [currentPage, setCurrentPage] = useState(searchParams.get("page")?Number(searchParams.get("page")):1);
    const [notFound, setNotFound] = useState(false);
    const [showMore, setShowMore] = useState(true);
    const [analogsPanel, setAnalogsPanel] = useState(null);

    const [showOkPanel, setShowOkPanel] = useState({name:null,fullname:null,count:null,show:true,okid:null});
    const [showFullScreenImage, setShowFullScreenImage] = useState({open:false, images:null, name:null});
    const [filterUse, setFilterUse] = useState(false);  
    const [gridFormat, setGridFormat] = useState(false);
    const [listFormat, setListFormat] = useState(true);
    const [sortResult, setSortResult] = useState('name');
    const [orderResult, setOrderResult] = useState('asc');
    const [BTP, setBTP] = useState(null)
    const [filters, setFilters] = useState([]);
    const [mainQuery, setMainQuery] = useState('');    
    const [searchResultOut, setSearchResultOut] =  useState([]);
    const [searchResultFull, setSearchResultFull] =  useState(null);
    const [searchResultPart, setSearchResultPart] =  useState(null);
    const [showAllOk, setShowAllOk] =  useState(false);
    const [showFilter, setShowFilter] =  useState(false);
    const [actBookmark, setActBookmark] =  useState(null);
    const [actCompare, setActCompare] =  useState(null);

    const [eqpMeta, setEqpMeta] = useState({title:'', description:' в каталоге нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});

    
    const onSearchChange = e => {
        setQuery(e.target.value)
    }

    const search = () => {
      if (query != '') {
            window.open('/eqpsearch/?query='+query,"_self");

      }
    }


    const genQueryString = () =>{
        let amp = false;    
        let qs = '/eqpsearch/'
        
        if(advanced == true) {
            qs+='advanced/'
            if (aname) {
                qs+=(amp)?'&':'?'
                qs+='aname='+aname
                amp = true
            }
            if (ashifr) {
                qs+=(amp)?'&':'?'
                qs+='ashifr='+ashifr
                amp = true
            }
            if (aok) {
                qs+=(amp)?'&':'?'
                qs+='aok='+aok+'&aokname='+aokname
                amp = true
            }
            if (acompany) {
                qs+=(amp)?'&':'?'
                qs+='acompany='+acompany
                amp = true
            }
            if (aregion) {
                qs+=(amp)?'&':'?'
                qs+='aregion='+aregion
                amp = true
            }
        } else
        if(favorite == true) {
            qs+='favorite/?favorite=true'
        }
        else         
        if(analogues == true) {
            qs+='analogues/'
            if (eq) {
                qs+=(amp)?'&':'?'
                qs+='eq='+eq
                amp = true
            }
            if (ok) {
                qs+=(amp)?'&':'?'
                qs+='ok='+ok+'&okname='+searchParams.get("okname")
                amp = true
            }            
        }
        else         
        if(ruanalogues == true) {
            qs+='ruanalogues/'
            if (eq) {
                qs+=(amp)?'&':'?'
                qs+='eq='+eq
                amp = true
            }
            if (ok) {
                qs+=(amp)?'&':'?'
                qs+='ok='+ok+'&okname='+searchParams.get("okname")
                amp = true
            }            
        }        
        else {
            
            if ((query != '') && (query != null)) {
                qs+=(amp)?'&':'?'+'query='+query
                amp = true
            }

            if (ok) {
                qs+=(amp)?'&':'?'
                qs+='ok='+ok+'&okname='+searchParams.get("okname")
                amp = true
            }

            if (chap) {
                qs+=(amp)?'&':'?'
                qs+='chap='+chap
                amp = true
            }

            if (sortResult) {
                qs+=(amp)?'&':'?'
                qs+='sort='+sortResult
                amp = true
            }        

            if (orderResult) {
                qs+=(amp)?'&':'?'
                qs+='order='+orderResult
                amp = true
            }

        }   
        
        return qs
    }

    const searchPage = (page = null) => {
                window.open(genQueryString()+'&page='+page,"_self");    
      }
  
      useEffect(() => {
        
        if (searchResultPart && eqpMeta.title == ''){
            var t = (searchParams.get("query")?searchParams.get("query"):searchParams.get("aname")?searchParams.get("aname"):searchParams.get("ashifr")?searchParams.get("ashifr"):(searchParams.get("okname"))?searchResult?.okname:'Результат поиска')+' - '+searchResultPart
            setEqpMeta({...eqpMeta, title: t+' '+searchResult?.count, description: t+eqpMeta.description,keywords:eqpMeta.keywords} );
        }
            
        if (searchResultFull && eqpMeta.title == '') {
            var t = (searchParams.get("query")?searchParams.get("query"):searchParams.get("aname")?searchParams.get("aname"):searchParams.get("ashifr")?searchParams.get("ashifr"):(searchParams.get("okname"))?searchResult?.okname:'Результат поиска')+' - '+searchResultFull;
            setEqpMeta({...eqpMeta, title: t+' '+searchResult?.count, description: t+eqpMeta.description, keywords:eqpMeta.keywords} );
        }

      },[searchResultPart, searchResultFull])

      useEffect(() => {


        if((firm != null)&&(eqFirm!=null)) {
            const nextSearchResultOut = [...searchResultOut];

            setSearchResultOut( nextSearchResultOut.map(eqItem=>{
                if(eqItem?.eq.ID == eqFirm){

                    eqItem.firms = eqItem.firms.map(firmItem => {
                        if (firmItem.ID == firm.id) {

                            firmItem.CONTENT = firm
                            dispatch(setEqFirm(null))
                            dispatch(setFirm(null))
                        }
                        return firmItem
                    })
                }
                return eqItem
            }))            
        }
    },[firm])

      useEffect(() => {
        if (loadBm) {

            av?.av?.ssetAv(!av?.av?.av);
            dispatch(setLoadBm(false))
            if((showOkPanel?.name == null) && (searchResult))
                dispatch(loadOk(searchResult.id, searchResult.ok_sub));
                else
            showOkPanel.count -= 1
            //setActBookmark(null)
        }
  },[loadBm])

    useEffect(() => {
        if (loadCm) {

            av?.av?.ssetAv(!av?.av?.av);
            dispatch(setLoadCm(false))
            if((showOkPanel?.name == null) && (searchResult))
                dispatch(loadOk(searchResult.id, searchResult.ok_sub));
                else
            showOkPanel.count -= 1
            //setActBookmark(null)
        }
    },[loadCm])  

      useEffect(() => {
            if (actCompare) {
                dispatch(setCompareStatus(actCompare))
                
                setActCompare(null)
            }
      },[actCompare])

      useEffect(() => {
        if (actBookmark) {
            dispatch(setBookmarkStatus(actBookmark))
        
            setActBookmark(null)
        }
    },[actBookmark])
      useEffect(() => {
                //alert('tut')


        if ((showOkPanel.okid) && (searchResult))
                setSearchResultPart(((favorite)?'В избранном ':(analogues)?'Аналогов найдено: ':(ruanalogues)?'Российских аналогов найдено ':('Найдено: '))+searchResult?.count+' '+getCountItemName(searchResult?.count)+' в категории «'+showOkPanel?.fullname+'»')

        
        if ((!loadBm)&&(showOkPanel?.name == null)&&(searchResult))
        if ((searchOk?.ok?.length)||(searchOk?.ok?.length == 0))  {
            setSearchResultFull(((favorite)?'В избранном ':(analogues)?'Аналогов найдено ':(ruanalogues)?'Российских аналогов найдено ':('Найдено: '))+searchResult?.count+' '+getCountItemName(searchResult?.count)+' '+((showOkPanel?.name == null) ? 'в '+searchOk?.ok?.length+' '+getCountCategoryName(searchOk?.ok?.length):'в категории «'+showOkPanel?.fullname+'»'))
        }
        },[searchOk, loadBm, loadCm, showOkPanel, searchResult])


      useEffect(() => {
        if (searchBTP != null) {
            let nextBTP = JSON.parse(JSON.stringify(searchBTP));
            setBTP(nextBTP)
        }
      },[searchBTP])

      
    useEffect(() => {

        //if (searchParams.get("okname"))
        //setEqpMeta({...eqpMeta, title: searchParams.get("okname"), description: searchParams.get("okname")+eqpMeta.description, keywords:searchParams.get("okname")+', '+eqpMeta.keywords} ); 

        if (aok)
            setCategory(aok, aokname, aokname)

        if (favorite == true) { 
            dispatch(loadFSearch({ page:currentPage, ok:(ok)?ok:'any'}));
            if (eqpMeta.title == '')
                setEqpMeta({...eqpMeta, title: 'Избранное - Импортозамещение и подбор нефтегазового оборудования', description: 'Мое избранное оборудование'+eqpMeta.description, keywords:eqpMeta.keywords} ); 
            

        }  
        //  else     
        // if (advanced == true) {
        //     dispatch(loadASearch({aname:aname, ashifr:ashifr, aok:aok, childok:false, acompany:acompany, aregion:aregion, page:currentPage}));
            
        // }
        else
        if ((query !='')&&(query != null))
        dispatch(loadSearch(query, currentPage));
        else
        if (ok !=null) {
            dispatch(loadSearch(null, currentPage, ok, eq, analogues?[{target:'analogues'}]:ruanalogues?[{target:'ruanalogues'}]:null, chap));
            if (eq != null)
                dispatch(loadEQ(eq));
            dispatch(loadBTP(ok));
        }
        

        

      }, []);

    useEffect(() => {
  
        if (filterUse) {
        

            dispatch(setSearchResult(null)); 
            setSearchResultOut([])

            if(showOkPanel.okid == null) {
                if (favorite == true) {
                    dispatch(loadFSearch({page:currentPage, ok:(ok)?ok:'any', params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
                    
                }   else 
                if (advanced == true) 
                    dispatch(loadASearch({aname:aname, ashifr:ashifr, aok:aok, childok:false, acompany:acompany, aregion:aregion, page:currentPage, params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
                else       
                if ((query !='')&&(query != null))
                dispatch(loadSearch(query, 1,null,[{sort:sortResult}, {order:orderResult}, {filters:BTP}]))
                else
                if (ok !=null) {
                    
                    dispatch(loadSearch(null, 1, ok, eq, [{sort:sortResult}, {analogs:searchAnalogsArr}, {target:target}, {order:orderResult}, {filters:BTP}]));
                }
    
            }
            else
            if (favorite == true) 
            dispatch(loadFSearch( {page:currentPage, ok:showOkPanel.okid, params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
            else
            if (advanced == true) 
            dispatch(loadASearch({aname:aname, ashifr:ashifr, aok:showOkPanel.okid,childok:false, acompany:acompany, aregion:aregion, page:currentPage, params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
            else {      

            dispatch(loadSearch(query, 1, showOkPanel.okid, eq, [{sort:sortResult}, {analogs:searchAnalogsArr}, {target:target}, {order:orderResult}, {filters:BTP}]));
            }
            setFilterUse(false)
            setCurrentPage(1)            
        }
        
    }, [filterUse]);

      useEffect(() => {

        if (searchResult) {
            dispatch(setSearchResult(null)); 
            setSearchResultOut([])

            //alert(showOkPanel.okid+' '+ok+' '+favorite)
            if(showOkPanel.okid == null) {
                if (favorite == true) 
                dispatch(loadFSearch({page:currentPage, ok:(ok)?ok:'any', params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
                else
                if (advanced == true) 
                dispatch(loadASearch({aname:aname, ashifr:ashifr, aok:aok,childok:false, acompany:acompany, aregion:aregion, page:currentPage, params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
                else
                if ((query !='')&&(query != null))
                dispatch(loadSearch(query, 1,null,null, [{sort:sortResult}, {order:orderResult}, {filters:(JSON.stringify(BTP) === JSON.stringify(searchBTP))?null:BTP}]))
                else
                if (ok !=null) {
                    dispatch(loadSearch(null, 1, ok, eq, [{sort:sortResult}, {analogs:searchAnalogsArr}, {target:target}, {order:orderResult}, {filters:(JSON.stringify(BTP) === JSON.stringify(searchBTP))?null:BTP}], chap));
                }
                
            }
            else {
                if (favorite == true) 
                    dispatch(loadFSearch({page:currentPage, ok:(showOkPanel.okid)?showOkPanel.okid:'any', params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
                else
                    dispatch(loadSearch(query, 1, showOkPanel.okid, eq, [{sort:sortResult}, {analogs:searchAnalogsArr}, {target:target}, {order:orderResult}, {filters:(JSON.stringify(BTP) === JSON.stringify(searchBTP))?null:BTP}]));

            }

            setCurrentPage(1)


        
        replace(genQueryString())
    }
      },[sortResult, orderResult])

      useEffect(() => {
        if (searchResult)
            if (searchResult.count != 0)
            {

                if ((searchResult?.okname) && (eqpMeta.title == ''))
                    setEqpMeta({...eqpMeta, title: searchResult?.okname+' найдено '+((analogues)?'аналогов ':(ruanalogues)?'российских аналогов ':'')+searchResult?.count+' '+getCountItemName(searchResult?.count), description: searchResult?.okname+eqpMeta.description, keywords:searchResult?.okname+', '+eqpMeta.keywords} ); 
    
                if (ok !=null) {
                    if ((searchResult.ok_sub == false)) {
                        if (!showOkPanel.name)
                            setShowOkPanel({name:searchParams.get("okname"),fullname:searchResult?.okname,count:searchResult.count,show:true,okid:ok})
                    setSearchResultPart(((favorite)?'В избранном ':(analogues)?'Аналогов найдено: ':(ruanalogues)?'Российских аналогов найдено ':('Найдено: '))+searchResult?.count+' '+getCountItemName(searchResult?.count)+' в категории «'+((showOkPanel.name)?showOkPanel.fullname:searchParams.get("okname"))+'»')
                    }
                }
                else if (showOkPanel.okid)
                setSearchResultPart(((favorite)?'В избранном ':('Найдено: '))+searchResult?.count+' '+getCountItemName(searchResult?.count)+' в категории «'+showOkPanel?.fullname+'»')
                
                if(!loadBm)
                setSearchResultOut(() => searchResultOut.concat(searchResult.result))

            //alert(loadBm)
            if(!searchOk)
                dispatch(loadOk(searchResult.id, searchResult.ok_sub));

            setNotFound(false)
            }
            else {
                setNotFound(true)
                dispatch(loadOk(null));
            }
            if (Math.ceil(searchResult?.count/21) == currentPage)
                setShowMore(false)
                else
                setShowMore(true)
      }, [searchResult]);      


    const genSearchResult = () => {
        let content = []
        searchResultOut.forEach( function callback (searchResultOutItem) {
            
            if(searchResultOutItem) {

            let prm = [] 
            searchResultOutItem.prm.forEach( function callback (prmItem) { 
                prm.push(<div className={classes.searchResultItemOk}>
                    {prmItem.name.trim()}, {prmItem.ei} {prmItem.levels != null? prmItem.levels: prmItem.val1 == prmItem.val2 ? prmItem.val1: prmItem.val1+' ... '+prmItem.val2}
                </div>)
                })
            let buff = 0
            if (prm.length == 0) {
                searchResultOutItem.eq.DESCRIPTION?.split('\n').forEach(function callback (prmItem) {
                    if (buff <= 3) 
                    prm.push(<div className={classes.searchResultItemOk}>
                        {prmItem}
                    </div>)
                buff++    
                })
            }

            let images = []
            let imagesView = []
            
            if (searchResultOutItem.old > 0)
            for(let i = 0; i <= searchResultOutItem.old-1; i++) {
                images.push(<div><img src={baseUrl+'/pic/?eid='+searchResultOutItem.eq.ID+'&count='+i} /></div>)
                imagesView.push(baseUrl+'/pic/?eid='+searchResultOutItem.eq.ID+'&count='+i)
            }
            else
            if ((searchResultOutItem.pics.length > 0) && (imagesView.length == 0))
                searchResultOutItem.pics.forEach( function callback (picsItem) { 
                    images.push(<div><img src={baseUrl+'/pic/?id='+picsItem} /></div>)
                    imagesView.push(baseUrl+'/pic/?id='+picsItem)
                })

                else {
                images.push(<div><img src={baseUrl+'/pic/?eid='+searchResultOutItem.eq.ID} /></div>)
                imagesView.push(baseUrl+'/pic/?eid='+searchResultOutItem.eq.ID)
                }
         

            content.push(
            <div className={listFormat?classes.searchResultStyle:classes.searchResultStyleGrid} style={{position: 'relative', paddingBottom: '60px'}}>
                <div style={{float: 'left', width: '160px',height: '160px',  margin:'10px', border: '1px solid #868686',display:'flex',cursor:'pointer'}} className={classes.imageGallery}>
                <Carousel showThumbs={false} dynamicHeight={false} centerMode={true} showStatus={false} onClickItem={()=>{setShowFullScreenImage({open:true, images:imagesView, name:searchResultOutItem.eq.NAME+' '+searchResultOutItem.eq.SHIFR})}}>
                    {images}

                </Carousel>
                </div>
                <div  style={{float: 'left', }}>
                <div>
                    <div class="link headerlink left notablet" className={classes.searchResultItemName}><Link target="_blank" to={"/eq/"+searchResultOutItem.eq.ID} className="link breadcrumbslink">{searchResultOutItem.eq.NAME} {searchResultOutItem.eq.SHIFR}</Link></div>
                        {prm}
                </div>
                <div className={classes.searchResultItemOk}>        
                    <div>{
                        (!isTariff)?
                        <Link to="#" className="link headerlink" style={{ padding: '10px 0 0 0px', display: 'flex', height: '20px'}} onClick={()=>{pleaseRegisterDialogHandleOpen()}}>Изготовители: {(searchResultOutItem.eq.firm_count)?searchResultOutItem.eq.firm_count:'0'}</Link>:
                        <div>
                            <div style={{color:"#929292", padding: '5px 0 0 0px'}}>Изготовители: {searchResultOutItem.eq.firm_count}</div>
                            {
                                (searchResultOutItem.allfirms == true)?
                                searchResultOutItem?.firms?.map(firmItem=> {
                                return <div>
                                    <div onClick={()=>onFirmClick(searchResultOutItem.eq.ID, firmItem.ID)} className={(firmItem.STATUS != 'ACTIVE')&&(firmItem.STATUS != 'UNKNOWN')?classes.headitemvalueFirmNotActive:classes.headitemvalueFirm}>{firmItem.NAME}</div>
                                    <div>{renderFirmDetail(searchResultOutItem.eq.ID, firmItem.ID)}</div>
                                    </div>
                                    })
                                :
                                searchResultOutItem?.firms?.filter(firmItem=>firmItem.STATUS=='ACTIVE' || firmItem.STATUS=='UNKNOWN')?.map(firmItem=> {
                                    return <div>
                                        <div onClick={()=>onFirmClick(searchResultOutItem.eq.ID, firmItem.ID)} className={classes.headitemvalueFirm}>{firmItem.NAME}</div>
                                        <div>{renderFirmDetail(searchResultOutItem.eq.ID, firmItem.ID)}</div>
                                        </div>
                                        })
                                
                            }
                        {((searchResultOutItem?.firms?.filter(firmItem=>firmItem.STATUS!='ACTIVE'&&firmItem.STATUS!='UNKNOWN')?.length>0))?
                        <div><span style={{cursor:'pointer', color:'#054C74', display:'block'}} onClick={()=>{
                            setSearchResultOut(searchResultOut?.map(srItem=>{
                                if(srItem.eq.ID == searchResultOutItem.eq.ID)
                                    srItem.allfirms = !srItem.allfirms
                                return srItem
                            }))
                        }}><span style={{verticalAlign: 'middle'}}>{searchResultOutItem.allfirms?<KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}</span><span style={{borderBottom: '1px dashed'}}>{searchResultOutItem.allfirms?'Скрыть в банкротстве и ликвидированные' : 'Показать в банкротстве и ликвидированные'}</span></span></div>
                        :null
                        }
                        </div>
                        

                    }</div>

                    <div>{
                    (!isTariff)?
                    <Link to="#" className="link headerlink" style={{ padding: '0 0 10px 0px', display: 'flex', height: '20px'}} onClick={()=>{pleaseRegisterDialogHandleOpen()}}>Регионы: {(searchResultOutItem.eq.region_count)?searchResultOutItem.eq.region_count:'0'}</Link>:
                    <div>
                        <div style={{color:"#929292", padding: '5px 0 0 0px'}}>Регионы: {searchResultOutItem.eq.region_count}</div>
                        {
                            
                            searchResultOutItem.regions.map (regionItem => {
                                
                                 return <div style={{ paddingLeft:"0px"}}>{regionItem.NAME}</div>
                            })
                        }
                    </div>
                    }</div>
                </div>
                </div>
                <div style={{ padding: '10px', position: 'absolute', top: '0', right:'0', width:'23px'}}>
                
                    <Bookmark onClick={()=>{setBookmark(searchResultOutItem.eq.ID)}} item={searchResultOutItem} status={searchResultOutItem.bookmark} action={pleaseRegisterDialogHandleOpen}/>
                
                
                    <Compare 
                        onClick={()=>{setCompare(searchResultOutItem.eq.ID)}} 
                        item={searchResultOutItem} 
                        status={searchResultOutItem.compare} 
                        action={pleaseRegisterDialogHandleOpen}
                    />
                
                {
                    (user?.role == 'operator')?
                    <IconButton style={{padding:'0', top:'-2px'}} onClick={()=>window.open('/eqedit/'+searchResultOutItem.eq.ID,"_blank")}>
                    <Tooltip title={<span style={{ fontSize:'14px'}}>Редактировать</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
                        <DesignServicesIcon style={{color:'green'}} />
                    </Tooltip>
                    
                    </IconButton>
                    :
                    null
                }
                
                </div>
                <div style={{ padding: '10px', position: 'absolute', bottom: '0', right:'0'}}>
                        <AnalogMenu searchResultOutItem={searchResultOutItem} type="all" isTariff={isTariff} gridFormat={gridFormat} clickAction={()=>{if(!isTariff)pleaseRegisterDialogHandleOpen()}}/>
                        {searchResultOutItem.ruanalogs != -1 ? 
                            <AnalogMenu searchResultOutItem={searchResultOutItem} type="ru" isTariff={isTariff} gridFormat={gridFormat} clickAction={()=>{if(!isTariff)pleaseRegisterDialogHandleOpen()}}/>:''}

                </div>                
            </div>  
        )
            }
            })
        return content   
    }  

    const renderFirmDetail = (eqId, firmId) => {
        var eqItem = searchResultOut?.find(o=>o?.eq.ID == parseInt(eqId))
        var firmItem = eqItem?.firms?.find(o => parseInt(o.ID) == parseInt(firmId))

        //var firmItem = firmDetails?.find(o => parseInt(o.id) == parseInt(firmId))
        if (firmItem?.CONTENT == 'loader')
            return <Loader type="linear"/>
        else
        if (firmItem?.CONTENT == null)
        return <div></div>
        else
        return <div style={{paddingLeft:'0px'}}>
            {(firmItem?.CONTENT?.status != '')&&(firmItem?.CONTENT?.status)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Статус:</span> <span style={(firmItem?.CONTENT?.status == "Действует")?{color:"darkgreen"}:(firmItem?.CONTENT?.status == "Ликвидирована")?{color:"darkred"}:null}>{firmItem?.CONTENT?.status}</span></div>:null}
            {(firmItem?.CONTENT?.date_update != '')&&(firmItem?.CONTENT?.date_update)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Дата обновления:</span> {firmItem?.CONTENT?.date_update}</div>:null}
            {(firmItem?.CONTENT?.innkpp != '')&&(firmItem?.CONTENT?.innkpp)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>ИНН/КПП:</span> {firmItem?.CONTENT?.innkpp}</div>:null}
            {(firmItem?.CONTENT?.ogrn != '')&&(firmItem?.CONTENT?.ogrn)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>ОГРН:</span> {firmItem?.CONTENT?.ogrn}</div>:null}
            {(firmItem?.CONTENT?.opfname != '')&&(firmItem?.CONTENT?.opfname)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Организационно-правовая форма:</span> {firmItem?.CONTENT?.opfname}</div>:null}
            {(firmItem?.CONTENT?.full != '')&&(firmItem?.CONTENT?.full)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Полное наименование:</span> {firmItem?.CONTENT?.full}</div>:null}
            {(firmItem?.CONTENT?.manager != '')&&(firmItem?.CONTENT?.manager)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Руководитель:</span> {firmItem?.CONTENT?.manager}</div>:null}
            {(firmItem?.CONTENT?.region != '')&&(firmItem?.CONTENT?.region)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Регион:</span> {firmItem?.CONTENT?.region}</div>:null}
            {(firmItem?.CONTENT?.addressline != '')&&(firmItem?.CONTENT?.addressline)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Адрес местонахождения:</span> {firmItem?.CONTENT?.addressline}</div>:null}
            {(firmItem?.CONTENT?.site != '')&&(firmItem?.CONTENT?.site)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Сайт:</span> <span className="breadcrumbslink" style={{cursor:'pointer'}} onClick={()=>window.open(firmItem?.CONTENT?.site,"_blank")}>{firmItem?.CONTENT?.site}</span></div>:null}
            </div>
    } 

    const onFirmClick = (eqId, id) => {

        
        const nextSearchResultOut = [...searchResultOut];

        setSearchResultOut( nextSearchResultOut.map(eqItem=>{
            if(eqItem?.eq.ID == eqId){
                eqItem.firms = eqItem.firms.map(firmItem => {
                    if (firmItem.ID == id) {
                        if ((firmItem.CONTENT == null)  && (eqFirm == null)) {
                            firmItem.CONTENT = 'loader'
                            dispatch(setEqFirm(eqId))
                            dispatch(loadFirm(id))
                        } else {
                            firmItem.CONTENT = null
                        }
                    }
                    return firmItem
                })
            }
            return eqItem
        }))

//        nextBTP[key].SELECTED = value
//        setBTP(nextBTP)


    }
    //console.log(searchResult)
// Тут правим
    const setBookmark = (eqid) => {
        setSearchResultOut((searchResultOut) => {
            return searchResultOut.map((item, j) => {
                if (item?.eq?.ID == eqid) {
                    item.bookmark = !item.bookmark
                    if ((favorite)&&(item.bookmark == false)) { 
                        //alert (item?.eq?.ID+' '+eqid+' '+item+' '+favorite+' '+item.bookmark)
                        searchResult.count -= 1
                        searchResult.id = searchResult?.id?.filter(val=>val!=eqid)
                        dispatch(setSearchResult(searchResult))
                }
                    setActBookmark(eqid)
                }
                if ((!favorite) || ((favorite)&&(item?.bookmark != false)))
                return item
            })
        })
    }

    const setCompare = (eqid) => {
        setSearchResultOut((searchResultOut) => {
            return searchResultOut.map((item, j) => {
                if (item?.eq?.ID == eqid) {
                    item.compare = !item.compare
                    if ((compare)&&(item.compare == false)) { 
                        //alert (item?.eq?.ID+' '+eqid+' '+item+' '+favorite+' '+item.bookmark)
                        searchResult.count -= 1
                        searchResult.id = searchResult?.id?.filter(val=>val!=eqid)
                        dispatch(setSearchResult(searchResult))
                }
                    setActCompare(eqid)
                }
                if ((!compare) || ((compare)&&(item?.compare != false)))
                return item
            })
        })
    }    

    const selectListFormat = () => {
        setListFormat(true)
        setGridFormat(false)
    }

    const selectGridFormat = () => {
        setListFormat(false)
        setGridFormat(true)
    }    

    const selectFilters = () => {
        setShowFilter(!showFilter)  
    }     

    const setSort = (event, value) => {
        setSortResult(value.props.value)
    }



    const setEI = (value, key) => {
        
        if(BTP != null){
        const nextBTP = [...BTP];

        nextBTP[key].SELECTED = value
        setBTP(nextBTP)
    }
    }  

    const changeFilter = (value,BTPCount,SELECTED) => {

        
        let delta = (BTP[BTPCount].forks[SELECTED].max-BTP[BTPCount].forks[SELECTED].min)
        let ed = delta/100


        if(BTP != null){

        let minval = BTP[BTPCount].forks[SELECTED].min+value[0]*ed
        let maxval = BTP[BTPCount].forks[SELECTED].min+value[1]*ed
        
        const nextBTP = [...BTP];
        nextBTP[BTPCount].forks[SELECTED].minval = minval.toFixed(2)
        nextBTP[BTPCount].forks[SELECTED].maxval = maxval.toFixed(2)
        setBTP(nextBTP)

        }
    }

    const checkFilterVal = (val, BTPCount, SELECTED, valtype) => {
        
        const nextBTP = [...BTP];

        if(val < BTP[BTPCount].forks[SELECTED].min)
            val = BTP[BTPCount].forks[SELECTED].min

        if(val > BTP[BTPCount].forks[SELECTED].max)
            val = BTP[BTPCount].forks[SELECTED].max    

        if (valtype == 'maxval')
            nextBTP[BTPCount].forks[SELECTED].maxval = val
        
        if (valtype == 'minval')
            nextBTP[BTPCount].forks[SELECTED].minval = val            
        setBTP(nextBTP)                 

    } 


    const changeFilterVal = (val, BTPCount, SELECTED, valtype) => {

        const nextBTP = [...BTP];

        
        if (valtype == 'maxval')
            nextBTP[BTPCount].forks[SELECTED].maxval = val
        
        if (valtype == 'minval')
            nextBTP[BTPCount].forks[SELECTED].minval = val

        setBTP(nextBTP)

    }


    const preFilterSearch = () => {
        setFilterUse(true)
    }

    const sliderTouchStart = useCallback(() => {
    
        document.addEventListener(
          "touchend",
          () => {
            preFilterSearch()
          },
          { once: true }
        );
      }, []);    

    const sliderMouseDown = useCallback(() => {
  
        document.addEventListener(
          "mouseup",
          () => {
            preFilterSearch()
          },
          { once: true }
        );
      }, []);
    

    const setActiveBTP = (BTPCount, checked) => {
        const nextBTP = [...BTP];
  
        nextBTP[BTPCount].active = checked
        setFilterUse(true)
        //console.log(nextBTP)
        setBTP(nextBTP)
      }

    const filterPanel = () => {

        let content = []
        let BTPCount = 0
        if(BTP != null) {
            BTP.forEach( function callback (BTPItem,key) {
                const BTPCountC = BTPCount
                content.push(
                    <div>
                        <Checkbox 
                            checked={BTPItem.active} 
                            key = {BTPCount}
                            onClick={(e, checked) => !isTariff?pleaseRegisterDialogHandleOpen():setActiveBTP(BTPCountC, e.target.checked)}
                            style={{float:'left', marginRight:'5px'}}
                            color="#054C74"/>
                        <TextField
                            sx={{
                                width: 300,
                            }}
                            size="small"
                            style={{float:'left', marginRight:'50px'}}
                            className={classes.forksMenu}
                            margin="dense"
                            padding="none"
                            disabled={!BTPItem.active} 
                            select
                            label={BTPItem.NAME}
                            defaultValue={BTPItem.forks[BTPItem.SELECTED].ei}
                            key = {BTPCount}
                            value={BTPItem.forks[BTPItem.SELECTED].ei}
                            inputProps={{placeholder: 'Input 1', 'data-state': 'Data State 1'}}
                            onChange={(e)=>{
                                setEI(BTPItem.forks.findIndex(function(item, index) {if(item.ei == e.target.value) return true}),key)
                            }}
                            

                                                     
                            >
                            {BTPItem.forks.map((option) => (
                                <MenuItem key={option.ei} value={option.ei}>
                                {option.ei}
                                </MenuItem>
                            ))}
                        </TextField>
                        
                        <div className={classes.forksExtr} style={{}}>
                        <TextField
                            id={'outlined-basic'}
                            label={'От'}
                            variant={'outlined'}
                            size="small"
                            disabled={!BTPItem.active} 
                            style={{width:'100px'}}
                            value={BTPItem.forks[BTPItem.SELECTED].minval}
                            onChange={(e, val) => changeFilterVal(e.target.value,BTPCountC,BTPItem.SELECTED,'minval')}
                            onBlur={(e, val) => {checkFilterVal(e.target.value,BTPCountC,BTPItem.SELECTED,'minval'); preFilterSearch()}}
                            onKeyDown={(e) => {if (e.key === 'Enter') preFilterSearch()}}
                            />
                        <span style={{padding:"0 10px", lineHeight: '40px'}}> - </span>
                        <TextField
                            id={'outlined-basic'}
                            label={'До'}
                            variant={'outlined'}
                            style={{width:'100px'}}
                            size="small"
                            disabled={!BTPItem.active} 
                            value={BTPItem.forks[BTPItem.SELECTED].maxval}
                            onChange={(e, val) => changeFilterVal(e.target.value,BTPCountC,BTPItem.SELECTED,'maxval')}
                            onBlur={(e, val) => {checkFilterVal(e.target.value,BTPCountC,BTPItem.SELECTED,'maxval'); preFilterSearch()}}
                            onKeyDown={(e) => {if (e.key === 'Enter') preFilterSearch()}}
                            />
                        </div>

                            <Slider
                            style={{width:'98%'}}
                            value={[(BTPItem.forks[BTPItem.SELECTED].minval-BTPItem.forks[BTPItem.SELECTED].min)*(100/(BTPItem.forks[BTPItem.SELECTED].max-BTPItem.forks[BTPItem.SELECTED].min)), (BTPItem.forks[BTPItem.SELECTED].maxval-BTPItem.forks[BTPItem.SELECTED].min)*(100/(BTPItem.forks[BTPItem.SELECTED].max-BTPItem.forks[BTPItem.SELECTED].min))]}
                            onMouseDown={sliderMouseDown}
                            disabled={!BTPItem.active} 
                            onTouchStart={sliderTouchStart}
                            onChange={(e, val) => {changeFilter(val,BTPCountC,BTPItem.SELECTED)}}
                            />
                    </div>
                    
                )
                BTPCount++
            })
        }

        return  <div className={!showFilter?'hidden':''} style={{margin: '0 5px'}}>{content}</div>

    }


    const sortAndFilterPanel = () => {
        const currencies = [{value:"name",label:"Название"},{value:"code",label:"Шифр"}]

        if(searchBTP != null) {
            searchBTP.forEach( function callback (searchBTPItem) {
                currencies.push({value:searchBTPItem.ID,label:searchBTPItem.NAME})
            })
        }

        return  <div style={{display: 'flow-root', padding:'3px',position:'relative'}} >
                    <div style={{float:'left'}}>
                    <TextField
                    size="small"
                    className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-sort"
                    select
                    label="Сортировать"
                    defaultValue="name"
                    value={sortResult}
                    disabled = {!isTariff}
                    onClick={()=>!isTariff?pleaseRegisterDialogHandleOpen():null}
                    onChange={setSort}
                    >
                    {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                        <Tooltip title={<span style={{ fontSize:'14px'}}>Сортировать</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>                
                        <SortOutlinedIcon className={(orderResult=='asc')?classes.ascStyle:classes.descStyle}
                        onClick={()=>{!isTariff?pleaseRegisterDialogHandleOpen():(orderResult == 'asc')?setOrderResult('desc'):setOrderResult('asc')}}
                        style={{color: "#054C74", padding:'3px',top: '15px', position: 'relative',  paddingLeft:'10px', cursor:"pointer"}}/>
                        </Tooltip>
                    </div>

                    <div style={{float:"right",color: "#054C74", position: 'absolute', top: '30%', right:"0"}}>
                        <span className="nomobile">
                        <Tooltip title={<span style={{ fontSize:'14px'}}>Разложить построчно</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>                
                        <FormatListBulletedOutlinedIcon onClick={selectListFormat} className={listFormat?classes.selectedFormat:null} style={{float:'left',paddingRight:'5px', cursor:"pointer", padding:'3px',}}/>
                        </Tooltip>
                        <Tooltip title={<span style={{ fontSize:'14px'}}>Разложить плиткой</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>                
                            <GridViewOutlinedIcon onClick={selectGridFormat} className={gridFormat?classes.selectedFormat:null}  style={{float:'left',marginRight:'5px', padding:'3px', cursor:"pointer",}}/>
                        </Tooltip>
                        </span>
                        <Tooltip title={<span style={{ fontSize:'14px'}}>Фильтры</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>                
                        <div onClick={selectFilters} className={!searchBTP?'hidden':classes.filterButton} >
                            
                            <FilterAltOutlinedIcon/>
                            
                            <div style={{position: 'relative', left: '-12px', top: '5px'}}><ArrowDropDownOutlinedIcon className={(showFilter)?classes.ascStyle:classes.descStyle}/></div>
                        </div>
                        </Tooltip>

                    </div>
        
                </div>
    }

    const genOkResult = (full) => {

        let content = [], j = 0

        if (searchOk?.ok) {
    
        searchOk.ok.forEach( function callback (searchOkItem) {
            let name='', i = 0
            searchOkItem.NAME.split(' ').forEach( function callback (searchOkItemName) {
                if (i <= 2)
                {
                    name = name + searchOkItemName
                    if (i < 2)
                    name = name + ' '
                    else
                    if (searchOkItem.NAME.split(' ').length >3)
                    name = name + '...'
                }
                i++
            })
            

            if ((full != true) && (content.length >= 3)) {
                return
            }            


            content.push(
                <Badge badgeContent={searchOkItem.count} className={classes.customBadge} style={{marginLeft:'10px', marginRight:'10px',}}>
                    <Tooltip title={<span style={{ fontSize:'14px'}}>{searchOkItem.NAME}</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
                        <Chip style={{margin:'3px'}} onClick={() => setCategory(searchOkItem.ID,searchOkItem.NAME,name,searchOkItem.count)} label={name} variant="outlined" />
                    </Tooltip>                                                  
                </Badge>
            )

            if (((full != true) && (content.length == 3)&&(searchOk.ok.length>3)) || ((full == true) && (searchOk.ok.length-1 == j))) {
                content.push(
                    <span style={{cursor:'pointer', color:'#054C74', display:'block'}} onClick={()=>{if(full) window.scroll({top: 0, left: 0, behavior: 'smooth' }); setShowAllOk(!showAllOk)}}><span style={{verticalAlign: 'middle'}}>{full? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}</span><span style={{borderBottom: '1px dashed'}}>{full?'Свернуть категории' : 'Все категории'}</span></span>
                )
            }

            
        j++
        })
        }

        return content

    }



    const getCountItemName = (count) => {
        if ((count % 10 == 0) || (count % 10 >= 5) || ((count>10)&&(count<20)))
        return 'единиц'
        else
        if (count % 10 == 1)
        return 'единица'    
        else
        return 'единицы'                    
    }

    const getCountCategoryName = (count) => {
        if ((count % 10 == 0) || (count == 11) || (count % 10 > 1))
        return 'категориях'
        else
        if (count % 10 == 1)
        return 'категории'    
        else
        return 'категориях'                    
    }    

    const loadNext = () => {
        
        setCurrentPage(currentPage+1)

        if (!ok)
            replace(genQueryString()+'&page='+(currentPage+1))
        dispatch(setSearchResult(null));
        if (favorite == true) 
        dispatch(loadFSearch({ page:(currentPage+1), ok:(ok)?ok:'any', params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
        else
        if (advanced == true) 
        dispatch(loadASearch({aname:aname, ashifr:ashifr, aok:aok,childok:false, acompany:acompany, aregion:aregion, page:(currentPage+1), params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
        else
        if(showOkPanel.okid == null)
        dispatch(loadSearch(query, currentPage+1, ok, eq, [{sort:sortResult},{analogs:searchAnalogsArr}, {target:target}, {order:orderResult}, {filters:(JSON.stringify(BTP) === JSON.stringify(searchBTP))?null:BTP}],chap));
        else
        dispatch(loadSearch(query, currentPage+1, showOkPanel.okid, eq,[{sort:sortResult}, {analogs:searchAnalogsArr}, {target:target}, {order:orderResult}, {filters:(JSON.stringify(BTP) === JSON.stringify(searchBTP))?null:BTP}]));
    }

    const loadPage = (page) => {
        setCurrentPage(page)
        setSearchResultOut([])
        window.scroll({top: 0, left: 0, behavior: 'smooth' })
        if (!ok||chap)
            replace(genQueryString()+'&page='+(page))
        dispatch(setSearchResult(null));
        if (favorite == true) 
        dispatch(loadFSearch({ page:page, params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
        else
        if (advanced == true) 
        dispatch(loadASearch({aname:aname, ashifr:ashifr, aok:aok, childok:false,acompany:acompany, aregion:aregion, page:page, params:[{sort:sortResult}, {order:orderResult}, {filters:BTP}]}));
        else
        if((showOkPanel.okid == null)&& (!chap))
        dispatch(loadSearch(query, page, ok, eq, [{sort:sortResult}, {order:orderResult}, {analogs:searchAnalogsArr}, {target:target}, {filters:BTP}]));
        else
        if((chap))
            dispatch(loadSearch(null, page, ok, eq, [{sort:sortResult}, {order:orderResult}, {analogs:searchAnalogsArr}, {target:target}, {filters:BTP}], chap));
        else
        dispatch(loadSearch(query, page, showOkPanel.okid, eq, [{sort:sortResult}, {order:orderResult}, {analogs:searchAnalogsArr}, {target:target}, {filters:BTP}], chap));
    }


    const setCategory = (okid = null, fullname, name, count) => {
        setCurrentPage(1)

        if (okid != null) {
            
            dispatch(loadBTP(okid, query));
            window.scroll({top: 0, left: 0, behavior: 'smooth' })
            setShowOkPanel({name:name,fullname:fullname,count:count,show:false,okid:okid})
            
            setCurrentPage(1)
            setSearchResultOut([])      
            dispatch(setSearchResult(null));    
              
            replace(genQueryString())
            if (favorite == true) 
            dispatch(loadFSearch({ page:1, ok:okid, params:[{sort:sortResult}, {order:orderResult}, {filters:null}]}));
            else
            if (advanced == true) 
            dispatch(loadASearch({aname:aname, ashifr:ashifr, aok:aok, childok:okid, acompany:acompany, aregion:aregion, page:1, params:[{sort:sortResult}, {order:orderResult}, {filters:null}]}));
            else
            dispatch(loadSearch(query, 1, okid, eq, [{sort:sortResult}, {order:orderResult}, {filters:null}]));
        }
        else {
            
            setShowOkPanel({name:null,fullname:null,count:null,show:true,okid:null})
            setSearchResultPart(null)
            setCurrentPage(1)
            setSortResult('name')
            setOrderResult('asc')
            setSearchResultOut([])      
            dispatch(setSearchResult(null));      
            dispatch(setSearchResult(null));    
            dispatch(setSearchBTP(null))
            setBTP(null)
            dispatch(setSearchOk(null))
            replace(genQueryString())
            if (favorite == true){ 
            dispatch(loadFSearch({page:currentPage, ok:'any', params:[{sort:sortResult}, {order:orderResult}, {filters:(JSON.stringify(BTP) === JSON.stringify(searchBTP))?null:BTP}]}));
            }
            else
            if (advanced == true) 
            dispatch(loadASearch({aname:aname, ashifr:ashifr, aok:aok, childok:false,acompany:acompany, aregion:aregion, page:currentPage, params:[{sort:sortResult}, {order:orderResult}, {filters:(JSON.stringify(BTP) === JSON.stringify(searchBTP))?null:BTP}]}));
            else

            if (ok !=null) 
                dispatch(loadSearch(null, 1, ok, eq, [{sort:sortResult}, {order:orderResult}, {analogs:searchAnalogsArr}, {target:target}, {filters:(JSON.stringify(BTP) === JSON.stringify(searchBTP))?null:BTP}],chap));
            else
                dispatch(loadSearch(query, 1,null,[{sort:'name'}, {order:'asc'}, {filters:null}]));
    
        }
        
    }
 
      const gotoPrevious = () =>
      currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);
      
      const gotoNext = () =>
      currentImageIndex + 1 < showFullScreenImage.images.length &&
      setCurrentIndex(currentImageIndex + 1);
      
      const [currentImageIndex, setCurrentIndex] = useState(0);

      //console.log(windowSize)


      const genEqParent = () => {

        if ((eqItem?.prm)&&(!analogsPanel)) {

        }
//        setAnalogsPanel(()=>{
//            eqItem.prm.map((item, j) => {
//                alert(item)
//            })
       // })
//        console.log(eqItem);

        let images = []
        let imagesView = []
        
        if (eqItem?.old > 0)
        for(let i = 0; i <= eqItem?.old-1; i++) {
            images.push(<div><img src={baseUrl+'/pic/?eid='+eqItem.eq.ID+'&count='+i} /></div>)
            imagesView.push(baseUrl+'/pic/?eid='+eqItem.eq.ID+'&count='+i)
        }
        else
        if ((eqItem.pics.length > 0) && (imagesView.length == 0))
            eqItem.pics.forEach( function callback (picsItem) { 
               // alert(baseUrl+'/pic/?id='+picsItem)
                images.push(<div><img src={baseUrl+'/pic/?id='+picsItem} /></div>)
                imagesView.push(baseUrl+'/pic/?id='+picsItem)
            })

            else {
               

            images.push(<div><img src={baseUrl+'/pic/?eid='+eqItem.eq.ID} /></div>)
            imagesView.push(baseUrl+'/pic/?eid='+eqItem.eq.ID)
            }

            let prm = [] 
            let prm2 = [] 
            console.log(eqItem.prm);
            eqItem?.prm?.forEach( function callback (prmItem) { 
                //console.log(BTP)
                let currentBTP = BTP?.filter(btp => btp.NAME == prmItem.name)
               
                if(currentBTP?.length>0)
                prm.push({
                    id: currentBTP[0].ID,
                    name: prmItem.name.trim()+", "+prmItem.ei+" "+(prmItem.levels != null? prmItem.levels: prmItem.val1 == prmItem.val2 ? prmItem.val1: prmItem.val1+' ... '+prmItem.val2),
                    checked: false
                })
                })
            
            if (prm.length == 0)
            eqItem.prm.forEach( function callback (prmItem) { 
                //console.log(BTP)
                prm2.push(prmItem.name.trim()+", "+prmItem.ei+" "+(prmItem.levels != null? prmItem.levels: prmItem.val1 == prmItem.val2 ? prmItem.val1: prmItem.val1+' ... '+prmItem.val2))
                })

            let buff = 0
            if ((prm.length == 0) && (prm2.length == 0)) {
                eqItem.eq.DESCRIPTION?.split('\n').forEach(function callback (prmItem) {
                    if (buff <= 3) 
                    prm2.push(prmItem)
                buff++    
                })
            }

            if ((prm.length>0)&&(!analogsPanel))
                setAnalogsPanel(prm)

     
        let content = []
        content.push(
            <div className={classes.searchResultStyle} style={{position: 'relative'}}>
                <div style={{float: 'left', width: '160px',height: '160px',  margin:'10px', border: '1px solid #868686',display:'flex',cursor:'pointer'}} className={classes.imageGallery}>
                <Carousel showThumbs={false} dynamicHeight={false} centerMode={true} showStatus={false} onClickItem={()=>{setShowFullScreenImage({open:true, images:imagesView, name:eqItem.eq.NAME+' '+eqItem.eq.SHIFR})}}>
                    {images}

                </Carousel>
                </div>
                <div style={{float: 'left', display:'grid',}}>
                    <div class="link headerlink left notablet" className={classes.searchResultItemName}><Link target="_blank" to={"/eq/"+eqItem.eq.ID} className="link breadcrumbslink">{eqItem.eq.NAME} {eqItem.eq.SHIFR}</Link></div>
                    <div className={classes.searchResultItemOk}>
                        {(analogsPanel?.length>0)?<div><b>Подбор {(analogues)?'аналогов':(ruanalogues)?'российских аналогов':null} по параметрам</b></div>:<div></div>}
                        <FormGroup>
                        {(analogsPanel)&&(analogsPanel?.length>0)?analogsPanel.map((item, j) => 
                            <FormControlLabel control={<Switch />} onChange={()=>changeAnalogsPanel(item.id)} checked={item.checked} label={item.name} />
                        ):null}
                        </FormGroup>
                        {prm2.map((item, j) => {
                            return <div className={classes.searchResultItemOk}>{item} </div>
                        })}
                    </div>
                </div>
                <div style={{ padding: '10px', position: 'absolute', top: '0', right:'0', width:'23px'}}>

                
                </div>
                
            </div>  
        )
        
            return content
      }

      const changeAnalogsPanel = (id) => {

            const nextAnalogsPanel = [...analogsPanel];
            let searchAnalogs = []
            nextAnalogsPanel.map(item => {
                if (item.id == id)
                    item.checked = !item.checked
                if (item.checked)
                    searchAnalogs.push(item.id)
            })
            setSearchAnalogsArr(searchAnalogs)
            dispatch(setSearchResult(null)); 
            setSearchResultOut([])
            setFilterUse(false)
            setCurrentPage(1)  
        dispatch(loadSearch(null, 1, ok, eq, [{sort:sortResult}, {order:orderResult}, {target:target}, {analogs:searchAnalogs}, {filters:BTP}]));
        setAnalogsPanel(nextAnalogsPanel)
        
      }

    return <div className={classes.paper}>
    <Helmet >
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>

           {(showFullScreenImage.images != null)? <Lightbox

                mainSrc={showFullScreenImage.images[currentImageIndex]}
                nextSrc={showFullScreenImage.images[(currentImageIndex + 1) % showFullScreenImage.images.length]}
                prevSrc={showFullScreenImage.images[(currentImageIndex + showFullScreenImage.images.length - 1) % showFullScreenImage.images.length]}
                onCloseRequest={() => {setShowFullScreenImage({open:false, images:null, name:null}); setCurrentIndex(0)}}
                isOpen={showFullScreenImage.open}
                onMovePrevRequest={gotoPrevious}
                onMoveNextRequest={gotoNext}
                images={showFullScreenImage.images}
                currentIndex={currentImageIndex}
               
                />:null}
            <PleaseRegisterDialog
                open={pleaseRegisterDialogOpen}
                onClose={pleaseRegisterDialogHandleClose}
            />           
                <div Style={'position:absolute'}>
                    <input type="text" onKeyDown={(e) => { if (e.key === 'Enter') search()}} autocomplete="off" onChange={onSearchChange} className={classes.promoPanelSearchTerms} id="search-terms" placeholder="Наименование или шифр оборудования" value={query}/>
                    <button onClick={search} className={classes.promoPanelSearchButon} >Найти</button>
                    
                        <Link to="/advanced" className="link headerlinkImageText">
                            <Tooltip title={<span style={{ fontSize:'14px'}}>Расширенный поиск</span>} arrow >                
                            <Tune className={classes.promoPanelAdv}/>
                            </Tooltip>
                        </Link>
                    
                    <SearchIcon onClick={search} className={classes.promoPanelSearch}/>
                </div>
                <div className={classes.searchResultMain} style={{paddingTop: '70px'}}>
                    {(eqItem)?genEqParent():null}
                    <div className={classes.searchHeaderTextLine}>
                        {((!showOkPanel.show)||((!searchResult?.ok_sub)&&(ok)))?searchResultPart:(searchResultFull)?searchResultFull:null}</div>
                    <div className={!searchOk?'':'hidden'}><OkSkeleton/></div>
                    <div className={notFound?'':'hidden'}style={{width:'100%', padding:'20px', textAlign:'center'}}>{searchParams.get("okname")?'Ничего не найдено в категории «'+searchParams.get("okname")+'»':'Ничего не найдено'}</div>
                    <span className={(showOkPanel.show&&!ok) || searchResult?.ok_sub?'':'hidden'} ><div className={classes.searchHeaderOk}>{genOkResult(showAllOk)}</div></span>
                    <span className={!showOkPanel.show?'':'hidden'} >
                        <div className={classes.searchHeaderOk}>
                        <Badge badgeContent={showOkPanel.count} className={classes.customBadge2} style={{marginLeft:'10px', marginRight:'10px',}}>
                            <Tooltip title={<span style={{ fontSize:'14px'}}>{showOkPanel.fullname}</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
                                <Chip style={{margin:'3px'}} onDelete={() => setCategory()} label={showOkPanel.name} variant="outlined" />
                            </Tooltip>
                        </Badge>                            
                        </div>
                    </span>
                    {(searchResult?.count>0)?sortAndFilterPanel():null}
                    {filterPanel()}
                </div>
                <div className={classes.searchResultMain}>
                    {genSearchResult()}
                    <div className={!searchResult ?'':'hidden'}><SearchSkeleton /></div>
                    <div className={!notFound && searchResult?'':'hidden'}>
                        <div >
                            <div className={classes.searchResultFooter}>
                                <span className={showMore?'':'hidden'}>
                                <Button variant="contained"  onClick={loadNext}  size={'large'} className={classes.button} style={{width: '300px', marginBottom: '20px'}}>
                                Показать ещё
                                </Button>
                                </span>
                                <div style={{margin:'10px 0 20px 0'}} className="nomobile">
                                    <Pagination count={Math.ceil(searchResult?.count/21)} onChange={(e, value) => loadPage(value)} page={currentPage} showFirstButton showLastButton style={{margin: 'auto', display:'inline-flex'}}/>
                                </div>
                            </div>
                        </div>    
                    </div>                                            
                </div>


        </div>

}