import React, {useState, useEffect }from 'react';
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getProfile, getAlerts, getNews, setAlerts, setNews, getLoader, loadProfileData, getLoading, setLoader, getMessage, saveSubscriptions, setMessage, getUser, getPurchases, loadPurchases
} from "../../redux/reducers/user-profile-personal-reducer";
import Loader from "../Loader/Loader.jsx";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import {useRedirect} from "../../hooks/useRedirect";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  subscriptionsArea: {
      paddingTop: '10px',
  },
  personalArea: {
    paddingTop: '10px',
    //display: 'flex',
    ['@media (max-width:767px)']: { 
      display: 'block',
      }         
  },
  button: {
    color: '#fff',
    backgroundColor: '#054C74',
    '&:hover': {
        backgroundColor: '#E6E6E6',
        color: '#1A1A1A',
    },        
  },  
  formArea: {
    float:'left',
    ['@media (max-width:767px)']: { 
      float: 'none',
      }   
  },
  formlineLeftItem: {
    float:'left', 
    minWidth:'150px', 
    textAlign:'right', 
    paddingRight: '10px', 
    lineHeight:'85px'
  },
  formlineRightItem: {
    float:"left", 
    width: "350px"
  }
}))

export default function SubscriptionsProfile() {
  const {replace} = useRedirect()
  const classes = useStyles()
  const [buttonVisible, setButtonVisible] = useState(true);
  const userProfile = useSelector(getUser)
  const news = useSelector(getNews)
  const alerts = useSelector(getAlerts)
  const saveLoader = useSelector(getLoader)
  const loading = useSelector(getLoading)
  const message = useSelector(getMessage)
  const purchases = useSelector(getPurchases)

  const dispatch = useDispatch()
  const types = {
    individual: 'Для физического лица',
    entity: 'Для юридического лица',
  }    
  const statuses = {
    new: 'Ожидайте выставления счета',
    sent: 'Ожидает оплаты',
    paid: 'Оплачен',
    expired: 'Оплата просрочена'
  } 

  useEffect(() => {
    
    //dispatch(loadProfileData)
    if ((userProfile)&&(!purchases))
      dispatch(loadPurchases)
  }, [userProfile])  

  const saveSubscriptionsAction = () => {
    dispatch(setMessage(''))
    dispatch(setLoader(true))
    setButtonVisible(false)
    dispatch(saveSubscriptions(news, alerts))
  
  
  
  }

  const newsCheckClick = (event) => {
    dispatch(setNews(event.target.checked))
  } 
  const alertsCheckClick = (event) => {
    dispatch(setAlerts(event.target.checked))
  } 

  if (!purchases)
  return ( <div style={{display: 'table', paddingTop:'100px', margin: 'auto'}}><Loader/></div>
    )
else
    return (
      <div className={classes.personalArea}>
      <div style={{display:'table', width:'-webkit-fill-available', clear:'both', paddingBottom: '20px'}}>
        <div style={{display:'table-row', }}>
          <div style={{display:'table-cell', padding: '5px 5px 15px 5px', fontWeight:'bold', borderBottom:'solid 1px #ccc'}}>Наименование тарифа</div>
          <div style={{display:'table-cell', padding: '5px 5px 15px 5px', fontWeight:'bold', borderBottom:'solid 1px #ccc' }}>Дата покупки</div>
          <div style={{display:'table-cell', padding: '5px 5px 15px 5px', fontWeight:'bold', borderBottom:'solid 1px #ccc' }}>Дата окончания</div>
        </div>
        {
          purchases?.map(purchItem =>
            <div style={{display:'table-row'}}>
            <div style={{display:'table-cell', padding: '10px 5px 10px 5px', borderBottom:'solid 1px #ccc', color:((new Date(purchItem?.datestop))<(new Date()))?'#aaa':'#000'}}>{types[purchItem.tariffinfo.TYP]+' '+purchItem.tariffinfo.DURATION+' дней'}</div>
            <div style={{display:'table-cell', padding: '10px 5px 10px 5px', borderBottom:'solid 1px #ccc', color:((new Date(purchItem?.datestop))<(new Date()))?'#aaa':'#000'}}>{
            (purchItem?.status == 'new')?
            <span 
              className="headerlinkImageText"
              style={{cursor:"pointer"}}              
              onClick={() => window.open('/payorder/'+purchItem?.id+'/', "_self")}
            >{statuses[purchItem?.status]}</span>
            :((purchItem?.status == 'sent')&&(userProfile?.role == 'individual'))?
            <span 
              className="headerlinkImageText"
              style={{cursor:"pointer"}}              
              onClick={() => window.open('https://importzamena.server.paykeeper.ru/bill/'+purchItem?.invoice_id, "_self")}
            >{statuses[purchItem?.status]}
            </span>
            :((purchItem?.status == 'sent')&&(userProfile?.role != 'individual'))?
            <Link target="_self" to={'/payorder/'+purchItem?.id+'/'} className="link breadcrumbslink">{statuses[purchItem?.status]}</Link>
            :(purchItem?.datebuy != null)?
            <span>{(purchItem?.datebuy)?((((new Date(purchItem?.datebuy)).getDate() < 10)?'0':'') + (new Date(purchItem?.datebuy)).getDate().toString()+'.'+(((((new Date(purchItem?.datebuy)).getMonth()+1) < 10)?'0':'')+ ((new Date(purchItem?.datebuy)).getMonth()+1)).slice(-2)+'.'+(new Date(purchItem.datebuy)).getFullYear()):null}</span>
            :statuses[purchItem?.status]
            }</div>
            <div style={{display:'table-cell', padding: '10px 5px 10px 5px', borderBottom:'solid 1px #ccc', color:((new Date(purchItem?.datestop))<(new Date()))?'#aaa':'#000'}}>{
            (purchItem?.datestop != null)?
            ((((new Date(purchItem?.datestop)).getDate() < 10)?'0':'') + (new Date(purchItem?.datestop)).getDate().toString()+'.'+(((((new Date(purchItem?.datestop)).getMonth()+1) < 10)?'0':'')+ ((new Date(purchItem?.datestop)).getMonth()+1)).slice(-2)+'.'+(new Date(purchItem.datestop)).getFullYear())
            :'-'
            }</div>
          </div>
            )
            
        }


      </div>

      {
          (!purchases?.length)?<div style={{marginBottom:'20px'}}>Нет купленных тарифов</div>:null
        }      
      <Grid container spacing={2} style={{marginBottom:'20px'}}>
                    <Grid item>
                        <span
                          className="headerlinkImageText"
                          style={{cursor:"pointer"}}
                          onClick={() => window.open("/tariffs", "_self")} 
                        >
                          Подобрать тариф
                        </span>
                        
                    </Grid>
                </Grid>




      <div className={classes.formArea}>
          <div >
          <Checkbox checked={news} onClick={newsCheckClick} color="#054C74"/>Новости сайта, рекламная информация
          </div>
          <div >
          <Checkbox checked={alerts} onClick={alertsCheckClick} color="#054C74"/>Оповещения о событиях
          </div>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <div className={!saveLoader?'':'hidden'} style={{width:'100%', paddingTop:'20px'}}>
                <Button variant="contained"  size={'large'} onClick={saveSubscriptionsAction}  className={classes.button}>
                Сохранить изменения
                </Button>
              </div>
              <span className={saveLoader?'':'hidden'}><Loader/></span>
            </Grid>
          </Grid>                                     
          <div style={{textAlign:'center', paddingTop:'20px'}}>{message}</div>
        </div>      
      </div>
    );
  }