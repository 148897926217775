import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";
import {HeaderPublic} from "../components/HeaderPublic/HeaderPublic";
import {FooterPublic} from "../components/FooterPublic/FooterPublic";
import {Container} from "../components/Container/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    main: {
        minHeight: 'calc(100vh - 272px)',
        paddingLeft:'0px',
        display: 'flow-root',
        paddingRight:'0px',
        marginLeft:'unset',
        marginRight:'unset',
        maxWidth:'unset'        
    }
}));

export const HomePublicLayout = ({children}) => {
    const classes = useStyles()
 
    return <Box>
        <HeaderPublic>
            <div></div>
        </HeaderPublic>
        <Container component={'main'} className={classes.main}>
            {children}
        </Container>
        <FooterPublic>
            <div></div>
        </FooterPublic>
    </Box>
}
