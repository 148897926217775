import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getLoggedIn, setTariff} from "../../redux/reducers/login-reducer.js";
import  Loader  from '../Loader/Loader.jsx';
import {GridCellExpand} from "../GridCellExpand/GridCellExpand.jsx";
import { PurchasesTable } from "../PurchasesTable/PurchasesTable.jsx";
import {useQuery} from "../../hooks/useQuery.js";
import {useRedirect} from "../../hooks/useRedirect.js";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { getError, getPurchase, getPurchasesError, getPurchasesLoader, getPurchases, loadPurchase, loadPurchases, setPurchase, loadOrg, getOrg, setOrg, savePurchaseInvoiceFile, setStatusPaid } from "../../redux/reducers/purchases-reducer.js";
import { PurchasesEditTable } from "../PurchasesEditTable/PurchasesEditTable.jsx";
import Container from '@mui/material/Container';
import Paper from "@material-ui/core/Paper";
import {Dialog} from "../Dialog/Dialog";
import {useDialog} from "../../hooks/useDialog";
import {DropzoneAreaBase} from "material-ui-dropzone";

const MAX_FILE_SIZE = 10000000

const useStyles = makeStyles((theme) => ({
  orderArea: {
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px #bfbfbf',
    borderRadius: '12px',
    width:'500px',
    padding: '15px',
    marginTop:'15px',
    ['@media (max-width:520px)']: { 
        width: '-webkit-fill-available',
        marginRight: '5px',
        marginLeft: '5px',
        marginBottom:'20px',
        height:'auto'
    },        

  },
  dropZone: {
    padding: '10px',
    "& .MuiGrid-container": {
      padding: "10px",
    },
  },
  chapterName: {
    fontSize:'20px', 
    textAlign: 'center',
    fontWeight: 400,
    marginBottom:'20px'
  },
  headitemname : {
    display: 'flex', 
    paddingTop: '10px',
    paddingLeft: '5px',
    fontSize:'16px',
    
    color: '#929292'
  },
  headitemvalue : {
    display: 'flex', 
    fontSize:'15px',
    paddingLeft: '5px',
  },   
  
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
    button: {
      color: '#fff',
      backgroundColor: '#054C74',
      '&:hover': {
          backgroundColor: '#E6E6E6',
          color: '#1A1A1A',
      },        
  },     

}))


export default function Purchases() {

    const query = useQuery()
    const id = query.get('id')
    const {replace} = useRedirect()

    const classes = useStyles()
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getLoggedIn)
    const purchases  = useSelector(getPurchases);    
    const purchasesItem = useSelector(getPurchase)
    const purchasesItemOrg = useSelector(getOrg)
    const purchasesLoader = useSelector(getPurchasesLoader)
    const error = useSelector(getPurchasesError)
    const [ip, setIp] = useState(false);

    const {open, handleClose, handleOpen} = useDialog()
    const [files, setFiles] = useState([])


    const roles = {
      operator: 'Администратор',
      individual: 'Физическое лицо',
      entity: 'Юридическое лицо',
    }
    const types = {
      individual: 'Физическое лицо',
      entity: 'Юридическое лицо',
    }    
    const statuses = {
      new: 'Ожидает выставления счета',
      sent: 'Ожидает оплаты',
      paid: 'Оплачен',
      expired: 'Оплата просрочена'
    } 
  

    useEffect(() => {
      if((purchases == null)&&(!purchasesLoader))
      dispatch(loadPurchases());
      if ((id) &&(!purchasesItem)&&(purchases)) {
        dispatch(loadPurchase(id))
    }      
    }, [purchases,id]);        


  useEffect(() => {

      if (!id){
        dispatch(setPurchase(null))
        dispatch(setOrg(null))
      }
  }, [id]);        



  useEffect(() => {
    if (purchasesItem?.id != null){
        if (purchasesItem.status == 'paid')
          dispatch(loadPurchases());
        if (purchasesItem?.invoice_file)
          setFiles (JSON.parse(purchasesItem?.invoice_file?.replaceAll("'", '"')))
        replace('/operator/?tab=Purchases&id='+purchasesItem?.id)
        if (purchasesItem?.orgfull != null){
          if (!purchasesItemOrg)
            dispatch(loadOrg(purchasesItem.user))
        }
      }

    }, [purchasesItem]);   

  

  const setTariffValue = (name, value) => {
    let purchasesItemCopy = {... purchasesItem}
    purchasesItemCopy[name] = value
    dispatch(setPurchase(purchasesItemCopy))
  }


  const closeEdit = () => {
    dispatch(setTariff(null))
    replace('/operator/?tab=Purchases')
  }

  
    
      const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              link = {'/operator/?tab=Purchases&id='+params.id}
              
             // clickHandle = {props.action}
          />
        )
      }        



    const genPurchasesColumns = () => {
      let compareColumns = [
          { 
          field: "id", 
          headerName: "ID", 
          minWidth: 15, 
          
//            renderCell: RenderDescription
          }, 
          { 
            field: "status", 
            headerName: "Статус", 
            minWidth: 100, flex: 1, 
            renderCell: RenderDescription
          },
          { 
            field: "user", 
            headerName: "Пользователь", 
            minWidth: 100, flex: 1, 
            renderCell: RenderDescription
          },           
          { 
            field: "role", 
            headerName: "Тип", 
            minWidth: 100, flex: 1, 
            renderCell: RenderDescription
          },           
          { 
            field: "org", 
            headerName: "Организация", 
            minWidth: 100, flex: 1, 
            renderCell: RenderDescription
          },           

          { 
          field: "tariff", 
          headerName: "Тариф", 
          minWidth: 100, flex: 1, 
          renderCell: RenderDescription
          
          }, 
          { 
            field: "datebuy", 
            headerName: "Дата покупки", 
            width: 300, flex: 1, 
            renderCell: RenderDescription
          }, 
          { 
              field: "datestop", 
              headerName: "Дата окончания", 
              width: 300, flex: 1, 
              renderCell: RenderDescription
          },             
          ]

      return compareColumns
  }      

  const genRows = () => {
    let rowData = []
    purchases?.forEach( function callback (purchItem) {
        let tmpRow = {  
          id:purchItem.id,
          tariff: types[purchItem.tariffinfo.TYP]+' '+purchItem.tariffinfo.DURATION+' дней',
          status: statuses[purchItem?.status],
          user:purchItem?.userinfo?.first_name+' '+purchItem?.userinfo?.last_name,
          role:roles[purchItem?.tariffinfo?.TYP],
          org:(!purchItem.orgfull)?'-':purchItem.orgfull+' ('+purchItem.orgfull+') '+purchItem.orginn,
          datebuy:(!purchItem.datebuy)?'-':new Date(Date.parse(purchItem.datebuy)).toLocaleDateString() +' '+new Date(Date.parse(purchItem.datebuy)).toLocaleTimeString(), 
          datestop:(!purchItem.datestop)?'-':new Date(Date.parse(purchItem.datestop)).toLocaleDateString() +' '+new Date(Date.parse(purchItem.datestop)).toLocaleTimeString(), 
        }
        rowData.push(tmpRow)
    })
    return rowData

}  

const eqDateTime = (dtString) => {
  const eqDT = {};
  eqDT['Y'] = dtString.split('T')[0].split('-')[0];
  eqDT['M'] = dtString.split('T')[0].split('-')[1];
  eqDT['D'] = dtString.split('T')[0].split('-')[2];

  eqDT['H'] = dtString.split('T')[1].split(':')[0];
  eqDT['I'] = dtString.split('T')[1].split(':')[1];
  eqDT['S'] = dtString.split('T')[1].split(':')[2].split('.')[0];
  return eqDT['D']+'.'+eqDT['M']+'.'+ eqDT['Y']+' '+ eqDT['H']+':'+ eqDT['I']+':'+ eqDT['S']
}


const handleDownloadFile = async (file) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = file[0]['data'];
  downloadLink.download = file[0]['file']['name'];
  downloadLink.click();
}

const handleAddFile = async (newFiles) => {
//  dispatch(setPurchase({...purchasesItem, newFiles}))
  setFiles(newFiles)
  dispatch(savePurchaseInvoiceFile(purchasesItem.id, getFiles(newFiles)))
}

const getFiles  = (files) => {
  const imageObject = {};
  imageObject['file'] = {}
  imageObject['data'] = files[0].data
  for (const key in files[0].file) {
      const value = files[0]['file'][key];
      (typeof value !== "function") && (imageObject['file'][key] = value);
  }

  return [imageObject];

}

const handleDeleteFile = async () => {
  setFiles([])
}

   
    return (
        ((purchases)&&(!id)&&(!purchasesLoader))?
        <div >
            
            <PurchasesEditTable style={{height:'auto'}} data={genRows()} columns={genPurchasesColumns()} />

        </div>
        :((((purchasesItem)&&(purchasesItem?.orgfull == null)) || (purchasesItemOrg))&&(id)&&(!purchasesLoader))?
            <div className={classes.orderArea}>
                <div><h1 className={classes.chapterName}>ЗАКАЗ №{purchasesItem?.id}<br/>{statuses[purchasesItem?.status]}
                {
                 ((purchasesItem?.tariffinfo?.TYP == 'individual') && (purchasesItem?.status == 'sent'))?
                 <span style={{fontSize:'16px'}}><br/>до {new Date(Date.parse(purchasesItem?.datecreate)+1000*1800).toLocaleDateString() +' '+new Date(Date.parse(purchasesItem?.datecreate)+1000*1800).toLocaleTimeString()}</span>
                 :null
                }
                </h1></div>
                {
                ((purchasesItem?.tariffinfo?.TYP == 'entity') && (purchasesItem?.status == 'sent'))?
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Button variant="contained" 
                    onClick={()=>dispatch(setStatusPaid(id, {setpaid:true}))}  
                    style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                    size={'large'} 
                    >
                      ПОДТВЕРДИТЬ ОПЛАТУ
                    </Button>
                  </Grid>
                </Grid>              


                :null
                }
                {
                ((purchasesItem?.tariffinfo?.TYP == 'entity') && (purchasesItem?.status == 'paid'))?
                  <div>
                      <div className={classes.headitemname}><a href={files[0]?.data} className="headerlinkImageText" style={{cursor:'pointer'}}  download={files[0]?.file?.name}>Скачать счет</a></div>
                  </div>
                :((purchasesItem?.tariffinfo?.TYP == 'entity') && ((purchasesItem?.status == 'sent') || (purchasesItem?.status == 'new')))?
                  <DropzoneAreaBase
                      dropzoneClass={classes.dropZone}
                      acceptedFiles={[
                        'application/pdf',

                      ]}

                      fileObjects={files}
                      filesLimit={1}
                      sx={{minHeight:'auto'}}
                      maxFileSize={MAX_FILE_SIZE}
                      onAdd={handleAddFile}
                      onDelete={handleDeleteFile}
                      dropzoneText={'Нажмите на выделенную область или перетащите pdf файл для загрузки'}
                      getFileLimitExceedMessage={(filesLimit) => `Превышено максимально допустимое количество файлов. Разрешено только ${filesLimit} `}
                      getFileAddedMessage={(fileName) => `Файл ${fileName} успешно добавлен `}
                      getFileRemovedMessage={(fileName) => `Файл ${fileName} удален `}
                      getDropRejectMessage={(rejectedFile) => `Файл ${rejectedFile.name} был отклонен `}
                      showPreviews={false}
                      showPreviewsInDropzone={true}
                      useChipsForPreview
                      previewChipProps={
                          {
                              onClick: (e)=>{ e.stopPropagation();handleDownloadFile(files)}
                          }}
                      previewText="Загруженные файлы"
                  />
                  :null
                  }
            
                <div>
                    <div className={classes.headitemname}>Дата заказа</div>
                    <div className={classes.headitemvalue}>{eqDateTime(purchasesItem?.datecreate)} </div>
                </div>                    
                <div>
                    <div className={classes.headitemname}>Тариф</div>
                    <div className={classes.headitemvalue}>{types[purchasesItem?.tariffinfo?.TYP]+' '+purchasesItem?.tariffinfo?.DURATION+' дней'} </div>
                </div>                    
                <div>
                    <div className={classes.headitemname}>Стоимость</div>
                    <div className={classes.headitemvalue}>{purchasesItem?.tariffinfo?.PRICE} р.</div>
                </div> 

                <div style={{paddingLeft:'5px'}}>
                  {(purchasesItemOrg?.inn != '')&&(purchasesItemOrg?.inn)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>ИНН:</span> {purchasesItemOrg?.inn}</div>:null}
                  {(purchasesItemOrg?.kpp != '')&&(purchasesItemOrg?.inn)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>КПП:</span> {purchasesItemOrg?.kpp}</div>:null}
                  {(purchasesItemOrg?.ogrn != '')&&(purchasesItemOrg?.ogrn)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>ОГРН:</span> {purchasesItemOrg?.ogrn}</div>:null}
                  {(purchasesItemOrg?.opfname != '')&&(purchasesItemOrg?.opfname)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Организационно-правовая форма:</span> {purchasesItemOrg?.opfname}</div>:null}
                  {(purchasesItemOrg?.full != '')&&(purchasesItemOrg?.full)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Полное наименование:</span> {purchasesItemOrg?.full}</div>:null}
                  {(purchasesItemOrg?.shortwithopf != '')&&(purchasesItemOrg?.shortwithopf)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Сокращенное наименование:</span> {purchasesItemOrg?.shortwithopf}</div>:null}
                  {(purchasesItemOrg?.managerName != '')&&(purchasesItemOrg?.managerName)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Руководитель:</span> {purchasesItemOrg?.managerName}, {purchasesItemOrg?.managerPost}</div>:null}
                  {(purchasesItemOrg?.addressline != '')&&(purchasesItemOrg?.addressline)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Адрес местонахождения:</span> {purchasesItemOrg?.addressline}</div>:null}
                </div>                                   

            </div>


        :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table'}}><Loader/></div>         
    )

}