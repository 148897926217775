import React, {useEffect, useState}  from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import {Search} from "../components/Search/Search";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '54px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: ''
    },

}))

export const SearchPage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)
    const [av, setAv] = useState(false);

    useEffect(() => {
//        document.title = 'Поиск - Импортозамещение и подбор нефтегазового оборудования';
      }, []);    


    const ssetAv = (avе) => {
        
        setAv(avе)
    }

    if (!isLoggedIn) 
    return <HomePublicLayout>
                    <Search/>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout av={av} setAv={setAv}>
                    <Search av={{av, ssetAv}}/>                  
                </HomePrivateLayout>    
}