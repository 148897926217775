import React, { createRef, useState, useEffect, useCallback } from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import {getFirm, getFirmError, getFirmLoader, setFirm, setFirmLoader, setFirmError} from "../../redux/reducers/firm-reducer";

import { createSearchParams, useSearchParams, Link, useParams } from "react-router-dom";
import {setCompareStatus, setBookmarkStatus,getLoadCm,setLoadCm, getLoadBm, setLoadBm, loadASearch, loadFSearch, loadSearch, getSearchResult, setSearchResult, getSearchOk, getLoadingResult, getLoadingOk, getError, loadOk, setSearchOk, getSearchBTP, loadBTP, setSearchBTP,loadCSearch} from "../../redux/reducers/search-reducer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Loader from "../Loader/Loader.jsx";
import Badge from "@material-ui/core/Badge";
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@mui/material/Chip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tune from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import { CompareTable } from "../CompareTable/CompareTable";
import {GridCellExpand} from "../../components/GridCellExpand/GridCellExpand.jsx";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Compare from "../Compare/Compare"
import {SearchSkeleton, OkSkeleton} from "../SearchSkeleton/SearchSkeleton.jsx";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    promoPanelSearchTerms: {
        marginTop: '20px',
        borderLeft: '2px solid #868686',
        borderTop: '2px solid #868686',
        borderBottom: '2px solid #868686',
        borderRight:0,
        
        width: '635px',
        height: '40px',
        borderRadius: '5px 0px 0px 5px',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        paddingLeft: '10px',
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '350px',
              borderRight: '2px solid #868686',
              borderRadius: '5px',
          },
          ['@media (max-width:360px)']: { 
            width: '300px',
          }
      },    
      promoPanelSearchButon: {
        background: '#054C74',
        borderRadius: '0px 5px 5px 0px',
        width: '115px',
        height: '40px',
        outline: 'none',
        border: '0',
        color: 'white',
        cursor: 'pointer',
        ['@media (max-width:500px)']: { 
            display: 'none'
            }           
      },
      promoPanelAdv: {
        cursor: 'pointer',
        position: 'absolute',
        right: '125px',
        top: '28px',
        ['@media (max-width:500px)']: { 
            display: 'none'
            }  
    },               
    promoPanelSearch: {
        position: 'absolute',
        right: '5px',
        top: '28px',
        display: 'none',
        ['@media (max-width:500px)']: { 
            display: 'block'
            }  
    },  
    searchHeaderOk: {width:'860px', paddingTop:'10px', margin: 'auto',
    ['@media (max-width:860px)']: { 
        width: 'auto',
        margin: '0 5px'
    },       
    ['@media (max-width:500px)']: { 
        width: 'auto',
        '& .MuiChip-label': { fontSize: '14px' }, 
        
    }
    },
    customBadge: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '10px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },
      customBadge2: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '4px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },
      compareResultMain: {paddingTop: '10px', paddingBottom: '0px', width:'95%', 
      ['@media (max-width:860px)']: { 
          width: '-webkit-fill-available',
          margin: '0'
      },   
      ['@media (max-width:500px)']: { 
          width: '-webkit-fill-available',
      }
      },
  
}))


export const CompareEQ = (av = null) => {

    const dispatch = useDispatch();
    const classes = useStyles()
    
    const [ok, setOk] = useState(null);
    const [searchResultOut, setSearchResultOut] =  useState(null);
    const [showAllOk, setShowAllOk] =  useState(false);
    

    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState(searchParams.get("query"));

    let searchResult = useSelector(getSearchResult)
    const searchOk = useSelector(getSearchOk)
    const searchBTP = useSelector(getSearchBTP)
    const loadCm = useSelector(getLoadCm)

    useEffect(() => {
        if (loadCm) {
            av?.av?.ssetAv(!av?.av?.av);
            dispatch(setLoadCm(false))

            if(searchResultOut.length == 0) {
                dispatch(loadCSearch({page:null, ok:'any'}))
                setOk(null)
            }
            else {
            dispatch(loadOk(searchResult.id, false));
            }
        }
    },[loadCm])  

    useEffect(() => {
    dispatch(loadCSearch({page:null, ok:(ok)?ok:'any'}));
    },[])

    useEffect(() => {
        if ((searchResult != null)&&(!ok)){
            dispatch(loadOk(searchResult.id, false));
            if (searchResult?.count == 0)
                setSearchResultOut([])
        }
        if(ok)
            setSearchResultOut(searchResult.result)

    }, [searchResult]);      

    useEffect(() => {
        
        if ((searchOk)&&(searchOk?.ok?.length) && (!ok))
            setOk(searchOk?.ok[0]?.ID)
            

    }, [searchOk])
    useEffect(() => {
        if (ok != null) {
            dispatch(loadBTP(ok))
            dispatch(loadCSearch({page:null, ok:(ok)?ok:'any'}));

        }

    }, [ok]);   

    const setCategory = (okid = null, fullname, name, count) => {
        if ((okid != null)&&(okid!=ok)){
            setOk(okid)
            setSearchResultOut(null)
        }

    }

    const genOkResult = (full) => {

        let content = [], j = 0
        let okfound = false
        if (searchOk?.ok) {
        for(let k = 0; k<2; k++) {
        searchOk.ok.forEach( function callback (searchOkItem) {
            let name='', i = 0
            searchOkItem.NAME.split(' ').forEach( function callback (searchOkItemName) {
                if (i <= 2)
                {
                    name = name + searchOkItemName
                    if (i < 2)
                    name = name + ' '
                    else
                    if (searchOkItem.NAME.split(' ').length >3)
                    name = name + '...'
                }
                i++
            })
            

            if ((full != true) && (content.length >= 3)) {
                return
            }   
            let bgcolor;         
            if (searchOkItem.ID == ok)
                bgcolor = "#f1faff"
            else
                bgcolor = "#ffffff"

            if(((k==0)&&(searchOkItem.ID==ok))||((k==1)&&(searchOkItem.ID!=ok)))
            content.push(
                <Badge badgeContent={searchOkItem.count} className={classes.customBadge} style={{marginLeft:'10px', marginRight:'10px'}}>
                    <Tooltip title={<span style={{ fontSize:'14px'}}>{searchOkItem.NAME}</span>} arrow PopperProps={{ style: { marginTop: -12 } }} style={{top:'-10px'}}>
                        <Chip style={{margin:'3px', backgroundColor:bgcolor}} onClick={() => setCategory(searchOkItem.ID,searchOkItem.NAME,name,searchOkItem.count)} label={name} variant="outlined" />
                    </Tooltip>                                                  
                </Badge>
            )

            if (((full != true) && (content.length == 3)&&(searchOk.ok.length>3)) || ((full == true) && (searchOk.ok.length-1 == j))) {
                content.push(
                    <span style={{cursor:'pointer', color:'#054C74', display:'block'}} onClick={()=>{if(full) window.scroll({top: 0, left: 0, behavior: 'smooth' }); setShowAllOk(!showAllOk)}}><span style={{verticalAlign: 'middle'}}>{full? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}</span><span style={{borderBottom: '1px dashed'}}>{full?'Свернуть категории' : 'Все категории'}</span></span>
                )
            }

            
        j++
        })
        }
        }

        return content

    }

    const onSearchChange = e => {
        setQuery(e.target.value)
    }

    const search = () => {
        if (query != '') {
              window.open('/eqpsearch/?query='+query,"_self");
  
        }
      }    

    const setCompare = (id) => {
                
        setSearchResultOut((searchResultOut) => {
//            return searchResultOut.map((item, j) => {
                //if (item?.eq?.ID != id) 
                //return item
            //})
            searchResultOut = searchResultOut.filter(eq=>eq.eq.ID !== id)
            searchResult.id = searchResult?.id?.filter(val=>val!=id)
            return searchResultOut
        })
        dispatch(setCompareStatus(id))
        
    }

    const genColumns = () => {
        let compareColumns = [
            { 
                field: "compare", 
                renderHeader: (params) => (<BookmarkBorderIcon/>),
                width:40,
                renderCell: (params) => (<Compare onClick={()=>setCompare(params.row.id)}  status={params.row.compare}/>)
            },
            { 
            field: "eqname", 
            headerName: "Наименование", 
            minWidth: 180, flex: 1, 
            renderCell: RenderDescription
            }, { 
            field: "shifr", 
            headerName: "Шифр", 
            width: 150,
            renderCell:RenderDescription, 
            }]
        searchBTP?.forEach( function callback (searchBTPItem) {
            compareColumns.push({ field: "f"+searchBTPItem.ID, headerName: searchBTPItem.NAME, flex: 1, minWidth:100, 
            renderCell: (params) => (<span>{params.row["fc"+searchBTPItem.ID]}</span>)
            //    sortable: false
        })
        })

        compareColumns.push({ 
            field: "firm", 
            headerName: "Изготовители", 
            width: 200,
            renderCell:RenderDescription, 
            })

        return compareColumns
    }

    const genRows = () => {
        let rowData = []
        
        searchResultOut?.forEach( function callback (searchResultOutItem) {

            let tmpRow = {id:searchResultOutItem.eq.ID,compare:searchResultOutItem.compare,eqname:searchResultOutItem.eq.NAME,shifr:searchResultOutItem.eq.SHIFR}
            tmpRow['firm'] = ""
            searchResultOutItem?.firms?.map((firmItem, i) => {
                tmpRow['firm'] += firmItem.NAME
                if(i < searchResultOutItem?.firms?.length)
                tmpRow['firm'] += "\n"
            })

            searchBTP?.forEach( function callback (searchBTPItem) {
                const fParam = searchResultOutItem?.prm.filter(prm=>prm.name == searchBTPItem.NAME)[0]
                
                if (fParam) {
                tmpRow["fc"+searchBTPItem.ID] = fParam?.levels != null? fParam?.levels: fParam?.val1 == fParam?.val2 ? fParam?.val1: fParam?.val1+' ... '+fParam?.val2
                tmpRow["fc"+searchBTPItem.ID] += " "+fParam?.ei

                tmpRow["f"+searchBTPItem.ID] = ""+fParam?.order
                }
                else {
                    tmpRow["fc"+searchBTPItem.ID] = "-"
                    tmpRow["f"+searchBTPItem.ID] = '0'
                }
            })

            rowData.push(tmpRow)

        })


        return rowData
    }

    const RenderDescription = (params) => {

        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
          />
        )
      
      
      
      }

    return <div className={classes.paper}>
                <div Style={'position:absolute'}>
                    <input type="text" onKeyDown={(e) => { if (e.key === 'Enter') search()}} autocomplete="off" onChange={onSearchChange} className={classes.promoPanelSearchTerms} id="search-terms" placeholder="Наименование или шифр оборудования" value={query}/>
                    <button onClick={search} className={classes.promoPanelSearchButon} >Найти</button>
                    <Link to="/advanced" className="link headerlinkImageText"><Tune className={classes.promoPanelAdv}/></Link>
                    <SearchIcon onClick={search} className={classes.promoPanelSearch}/>
                </div>     
                <div className={classes.compareResultMain} style={{paddingTop: '70px'}}>

                    <div className={searchResultOut==null?'':''} >
                        <div className={!searchOk?'':'hidden'}><OkSkeleton/></div>
                        <div className={classes.searchHeaderOk}>{genOkResult(showAllOk)}</div>
                        <div className={searchResultOut==null?'hidden':''} style={{marginBottom:'30px'}}>
                            <CompareTable  style={{height:'auto'}} data={genRows()} columns={genColumns()}/>
                        </div>


                    </div>
                    <div className={searchResultOut==null?'':'hidden'} ><div style={{margin: '40px auto 40px auto', display: 'table'}}><Loader/></div></div>
                    
                </div>
                
            </div>

}

