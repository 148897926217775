import {ProfileAPI} from "../../api/api";
import {createErrorNotification} from "./notification-reducer";
import {logout, setTariff} from "./login-reducer.js";

const SUCCESS_LOADED_PROFILE = 'masterOfActs/SUCCESS_LOADED_PROFILE'
const SET_PROFILE = 'masterOfActs/SET_PROFILE'

const initialState = {
    user: null,
    loading: true
}

export const userProfile = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_LOADED_PROFILE:
            return {...state, user: action.user, loading: false}
        case SET_PROFILE:
            return {...state, user: action.user}
        default:
            return state
    }
}

export const getProfile = (state) => state.userProfile

export const successLoadedProfile = user => ({type: SUCCESS_LOADED_PROFILE, user})
export const setProfile = user => ({type: SET_PROFILE, user})


export const loadProfileData = async dispatch => {
    const response = await ProfileAPI.getProfile()

    if (response.status === 200) {
        
        localStorage.setItem('tariff', (response?.data?.tariff > 0)?true:false);        
        dispatch(setTariff((response?.data?.tariff > 0)?true:false))
        dispatch(successLoadedProfile(response.data))
    } if (response.status === 205) {
        dispatch(logout())

    }
}


export const updateProfileData = (values) => async (dispatch) => {
    try {
        await ProfileAPI.updateProfile(values)

    } catch (e) {
        dispatch(createErrorNotification(Object.values(e.response.data)[0]))
    }
}

export const updatePassword = (userID, values, onSuccess) => async (dispatch) => {
    try {
        await ProfileAPI.updatePassword(userID, values)
        onSuccess()
        dispatch(logout())
    } catch (e) {
        dispatch(createErrorNotification(Object.values(e.response.data)[0]))
    }
}