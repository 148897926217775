import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Route, Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {loadSpecs, getSpecs, getSpec, loadSpecEdit, setSpec, getOk, newSpec, updateSpec, getSpecLoader, getSaved, setSaved, loadPrm, getPrm, deleteSpec, getError, setError} from "../../redux/reducers/spec-reducer";

import {getLoggedIn} from "../../redux/reducers/login-reducer";
import  Loader  from '../Loader/Loader';
import {API_SERVER_VAL} from "../../config";
import Button from "@material-ui/core/Button";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@material-ui/core/Grid";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useRedirect} from "../../hooks/useRedirect";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from '@mui/material/MenuItem';
import {useQuery} from "../../hooks/useQuery";
import {OkPanel} from "../../containers/OkPanel/OkPanel";
import Autocomplete from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';

const baseUrl = `${API_SERVER_VAL}/api/eqp`

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px',
        width: '100%',
    },
    FirmMain: {paddingTop: '70px', paddingBottom: '30px', width:'1024px', 
    ['@media (max-width:860px)']: { 
        width: '-webkit-fill-available',
        margin: '0 5px'
    },   
    ['@media (max-width:500px)']: { 
        width: '-webkit-fill-available',
    }
    },    
    mainDialog: {
        "& div.MuiPaper-root": {
            margin: '5px',
            height: 'calc(100% - 5px)',
            minWidth: '60%'
            
          },
        "& .MuiTypography-root": {
          padding: '5px',
          margin: 'auto'      
        },
        "& .MuiDialogContent-root": {
            padding: '0',
            
        },
        "& .MuiTreeItem-label": {
            color: '#1A1A1A'
        }

    },    

    headitemname : {
        display: 'flex', 
        paddingTop: '10px',
        paddingLeft: '5px',
        fontSize:'16px',
        
        color: '#929292'
    },
    headitemvalue : {
        display: 'flex', 
        fontSize:'15px',
        paddingLeft: '5px',
    },
    orderMenu: { minWidth:'100px',
    width:'100%',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    },     

}))



export const SpecEdit = (av) => {
    const query = useQuery()
    const id = query.get('id')


    const classes = useStyles()
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getLoggedIn)
    const [dialogClassifier, setDialogClassifier] = useState(null);
    const [owner, setOwner] =  useState(1);


    const specTypeList = [
        {name:"Числовой", value:0},
        {name:"Списковый", value:1},

    ]
    

    const {replace} = useRedirect()

    const specItem = useSelector(getSpec)
    const specLoader = useSelector(getSpecLoader)
    const saved = useSelector(getSaved)
    const prm = useSelector(getPrm)
    const error = useSelector(getError)

    


    useEffect(() => {
        
        dispatch(loadSpecEdit(id))

        

    }, []);

    useEffect(() => {

        if(saved == true) {
        dispatch(setSaved(false))
        replace('/operator/?tab=Reference&component=spec')
        }

    }, [saved]);


    const setSpecValue = (name, value) => {
        let specItemCopy = {... specItem}
        specItemCopy[name] = value
        dispatch(setSpec(specItemCopy))
    }

    const setAcSpecValue = (_, value) => {
        setSpecValue('EI', value.ID)
    }

    const сloseDialogClassifier = () => {
        setDialogClassifier(null);
      };

      const ok = () => {
        let content = []

        if(specItem?.ok?.length > 0)
        specItem?.ok?.forEach( function callback (okItem) {
            if (okItem?.STATUS != 'deleted')
            content.push(<div className={classes.headitemvalue} style={{paddingTop:'3px'}}>{okItem.NAME}
                                <IconButton style={{padding:'0', top:'-2px'}} onClick={()=>{
                                    (okItem?.status == 'new')?
                                    dispatch(setSpec({... specItem, ok:specItem?.ok?.filter(option=>option.ID != okItem.ID )}))
                                    :
                                    dispatch(setSpec({... specItem, ok:specItem?.ok?.map(option=>{
                                        if (option.ID == okItem.ID)
                                            option['STATUS'] = 'deleted'
                                        return option
                                    }
                                         )}))
                                    }} >
                                    <CloseIcon />
                                </IconButton> 
                        </div>)
        })

        return content
    }      
    
    const addClassifier = (id, name) => {
        dispatch(setSpec({... specItem, ok:specItem?.ok?.concat([{ID:id, NAME:name, STATUS:'new'}])}))
        сloseDialogClassifier()
    }

    
return  <div className={classes.paper}>

<Dialog
            className={(dialogClassifier != 'delete')?classes.mainDialog:null}
        open={(dialogClassifier == null)?false:true}
        onClose={сloseDialogClassifier}
        handleClose={сloseDialogClassifier}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
      >
        <DialogTitle id="scroll-dialog-title">
        
        {(dialogClassifier == 'delete')?
        <DialogContentText
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
        Вы действительно хотите удалить «{specItem.NAME}»?

        </DialogContentText>
        :(dialogClassifier == 'ok')?
        <TextField
                    size="small"
                    className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-sort"
                    select
                    label="Тип оборудования"
                    defaultValue="1"
                    value={owner}
                    onChange={(event, value) => setOwner(value.props.value)}
                    >
                    
                    <MenuItem key="production" value="1">Оборудование для добычи нефти и газа</MenuItem>
                    <MenuItem key="drilling" value="2">Буровое оборудование</MenuItem>
        </TextField>
        :null
        }
        </DialogTitle>
        <DialogContent dividers="paper">
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          >
            {(dialogClassifier == 'ok')?
            <OkPanel owner={owner} action={addClassifier}/>
            :null
            }

            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={сloseDialogClassifier}
          >Закрыть</Button>
        {dialogClassifier == 'delete'?
        <Button style={{color:'red'}} onClick={()=>{сloseDialogClassifier(); dispatch(setError(null)); dispatch(deleteSpec(specItem))}}>Удалить</Button>
        :null}          
        </DialogActions>
      </Dialog>      

    {((!specLoader)&&(specItem))?
    <div style={{padding:'20px 0'}}>
           <div>
               <TextField
                       size="small"
                       className={classes.orderMenu}
                       style={{ minWidth:'380px'}}
                       margin="dense"
                       padding="none"
                       id="standard-shifr"
                       error={!!error?.specNAME}
                       helperText={error?.specNAME?.text}                       
                       label="Наименование"
                       value={specItem.NAME}
                       onChange={(e)=> setSpecValue('NAME', e.target.value)}
                       >
                </TextField>
            </div> 
            <div>
               <TextField
                       size="small"
                       className={classes.orderMenu}
                       style={{ minWidth:'380px'}}
                       margin="dense"
                       padding="none"
                       id="standard-shifr"
                       error={!!error?.specCOMMENT}
                       helperText={error?.specCOMMENT?.text}
                       label="Обозначение"
                       value={specItem.COMMENT}
                       onChange={(e)=> setSpecValue('COMMENT', e.target.value)}
                       >
                </TextField>
            </div>             
            <div>
                    <TextField
                    size="small"
                    style={{ width:'180px'}}
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-status"
                    error={!!error?.specTYP}
                    helperText={error?.specTYP?.text}
                    select
                    label="Тип"
                    defaultValue={null}
                    value={specItem.TYP}
                    onChange={(e)=>setSpecValue('TYP', e.target.value)}

                    >
                    {
                        
                        specTypeList?.map((option)=> (
                        <MenuItem key={option.value} value={option.value}>
                            {option.name}
                        </MenuItem>
                        ))
                    }
                    </TextField>                           
            </div>     
            <div style={{paddingTop:'10px'}}>
            <Autocomplete
            size="small"

      disablePortal
      id="combo-box-demo"
      options={prm}
      value={prm?.find(el=>el.ID == specItem.EI)}
      onChange={setAcSpecValue}
      margin="dense"
      padding="none"
      getOptionLabel={(option) => option.NAME}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} error={!!error?.specEI} helperText={error?.specEI?.text} label="Измеряемая величина" />}
    />              
            </div>       

            <div>     
                <div className={classes.headitemname}>Отраслевой классификатор</div>
                {ok()}
                <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline', marginLeft:'5px'}} onClick={() => setDialogClassifier('ok')} >Добавить позицию классификатора</div>
            </div> 

            <Grid container spacing={2} justifyContent="center" style={{padding:"20px 0"}}>
                    <Grid item>
                        <Button variant="contained" 
                            //disabled={submitLoader} ]
                            onClick={() => {


                                //setFirmLoaded(false)
                                //dispatch(setGeo(null))                        
                                
                                if (id != 'new') {
                                    dispatch(updateSpec(id, specItem))
                                    
                                }
                                else {
                                    
                                    dispatch(newSpec(specItem))
                                }
                                    
                            }}  
                            style={{color: '#fff', backgroundColor: 'darkgreen', marginBottom:'30px'}}
                            size={'large'} 
                        >
                            Сохранить
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button 
                        style={{color: '#fff', backgroundColor: 'darkred', marginBottom:'30px'}}
                        size={'large'}
                        onClick={()=>setDialogClassifier('delete')}
                        >Удалить</Button>                    
                    </Grid>
                    <Grid item>
                        <Button 
                        style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                        size={'large'} 
                        onClick={()=>replace('/operator/?tab=Reference&component=spec')}
                        >Отмена</Button>                    
                    </Grid>                    
            </Grid>              

        </div>:
        <div style={{padding:'100px'}}><Loader/></div> 
        }

        </div>

}