import React, { useState, useCallback, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    authorization, getError, getLoggedIn,
    getLogin, checkCodeAction, getLoader, setLoader, getCodeMatch,
    getPassword, getRememberMe,
    rememberMeSwitch,
    setLogin,
    setPassword
} from "../redux/reducers/login-reducer";
import {getProfile, loadProfileData} from "../redux/reducers/user-profile-personal-reducer";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Lock from '@material-ui/icons/Lock';
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';

import {Navigate} from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader.jsx";


import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { handleLogin } from '../js/UserActions';


const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        display: 'block',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px',

        display: 'flow-root'
    },
    loginLeft : {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/mainbanner-m.jpg)',
        backgroundPositionY: '70%',
        backgroundPositionX: '20%',
        width: '100%',
        float: 'left',
        minHeight: 'calc(100vh - 272px)',
        
        ['@media (max-width:900px)']: { 
            
          } ,
    },
    loginRight: {
        width: '50%',
        float: 'right',
        backgroundColor: '#fff',
        paddingBottom: '50px',
        minHeight: 'inherit',
        ['@media (max-width:900px)']: { 
            width: '100%',
            paddingBottom: '50px',
        },
    },
    lockBlock: {
        width:'80px',
        height:'80px',
        border: '2px solid #054C74',
        marginTop: '50px',
        marginLeft: '50%',
        borderRadius: '40px',
        position: 'relative',
        left: '-40px',
        ['@media (max-width:767px)']: { 
            marginTop: '15px',
            }  
    },
    lock: {
        fontSize: 60,
        color: '#054C74',
        marginLeft: '10px',
        marginTop: '5px',

    },
    loginBox: {
        padding: '10px 50px 20px 50px',
        ['@media (max-width:767px)']: { 
            padding: '10px 10px 50px 10px',
            }          

    },
    button: {
        color: '#fff',
        backgroundColor: '#054C74',
        '&:hover': {
            backgroundColor: '#E6E6E6',
            color: '#1A1A1A',
        },        
    },
    chapterName: {
        fontSize:'40px', 
        textAlign: 'center',
        fontWeight: 800
    }


}))

export const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [userCode, setUserCode] = useState('');
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);    

    const classes = useStyles()

    const login = useSelector(getLogin)
    const password = useSelector(getPassword)
    const rememberMe = useSelector(getRememberMe)
    const isLoggedIn = useSelector(getLoggedIn)
    const error = useSelector(getError)
    const {user} = useSelector(getProfile)
    const codeLoader = useSelector(getLoader)
    const codeMatch = useSelector(getCodeMatch)



    const [submitLoader, setsubmitLoader] = useState(true);

    const dispatch = useDispatch()

    const onLoginChange = e => {
        dispatch(setLogin(e.target.value))
    }
    const onPasswordChange = e => {
        dispatch(setPassword(e.target.value))
    }
    const onRememberMeClick = () => {
        dispatch(rememberMeSwitch())
    }

    const [eqpMeta, setEqpMeta] = useState({title:'Вход - Импортозамещение и подбор нефтегазового оборудования', description:'Вход в каталог нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});

    const submitUpdate =() => setsubmitLoader(!submitLoader);

     useEffect(() => {
            if (codeMatch) {
                dispatch(loadProfileData)
            }
       },[codeMatch]);
       
    useEffect(() => {
        setsubmitLoader(!submitLoader)
       },[error]);

    useEffect(() => {
        if (isLoggedIn)
        dispatch(loadProfileData)
       },[isLoggedIn]);

       

    const submit = () => {
        submitUpdate();
        dispatch(authorization(login, password))
    }
    
    const onCodeChange = e => {
        setUserCode(e.target.value)    
    }

    const checkCode = () => {
        dispatch(setLoader(true))
        dispatch(checkCodeAction(userCode))
    
    }
    
    if (isLoggedIn&& !user?.checkcode) return <Navigate to={'/'}/>

    if (isLoggedIn&& user?.tf && user?.checkcode) 
    return  <HomePrivateLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/login"} rel="canonical"></link>
        
    </Helmet>             
        
                    <div className={classes.paper}>
                        <div className={classes.loginLeft}>
                        <div className={classes.loginRight}>

                            <div className={classes.loginBox}>
                            <div className={classes.lockBlock}><Lock className={classes.lock}/></div>
                            <div className={classes.chapterName}>ПРОВЕРКА</div>  

                            <div className={classes.activationBox}><p>Укажите проверочный код, направленный на адрес: {user.email}</p>

                            <TextField
                                        id={'outlined-basic'}
                                        label={'Код'}
                                        style = {{width: 300}}
                                        margin={'normal'}
                                        variant={'outlined'}
                                        error={!!error}
                                        helperText={error?.userCode?.text}
                                        value={userCode}
                                        onChange={onCodeChange}
                                        onKeyDown={(e) => {
                                    if (e.key === 'Enter') checkCode()
                                        }}                                        
                                        />
                            <span  className={!codeLoader?'':'hidden'}>                        
                            <Button 
                                variant="contained" 
                                
                                disabled={codeLoader}
                                onClick={checkCode}
                                size={'large'}
                                style={{margin: '20px 0 0 10px'}}
                                className={classes.button}>
                                    Отправить
                            </Button>
                            </span>
                            
                                <span className={codeLoader?'':'hidden'}><div style={{margin: '20px 0 0 10px', display: 'inline-flex'}}><Loader/></div></span>
                            
                            </div>                                 

                            </div>


                        </div>
                        </div>
                    </div>

            </HomePrivateLayout>


    if (!isLoggedIn) 
    return  <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>             

                    <div className={classes.paper}>
                        <div className={classes.loginLeft}>
                        <div className={classes.loginRight}>

                            <div className={classes.loginBox}>
                            <div className={classes.lockBlock}><Lock className={classes.lock}/></div>
                            <div className={classes.chapterName}>Войти</div>    
                                <form>
                                    <TextField
                                        id={'outlined-basic'}
                                        label={'Электронная почта'}
                                        fullWidth={true}
                                        margin={'normal'}
                                        autoComplete={'username'}
                                        variant={'outlined'}
                                        type={'email'}
                                        error={!!error?.login}
                                        helperText={error?.login?.text}
                                        value={login}
                                        onChange={onLoginChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') submit()
                                        }}                                        
                                    />
                                    <TextField
                                        id={'outlined-basic'}
                                        label={'Пароль'}
                                        fullWidth={true}
                                        margin={'normal'}
                                        variant={'outlined'}
                                        error={!!error?.password}
                                        helperText={error?.password?.text}
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={onPasswordChange}
                                        InputProps={{ 
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowPassword}
                                                  onMouseDown={handleMouseDownPassword}
                                                >
                                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') submit()
                                        }}
                                    />

                                    <FormControlLabel 
                                        control={<Checkbox value={rememberMe}
                                        onClick={onRememberMeClick} color="#054C74"/>}
                                        label="Запомнить меня"
                                    />
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item>
                                            <Button variant="contained" disabled={submitLoader} onClick={submit}  size={'large'} className={classes.button}>
                                                Войти
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                                <div style={{paddingTop: 20}}>
                                    <div className="left"><Link to="/recovery" className="link headerlink">Забыли пароль</Link></div>
                                    <div className="right"><Link to="/registration" className="link headerlink">Зарегистрироваться</Link></div>
                                    </div>
                            </div>
                           


                        </div>
                        </div>
                    </div>
            </HomePublicLayout>
}