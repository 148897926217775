import React, {useState, useEffect} from 'react';
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import {getLoggedIn} from "../redux/reducers/login-reducer";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Lock from '@material-ui/icons/Lock';
import {
    recoveryCheckLogin, getLogin,
    getCode,
    getRecovery, getError,
    getSendMail,
    setLogin, 
    getPassword, setPassword, recoveryCheckCode, recoveryChangePassword, getCompleet
} from "../redux/reducers/recovery-reducer";

import {Navigate, useSearchParams} from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import  Loader  from '../components/Loader/Loader';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        display: 'block',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px',

        display: 'flow-root'
    },
    loginLeft : {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/mainbanner-m.jpg)',
        backgroundPositionY: '70%',
        backgroundPositionX: '20%',
        width: '100%',
        float: 'left',
        minHeight: 'calc(100vh - 272px)',
        
        ['@media (max-width:900px)']: { 
            
          } ,
    },
    loginRight: {
        width: '50%',
        float: 'right',
        backgroundColor: '#fff',
        paddingBottom: '50px',
        minHeight: 'inherit',
        ['@media (max-width:900px)']: { 
            width: '100%',
            paddingBottom: '50px',
        },
    },
    lockBlock: {
        width:'80px',
        height:'80px',
        border: '2px solid #054C74',
        marginTop: '50px',
        marginLeft: '50%',
        borderRadius: '40px',
        position: 'relative',
        left: '-40px',
        ['@media (max-width:767px)']: { 
            marginTop: '15px',
            }  
    },
    lock: {
        fontSize: 60,
        color: '#054C74',
        marginTop: '5px',

    },
    loginBox: {
        padding: '10px 50px 20px 50px',
        textAlign: 'center',
        ['@media (max-width:767px)']: { 
            padding: '10px 10px 50px 10px',
            }          

    },
    button: {
        color: '#fff',
        backgroundColor: '#054C74',
        '&:hover': {
            backgroundColor: '#E6E6E6',
            color: '#1A1A1A',
        },        
    },
    chapterName: {
        fontSize:'30px', 
        textAlign: 'center',
        fontWeight: 800
    }

}))
export const RecoveryPage = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)

    const login = useSelector(getLogin)
    const code = useSelector(getCode)
    const isRecovery = useSelector(getRecovery)
    const isSendmail = useSelector(getSendMail)
    const error = useSelector(getError)
    const password = useSelector(getPassword)
    const isCompleet = useSelector(getCompleet)

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);    

    const [showPassword, setShowPassword] = useState(false);

    const [checkEmailVisible, setCheckEmailVisible] = useState('');

    const [PasswordVisible, setPasswordVisible] = useState('hidden');

    const [checkEmailMessage, setCheckEmailMessage] = useState('');

    const [messageLoaderVisible, setMessageLoaderVisible] = useState('hidden');
    const [loaderVisible, setLoaderVisible] = useState('hidden');
    const [buttonVisible, setButtonVisible] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
         
        
        if ((isSendmail) && (searchParams.get("code") == null)) {
            setCheckEmailVisible('hidden')
            setCheckEmailMessage('На адрес '+login+' отправлена ссылка для восстановления пароля')
        }


        if (code != '') {

            if (error != null) {
                setMessageLoaderVisible('hidden')
                setPasswordVisible('hidden')
                setCheckEmailMessage(error?.message?.text)
            } else {
                setMessageLoaderVisible('hidden')
                setPasswordVisible('')
            }

          }

        if((isSendmail) && (searchParams.get("code") != null)) {

                setMessageLoaderVisible('hidden')
                setPasswordVisible('')
            }

        if (isCompleet) {
                setMessageLoaderVisible('hidden')
                setPasswordVisible('hidden')
                setCheckEmailMessage('Ваш пароль успешно изменен')
            }

    })

    const [eqpMeta, setEqpMeta] = useState({title:'Восстановление пароля - Импортозамещение и подбор нефтегазового оборудования', description:'Восстановление пароля в каталоге нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    
    useEffect(() => {
        
        
        if (code == '')
            setPasswordVisible('hidden')

        if ((searchParams.get("code") != null) && (code == '')) {
            setCheckEmailVisible('hidden')
            setMessageLoaderVisible('')
            dispatch(recoveryCheckCode(searchParams.get("code")))
            console.log(error);

        }        
    },[code])

    useEffect(() => {
        
        if (error != null) {
            setLoaderVisible('hidden')
            setButtonVisible('')
        }
        }, [error])    

    const onLoginChange = e => {
        dispatch(setLogin(e.target.value))
    }    

    const onPasswordChange = e => {
        dispatch(setPassword(e.target.value))
    }    

    const submit = () => {
     setLoaderVisible('')
     setButtonVisible('hidden')
            dispatch(recoveryCheckLogin(login))
    }

   
    const submit2 = () => {
        setLoaderVisible('')
        setButtonVisible('hidden')
        dispatch(recoveryChangePassword(code, password))
       } 

    if (isLoggedIn) return <Navigate to={'/'}/>

    if (!isLoggedIn) 
    return  <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>         
                    <div className={classes.paper}>
                        <div className={classes.loginLeft}>
                        <div className={classes.loginRight}>

                            <div className={classes.loginBox}>
                            <div className={classes.lockBlock}><Lock className={classes.lock}/></div>
                            <div className={classes.chapterName}>Восстановление пароля</div>    
                                <div className={checkEmailVisible}>
                                
                                    <TextField
                                        id={'outlined-basic'}
                                        label={'Электронная почта'}
                                        fullWidth={true}
                                        margin={'normal'}
                                        autoComplete={'username'}
                                        variant={'outlined'}
                                        type={'email'}
                                        error={!!error}
                                        helperText={error?.login?.text}
                                        value={login}
                                        onChange={onLoginChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') submit()
                                        }}                                        
                                    />
                                   <Grid container spacing={2} justifyContent="center" style={{paddingTop:"20px"}}>
                                        <Grid item>
                                        <div className={buttonVisible}>
                                            <Button variant="contained" onClick={submit}  size={'large'} className={classes.button}>
                                                Продолжить
                                            </Button>
                                            </div>
                                            <span className={loaderVisible}><Loader/></span>    
                                        </Grid>
                                    </Grid>
                                
                                </div>

                                <div className={PasswordVisible}>
                                
                                    <TextField
                                        id={'outlined-basic'}
                                        label={'Новый пароль'}
                                        fullWidth={true}
                                        margin={'normal'}
                                        variant={'outlined'}
                                        error={!!error}
                                        helperText={error?.password?.text}
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={onPasswordChange}
                                        InputProps={{ 
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowPassword}
                                                  onMouseDown={handleMouseDownPassword}
                                                >
                                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') submit2()
                                        }}
                                    />
                                   <Grid container spacing={2} justifyContent="center" style={{paddingTop:"20px"}}>
                                        <Grid item>
                                        <div className={buttonVisible}>
                                            <Button variant="contained" onClick={submit2}  size={'large'} className={classes.button}>
                                                Изменить
                                            </Button>
                                            </div>
                                            <span className={loaderVisible}><Loader/></span>    
                                        </Grid>
                                    </Grid>
                                
                                </div>
                                <div className={classes.loginBox} style={{display: 'inline-block'}}><div className={messageLoaderVisible}><Loader/></div>{checkEmailMessage}</div>

                            </div>
                        </div>
                        </div>
                    </div>
            </HomePublicLayout>          
}