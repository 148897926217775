import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getOk, loadOk } from "../../redux/reducers/ok-reducer";
import {useDispatch, useSelector} from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ConstructionIcon  from '@mui/icons-material/Construction';
import {OkPanelSkeleton} from "../../components/SearchSkeleton/SearchSkeleton.jsx";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
}))

let treeAction = null

const getTreeItemsFromData = (treeItems) => {

    return treeItems.map(treeItemData => {

      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }
      return (
        (treeItemData.children.length==0)?
        <TreeItem
             key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          onClick={()=> {treeAction(treeItemData.id,treeItemData.name)}}
        />:
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
        />        
      );
    });
  };

const DataTreeView = ({ treeItems, action }, ) => {

  treeAction = action
  return (
    (treeItems!=null)?
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultEndIcon={<ConstructionIcon/>}
    >
      {getTreeItemsFromData(treeItems, ()=>action)}
    </TreeView>
    :''
  );
};  

export const OkPanel = (props) => {

    const classes = useStyles()
    const { ok1 } = useSelector(getOk);    
    const { ok2 } = useSelector(getOk);    

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(loadOk(props.owner));
      }, [dispatch,props.owner]);        

      
    return  <div className={classes.paper} style={{float:'left'}}>
        <div className={((props.owner == 1 && !ok1) || (props.owner == 2 && !ok2))?'':'hidden'}><OkPanelSkeleton/></div>
        <DataTreeView treeItems={(props.owner == 1)?ok1:ok2} action={props.action?props.action:null} />
            </div>

}