import { EQPAPI } from "../../api/api";

const SET_TAIFFS = "tariffReducer/SET_TAIFFS";
const ERROR_LOAD = "tariffReducer/ERROR_LOAD";
const SET_TARIFF = 'tariffReducer/SET_TARIFF';
const SET_ERROR = "tariffReducer/ERROR";
const SET_LOADER = 'tariffReducer/SET_LOADER';


const initialState = {
    tariffs: null,
    error: null,
    tariff: null,
    loader: false,
    
  };


  export const tariffReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_TARIFF:
        return {
            ...state,
            tariff: action.tariff
        }
      case SET_LOADER:
        return {
              ...state,
              loader: action.loader
            }              

        case SET_TAIFFS:
        return {
          ...state,
          tariffs: action.tariffs,
        };
        case ERROR_LOAD:
        return {
            ...state,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }

        default:
            return state;
    }
};


export const getTariffs = (state) => state.tariffReducer.tariffs; 
export const getTariff = (state) => state.tariffReducer.tariff; 
export const getTariffError = state => state.tariffReducer.error
export const getTariffLoader = state => state.tariffReducer.loader

export const setTariff = (tariff) => ({type: SET_TARIFF, tariff})
export const setTariffError = (error) => ({type: SET_ERROR, error})
export const setTariffLoader = (loader) => ({type: SET_LOADER, loader})
export const setTariffs = (tariffs) => ({ type: SET_TAIFFS, tariffs});

export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadTariff = (id) => async dispatch => {
  try {
      
      const response = await EQPAPI.getTariff(id)
      
      if (response.status === 200 && response.data.length !== 0) {
          const result = response.data
          dispatch(setTariff(result))
      }


  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}


export const loadTariffs = () => async (dispatch) => {
    const response = await EQPAPI.getTariffsEdit();
    if (response.status === 200 && response.data.length !== 0) {
      dispatch(setTariffs(response.data));

    } else {
      dispatch(errorLoad());
    }

  };

export const newTariff = (tariff) => async dispatch => {

    let error = {iserror: false}

    if ((tariff?.DURATION?.toString() == null) ||(tariff?.DURATION.toString() == ''))  error = {...error,  tariffDURATION:   {text: 'Не указан срок действия тарифа в днях'}, iserror: true}
    if ((tariff?.PRICE?.toString() == null) ||(tariff?.PRICE.toString() == ''))  error = {...error,  tariffPRICE:   {text: 'Не указана стоимость'}, iserror: true}
    
    if (error.iserror )
    dispatch(setTariffError(error))
    else
    try {
      dispatch(setTariffLoader(true))
        const response = await EQPAPI.newTariff(tariff)
        
        if (response.status === 200 && response.data.length !== 0) {
            dispatch(setTariffLoader(false))
            const result = response.data
            dispatch(setTariff(result))
            dispatch(setTariffError(null))
        }
        const response2 = await EQPAPI.getTariffsEdit();
        if (response2.status === 200 && response2.data.length !== 0) {
          dispatch(setTariffs(response2.data));
    
        }        
    }
    catch (error) {
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const updateTariff = (tariff) => async dispatch => {

  let error = {iserror: false}

  if ((tariff?.DURATION?.toString() == null) ||(tariff?.DURATION.toString() == ''))  error = {...error,  tariffDURATION:   {text: 'Не указан срок действия тарифа в днях'}, iserror: true}
  if ((tariff?.PRICE?.toString() == null) ||(tariff?.PRICE.toString() == ''))  error = {...error,  tariffPRICE:   {text: 'Не указана стоимость'}, iserror: true}

  
  if (error.iserror )
  dispatch(setTariffError(error))
  else
  try {
    dispatch(setTariffLoader(true))
      const response = await EQPAPI.updateTariff(tariff)
      
      if (response.status === 200 && response.data.length !== 0) {
          dispatch(setTariffLoader(false))
          const result = response.data
          dispatch(setTariff(result))
          dispatch(setTariffError(null))
      }
      const response2 = await EQPAPI.getTariffsEdit();
      if (response2.status === 200 && response2.data.length !== 0) {
        dispatch(setTariffs(response2.data));
  
      }      
  }
  catch (error) {
      error = {...error, userCode: {text: 'Что-то пошло не так...'}}
      error = {...error, iserror: true}
  }     
}

export const deleteTariff = (pic) => async dispatch => {
  dispatch(setTariffLoader(true))
  const response = await EQPAPI.deletePic(pic)
  
  if (response.status === 200 && response.data.length !== 0) {
      const result = response.data
      dispatch(setTariffs(result));
      dispatch(setTariffLoader(false))
    }
}
