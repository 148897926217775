import React, { createRef, useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AvatarUpload from "../AvatarUpload/AvatarUpload.jsx"
import Avatar from "@material-ui/core/Avatar";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import {getProfile, loadProfileData, setFirstName, setLastName, setMidName, setKPP, setPhoto,
        getFirstName, getLastName, getMidName, getKPP, setError, getError, savePersonalData, getPhoto,
        setLoader, getLoader
} from "../../redux/reducers/user-profile-personal-reducer";
import Loader from "../Loader/Loader.jsx";
import {    Link  } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  personalArea: {
      paddingTop: '10px',
      display: 'flex',
      ['@media (max-width:767px)']: { 
        display: 'block',
        }         
  },
  button: {
    color: '#fff',
    backgroundColor: '#054C74',
    '&:hover': {
        backgroundColor: '#E6E6E6',
        color: '#1A1A1A',
    },        
  },  
  avatar: {
    margin: '0',
    width: '150px',
    height: '150px',
    backgroundColor: '#fff',
    border: '2px solid #868686',
    fontSize: 24,
    color: '#868686',        
    "&:hover": {
        cursor: 'pointer'
    },
  },
  formArea: {
    float:'left',
    ['@media (max-width:767px)']: { 
      float: 'none',
      }   
  },
  formAreaAvatar: {
    float:'left',
    marginTop:'auto', 
    marginBottom:'auto'   , 
    ['@media (max-width:767px)']: { 
      float: 'none',
      marginTop:'unset', 
      marginBottom:'unset',
      display: 'table',
      margin: 'auto',
      }   
  },  
    formlineLeftItem: {
      float:'left', 
      minWidth:'150px', 
      textAlign:'right', 
      paddingRight: '10px', 
      lineHeight:'85px'
    },
    formlineRightItem: {
      float:"left", 
      width: "350px"
    }
  

}))


export default function PersonalProfile(av) {


  const [buttonVisible, setButtonVisible] = useState(true);
  

  const firstname = useSelector(getFirstName)
  const lastname = useSelector(getLastName)
  const midname = useSelector(getMidName)
  const kpp = useSelector(getKPP)  
  const error = useSelector(getError)
  const photo = useSelector(getPhoto)
  const saveLoader = useSelector(getLoader)

const onFirstnameChange = e => {
    dispatch(setFirstName(e.target.value))
}
const onLastnameChange = e => {
  dispatch(setLastName(e.target.value))
}
const onMidNameChange = e => {
  dispatch(setMidName(e.target.value))
}
const onKPPChange = e => {
  dispatch(setKPP(e.target.value))
}







  const [file, setFile] = useState(null);
  const handleChange = function loadFile(event) {
    if (event.target.files.length > 0) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); 
        reader.onloadend = function() {
          var base64data = reader.result;                
          setFile(base64data);
          dispatch(setPhoto(base64data))
        }        
        
    }
};

  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    //dispatch(loadProfileData)
  }, [])  

  const profile = useSelector(getProfile)
//  const org = useSelector(getOrg)
const savePersonal = () => {
  dispatch(setLoader(true))
  setButtonVisible(false)
  dispatch(savePersonalData(photo, firstname, lastname, midname, kpp, profile.org))

//  dispatch(registrationLegal(login, password, supplier?'supplier':'entity', firstname, lastname, inn, kpp, ip))

}

useEffect(() => {
  
  av.av.ssetAv(saveLoader);

  if (!saveLoader) {
  
  dispatch(loadProfileData)
  }
  //  if (error != null) {
//    dispatch(setLoader(false))
//      setButtonVisible(true)
//  }

}, [saveLoader])
//alert (saveLoader)
if (profile.loading)
    return ( <div style={{display: 'table', paddingTop:'100px', margin: 'auto'}}><Loader/></div>
      )
else
    return (
        <div className={classes.personalArea}>
          <div className={classes.formAreaAvatar} style={{}}>
            <input type="file" onChange={handleChange} id="upload" accept="image/*" style={{display: "none"}}/>
            <label htmlFor="upload">
              <IconButton color="primary" aria-label="загрузка аватара" component="span">
                <Avatar id="avatar" src={photo} className={classes.avatar}/>
              </IconButton>
            </label>
            <label htmlFor="avatar"/>
          </div>
          <div className={classes.formArea}>
              <div style={{display:'flex'}}>
                <div className={classes.formlineLeftItem}>Имя</div>
                <div className={classes.formlineRightItem}>
                  <TextField
                  id={'outlined-basic'}
                  label={'Имя'}
                  fullWidth={true}
                  margin={'normal'}
                  autoComplete={'firstname'}
                  variant={'outlined'}
                  type={'string'}
                  error={!!error?.firstname}
                  helperText={error?.firstname?.text}
                  value={firstname}
                  onChange={onFirstnameChange}
                />
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div className={classes.formlineLeftItem}>Фамилия</div>
                <div className={classes.formlineRightItem}>
                  <TextField
                  id={'outlined-basic'}
                  label={'Фамилия'}
                  fullWidth={true}
                  margin={'normal'}
                  autoComplete={'lastname'}
                  variant={'outlined'}
                  type={'string'}
                  error={!!error?.lastname}
                  helperText={error?.lastname?.text}
                  value={lastname}
                  onChange={onLastnameChange}
                />
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div className={classes.formlineLeftItem}>Отчество</div>
                <div className={classes.formlineRightItem}>
                  <TextField
                  id={'outlined-basic'}
                  label={'Отчество'}
                  fullWidth={true}
                  margin={'normal'}
                  autoComplete={'middlename'}
                  variant={'outlined'}
                  type={'string'}
                  error={!!error?.midname}
                  helperText={error?.midname?.text}
                  value={midname}
                  onChange={onMidNameChange}
                />
                </div>
              </div>
              {(profile.org != null) ? 
                  <div>
                    <div style={{display:'flex'}}>
                      <div className={classes.formlineLeftItem} style={{lineHeight:'40px'}}>Организация</div>
                      <div className={classes.formlineRightItem} style={{fontWeight:'bold'}}>{profile.org.fullwithopf}</div>
                    </div> 
                    <div style={{display:'flex'}}>
                      <div className={classes.formlineLeftItem} style={{lineHeight:'40px'}}>ИНН</div>
                      <div className={classes.formlineRightItem} style={{lineHeight:'40px', fontWeight:'bold'}}>{profile.org.inn}</div>
                    </div> 
                  
                  </div>
                
                : null}              

                {((profile.org !== null) && (profile.org?.typename !== 'INDIVIDUAL')) ? 
                  <div>
                    <div style={{display:'flex'}}>
                    <div className={classes.formlineLeftItem}>КПП</div>
                      <div className={classes.formlineRightItem}>
                        <TextField
                        id={'outlined-basic'}
                        label={'КПП'}
                        fullWidth={true}
                        margin={'normal'}
                        autoComplete={'kpp'}
                        variant={'outlined'}
                        type={'string'}
                        error={!!error?.kpp}
                        helperText={error?.kpp?.text}
                        value={kpp}
                        onChange={onKPPChange}
                      />
                      </div>
                    </div>                    
                  </div>
                
                : null}                  
              <div style={{display:'flex'}}>
                <div className={classes.formlineLeftItem} style={{lineHeight:'40px'}}>Электронная почта</div>
                <div className={classes.formlineRightItem} style={{lineHeight:'40px', fontWeight:'bold'}}>
                  {profile.user.email}
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div className={classes.formlineLeftItem} style={{lineHeight:'40px'}}>Пароль</div>
                <div className={classes.formlineRightItem} style={{lineHeight:'40px', fontWeight:'bold'}}>
                <Link to="/profile/?tab=changepassword"  className="headerlinkImageText" style={{textDecoration: 'none'}}>Изменить пароль</Link>
                </div>
              </div>     
              <Grid container spacing={2} justifyContent="center">
                                        <Grid item>
                            <div className={!saveLoader?'':'hidden'} style={{width:'100%'}}>
                            <Button variant="contained"  size={'large'} onClick={savePersonal}  className={classes.button}>
                                Сохранить изменения
                            </Button>
                            </div>
                            <span className={saveLoader?'':'hidden'}><Loader/></span>
                        </Grid>
                    </Grid>                                                                 
          </div>

      </div>
    );
  }