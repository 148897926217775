import axios from "axios";
import {API_SERVER_VAL} from "../config";
import {resetStore} from "../redux/store";

const baseUrl = `${API_SERVER_VAL}/api/`

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })

    failedQueue = []
}

export const clearCredentials = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('persist:appState');
    resetStore()
    instance.defaults.headers['Authorization'] = null;
}

export const instance = axios.create(
    {
        withCredentials: true,
        baseURL: baseUrl,
        headers: {
            'Authorization': localStorage.getItem('access_token') ? "JWT " + localStorage.getItem('access_token') : null,
        }
    }
)

instance.interceptors.response.use(
    response => {
        return response
    },
    err => {
        const originalRequest = err.config
        if (err?.response?.status === 401 && err.response?.data?.code === 'token_not_valid') {
            if (isRefreshing) {
                if (originalRequest.url !== '/token/refresh/') {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({resolve, reject})
                    })
                        .then(token => {
                            originalRequest.headers['Authorization'] = "JWT " + token
                            return instance(originalRequest)
                        })
                        .catch(err => {
                            return Promise.reject(err)
                        })
                } else {
                    clearCredentials()
                    window.location.href = '/login/'
                    isRefreshing = false
                    failedQueue = []
                }

            }

            isRefreshing = true

            return new Promise(async function (resolve, reject) {
                try {
                    let refreshToken = localStorage.getItem('refresh_token')
                    if (refreshToken == '')
                        refreshToken = '123'
                    const response = await instance.post('/token/refresh/', {refresh: refreshToken})
                    localStorage.setItem('access_token', response?.data.access)
                    localStorage.setItem('refresh_token', response?.data.refresh)
                    instance.defaults.headers['Authorization'] = "JWT " + response?.data.access
                    originalRequest.headers['Authorization'] = "JWT " + response?.data.access
                    processQueue(null, response.data.access)
                    resolve(instance(originalRequest))
                } catch (err) {
                    processQueue(err, null)
                    reject(err)
                } finally {
                    isRefreshing = false
                }
            })
        }
        return Promise.reject(err)
    }
)
