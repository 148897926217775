import React, {useState} from 'react';

export const useDialogLogin = (initialIsOpen = false) => {
    const [open, setIsOpen] = useState(initialIsOpen)

    const handleOpen = () => setIsOpen(true)

    const handleClose = () => setIsOpen(false)

    return {open, handleOpen, handleClose}
}