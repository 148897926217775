import React, { useState, useCallback, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { getLoggedIn } from "../redux/reducers/login-reducer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Tabs} from "../components/TabsRegistration/TabsRegistration";
import {RegistrationIndividual} from "../components/RegistrationIndividual/RegistrationIndividual";
import {RegistrationLegal} from "../components/RegistrationLegal/RegistrationLegal";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import {Navigate} from "react-router-dom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '54px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: ''
    },
    loginLeft : {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/mainbanner-m.jpg)',
        backgroundPositionY: '70%',
        backgroundPositionX: '20%',
        width: '100%',
        float: 'left',
        minHeight: 'calc(100vh - 272px)',
        
        ['@media (max-width:900px)']: { 
            
          } ,
    },
    loginRight: {
        width: '50%',
        float: 'right',
        backgroundColor: '#fff',
        paddingBottom: '50px',
        minHeight: 'inherit',
        ['@media (max-width:900px)']: { 
            width: '100%',
            paddingBottom: '50px',
        },
    },

}))


const tabsConfig = [
    {value: 'individual', label: 'Физическое лицо', component: <RegistrationIndividual/>},
    {value: 'entity', label: 'Юридическое лицо', component: <RegistrationLegal/>},
]

export const RegistrationPage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)

    const [eqpMeta, setEqpMeta] = useState({title:'Регистрация - Импортозамещение и подбор нефтегазового оборудования', description:'Регистрация в каталоге нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    if (!isLoggedIn) 
        return <HomePublicLayout>
    <Helmet >
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/registration"} rel="canonical"></link>
    </Helmet>             
                        <div className={classes.loginLeft}>
                            <div className={classes.loginRight}>
                                <Tabs tabs={tabsConfig}/>
                            </div>
                        </div>
        </HomePublicLayout>
        else
        return  <Navigate to={'/'}/>        
}