import React, { useEffect, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',


    },

}))

export const PolicyPage = () => {
    const classes = useStyles()
    const [eqpMeta, setEqpMeta] = useState({title:'Политика конфиденциальности - Импортозамещение и подбор нефтегазового оборудования', description:'Политика конфиденциальности в каталоге нефтегазового оборудования, ориентированного на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});

    const isLoggedIn = useSelector(getLoggedIn)

    if (!isLoggedIn) 
    return <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/policy"} rel="canonical"></link>
    </Helmet>             
        
                    <div className={classes.paper}>
                     <PolicyContent/>
                    </div>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>             

                    <div className={classes.paper}>
                    <PolicyContent/>
                    </div>                  
                </HomePrivateLayout>          

        
}

const PolicyContent = () => {

    return  <div className="App-page">
                <h1 className="App-h1">Политика конфиденциальности персональных данных</h1>
               
                <div className="App-page-paragraph-name">1. Общие положения</div>
                
                <p>1.1. Политика ООО «НПФ «ПроКон» (ИНН 7715438476, ОГРН 1147746800772, адрес местонахождения: 127018, г. Москва, вн.тер.г. Муниципальный Округ Марьина Роща, проезд Марьиной Рощи 3-Й, д. 40 стр. 1, этаж 5, пом/ком V/27) (далее – «Общество») в отношении обработки персональных данных (далее – «Политика») определяет порядок обработки и защиты Обществом персональных данных.</p>
                <p>1.2. Действие Политики распространяется на все персональные данные субъектов, обрабатываемые Обществом с применением средств автоматизации и без применения таких средств.</p>
                <p>1.3. Политика определяет права и обязанности руководителей и работников Общества, порядок использования персональных данных в служебных целях, а также порядок взаимодействия по поводу обработки и защиты персональных данных. Политика обязательна к исполнению всеми работниками Общества, имеющими доступ к персональным данным.</p>
                <p>1.4. К Политике имеет доступ любой субъект персональных данных. Актуальная версия Политики публикуется на сайте https://www.importzamena.ru/ (далее – «Сайт»).</p>
                <div className="App-page-paragraph-name">2. Определения</div>
                <p>2.1. Персональные данные - любая информация, относящаяся к прямо или косвенно определённому или определяемому физическому лицу (субъекту персональных данных);</p>
                <p>2.2. Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
                <p>2.3. Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники;</p>
                <p>2.4. Распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному кругу лиц;</p>
                <p>2.5. Предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
                <p>2.6. Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
                <p>2.7. Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;</p>
                <p>2.8. Обезличивание персональных данных - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;</p>
                <p>2.9. Информационная система персональных данных - совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>
                <div className="App-page-paragraph-name">3. Порядок и условия обработки персональных данных</div>
                <p>3.1. При обработке персональных данных Общество руководствуется:
                    <ul>
                        <li>Конституцией Российской Федерации;</li>
                        <li>Гражданским кодексом Российской Федерации;</li>
                        <li>Трудовым кодексом Российской Федерации;</li>
                        <li>Федеральным законом от 27.07.2006 г. № 149-ФЗ «Об информации, информационных технологиях и о защите информации»;</li>
                        <li>Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных»;</li>
                        <li>Постановлением Правительства Российской Федерации от 01.11.2012 г. № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;</li>
                        <li>Приказом Федеральной службы по техническому и экспортному контролю от 18.02.2013 г. № 21 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»;</li>
                        <li>Постановлением Правительства Российской Федерации от 15.09.2008 г. № 687 «Об утверждении положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;</li>
                        <li>Уставом Общества;</li>
                        <li>Настоящей Политикой;</li>
                        <li>Согласиями на обработку персональных данных, получаемыми Обществом от субъектов персональных данных.</li>
                    </ul>
                </p>
                <p>3.2. При обработке персональных данных Общество придерживается следующих принципов:
                    <ul>
                        <li>законности;</li>
                        <li>ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей;</li>
                        <li>недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;</li>
                        <li>соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки;</li>
                        <li>недопущения избыточности обрабатываемых персональных данных по отношению к заявленным целям обработки;</li>
                        <li>прозрачности обработки персональных данных: субъекту персональных данных может предоставляться соответствующая информация, касающаяся обработки его персональных данных;</li>
                        <li>осуществления хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют заявленные цели обработки персональных данных.</li>
                    </ul>
                </p>
                <p>3.3. Общество не обрабатывает специальные категории персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни.</p>
                <p>3.4. Общество не принимает решений, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих права и законные интересы субъектов, на основании исключительно автоматизированной обработки персональных данных. Данные, имеющие юридические последствия или затрагивающие права и законные интересы субъекта персональных данных, подлежат перед их использованием проверке со стороны уполномоченных работников Общества.</p>
                <p>3.5. Общество не размещает персональные данные субъекта персональных данных в общедоступных источниках без его предварительного согласия.</p>
                <p>3.6. Общество вправе передавать персональные данные субъектов персональных данных третьим лицам для обработки по договору поручения, в том числе хостинг-провайдерам, с соблюдением условий конфиденциальности и требований к поручению обработки персональных данных, предусмотренных Федеральным законом РФ от 27.07.2006 г. № 152-ФЗ «О персональных данных».</p>
                <p>3.7. Общество осуществляет обработку персональных данных с использованием средств автоматизации и без их использования, выполняя требования к автоматизированной и неавтоматизированной обработке персональных данных, предусмотренные Федеральным законом РФ от 27.07.2006 г. № 152-ФЗ «О персональных данных» и принятыми в соответствии с ним нормативными правовыми актами.</p>
                <p>3.8. В рамках указанных в разделе 4 настоящей Политики целей сбора персональных данных Общество вправе совершать любые действия (операции) или совокупность действий (операций), с использованием средств автоматизации или без использования таких средств, предусмотренные п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных», включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение, передачу (распространение, предоставление, доступ) персональных данных третьим лицам с соблюдением мер, обеспечивающих защиту персональных данных от несанкционированного доступа, в объеме, необходимом для достижения целей данного субъектом персональных данных согласия.</p>
                <p>3.9. Информация, относящаяся к персональным данным, ставшая известной Обществу, является конфиденциальной информацией и охраняется законом.</p>
                <div className="App-page-paragraph-name">4. Категории персональных данных, цель их обработки и субъекты</div>
                <p>4.1 Цель обработки персональных данных:
                    <ul>
                        <li>Для осуществления Обществом предпринимательской деятельности путем реализации товаров и услуг с использованием Сайта, в том числе для целей:</li>
                        <li>регистрации и обслуживания аккаунта (личного кабинета) клиента на Сайте;</li>
                        <li>информирования клиента об оборудовании и услугах, а также о проводимых акциях, конкурсах и иных стимулирующих мероприятиях;</li>
                        <li>улучшение пользовательского взаимодействия с сайтом и повышения комфорта его использования, в том числе формирования подборки или списка оборудования в соответствии с предпочтениями пользователя;</li>
                        <li>проведения рекламных акцией, организации конкурсов, розыгрышей и иных стимулирующих мероприятий (в том числе определение победителей, доставка призов);</li>
                        <li>участия клиента в программах лояльности;</li>
                        <li>обеспечения связи с клиентом, установления с клиентом обратной связи, в том числе предоставления клиенту возможности направления уведомлений, сообщений, запросов и информации, касательно использования Сайта и возможности приобретения доступа к платному функционалу Сайта, предоставления клиентам возможности получения клиентской поддержки по  электронной почте;</li>
                        <li>направления клиентам информационных сообщений об оборудовании и услугах, о мероприятиях, организуемых Обществом, о товарах, услугах и работах партнеров Общества и третьих лиц;</li>
                        <li>проведения Обществом опросов и маркетинговых исследований через Сайт и клиентские службы Общества;</li>
                        <li>использования cookies для идентификации зарегистрированных пользователей Сайта, ведения статистики о пользователях Сайта и их запросах, подсчета количества посетителей Сайта и оценки технических возможностей Сайта;</li>
                        <li>проведения Обществом статистических и иных исследований через Сайт и клиентские службы Общества на основе обезличенных данных, формирование статистической отчетности.</li>
                    </ul>
                </p>
                <p>4.2 Категории субъектов персональных данных
                    <ul>
                        <li>посетители Сайта;</li>
                        <li>зарегистрированные пользователи Сайта;</li>
                        <li>физические лица - клиенты Общества, приобретающие у Общества доступ к платному функционалу Сайта;</li>
                        <li>представители и работники юридических лиц - клиенты Общества, приобретающие у Общества доступ к платному функционалу Сайта;</li>
                    </ul>
                </p>
                <p>4.3 Категории и перечень персональных данных
                    <ul>
                        <li>фамилия, имя, отчество (указанные вместе или по отдельности);</li>
                        <li>адрес электронной почты (e-mail);</li>
                        <li>город проживания;</li>
                        <li>место работы, должность;</li>
                        <li>адрес для корреспонденции;</li>
                        <li>данные из файлов cookie, пользовательские данные (IP-адрес; тип используемого устройства; операционная система устройства и тип браузера; источник захода на сайт и информация поискового или рекламного запроса; пользовательские клики; просмотры страниц, заполнения полей; показы и просмотры баннеров и видео; параметры сессии; данные о времени посещения; идентификатор пользователя, хранимый в cookie; данные, характеризующие аудиторные сегменты). Сбор указанных данных может осуществляться в том числе с использованием метрических программных средств, включая Яндекс Метрику и Google Analytics. При использовании указанного программного обеспечения возможна трансграничная передача персональных данных;</li>
                        <li>иная информация, относящаяся к личности клиента, которую клиент пожелает оставить на Сайте.</li>
                    </ul>
                </p>
                <div className="App-page-paragraph-name">5. Правовые основания и сроки обработки персональных данных</div>
                <p>5.1 Согласие субъекта персональных данных 
                    <ul>
                        <li>В течение срока, на который было дано согласие на обработку персональных данных</li>
                    </ul>
                </p>
                <p>5.2 Для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей:
                    <ul>
                        <li>В течение срока, установленного соответствующими международным договором или законом.</li>
                    </ul>
                </p>
                <p>5.3 Для исполнения договора (в том числе оферты или пользовательского соглашения), стороной которого является субъект персональных данных:/
                    <ul>
                        <li>В течение срока действия такого договора, кроме случаев, когда более длительный срок обработки персональных данных установлен договором или действующим законодательством Российской Федерации.</li>
                    </ul>
                </p>
                <p>5.3 При необходимости обработки персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом:
                    <ul>
                        <li>В течение срока, установленного соответствующими международным договором или законом.</li>
                    </ul>
                </p>
                <p>5.4 Для исполнения судебного акта, акта иного органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве:
                    <ul>
                        <li>В течение срока, необходимого для исполнения соответствующего акта.</li>
                    </ul>
                </p>
                <p>5.5 Для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно:
                    <ul>
                        <li>До момента, когда получение согласия субъекта персональных данных станет возможным или когда отпадут обстоятельства, угрожающие жизни, здоровью или иным жизненно важным интересам (в зависимости от того, какое обстоятельство наступит раньше).</li>
                    </ul>
                </p>
                <p>5.6 Для осуществления прав и законных интересов Общества или третьих лиц при условии, что при этом не нарушаются права и свободы субъекта персональных данных:
                    <ul>
                        <li>В течение срока, необходимого для осуществления прав и обеспечения законных интересов Общества и третьих лиц. Конкретный срок определяется Обществом с учетом положений настоящей Политики, внутренних документов и локальных нормативных актов Общества, а также принципов обработки персональных данных и требований действующего законодательства Российской Федерации, в том числе в части прекращения обработки персональных данных при достижении конкретных, заранее определенных и законных целей такой обработки.</li>
                    </ul>
                </p>
                <div className="App-page-paragraph-name">6. Права субъектов персональных данных</div>
                <p>6.1. Требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;</p>
                <p>6.2. Получать от Общества следующие сведения:
                    <ul>
                        <li>подтверждение факта обработки персональных данных Обществом;</li>
                        <li>правовые основания и цели обработки персональных данных;</li>
                        <li>сведения о применяемых Обществом способах обработки персональных данных;</li>
                        <li>наименование и место нахождения Общества;</li>
                        <li>сведения о лицах (за исключением работников Общества), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с Обществом или на основании федерального закона;</li>
                        <li>перечень обрабатываемых персональных данных, относящихся к гражданину, от которого поступил запрос и источник их получения, если иной порядок предоставления таких данных не предусмотрен федеральным законом;</li>
                        <li>сведения о сроках обработки персональных данных, в том числе о сроках их хранения;</li>
                        <li>порядок осуществления гражданином прав, предусмотренных Федеральным законом «О персональных данных» № 152-ФЗ;</li>
                        <li>информацию об осуществленной или о предполагаемой трансграничной передаче персональных данных;</li>
                        <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению Общества, если обработка поручена или будет поручена такому лицу;</li>
                        <li>иные сведения, предусмотренные Федеральным законом «О персональных данных» № 152-ФЗ или другими федеральными законами;</li>
                    </ul>
                </p>
                <p>6.3. Отозвать своё согласие на обработку персональных данных;</p>
                <p>6.4. Требовать устранения неправомерных действий Общества в отношение его персональных данных;</p>
                <p>6.5. Обжаловать действия или бездействие Общества в Федеральную службу по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор) или в судебном порядке в случае, если субъект персональных данных считает, что Общество осуществляет обработку его персональных данных с нарушением требований Федерального закона № 152-ФЗ «О персональных данных» или иным образом нарушает его права и свободы;</p>
                <p>6.6. Защищать свои права и законные интересы, в том числе требовать возмещения убытков и/или компенсации морального вреда в судебном порядке.</p>
                <div className="App-page-paragraph-name">7. Порядок ответа на запросы субъектов персональных данных</div>
                <p>7.1. Субъекты персональных данных могут получить разъяснения по интересующим вопросам обработки персональных данных, в том числе, уточнить персональные данные или отозвать своё согласие на обработку персональных данных, направив запрос в адрес ООО «НПФ «ПроКон» в письменном виде по адресу: 119330, г. Москва, а/я 703. В тексте запроса необходимо указать:
                    <ul>
                        <li>фамилию, имя, отчество (при наличии) субъекта персональных данных;</li>
                        <li>реквизиты документа, подтверждающего полномочия представителя субъекта персональных данных (в случае, если субъект персональных данных обращается к Обществу через представителя);</li>
                        <li>номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, а также сведения о дате выдачи указанного документа и выдавшем его органе;</li>
                        <li>сведения, подтверждающие участие субъекта персональных данных в отношениях с Обществом либо сведения, иным образом подтверждающие факт обработки персональных данных Обществом;</li>
                        <li>подпись субъекта персональных данных или его представителя.</li>
                    </ul>
                </p>
                <p>7.2. Запрос, указанный в п. 7.1 настоящей Политики, может быть подписан электронной подписью и направлен в форме электронного документа на адрес электронной почты info@importzamena.ru.</p>
                <p>7.3. Общество отвечает на поступившие запросы субъектов персональных данных в порядке и в сроки, установленные Федеральным законом РФ от 27.07.2006 г. № 152-ФЗ «О персональных данных».</p>
                <div className="App-page-paragraph-name">8. Сведения о реализуемых мерах по защите персональных данных</div>
                <p>8.1. Общество при обработке персональных данных принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных. В частности, Общество принимает следующие меры:
                    <ul>
                        
                        <li>определение угроз безопасности персональных данных при их обработке в информационных системах персональных данных;</li>
                        <li>применение организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах;</li>
                        <li>применение прошедших в установленном порядке процедуру оценки соответствия средств защиты информации;</li>
                        <li>оценка эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы персональных данных;</li>
                        <li>обнаружение фактов несанкционированного доступа к персональным данным и принятие мер, в том числе мер по обнаружению, предупреждению и ликвидации последствий компьютерных атак на информационные системы персональных данных и по реагированию на компьютерные инциденты в них;</li>
                        <li>восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>
                        <li>регистрация и учет всех действий, совершаемых с персональными данными в информационной системе персональных данных;</li>
                        <li>контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности информационных систем персональных данных;</li>
                        <li>учет машинных носителей персональных данных;</li>
                        <li>организация пропускного режима на территорию Общества;</li>
                        <li>размещение технических средств обработки персональных данных в пределах охраняемой территории;</li>
                        <li>поддержание технических средств охраны, сигнализации в постоянной готовности;</li>
                        <li>проведение мониторинга действий пользователей, проведение разбирательств по фактам нарушения требований безопасности персональных данных;</li>
                        <li>в целях координации действий по обеспечению безопасности персональных данных в Обществе назначены лица, ответственные за обеспечение безопасности персональных данных.</li>
                    </ul>
                </p>
                <div className="App-page-paragraph-name">9. Изменение Политики</div>
                <p>9.1. Общество периодически актуализирует настоящую Политику и вправе в одностороннем порядке в любой момент изменять её условия. При этом, Общество не обязано уведомлять субъектов персональных данных о внесённых изменениях. Общество рекомендует субъектам персональных данных самостоятельно отслеживать настоящую Политику на предмет возможных изменений.</p>


            </div>


}