import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {getFirm, getFirmError, getFirmLoader, setFirm, setFirmLoader, setFirmError, loadFirm, setGeo, getGeo, loadGeo, loadFirmSync, updateFirm, newFirm} from "../../redux/reducers/firm-reducer";
import {RegionsPanel} from "../../containers/RegionsPanel/RegionsPanel";
import {SearchBar} from "../SearchBar/SearchBar";
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import  Loader  from '../Loader/Loader';
import {API_SERVER_VAL} from "../../config";
import Button from "@material-ui/core/Button";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@material-ui/core/Grid";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {OkPanel} from "../../containers/OkPanel/OkPanel";
import {useRedirect} from "../../hooks/useRedirect";
import Checkbox from "@material-ui/core/Checkbox";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MenuItem from '@mui/material/MenuItem';

import {getFirms, setFirms, loadFirmEdit} from "../../redux/reducers/firm-reducer";

import TextField from '@mui/material/TextField';

const baseUrl = `${API_SERVER_VAL}/api/eqp`

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px',
        width: '100%',
    },
    FirmMain: {paddingTop: '70px', paddingBottom: '30px', width:'1024px', 
    ['@media (max-width:860px)']: { 
        width: '-webkit-fill-available',
        margin: '0 5px'
    },   
    ['@media (max-width:500px)']: { 
        width: '-webkit-fill-available',
    }
    },    
    mainDialog: {
        "& div.MuiPaper-root": {
            margin: '5px',
            height: 'calc(100% - 5px)',
            minWidth: '60%'
            
          },
        "& .MuiTypography-root": {
          padding: '5px',
          margin: 'auto'      
        },
        "& .MuiDialogContent-root": {
            padding: '0',
            
        },
        "& .MuiTreeItem-label": {
            color: '#1A1A1A'
        }

    },    

    headitemname : {
        display: 'flex', 
        paddingTop: '10px',
        paddingLeft: '5px',
        fontSize:'16px',
        
        color: '#929292'
    },
    headitemvalue : {
        display: 'flex', 
        fontSize:'15px',
        paddingLeft: '5px',
    },
    orderMenu: { minWidth:'100px',
    width:'100%',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    },     

}))



export const FirmEdit = (av) => {
    const {id} = useParams()

    const classes = useStyles()
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getLoggedIn)

    const [hasINN, setHasINN] = useState(false)
    const [hasKPP, setHasKPP] = useState(false)
    const [firmLoaded, setFirmLoaded] = useState(false)
    const [dialogClassifier, setDialogClassifier] = useState(null);



    const firmStatusList = [
        {name:"Действует", value:"ACTIVE"},
        {name:"Ликвидируется", value:"LIQUIDATING"},
        {name:"Ликвидирована", value:"LIQUIDATED"},
        {name:"Банкротство", value:"BANKRUPT"},
        {name:"Реорганизация", value:"REORGANIZING"},
        {name:"Не известно", value:"UNKNOWN"}
    ]
    

    const {replace} = useRedirect()

    const firmItem = useSelector(getFirm)
    const geoItem = useSelector(getGeo)
    const syncLoader = useSelector(getFirmLoader)

    useEffect(() => {
        dispatch(loadFirmEdit(id))

    }, []);

    useEffect(() => {
        if (!firmLoaded) {
            

            if ((firmItem?.ID) || (firmItem != null)) {

                setFirmLoaded(true)
                if (firmItem?.inn != '')
                    setHasINN(true)
                if (firmItem?.kpp != '')
                    setHasKPP(true)                
            }
            if(firmItem?.GEO != null)
            dispatch(loadGeo(firmItem.GEO))
            else
                if (firmItem!=null)
                    dispatch(setGeo({id:-1, name:"Не указан"}))
            
        }
       
        if ((firmItem?.ID != null) && (id == 'new'))
                replace('/firmedit/'+firmItem.ID)

    }, [firmItem]);
 

    const setFirmValue = (name, value) => {
        let firmItemCopy = {... firmItem}
        firmItemCopy[name] = value
        dispatch(setFirm(firmItemCopy))
    }

    const setRegionItem = (id, name) => {
        dispatch(setGeo({id:null, name:"Не указан"}))
        dispatch(loadGeo(id))
        setFirmValue('GEO', id)
        
        сloseDialogClassifier()
    }    
    const сloseDialogClassifier = () => {
        setDialogClassifier(null);
      };
    
      
return  <div className={classes.paper}>

<Dialog
            className={classes.mainDialog}
        open={(dialogClassifier == null)?false:true}
        onClose={сloseDialogClassifier}
        handleClose={сloseDialogClassifier}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
      >
        <DialogTitle id="scroll-dialog-title">
        
        {(dialogClassifier == 'region')?
        <div>Регионы</div>
        :null
        }
        </DialogTitle>
        <DialogContent dividers="paper">
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          >

            {(dialogClassifier == 'region')?
            <RegionsPanel locality={true} action={setRegionItem}/>
            :null
            }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          //onClick={сloseDialogClassifier}
          >Закрыть</Button>
        </DialogActions>
      </Dialog>      

        <SearchBar/>
        {(geoItem!=null)?        
        <div className={classes.FirmMain}>
            {
            (firmItem?.ID != null)?
            <div>     
                <div className={classes.headitemname} style={{paddingBottom:'10px'}}>Изменен: {firmItem?.CHANGED}</div>
            </div>  
            :null
            }
            <div>
                    <TextField
                    size="small"
                    style={{ width:'180px'}}
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-status"
                    select
                    label="Статус"
                    defaultValue={'UNKNOWN'}
                    value={firmItem.status}
                    
                    onChange={(e)=>setFirmValue('status', e.target.value)}
//                    value={techSpecTmp.id}
//                    onChange={setTechSpec}
                    >
                    {
                        
                        firmStatusList?.map((option)=> (
                        <MenuItem key={option.value} value={option.value}>
                        {option.name}
                        </MenuItem>
                    ))
                    }
                    </TextField>                           
            </div>            
            {(!hasINN)?            
            <div>
           
                <TextField
                            size="small"
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-shifr"
                            label="Наименование"
                            value={firmItem.NAME}
                            onChange={(e)=> setFirmValue('NAME', e.target.value)}
                            >
                            

                </TextField>
            </div> 
            :
            <div >     
                <div className={classes.headitemname}>Наименование</div>
                <div className={classes.headitemvalue}>{(firmItem.opfshort == '')? "Необходимо синхронизироваться с Федеральными реестрами": (firmItem.opfshort +' «'+((firmItem.short)?firmItem.short:firmItem.full)+'»')}</div>
            </div> 
}
                   
            {(!hasINN)?
            <div>
                <TextField
                            size="small"
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-shifr"
                            label="Почтовый индекс"
                            sx={{
                                width: 300
                            }}
                            value={firmItem.POSTINDEX}
                            onChange={(e)=> setFirmValue('POSTINDEX', e.target.value)}
                            >
                            

                </TextField>
                <TextField
                            size="small"
                            style={{marginLeft:'10px'}}
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-shifr"
                            label="Адрес"
                            sx={{
                                width: 500
                            }}
                            value={firmItem.ADDRESS}
                            onChange={(e)=> setFirmValue('ADDRESS', e.target.value)}
                            >
                            

                </TextField>          
                      
            </div>
            :
            <div >     
                <div className={classes.headitemname}>Адрес</div>
                <div className={classes.headitemvalue}>{(firmItem.addressline!='')?firmItem.addressline:"Необходимо синхронизироваться с Федеральными реестрами"}</div>
            </div> 
            }            
            <div style={{paddingBottom:'10px'}}>     
                <div className={classes.headitemname}>Регион</div>
                <div style={{paddingLeft:'5px'}}>{geoItem.name} {geoItem.id == null?<Loader type="linear"/>:
                    geoItem.id != -1? 
                                    <IconButton style={{padding:'0'}} onClick={()=>dispatch(setGeo({id:-1, name:"Не указан"}))}>
                                    <CloseIcon />
                                </IconButton> 
                                :null   
                            }</div> 
                <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', margin:'3px 0 10px 5px', height: '20px', lineHeight: '20px', display:'inline',}} 
                onClick={() => setDialogClassifier('region')} 
                >
                    Указать регион</div>
            </div> 
            <div>
                <TextField
                            size="small"
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-shifr"
                            label="Сайт"
                            value={firmItem.WEBSITE}
                            sx={{width:"50%"}}
                            onChange={(e)=> setFirmValue('WEBSITE', e.target.value)}
                            >
                            

                </TextField>
                {(firmItem.WEBSITE!="")?<Link to={"/parsestudy/"+id} style={{ cursor:'pointer', color:'#054C74',margin:'3px 0 10px 5px', height: '20px', lineHeight: '50px', display:'inline',}}>Настройка парсинга</Link>:null}
            </div> 
            <div style={{paddingTop:'10px'}}>
                <TextField
                        size="small"
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-name"
                        label="Описание"
                        value={firmItem.DEVCOMMENT}
                        multiline
                        rows={10}
                        onChange={(e)=> setFirmValue('DEVCOMMENT', e.target.value)}  
                >
                            

                </TextField>        
            </div> 
            <div>
                <Checkbox 
                checked={(hasINN)?true:false} 
                onClick={()=>setHasINN(!hasINN)} 
                color="#054C74"/>Есть ИНН  
            </div> 
            {(hasINN)?
            <div>
                <TextField
                            size="small"
                            className={classes.orderMenu}
                            margin="dense"
                            padding="none"
                            id="standard-shifr"
                            label="ИНН"
                            sx={{
                                width: 300
                            }}
                            value={firmItem.inn}
                            onChange={(e)=> setFirmValue('inn', e.target.value)}
                            >
                            

                </TextField>
                {
                    (hasKPP)?
                    <TextField
                    size="small"
                    style={{marginLeft:'10px'}}
                    className={classes.orderMenu}
                    margin="dense"
                    padding="none"
                    id="standard-shifr"
                    label="КПП"
                    sx={{
                        width: 300
                    }}
                    value={firmItem.kpp}
                    onChange={(e)=> setFirmValue('kpp', e.target.value)}
                    >

                            

                </TextField>
                    :null
                
                }


                
                <span style={{lineHeight:"50px"}}>       
                <Checkbox 
                checked={(!hasKPP)?true:false} 
                onClick={()=>{setHasKPP(!hasKPP);setFirmValue('kpp', '')}} 
                color="#054C74"/>Нет КПП    
                </span>
                      
            </div>            
            :null
            }
            {(hasINN)?
            <div style={{paddingBottom:'20px'}}>     
                <div className={classes.headitemname}>Синхронизация с Федеральными реестрами {firmItem.actualitydate}</div>
                <div className={classes.headitemvalue}>
                {(!syncLoader)?
                <Button variant="contained" 
                        startIcon={<CloudSyncIcon />}
                                                //disabled={submitLoader} 
                        onClick={()=>{dispatch(setFirmLoader(true));dispatch(loadFirmSync(firmItem))}}
                        style={{color: '#054C74', backgroundColor: '#fff', margin:'10px 0 30px 0'}}
                        size={'large'} 
                >
                    Синхронизировать
                </Button>
                :<Loader type="linear"/>
                }
                </div>
            </div> 
            :null}
                     
 
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    {

                    <Button variant="contained" 
                        //disabled={submitLoader} ]
                        onClick={() => {


                            setFirmLoaded(false)
                            dispatch(setGeo(null))                        
                            
                            if (id != 'new')
                                dispatch(updateFirm(id, firmItem))
                            else 
                                dispatch(newFirm(firmItem))
                                
                        }}  
                        style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                        size={'large'} 
                    >
                        Сохранить
                    </Button>
                    
                    }
                </Grid>
            </Grid>              

        </div>:
        <div style={{padding:'100px'}}><Loader/></div> }

        </div>

}