import {AuthAPI} from "../../api/api";

const SET_LOGIN = 'loginReducer/SET_LOGIN';
const SET_ACTIVATED = 'loginReducer/SET_ACTIVATED';
const SET_PASSWORD = 'loginReducer/SET_PASSWORD';
const REMEMBER_ME_SWITCH = 'loginReducer/REMEMBER_ME_SWITCH';
const SET_LOGGED_IN = 'loginReducer/SET_LOGGED_IN';
const SET_ERROR = 'loginReducer/SET_ERROR';
const SET_LOADER = 'loginReducer/SET_LOADER';
const SET_CODEMATCH = 'loginReducer/SET_CODEMATCH';
const SET_TARIFF = 'loginReducer/SET_TARIFF';

const initialState = {
    login: '',
    password: '',
    rememberMe: false,
    isLoggedIn: false,
    isActivated: false,
    error: null,
    loader: false,
    codematch: false,
    tariff: false
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVATED:
            return {
                ...state,
                isActivated: action.isActivated
            }
        case SET_LOGIN:
            return {
                ...state,
                login: action.login
            }
        case SET_TARIFF:
            return {
                ...state,
                tariff: action.tariff
            }            
        case SET_PASSWORD:
            return {
                ...state,
                password: action.password
            }
        case REMEMBER_ME_SWITCH:
            return {
                ...state,
                rememberMe: !state.rememberMe
            }
        case SET_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn
            }

        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_LOADER:
            return {
                ...state,
                loader: action.loader
            }              

        case SET_CODEMATCH:
            return {
                ...state,
                codematch: action.codematch
            }              
        default:
            return state;
    }
}

export const getLogin = state => state.loginReducer.login
export const getTariff = state => state.loginReducer.tariff
export const getPassword = state => state.loginReducer.password
export const getRememberMe = state => state.loginReducer.rememberMe
export const getLoggedIn = state => state.loginReducer.isLoggedIn
export const getError = state => state.loginReducer.error
export const setActivated = state => state.loginReducer.isActivated
export const getLoader = state => state.loginReducer.loader
export const getCodeMatch = state => state.loginReducer.codematch


export const setLogin = (login) => ({type: SET_LOGIN, login})
export const setTariff = (tariff) => ({type: SET_TARIFF, tariff})
export const setPassword = (password) => ({type: SET_PASSWORD, password})
export const rememberMeSwitch = () => ({type: REMEMBER_ME_SWITCH})
export const setLoggedIn = (isLoggedIn) => ({type: SET_LOGGED_IN, isLoggedIn})
export const setError = (error) => ({type: SET_ERROR, error})
export const getActivated = (isActivated) => ({type: SET_ACTIVATED, isActivated})
export const setLoader = (loader) => ({type: SET_LOADER, loader})
export const setCodeMatch = (codematch) => ({type: SET_CODEMATCH, codematch})

export const checkCodeAction = (tfcode = null) => async dispatch => {
    dispatch(setError(null))
    try {
        let error = {}
        if ((tfcode != null) && (tfcode == '')) {
            error = {...error, userCode: {text: 'Укажите код'}}
            error = {...error, iserror: true}
            dispatch(setError(error))
            dispatch(setLoader(false))
        }
        else {

            const response = await AuthAPI.checkCode({tfcode:tfcode})
            if (response.status === 200) {
                if (!response.data.codematch) {
                    error = {...error, userCode: {text: 'Указан неверный код'}}
                    error = {...error, iserror: true}
                    dispatch(setLoader(false))
                    dispatch(setError(error))
        
                    dispatch(setCodeMatch(response.data.codematch))
                }
                else {
                dispatch(setError(null))   
                dispatch(setCodeMatch(response.data.codematch))
                }
    
            }


        }

    }
    catch (error) {
        dispatch(setLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }        
}


export const authorization = (username, password) => async dispatch => {
    try {
        if (!username || !password) {
            let error = {}
            if (!username) {
                error = {...error, login: {text: 'Укажите адрес электронной почты'}}
            }
            if (!password) {
                error = {...error, password: {text: 'Укажите пароль'}}
            }
            dispatch(setError(error))
        }

        const response = await AuthAPI.login(username, password)
        if (response.status === 200 && response.statusText === 'OK') {
            dispatch(setLogin(''))
            dispatch(setPassword(''))
            dispatch(setLoggedIn(true))
            
            dispatch(setTariff((response?.data?.tariff > 0)?true:false))
            localStorage.setItem('tariff', (response?.data?.tariff > 0)?true:false);
            dispatch(setError(null))
        } else {
            dispatch(setPassword(''))
        }
    } catch (error) {
        if (error?.response?.data?.detail === 'No active account found with the given credentials') {
            dispatch(setError({password: {text: 'Неверное имя пользователя или пароль'}}))
        }
    }

}

export const logout = () => async (dispatch) => {

    try {
        await AuthAPI.logout()
        document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        localStorage.removeItem('token')
        sessionStorage.removeItem('token')        
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('persist:appState');
    } finally {
        dispatch(clearCredentials())
    }
}

export const clearCredentials = () => async (dispatch) => {
    clearCredentials()
    dispatch(setLoggedIn(false));
}