import React, { useEffect, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import { Helmet } from 'react-helmet';
import {    Link  } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import Lightbox  from 'react-image-lightbox';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}))

export const HelpPage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)
    const [eqpMeta, setEqpMeta] = useState({title:'Помощь - Импортозамещение и подбор нефтегазового оборудования', description:'Это Каталог нефтегазового оборудования, ориентированный на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. Это принципиально новый инструментарий, позволяющий находить нефтегазовое оборудование, в том числе по основным эксплуатационным характеристикам, и осуществлять автоматический подбор однотипного нефтегазового оборудования для конкретной позиции Каталога', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    if (!isLoggedIn) 
    return <HomePublicLayout>
                <Helmet>
                    <title>{eqpMeta.title}</title>
                    <meta name="description" content={eqpMeta.description}/>
                    <meta name="keywords" content={eqpMeta.keywords}/>
                    <link href={"https://importzamena.ru/help"} rel="canonical"></link>
                </Helmet> 
                    <div className={classes.paper}>
                    <HelpContent/>
                    </div>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout>
                <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet> 
                    <div className={classes.paper}>
                     <HelpContent/>
                    </div>                  
                </HomePrivateLayout>    
}

const HelpContent = () => {
    const classes = useStyles()
    const [showFullScreenImage, setShowFullScreenImage] = useState({open:false, images:null, name:null});

    return  <div className="App-page">
                {(showFullScreenImage.images != null)?<Lightbox

                    mainSrc={showFullScreenImage.images[0]}
                    //nextSrc={showFullScreenImage.images[(currentImageIndex + 1) % showFullScreenImage.images.length]}
                    //prevSrc={showFullScreenImage.images[(currentImageIndex + showFullScreenImage.images.length - 1) % showFullScreenImage.images.length]}
                    onCloseRequest={() => {setShowFullScreenImage({open:false, images:null, name:null})}}
                    isOpen={showFullScreenImage.open}
                    //onMovePrevRequest={gotoPrevious}
                    //onMoveNextRequest={gotoNext}
                    images={showFullScreenImage.images}
                    //currentIndex={0}
                />:null}
                <h1 className="App-h1">Российский Инновационный Цифровой Сервис «Банк Данных нефтегазового оборудования»</h1>
                
                <p>Цифровой сервис включает одноимённый программный продукт (БД НГО) и базу данных по нефтегазовому оборудованию, расположенные на российском виртуальном сервере. Доступ к базе данных осуществляется через сеть Интернет. 
                    <ul>	
                    <li>Бесперебойная работа и актуализация сервиса сопровождается российскими IT специалистами. </li>
                    <li>БД НГО надежно защищен от несанкционированного копирования и хакерского взлома. </li>
                    <li>Цифровой сервис обеспечивает пользователям возможность удаленной работы с базой данных по нефтегазовому оборудованию с любого электронного устройства (рабочая станция, планшет, смартфон).</li>
                    <li>Отличительной особенностью цифрового сервиса является хранение информации в структурированном виде, что позволяет получать представительные выборки по классам оборудования, проводить их сортировку по техническим параметрам.</li>
                    <li>В основе структурного хранения информации лежат классификаторы нефтегазового оборудования.</li>
                    <li>Пользователю предоставлена возможность создавать и хранить собственные выборки оборудования. В том числе, создавать каталоги оборудования по различным видам (позициям классификаторов) как в электронном, так и печатном виде.</li>
                    <li>Всегда актуальная информация о производителях и оборудовании. Актуализация информации обеспечивается прямой коммутацией с базами данных предприятий (адреса, контакты, реквизиты) и сайтами производителей оборудования.</li>
                    </ul>  
                </p>
                <p >
                    <div style={{textAlign:'left'}}><a href="#cap1" className="headerlink">Направления использования Цифрового сервиса</a></div>
                    <div style={{textAlign:'left'}}><a href="#cap2" className="headerlink">Информация, представленная в Банке данных</a></div>
                    <div style={{textAlign:'left'}}><a href="#cap3" className="headerlink">Инновации цифрового сервиса</a></div>
                    <div style={{textAlign:'left'}}><a href="#cap4" className="headerlink">Программное обеспечение</a></div>
                    <div style={{textAlign:'left'}}><a href="#cap5" className="headerlink">Информационная безопасность</a></div>
                    <div style={{textAlign:'left'}}><a href="#cap6" className="headerlink">Классификаторы</a></div>
                    <div style={{textAlign:'left'}}><a href="#cap7" className="headerlink">Функциональные возможности</a></div>
                    <div style={{textAlign:'left'}}>&emsp;<a href="#cap8" className="headerlink">Анализ данных</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap9" className="headerlink">Поиск оборудования по заданным параметрам и изготовителям</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap10" className="headerlink">Создание пользовательской подборки оборудования</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap11" className="headerlink">Сравнение оборудования</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap12" className="headerlink">Работа с результатами поиска</a></div>
                    <div style={{textAlign:'left'}}>&emsp;<a href="#cap13" className="headerlink">Цифровой паспорт оборудования</a></div>
                    <div style={{textAlign:'left'}}>&emsp;<a href="#cap14" className="headerlink">Примеры анализа данных</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap15" className="headerlink">Поиск оборудования по наименованию и шифру</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap16" className="headerlink">Поиск по классификаторам</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap17" className="headerlink">Расширенный поиск</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap18" className="headerlink">Сортировка и ранжирование по техническим параметрам</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap19" className="headerlink">Изменение представления результатов поиска</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap20" className="headerlink">Подбор аналогов оборудование. Импортозамещение</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap21" className="headerlink">Работа с пользовательской подборкой (Избранное оборудование)</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap22" className="headerlink">Сравнение оборудования по параметрам и изготовителям</a></div>
                    <div style={{textAlign:'left'}}>&emsp;&emsp;<a href="#cap23" className="headerlink">Логистика поставок оборудования</a></div>
                </p>                
                <div id="cap1" className="App-page-paragraph-name">Направления использования Цифрового сервиса</div>
                <p>
                <div style={{display:'flex'}}><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>Экспертный анализ рынка НГО</div></div>
                    <ul style={{margin:0, paddingBottom:'5px'}}>
                        <li>Цифровой помощник в подборе нефтегазового оборудования, которое производится в РФ и странах СНГ.</li>
                    </ul>
                <div style={{display:'flex'}}><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>Импортозамещение</div></div>
                    <ul style={{margin:0, paddingBottom:'5px'}}>
                        <li>Подбор российского оборудования по техническим характеристикам</li>
                    </ul>
                <div style={{display:'flex'}}><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>Логистика</div></div>
                    <ul style={{margin:0, paddingBottom:'5px'}}>
                        <li>Выбор оптимального варианта по характеристикам и логистике поставки оборудования</li>
                    </ul>                                    
                </p>
                <div id="cap2" className="App-page-paragraph-name">Информация, представленная в Банке данных</div>
                <p>
                Банк данных НГО включает данные по оборудованию и предприятиям изготовителям:
                <div style={{display:'flex'}}><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>16 948  позиций  по оборудованию для добычи нефти и газа;</div></div>
                <div style={{display:'flex'}}><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>3 674  позиции  по оборудованию для бурения нефти и газа;</div></div>
                <div style={{display:'flex'}}><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>871  предприятие изготовителей оборудования.</div></div>
                </p>
                <p>Все данные получены из открытых источников. </p>
                <p>Данные прошли верификацию экспертами по оборудованию.</p>
                <p>Данные по оборудованию в зависимости от назначения и конструктивных особенностей систематизированы по позициям классификаторов и загружены в базу данных. </p>
                <p>В Банке данных представлена информация за период 2005 – 2023 годы. Это позволяет отследить потенциал производителей по выпускаемому оборудованию.</p>
                <p>Ведется постоянная актуализация данных по оборудованию и предприятиям изготовителям.</p>


                <div id="cap3" className="App-page-paragraph-name">Инновации цифрового сервиса</div>
                <p>Программный продукт не имеет отечественных и зарубежных аналогов.</p>
                <p>Разработаны и используются оригинальные классификаторы нефтегазового оборудования.</p>
                <p>Расширенные возможности поиска и сравнения аналогов нефтегазового оборудования по техническим характеристикам.</p>
                <p>Оптимальный набор технических характеристик и графическая информация, для полного представления о нефтегазовом оборудовании.</p>
                <p>Наряду с отечественным, содержит данные по импортному нефтегазовому оборудованию. Сервис позволяет подбирать отечественные аналоги импортного оборудования.</p>
                <p>Адаптивный, ориентированный на пользователя интуитивно понятный интерфейс.</p>

                <div id="cap4" className="App-page-paragraph-name">Программное обеспечение</div>
                <p>Программный продукт создан на базе свободного ПО с открытым кодом</p>
                <p>База данных<br />
                &emsp;PostgreSQL: система управления реляционными базами данных.</p>
                <p>Серверная часть<br />
                &emsp;Python: язык программирования.
                &emsp;Django: фреймворк для веб-приложений на языке Python.</p>
                <p>Клиентская часть<br />
                &emsp;JavaScript: язык программирования для создания веб-приложений.
                &emsp;React: JavaScript-библиотека для разработки пользовательских интерфейсов.</p>
                <p>Веб-сервер<br />
                &emsp;Nginx: программное обеспечение для создания легкого и мощного веб-сервера.</p>

                <div id="cap5" className="App-page-paragraph-name">Информационная безопасность</div>
                <p>Для усиленной защиты от несанкционированного доступа предусмотрена двухфакторная авторизация пользователей.</p>
                <p>Предусмотрена защита от одномоментного скачивания больших объемов информации (отделение человека от робота).</p>
                <p>Для защиты информации от копирования технические характеристики представлены в различных системах счисления.</p>

                <div id="cap6" className="App-page-paragraph-name">Классификаторы</div>
                <p>Данные по оборудованию систематизированы по позициям классификаторов оборудования для добычи нефти и газа (438 позиций) и для бурового оборудования (168 позиций).</p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic1.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                
                <div id="cap7" className="App-page-paragraph-name">ФУНКЦИОНАЛЬНЫЕ ВОЗМОЖНОСТИ</div>
                <p>
                <span  id="cap8" style={{fontWeight:'bold'}}>Анализ данных</span>
                <div  id="cap9" style={{display:'flex', paddingTop:'10px'}} ><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>
                    Поиск оборудования по заданным параметрам и изготовителям</div>
                </div>
                
                </p>
                <p>
                Поиск оборудования может осуществляться: 
                    <ul>
                    <li>по наименованию и шифру;</li>
                    <li>по позиции классификатора;</li>
                    <li>по набору показателей (изготовитель, регион, наименование, классификатор)  -расширенный поиск.</li>
                    </ul>
                </p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic2.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>
                <a href="/advanced" className="headerlink">Расширенный поиск</a> предоставляет возможность искать оборудование по совокупности запросов (наименованию, шифру, позиции классификатора, изготовителю, стране, области, городу) либо по одному из них.
                </p>
                <div id="cap10" style={{display:'flex', paddingTop:'10px'}} ><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>
                Создание пользовательской подборки оборудования</div>
                </div>
                
                <p>Пользователь может сформировать собственную подборку оборудования.</p>
                <p>Для того, чтобы добавить выбранное оборудование в пользовательскую подборку, необходимо отметить его звездочкой в миниатюре или карточке оборудования.</p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic4.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                
                <div id="cap11" style={{display:'flex', paddingTop:'10px'}} ><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>
                Сравнение оборудования</div>
                </div>

                <p>Пользователь может сравнить оборудование одного типа между собой.</p>
                <p>Для того, чтобы добавить выбранное оборудование к сравнению, необходимо отметить его флажком в миниатюре или карточке оборудования.</p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic27.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})} /></p>

                <div id="cap12" style={{display:'flex', paddingTop:'10px'}} ><CheckIcon style={{float:'left', color:'darkgreen'}}/> <div style={{float:'left', lineHeight:'28px'}}>
                Работа с результатами поиска</div>
                </div>
                
                <p>После вывода перечня оборудования по запросу предоставляется возможность:
                    <ul>
                    <li>сортировать оборудования по наименованию;</li>
                    <li>добавлять оборудование в категории избранного;</li>
                    <li>добавлять к сравнению;</li>
                    <li>подобрать аналоги оборудования (в том числе импортного оборудования).</li>
                    </ul>
                </p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic5.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})} /></p>
                <ol>
                    <li>Поиск по наименованию и шифру</li>
                    <li>Расширенный поиск</li>
                    <li>Позиции классификатора</li>
                    <li>Сортировка</li>
                    <li>Направление сортировки</li>
                    <li>Представление результатов</li>
                    <li>Добавить в избранное</li>
                    <li>Добавить к сравнению</li>
                    <li>Подбор аналогов</li>
                    <li>Подбор импортозамещающих аналогов</li>
                </ol>
                <p>
                После вывода перечня оборудования по категории (позиция классификатора) предоставляется возможность: 
                    <ul>
                        <li>сортировать оборудование по техническим характеристикам;</li>
                        <li>добавить оборудование в категории избранного;</li>
                        <li>добавить к сравнению;</li>
                        <li>подобрать аналоги оборудования (в том числе импортного оборудования);</li>
                        <li>использовать фильтр по техническим характеристикам.</li>
                    </ul>
                </p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic6.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <ol>
                    <li>Выбранная категория</li>
                    <li>Возможность сортировать по техническим параметрам</li>
                    <li>Выбор размерности</li>
                    <li>Показать/Скрыть панель фильтров технических параметров</li>
                    <li>Минимальное и Максимальное значение пааметра</li>
                    <li>Управление минимальным и максимальным значением параметра</li>
                </ol>                
                <p  id="cap13"><span style={{fontWeight:'bold'}}>Цифровой паспорт оборудования</span></p>
                
                <p>Цифровой паспорт (карточка) обордования отражает всю доступную о нем информацию, включая описание и значения технических характеристик и параметров.</p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic7.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})} /></p>
                
                <div id="cap14" className="App-page-paragraph-name">Примеры анализа данных</div>
                <p>
                На первом этапе рекомендуется сформировать выборку оборудования с использованием функции поиска по наименованию и шифру, по классификаторам или путем расширенного поиска.
                После этого с использованием встроенного функционала, постепенно сужать круг поиска, используя фильтры и сортировку с ранжированием. 
                Найденное оборудование возможно добавлять как к сравнению, так и в собственную избранную подборку оборудования, осуществлять подбор аналогов или импортозамещающего оборудования.

                </p>
                <p id="cap15"><span style={{fontWeight:'bold'}}>Поиск оборудования по наименованию и шифру</span></p>
                <p>В поисковой строке на любом этапе работы можно набрать полное наименование (часть полного наименования) оборудования или его шифр (часть шифра).</p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic8.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p  id="cap16"><span style={{fontWeight:'bold'}}>Поиск по классификаторам</span></p>
                <p>Для поиска и подбора оборудования определенного типа и назначения, на главной странице размещены классификаторы оборудования. Переход на главную страницу возможен на любом этапе поиска, путём нажатия на кнопку <img style={{width:'15px'}} src="/images/instructions/pic19.png" alt="" /> расположенную верхнем левом углу экрана.</p>
                <p>В выпадающем списке классификаторов, классы оборудования доступные для выбора, имеют значок <img style={{width:'15px'}} src="/images/instructions/pic20.png" alt="" /></p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic21.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p id="cap17"><span style={{fontWeight:'bold'}}>Расширенный поиск</span></p>
                <p>Расширенный поиск позволяет с помощью различных параметров получить более точную подборку оборудования. </p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic3.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Параметры расширенного поиска:
                    <ol>
                        <li>Шифр оборудования</li>
                        <li>Наименование оборудования</li>
                        <li>Тип оборудования</li>
                        <li>Изготовитель</li>
                        <li>Регион</li>
                    </ol>
                </p>
                <p>Поиск по изготовителю и региону доступен только пользователям с оплаченым доступом</p>
                <p id="cap18"><span style={{fontWeight:'bold'}}>Сортировка и ранжирование по техническим параметрам</span></p>
                <p>Для сортировки и ранжирования по техническим параметрам, необходимо в результатах поиска выбрать категорию оборудования (позицию классификатора оборудования). Если этого не сделать, то сортировать возможно только по наименованию или шифру оборудования. Направление сортировки изменяется нажатием функциональной кнопки <img style={{width:'15px'}} src="/images/instructions/pic15.png" alt="" /> - по возрастанию или <img style={{width:'15px'}} src="/images/instructions/pic16.png" alt="" /> - по убыванию.</p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic9.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Для примера, уточним свой запрос и выберем категорию «Агрегат кислотной обработки скважин», после чего появится дополнительная возможность исользовать фильтры и сортировку по техническим параметрам оборудования. </p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic10.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Кнопка <img style={{width:'15px'}} src="/images/instructions/pic17.png" alt="" /> откроет меню фильтров технических параметров.</p>
                <p>Для примера зададим диапазон «Давление макс.» 96 – 116,2 МПа и «Подача рабочей смеси» для диапазона 17,94 – 58,12 дм3/с</p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic18.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Сортировка и ранжирование доступны только пользователям с оплаченым доступом</p>
                <p id="cap19"><span style={{fontWeight:'bold'}}>Изменение представления результатов поиска</span></p>
                <p>Используя функциональные кнопки <img style={{width:'15px'}} src="/images/instructions/pic11.png" alt="" /> <img style={{width:'15px'}} src="/images/instructions/pic12.png" alt="" />, возможно вывести результаты поиска как построчно, так и в матричном представлении.</p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic13.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p><img className="App-page-help-pic" src="/images/instructions/pic14.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>

                <p id="cap20"><span style={{fontWeight:'bold'}}>Подбор аналогов оборудования и импортозамещение</span></p>
                <p>Функция подбора аналогов оборудования и подбора российских аналогов импортного оборудования (Импортозамещение) доступна как в цифровом паспорте оборудования, так и в результатах поиска в его миниатюре.</p>
                <p><img className="App-page-help-pic" style={{maxWidth:'400px'}} src="/images/instructions/pic22.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Если обордование присутствует в нескольких позициях классификатора, то необходимо перед переходом к подбору аналогов выбрать один из них, после чего будет сформирована соответствующая выборка аналогичного оборудования.</p>
                <p><img className="App-page-help-pic" style={{maxWidth:'400px'}} src="/images/instructions/pic23.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Для примера рассмотрим поиск российских аналогов "Установка насосная для ГРП и кислотная передвижная Модель МFР 2000-BL"KODIAK" (США).</p>
                <p><img className="App-page-help-pic"  src="/images/instructions/pic24.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>На примере показано, что в категории "Установка насосная" найдено 44 российских аналога американской установки МFР 2000-BL"KODIAK" с различными техническими характеристиками, отличными от данной модификации импортной установки. 
                    Выбирая параметры из предложеного списка, возможно подобрать по ним полный полный аналог российского оборудования.</p>
                <p>Выберем параметр "Давление, макс.", после чего будет найдено 9 российских аналога американской установки МFР 2000-BL"KODIAK" с максимальным давлением 105 МПа и выше</p>
                <p><img className="App-page-help-pic"  src="/images/instructions/pic25.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Если выбрать все предложеные параметры, то в качестве полного аналога по заданным техническим характеристикам будет подобран 1 российский аналог.</p>
                <p><img className="App-page-help-pic"  src="/images/instructions/pic26.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>

                <p id="cap21"><span style={{fontWeight:'bold'}}>Работа с пользовательской подборкой (Избранное оборудование)</span></p>
                <p>Раздел «Избранное» позволяет держать под рукой заинтересовавшее вас оборудование и не искать его повторно.</p>
                <p>Кнопка <img style={{width:'15px'}} src="/images/instructions/pic28.png" alt="" /> позволяет поместить оборудование в раздел «Избранное».</p>
                <p>Помещение оборудования в раздел «Избранное» производится путем нажатия на кнопку <img style={{width:'15px'}} src="/images/instructions/pic28.png" alt="" /> после чего она меняет цвет на красный <img style={{width:'15px'}} src="/images/instructions/pic29.png" alt="" />.</p>
                <p>Для того чтобы убрать единицу оборудования из раздела с избранным оборудованием необходимо повторно нажать на кнопку <img style={{width:'15px'}} src="/images/instructions/pic29.png" alt="" />.</p>
                <p><img className="App-page-help-pic"  src="/images/instructions/pic30.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>В остальном работа с избранным оборудованием аналогична работе с обычными результатами поиска с единственным отличем, что все выборки, сортировки и ранжирования будут производиться только с оборудованием с пометкой <img style={{width:'15px'}} src="/images/instructions/pic29.png" alt="" /> «Избранное».</p>
                
                <p id="cap22"><span style={{fontWeight:'bold'}}>Сравнение оборудования по параметрам и изготовителям</span></p>
                <p>Раздел «Сравнить» позволяет сравнить между собой оборудование одного типа по техническим характеристиками и производителям.</p>
                <p>Кнопка <img style={{width:'15px'}} src="/images/instructions/pic31.png" alt="" /> позволяет поместить оборудование в раздел «Сравнить».</p>
                <p>Помещение оборудования в раздел «Сравнить» для сравнения по характеристикам и производителям производится путем нажатия на кнопку <img style={{width:'15px'}} src="/images/instructions/pic31.png" alt="" /> после чего она меняет цвет на красный <img style={{width:'15px'}} src="/images/instructions/pic32.png" alt="" />.</p>
                <p>Для того чтобы убрать оборудование из раздела для сравнения необходимо повторно нажать на кнопку <img style={{width:'15px'}} src="/images/instructions/pic32.png" alt="" />.</p>
                <p><img className="App-page-help-pic"  src="/images/instructions/pic33.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Сравнивать между собой возможно оборудование одного типа. Для этого в верхней части раздела представлены все доступные для выбора типы оборудования для оборудования находящегося в данном разделе.</p>
                <p>Для того, чтобы отсортировать оборудование по любой из колонок таблицы сравнения нобходимо нажать ее заголовк. Повторное нажатие отсортирует в обратном направлении.</p>
                <p>Для раскрытия полного списка изготовителей единицы оборудования необходимо навести курсор мыши на ячейку с производителями.</p>

                <p id="cap23"><span style={{fontWeight:'bold'}}>Логистика поставок оборудования</span></p>
                <p>Для поиска оборудования в определенном регионе, все производители систематизированы по встроенному георграфическому справочнику.</p>
                <p><img className="App-page-help-pic"  src="/images/instructions/pic34.png" alt="" onClick={(e)=>setShowFullScreenImage({open:true, images:[e.currentTarget.src], name:e.currentTarget.alt})}/></p>
                <p>Для того, чтобы найти оборудование в том или ином регионе, необходимо воспользоваться расширенным поиском, и выбрать регион из справочника.</p>


            </div>
}
