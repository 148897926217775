import { EQPAPI } from "../../api/api";

const SET_MANUFACTURES = 'ASReducer/SET_MANUFACTURES';
const SET_REGIONS = 'ASReducer/SET_REGIONS';
const SET_ERROR = "ASReducer/ERROR";
const SET_TECHSPEC = "ASReducer/SET_TECHSPEC";
const SET_SEARCH_RESULT = "ASReducer/SET_SEARCH_RESULT";
const SET_LOADER = "ASReducer/SET_LOADER";

const initialState = {
    manufactures: null,
    regions: null,
    techSpec: null,
    error: null,
    search_result:null,
    loader:false,

}

export const ASReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_RESULT:
            return {
                ...state,
                search_result: action.search_result
            }
        case SET_MANUFACTURES:
        return {
          ...state,
          manufactures: action.manufactures,
        };
        case SET_LOADER:
        return {
          ...state,
          loader: action.loader,
        };

        case SET_REGIONS:
        return {
          ...state,
          regions: action.regions,
        };   
        case SET_TECHSPEC:
        return {
          ...state,
          techSpec: action.techSpec,
        };                
        case SET_ERROR:
        return {
            ...state,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };        
        default:
            return state;
    }
};

export const getManufactures = (state) => state.ASReducer.manufactures; 
export const getRegions = (state) => state.ASReducer.regions; 
export const getTechSpec = (state) => state.ASReducer.techSpec; 
export const getSearchResult = state => state.ASReducer.search_result
export const getLoader = (state) => state.ASReducer.loader; 


export const setManufactures = (manufactures) => ({type: SET_MANUFACTURES, manufactures})
export const setRegions = (regions) => ({type: SET_REGIONS, regions})
export const setTechSpec = (techSpec) => ({type: SET_TECHSPEC, techSpec})
export const setSearchResult = (search_result) => ({type: SET_SEARCH_RESULT, search_result})
export const setLoader = (loader) => ({type: SET_LOADER, loader})


export const loadManufactures = () => async dispatch => {

    const response = await EQPAPI.getFirms()
    if (response.status === 200 && response.data.length !== 0) {
        const result = response.data;
        dispatch(setManufactures(result));
    }

}

export const loadTechSpec = (okid) => async dispatch => {

    const response = await EQPAPI.getOkBTP(okid)
    if (response.status === 200 && response.data.length !== 0) {
        const result = response.data;
        dispatch(setTechSpec(result));
    }

}

export const operatorEQPSearch = (terms) => async dispatch => {

    const response = await EQPAPI.getOperatorEQPSearch(terms)
    dispatch(setLoader(false))
    if (response.status === 200 ) {
        const result = response.data;
        
        dispatch(setSearchResult(result));
    }

}