import { EQPAPI } from "../../api/api";

const HTML = "parseReducer/HTML";
const HTML_LOADER = "parseReducer/HTML_LOADER";
const SAVE_LOADER = "parseReducer/SAVE_LOADER";
const ERROR_LOAD = "parseReducer/ERROR_LOAD";


const initialState = {
    html: null,
    html_loader: false,
    save_loader: false,
    error: null,
  };


  export const parseReducer = (state = initialState, action) => {
    switch (action.type) {
        case HTML_LOADER:
        return {
          ...state,
          html_loader: action.html_loader,
        };
        case SAVE_LOADER:
        return {
          ...state,
          save_loader: action.save_loader,
        };
        case HTML:
        return {
          ...state,
          html: action.html,
        };        
        case ERROR_LOAD:
        return {
            ...state,
            html_loader: false,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };        
        default:
            return state;
    }
};


export const getHtml = (state) => state.parseReducer.html; 
export const getHtmlLoader = (state) => state.parseReducer.html_loader; 
export const getSaveLoader = (state) => state.parseReducer.save_loader; 


export const setHtml = (html) => ({
    type: HTML,
    html,
  });

  export const setHtmlLoader = (html_loader) => ({ type: HTML_LOADER, html_loader });
  export const setSaveLoader = (save_loader) => ({ type: SAVE_LOADER, save_loader });  

export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadHtml = (parseItem) => async (dispatch) => {
  
    const response = await EQPAPI.getParseUrl(parseItem);
    if (response.status === 200 && response.data.length !== 0) {

      const new_data = response.data;
      dispatch(setHtml(new_data));
      dispatch(setHtmlLoader(false))
    } else {
      dispatch(errorLoad());
    }

  };

 export const saveParseSettings = (settings) => async (dispatch) => {
  
  var paramNames = ['name', 'shifr', 'description', 'pics', 'tth']
  
  paramNames?.forEach((paramItem, key) => {
    settings = (settingsPreparation(settings, paramItem))
  })


    const response = await EQPAPI.setParseSettings(settings);
    if (response.status === 200 && response.data.length !== 0) {

      const new_data = response.data;
      dispatch(setSaveLoader(false));

      
    } else {
      dispatch(errorLoad());
    }

  };

  const settingsPreparation = (settings, name) => {
    if (settings[name]) {
      delete settings[name]['event']
      delete settings[name]['result']
      
      settings[name]["chain"]?.forEach((ch, key) => {
        delete ch.target
      })
    }

    return settings
  }