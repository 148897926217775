import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import {useQuery} from "../../hooks/useQuery";
import MuiTabs from "@material-ui/core/Tabs";
import {useRedirect} from "../../hooks/useRedirect";
import {RouteNames} from "../../router/consts";
import Lock from '@material-ui/icons/Lock';

import {useParams} from "react-router-dom"
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(() => ({
    tabs: {
        height: '100%',
        paddingTop: '10px'
    },
    contentArea: {
        flexGrow: 2
    },
    indicator: {
        backgroundColor: '#054C74'

      },
    lockBlock: {
        width:'80px',
        height:'80px',
        border: '2px solid #054C74',
        marginTop: '50px',
        marginLeft: '50%',
        borderRadius: '40px',
        position: 'relative',
        left: '-40px',
        ['@media (max-width:767px)']: { 
            marginTop: '15px',
            }  
    },
    lock: {
        fontSize: 60,
        color: '#054C74',
        marginLeft: '10px',
        marginTop: '5px',

    },
    chapterName: {
        fontSize:'32px', 
        textAlign: 'left',
        fontWeight: 400
    },
    tabStyle: {
        ['@media (max-width:500px)']: { 
            width:'25%',
            } 
    }
     
}))

export const Tabs = ({tabs, ...otherProps}) => {
    const classes = useStyles()

    const {projectID} = useParams()
    const query = useQuery()
    const {replace} = useRedirect()

    const handleTabClick = (event, newValue) => {
        if(!currentComponentName)
        replace(RouteNames.OPERATOR + `/?tab=${newValue}`)
        else
        replace(RouteNames.OPERATOR + `/?tab=${newValue}&component=${currentComponentName}`)
        
    }

    const currentValue = query.get('tab') || tabs[0]?.value
    const menuComponent = query.get('component')
    const [currentComponent, setCurrentComponent] = useState(null)
    const [currentComponentName, setCurrentComponentName] = useState(null)



    useEffect(() => {
    if (menuComponent){
        
        if(tabs.find(f => f.value === currentValue)?.menuComponent) {
            setCurrentComponent(tabs.find(f => f.value === currentValue).menuComponent[menuComponent])
            setCurrentComponentName(menuComponent)
        }
        else 
            setCurrentComponent(tabs.find(f => f.value === currentValue).component)
        
    }
    else 
    if (tabs.find(f => f.value === currentValue).component)
        setCurrentComponent(tabs.find(f => f.value === currentValue).component)
    }, [currentValue, menuComponent])        

    return <div>
                        <Grid item container direction={'column'} xs className={classes.tabs} wrap={"nowrap"}>
                        
                        <div className={classes.chapterName}>Администрирование</div>    
                            <Grid item style={{borderBottom: '1px solid #1A1A1A'}}>
                                <MuiTabs value={currentValue} onChange={handleTabClick} classes={{indicator: classes.indicator}}>
                                    {tabs && tabs.map((tab, index) => <Tab key={`project-edit-tab-${index}`} onClick={tab.onclick} value={tab.value} label={tab.label} className={classes.tabStyle} style={{textTransform: 'none', padding: '0'}} {...otherProps}/>)}
                                </MuiTabs>
                            </Grid>
                            <Grid item className={classes.contentArea}>
                                <div style={{height: '100%'}}>
                                    {currentComponent}
                                </div>
                            </Grid>
                        </Grid>
            </div>
}