import React, { useEffect, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}))

export const AboutPage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)
    const [eqpMeta, setEqpMeta] = useState({title:'О нас - Импортозамещение и подбор нефтегазового оборудования', description:'Это Каталог нефтегазового оборудования, ориентированный на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. Это принципиально новый инструментарий, позволяющий находить нефтегазовое оборудование, в том числе по основным эксплуатационным характеристикам, и осуществлять автоматический подбор однотипного нефтегазового оборудования для конкретной позиции Каталога', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    if (!isLoggedIn) 
    return <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/about"} rel="canonical"></link>
    </Helmet> 
                    <div className={classes.paper}>
                    <AboutContent/>
                    </div>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout>
                <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet> 
                    <div className={classes.paper}>
                     <AboutContent/>
                    </div>                  
                </HomePrivateLayout>    
}

const AboutContent = () => {
    const classes = useStyles()

    return  <div className="App-page">
                <h1 className="App-h1">О НАС</h1>
                
                <p>ООО "НПФ "ПроКон" образована в 2014 г.  Наша цель - не только быстро и качественно выполнить работу, но и предложить наилучшее решение поставленной задачи.</p>
                <p>Во главе компании стоят высококвалифицированные специалисты с большим опытом проектирования разработки месторождений и подсчета запасов.</p>
                <p>В составе компании эксперты ЦКР. Нефтяники и газовики. Это позволяет нам выполнять услуги самого широкого профиля.</p>
                
                <div className="App-page-paragraph-name">УСЛУГИ</div>
                
                <p>Наша компания постоянно развивается и растет. Мы предоставляем широкий спектр профессиональных услуг:
                    <ul>
                    <li>проектирование ГРР;</li>
                    <li>подсчет запасов углеводородов;</li>
                    <li>проекты разработки нефтяных и газовых месторождений;</li>
                    <li>проектирование ВПХГ;</li>
                    <li>сопровождение разработки месторождений;</li>
                    <li>аудит запасов по SPE PRMS;</li>
                    <li>оценка нефтегазовых активов;</li>
                    <li>подбор нефтегазового оборудования.</li>
                    </ul>
                </p>
                
                <div className="App-page-paragraph-name">ГЕОГРАФИЯ ПРОЕКТОВ</div>
                
                <p>Специалисты компании имеют опыт подсчета запасов и выполнения технических проектов по месторождениям нефти и газа регионов России:
                    <ul>
                        <li>Сахалинская область;</li>
                        <li>Республика Коми;</li>
                        <li>Иркутская область;</li>
                        <li>Республика Саха (Якутия);</li>
                        <li>Ханты-Мансийский автономный округ - Югра;</li>
                        <li>Ямало-Ненецкий автономный округ;</li>
                        <li>Ненецкий автономный округ;</li>
                        <li>Республики Башкортостан;</li>
                        <li>Республика Татарстан;</li>
                        <li>Республика Адыгея;</li>
                        <li>Республика Калмыкия;</li>
                        <li>Саратовская область;</li>
                        <li>Волгоградская область;</li>
                        <li>Ульяновская область;</li>
                        <li>Самарская область;</li>
                        <li>Астраханская область;</li>
                        <li>Оренбургская область.</li>
                    </ul>
                </p>
                
                <div className="App-page-paragraph-name">РОССИЙСКИЙ ИННОВАЦИОННЫЙ ЦИФРОВОЙ СЕРВИС <br/>“БАНК ДАННЫХ НЕФТЕГАЗОВОГО ОБОРУДОВАНИЯ” (БД НГО)</div>
                
                <p>Наша компания выводит в 2024 году в пробную эксплуатацию информационно-аналитический цифровой сервис БД НГО по поиску, анализу данных, хранению и обновлению информации по нефтегазовому оборудованию и его производителям в отраслевом цифровом пространстве Интернета.</p>
                <p>Цифровой сервис разработан российскими учеными и специалистами в области проектирования и использования нефтегазового оборудования для геологоразведки и разработки месторождений нефти и газа.</p>
                <p>Рабочая группа по сопровождению функционирования БД НГО нашей компании включает IT- специалистов, экспертов по нефтегазовому оборудованию, технический персонал.</p>
 
             </div>
}
