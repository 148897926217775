import React from 'react';
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Skeleton from "@material-ui/lab/Skeleton";
import {makeStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import {RuIcon} from '../RuIcon/RuIcon';


const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: '0',
        width: '25px',
        height: '25px',
        backgroundColor: '#fff',
        border: '2px solid #054C74',
        fontSize: 24,
        color: '#054C74',        
        "&:hover": {
            cursor: 'pointer'
        },
    },
    button: {
        color: '#054C74',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#eeeeee',
            color: '#054C74',
        },        
    },

    customBadge: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '10px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },
      customBadge2: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '4px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },
}))


export const AnalogMenu = ({searchResultOutItem, type, isTariff, gridFormat, clickAction}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return <>
    <div style={{float:'left'}}>
        <Badge badgeContent={searchResultOutItem.analogs} className={classes.customBadge} style={{marginLeft:'10px', float: 'right', marginRight:'10px',}}>
            <Button 
                variant="outlined" 
                onClick={isTariff?handleClick:clickAction}  
                startIcon={(type=="ru")?<RuIcon />:<ContentCopyIcon />} 
                size={'large'} className={classes.button} >
                    <span className="nomobile" style={gridFormat?{display:'none'}:null}>{(type=="ru")?'Импортозамещение':'Аналоги'}</span></Button>
        </Badge>

        <Menu anchorOrigin={{vertical: "bottom", horizontal: "center"}}
              transformOrigin={{vertical: "top", horizontal: "center"}}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{}}
        >
            {searchResultOutItem?.ok.map((option) => (
                <MenuItem component={Link} target="_blank" to={'/eqpsearch/'+((type=="ru")?'ruanalogues':'analogues')+'/?eq='+searchResultOutItem.eq.ID+'&ok='+option.ID+'&okname='+option.NAME}>
                    <ListItemText className="headerlinkImageText" primary={option.NAME}/>
                </MenuItem>
            ))}
          
        </Menu>
        </div>
    </>
}