import { EQPAPI } from "../../api/api";

const SET_IV = "ivReducer/SET_IV";
const SET_EI = "ivReducer/SET_EI";
const ERROR_LOAD = "ivReducer/ERROR_LOAD";
const SET_ERROR = "ivReducer/SET_ERROR";
const SET_LOADING = "ivReducer/SET_LOADING";


const initialState = {
    iv: null,
    ei:null,
    loading: false,
    error: null,
  };


  export const ivReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IV:
        return {
          ...state,
          iv: action.iv,

        };
        case SET_EI:
        return {
          ...state,
          ei: action.ei,
        };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            } 
        case ERROR_LOAD:
        return {
            ...state,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };        
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }        
        default:
            return state;
    }
};


export const getIV = (state) => state.ivReducer.iv; 
export const getEI = (state) => state.ivReducer.ei; 
export const getError = state => state.ivReducer.error
export const getLoading = state => state.ivReducer.loading


export const setIV = (iv) => ({
    type: SET_IV,
    iv,
  });

  export const setEI = (ei) => ({
    type: SET_EI,
    ei,
  });

  export const setError = (error) => ({type: SET_ERROR, error})
  export const setLoading = (loading) => ({type: SET_LOADING, loading})

export const errorLoad = () => ({ type: ERROR_LOAD });  

export const loadEI = (id) => async (dispatch) => {

    const response = await EQPAPI.getEIEdit(id);
    if (response.status === 200 && response.data.length !== 0) {

      const new_data = response.data;
      dispatch(setEI(new_data));

    } else {
      dispatch(errorLoad());
    }

  };

  export const loadIV = () => async (dispatch) => {

    const response = await EQPAPI.getIV();
    if (response.status === 200 && response.data.length !== 0) {

      const new_data = response.data;
      dispatch(setIV(new_data));

    } else {
      dispatch(errorLoad());
    }

  };  

  export const newIV = (iv) => async dispatch => {
    if (iv.NAME =='')
      iv.NAME ='Без названия'
        const response = await EQPAPI.newIV(iv)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setIV(result));
            dispatch(setLoading(false))        
        }
} 

export const newEI = (ei, prm) => async dispatch => {
  let error = {iserror: false}
  
  dispatch(setLoading(true))
  if (ei == null) {
  
    error = {...error, 
      eiNAME:   {text: 'Не указано наименование'},
      eiR_USL:  {text: 'Не указано сокащенное наименование'},
      eiCALC:   {text: 'Не указан расчетный коэффициент'},
      iserror: true
    } 
    
  }
  else {
  
  if ((ei?.NAME == undefined) ||(ei?.NAME == ''))  error = {...error,  eiNAME:   {text: 'Не указано наименование'}, iserror: true}
  if ((ei?.R_USL == undefined) ||(ei?.R_USL == '')) error = {...error,  eiR_USL:   {text: 'Не указано сокащенное наименование'}, iserror: true}
  if ((ei?.CALC == undefined) ||(ei?.CALC == ''))  error = {...error,  eiCALC:   {text: 'Не указан расчетный коэффициент'}, iserror: true}
  }
  
  if (error.iserror )
    dispatch(setError(error))
  else
    {
    ei['PRM'] = prm - 1000000000
    
        const response = await EQPAPI.newEI(ei)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setIV(result));
            dispatch(setLoading(false))        
        }
        
    }
  }  

  export const updateEI = (ei) => async dispatch => {
    let error = {iserror: false}
    
    dispatch(setLoading(true))
    if (ei == null) {
    
      error = {...error, 
        eiNAME:   {text: 'Не указано наименование'},
        eiR_USL:  {text: 'Не указано сокащенное наименование'},
        eiCALC:   {text: 'Не указан расчетный коэффициент'},
        iserror: true
      } 
      
    }
    else {
    
    if ((ei?.NAME == undefined) ||(ei?.NAME == ''))  error = {...error,  eiNAME:   {text: 'Не указано наименование'}, iserror: true}
    if ((ei?.R_USL == undefined) ||(ei?.R_USL == '')) error = {...error,  eiR_USL:   {text: 'Не указано сокащенное наименование'}, iserror: true}
    if ((ei?.CALC == undefined) ||(ei?.CALC == ''))  error = {...error,  eiCALC:   {text: 'Не указан расчетный коэффициент'}, iserror: true}
    }
    
    if (error.iserror )
      dispatch(setError(error))
    else
      {
          //delete ei['PRM']
          const response = await EQPAPI.updateEI(ei)
          
          if (response.status === 200 && response.data.length !== 0) {
              const result = response.data
              dispatch(setIV(result));
              dispatch(setLoading(false))        
          }
          
      }
    }    

    export const deleteEI = (ei) => async dispatch => {
      const response = await EQPAPI.deleteEI(ei)
      
      if (response.status === 200 && response.data.length !== 0) {
          const result = response.data
          dispatch(setIV(result));
      }
    }

  export const updateIV = (iv) => async dispatch => {
        iv.ID -= 1000000000
        const response = await EQPAPI.updateIV(iv)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setIV(result));
        }
}

  export const deleteIV = (iv) => async dispatch => {
    iv.ID -= 1000000000
    const response = await EQPAPI.deleteIV(iv)
    
    if (response.status === 200 && response.data.length !== 0) {
        const result = response.data
        dispatch(setIV(result));
    }
  }