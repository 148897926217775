import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getOk, loadOk, newOk, updateOk, deleteOk, getOkitem, setOkitem, loadOkitem } from "../../redux/reducers/ok-reducer";
import {loadSpecs, getSpecs} from "../../redux/reducers/spec-reducer";
import {useDispatch, useSelector} from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ConstructionIcon  from '@mui/icons-material/Construction';
import {OkPanelSkeleton} from "../../components/SearchSkeleton/SearchSkeleton.jsx";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {Button, ButtonGroup, Divider, Tooltip, Typography} from "@material-ui/core";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Loader from "../../components/Loader/Loader";
import Grid from "@material-ui/core/Grid";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {GridCellExpand} from "../../components/GridCellExpand/GridCellExpand.jsx";
import { SpecsTable } from "../../components/SpecsTable/SpecsTable";



const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
    headitemname : {
      display: 'flex', 
      paddingTop: '10px',
      paddingLeft: '5px',
      fontSize:'16px',
      
      color: '#929292'
  },
  headitemvalue : {
      display: 'flex', 
      fontSize:'15px',
      paddingLeft: '5px',
      color: '#0f0f0f'
  },
}))

let treeAction = null

const getTreeItemsFromData = (treeItems) => {

    return treeItems.map(treeItemData => {

      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }
      return (
        (treeItemData.children.length==0)?
        <TreeItem
             key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          onClick={()=> {treeAction(treeItemData.id,treeItemData.name)}}
        />:
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          onClick={()=> {treeAction(treeItemData.id,treeItemData.name)}}
        />        
      );
    });
  };

const DataTreeView = ({ treeItems, action }, ) => {

  treeAction = action
  return (
    (treeItems!=null)?
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultEndIcon={<ConstructionIcon/>}
    >
      {getTreeItemsFromData(treeItems, ()=>action)}
    </TreeView>
    :''
  );
};  

export const OkEdit = (props) => {

    const classes = useStyles()
    const { ok1 } = useSelector(getOk);    
    const { ok2 } = useSelector(getOk);    
    const okItem = useSelector(getOkitem);
    const  specs  = useSelector(getSpecs); 

    const [selectedItem, setSelectedItem] = useState(null);
    const [dialogClassifier, setDialogClassifier] = useState(null);
    const [owner, setOwner] = useState(props.owner);
    const [okName, setOkName] = useState(null);

    const сloseDialogClassifier = () => {
      setDialogClassifier(null);
      dispatch(setOkitem(null))
    };

    const selectItem = (id, name) => {
      setSelectedItem({id:id, name:name})
      dispatch(setOkitem(null))
    }


    const dispatch = useDispatch();

    useEffect(() => {

      dispatch(loadSpecs());
    }, []); 

    useEffect(() => {

        dispatch(loadOk(owner));
      }, [dispatch,owner]);        

      useEffect(() => {
        if ((okItem != null)&&(okItem?.ID_OWNER == null))
        dispatch(setOkitem({... okItem, ID_OWNER:owner}))
      }, [okItem]); 
      
      
      const addSpec = (id, name) => {
        
        dispatch(setOkitem({... okItem, btp:okItem?.btp?.concat([{ID:id, NAME:name, STATUS:'new'}])}))

        if ((dialogClassifier == 'btp')&&(okItem?.ID == null))
          setDialogClassifier('add')
          else if ((dialogClassifier == 'btp')&&(okItem?.ID != null))
          setDialogClassifier('edit')

      }

      const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              //link = {'/operator/?tab=Reference&component=spec&id='+params.id}
              clickHandle = {addSpec}
          />
        )
      }        

      const genColumns = () => {
        let compareColumns = [
            { 
            field: "id", 
            headerName: "ID", 
            minWidth: 15, 
            
//            renderCell: RenderDescription
            }, 
            { 
            field: "NAME", 
            headerName: "Наименование", 
            minWidth: 300, flex: 1, 
            renderCell: RenderDescription
            },
            { 
                field: "TYP", 
                headerName: "Тип", 
                width: 100, flex: 1, 
                renderCell:RenderDescription, 
            },                  
            { 
              field: "COMMENT", 
              headerName: "Обозначение", 
              width: 100, flex: 1, 
              renderCell:RenderDescription, 
            }, 

            ]

        return compareColumns
    }      

    const genRows = () => {
        let rowData = []
        specs?.forEach( function callback (firmItem) {
            let tmpRow = {id:firmItem.ID,NAME:firmItem.NAME,COMMENT:firmItem.COMMENT,TYP:
            (firmItem.TYP == 0)? "Числовой"
            :(firmItem.TYP == 1)?"Списочный"
            :"Не указан"}
            rowData.push(tmpRow)
        })
        return rowData

    }  


    const saveNewOk = () => {

      dispatch(newOk(okItem))
      setOkName(null)
      сloseDialogClassifier()
    }

    const updateEditedOk = () => {
      dispatch(updateOk(okItem))
      setOkName(null)
      сloseDialogClassifier()
    }    

    const deleteEditedOk = () => {
      dispatch(deleteOk({ID:selectedItem.id, ID_OWNER:owner}))
      setOkName(null)
      setSelectedItem(null)
      сloseDialogClassifier()
    }    

    const btp = () => {
      let content = []

      if(okItem?.btp?.length > 0)
      okItem?.btp?.forEach( function callback (btpItem) {
          if (btpItem?.STATUS != 'deleted')
          content.push(<div className={classes.headitemvalue} style={{paddingTop:'3px'}}>{btpItem.NAME}
                              <IconButton style={{padding:'0', top:'-2px'}} onClick={()=>{
                                  (btpItem?.STATUS == 'new')?
                                  dispatch(setOkitem({... okItem, btp:okItem?.btp?.filter(option=>option.ID != btpItem.ID )}))
                                  :
                                  dispatch(setOkitem({... okItem, btp:okItem?.btp?.map(option=>{
                                      if (option.ID == btpItem.ID)
                                          option['STATUS'] = 'deleted'
                                      return option
                                  }
                                       )}))
                                  }} >
                                  <CloseIcon />
                              </IconButton> 
                      </div>)
      })

      return content
  }     

    const okDialog = (props) => {
      
      return  <Dialog
      open={(dialogClassifier == null)?false:true}
      onClose={сloseDialogClassifier}
      handleClose={сloseDialogClassifier}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      style = {{minWidth: 500}}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: (dialogClassifier == 'btp')?"1000px":null,  // Set your width here
          },
        },
      }}
      >

      <DialogTitle id="scroll-dialog-title">
        {
        (dialogClassifier == 'add')?
          <h2>Добавить новую позицию классификатора{(selectedItem?.id)?' в "'+selectedItem.name+'"':null}</h2>
        :(dialogClassifier == 'btp')?
          <h2>Добавить базовую техническую характеристику</h2>          
        :(dialogClassifier == 'edit')?
          <h2>Изменить позицию классификатора</h2>
        :(dialogClassifier == 'delete')?
          <h2>Удалить позицию классификатора</h2>

        :null
        }
        
      </DialogTitle>
      <DialogContent dividers="paper">
      {(dialogClassifier == 'delete')?
        <DialogContentText
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
        Вы действительно хотите удалить «{selectedItem.name}»

        </DialogContentText>
        :
        <DialogContentText
        
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
          {
          ((dialogClassifier == 'btp'))?
          <SpecsTable style={{height:'auto'}} add={false} data={genRows()} columns={genColumns()}/>
          :((okItem != null))?
          <div>
           <TextField
                        
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-add-okp"
                        label="Наименование"
                        value={okItem?.NAME}
                        style = {{width: 400}}
                        onChange={(e)=> dispatch(setOkitem({... okItem, NAME:e.target.value})) }
                        //onChange={setOwnerItem}
                        >
                        
                        </TextField>
           <div>     
           <div className={classes.headitemname}>Базовые технические характеристики</div>
           {
           btp()
           }
           <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline', marginLeft:'5px'}} onClick={() => setDialogClassifier('btp')} >Добавить техническую характеристику</div>
       </div> 
       </div>
          :
          <Grid container spacing={2} justifyContent="center">
          <Grid item>
          <Loader/>
          </Grid>
            </Grid> 
          }
          
        </DialogContentText>
    }
      </DialogContent>
      <DialogActions>
        <Button style={{color:'#054C74'}} onClick={()=>(dialogClassifier == 'btp')&&(okItem?.ID == null)?setDialogClassifier('add'):(dialogClassifier == 'btp')&&(okItem?.ID != null)?setDialogClassifier('edit'): сloseDialogClassifier()}>Отмена</Button>
        {dialogClassifier == 'delete'?
        <Button style={{color:'red'}} onClick={()=>deleteEditedOk()}>Удалить</Button>
        :(dialogClassifier != 'btp')?<Button style={{color:'#054C74'}} onClick={()=> { (okItem?.ID == null)?saveNewOk():updateEditedOk()}}>Сохранить</Button>:null
        }
      </DialogActions>
    </Dialog> 

    }
  
    const setOwnerItem = (event, value) => {
      if (selectedItem != value.props.value)
        setSelectedItem(null)
      setOwner(value.props.value)
      
  }
     
  
    return  <div className={classes.paper} style={{float:'left'}}>
            {okDialog()}
            <div style={{width:'100%'}}>
                  <TextField
                        size="small"
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-select-sort"
                        select
                        label="Тип оборудования"
                        defaultValue="1"
                        value={owner}
                        onChange={setOwnerItem}
                        >
                        
                        <MenuItem key="production" value="1">Оборудование для добычи нефти и газа</MenuItem>
                        <MenuItem key="drilling" value="2">Буровое оборудование</MenuItem>
              </TextField>
              <ButtonGroup style={{padding:'10px'}} size={'small'} variant={'text'} hidden >
                <Button 
                  onClick={()=>{setOkName(null);dispatch(loadOkitem({id:'new'}));setDialogClassifier('add')}}
                ><AddOutlinedIcon/></Button>
                <Button 
                  disabled={!selectedItem?.id} 
                  onClick={()=>{setOkName(selectedItem.name);dispatch(loadOkitem(selectedItem));setDialogClassifier('edit')}}
                  //</ButtonGroup>onClick={onEdit}
                >
                    <EditOutlinedIcon/></Button>
                <Button 
                  disabled={!selectedItem?.id} 
                  onClick={()=>{setOkName(selectedItem.name);setDialogClassifier('delete')}}
                  ///onClick={onDelete}
                ><DeleteOutlinedIcon/></Button>
              </ButtonGroup>
            </div>

            <div className={((owner == 1 && !ok1) || (owner == 2 && !ok2))?'':'hidden'}><OkPanelSkeleton/></div>
            <DataTreeView treeItems={(owner == 1)?ok1:ok2} action={selectItem} />
            </div>

}