import React, { useEffect, useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { HomePublicLayout } from '../layouts/HomePublicLayout';
import { HomePrivateLayout } from '../layouts/HomePrivateLayout';
import {useSelector} from "react-redux";
import {getLoggedIn} from "../redux/reducers/login-reducer";
import AdvancedSearch from "../components/AdvancedSearch/AdvancedSearch"
import { Helmet } from 'react-helmet';


const useStyles = makeStyles((theme) => ({
    paper: {
        margin: '24px 5px 20px 5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: ''
    },
    chapterName: {
        fontSize:'30px', 
        textAlign: 'center',
        fontWeight: 400
    },

}))
export const AdvancedSearchPage = () => {
    const classes = useStyles()
    const isLoggedIn = useSelector(getLoggedIn)

    const [eqpMeta, setEqpMeta] = useState({title:'Расширенный поиск - Импортозамещение и подбор нефтегазового оборудования', description:'Расширенный поиск в каталоге нефтегазового оборудования, ориентированном на решение инженерно-технических задач, связанных с поиском,  выбором и импортозамещением оборудования. ', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});


    if (!isLoggedIn) 
    return <HomePublicLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/advanced"} rel="canonical"></link>
    </Helmet>        
                    <div className={classes.paper}>
                        <div className={classes.chapterName}>Расширенный поиск оборудования</div>
                     <AdvancedSearch/>
                    </div>
        </HomePublicLayout>
        else
        return  <HomePrivateLayout>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
    </Helmet>            
                    <div className={classes.paper}>
                        <div className={classes.chapterName}>Расширенный поиск оборудования</div>
                        <AdvancedSearch/>
                    </div>                  
                </HomePrivateLayout>   
}