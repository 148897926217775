import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getRegions, loadRegions, newRegion, updateRegion, deleteRegion } from "../../redux/reducers/regions-reducer";
import {useDispatch, useSelector} from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ConstructionIcon  from '@mui/icons-material/Construction';
import {OkPanelSkeleton} from "../../components/SearchSkeleton/SearchSkeleton.jsx";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {Button, ButtonGroup, Divider, Tooltip, Typography} from "@material-ui/core";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
}))

let treeAction = null

const getTreeItemsFromData = (treeItems) => {

    return treeItems.map(treeItemData => {

      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }
      return (
        (treeItemData.children.length==0)?
        <TreeItem
             key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          onClick={()=> {treeAction(treeItemData.id,treeItemData.name)}}
        />:
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          onClick={()=> {treeAction(treeItemData.id,treeItemData.name)}}
        />        
      );
    });
  };

const DataTreeView = ({ treeItems, action }, ) => {

  treeAction = action
  return (
    (treeItems!=null)?
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultEndIcon={<ConstructionIcon/>}
    >
      {getTreeItemsFromData(treeItems, ()=>action)}
    </TreeView>
    :''
  );
};  

export const GeoEdit = (props) => {

    const classes = useStyles()
    const regions = useSelector(getRegions);    
  

    const [selectedItem, setSelectedItem] = useState(null);
    const [dialogClassifier, setDialogClassifier] = useState(null);
    const [regionName, setRegionName] = useState(null);

    const сloseDialogClassifier = () => {
      setDialogClassifier(null);
    };

    const selectItem = (id, name) => {
      setSelectedItem({id:id, name:name})
    }


    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(loadRegions());
      }, []);        


    const saveNewRegion = () => {
      dispatch(newRegion({ID_PARENT:(selectedItem?.id)?selectedItem.id:null, NAME:regionName}))
      setRegionName(null)
      сloseDialogClassifier()
    }

    const updateEditedRegion = () => {
      dispatch(updateRegion({ID:selectedItem.id, NAME:regionName}))
      setRegionName(null)
      сloseDialogClassifier()
    }    

    const deleteEditedRegion = () => {
      dispatch(deleteRegion({ID:selectedItem.id}))
      setSelectedItem(null)
      setRegionName(null)
      сloseDialogClassifier()
    }    

    const okDialog = (props) => {
      
      return  <Dialog
      className={classes.mainDialog}
      open={(dialogClassifier == null)?false:true}
      onClose={сloseDialogClassifier}
      handleClose={сloseDialogClassifier}
      //scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      style = {{minWidth: 500}}
      >

      <DialogTitle id="scroll-dialog-title">
        {
        (dialogClassifier == 'add')?
          <h2>Добавить новую позицию классификатора{(selectedItem?.id)?' в "'+selectedItem.name+'"':null}</h2>
        :(dialogClassifier == 'edit')?
          <h2>Изменить позицию классификатора</h2>
        :(dialogClassifier == 'delete')?
          <h2>Удалить позицию классификатора</h2>

        :null
        }
        
      </DialogTitle>
      <DialogContent dividers="paper">
      {(dialogClassifier == 'delete')?
        <DialogContentText
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
        Вы действительно хотите удалить «{selectedItem.name}»

        </DialogContentText>
        :
        <DialogContentText
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
           <TextField
                        
                        className={classes.orderMenu}
                        margin="dense"
                        padding="none"
                        id="standard-add-okp"
                        label="Наименование"
                        value={regionName}
                        style = {{width: 400}}
                        onChange={(e)=> setRegionName(e.target.value)}
                        //onChange={setOwnerItem}
                        ></TextField>
          
        </DialogContentText>
    }
      </DialogContent>
      <DialogActions>
        <Button style={{color:'#054C74'}} onClick={сloseDialogClassifier}>Отмена</Button>
        {dialogClassifier == 'delete'?
        <Button style={{color:'red'}} onClick={()=>deleteEditedRegion()}>Удалить</Button>
        :<Button style={{color:'#054C74'}} onClick={()=>(dialogClassifier == 'add')?saveNewRegion():updateEditedRegion()}>Сохранить</Button>
        }
      </DialogActions>
    </Dialog> 

    }
  

     
  
    return  <div className={classes.paper} style={{float:'left'}}>
            {okDialog()}
            <div style={{width:'100%'}}>

              <ButtonGroup style={{padding:'10px'}} size={'small'} variant={'text'} hidden >
                <Button 
                  onClick={()=>setDialogClassifier('add')}
                ><AddOutlinedIcon/></Button>
                <Button 
                  disabled={!selectedItem?.id} 
                  onClick={()=>{setRegionName(selectedItem.name);setDialogClassifier('edit')}}
                  //</ButtonGroup>onClick={onEdit}
                >
                    <EditOutlinedIcon/></Button>
                <Button 
                  disabled={!selectedItem?.id} 
                  onClick={()=>{setRegionName(selectedItem.name);setDialogClassifier('delete')}}
                  ///onClick={onDelete}
                ><DeleteOutlinedIcon/></Button>
              </ButtonGroup>
            </div>

            <div className={(!regions)?'':'hidden'}><OkPanelSkeleton/></div>
            <DataTreeView treeItems={regions} action={selectItem} />
            </div>

}