import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setBookmarkStatusEq,setCompareStatusEq, getLoadBmEq, setLoadBmEq, getLoadCmEq, setLoadCmEq, loadEQ, getEQ, setEQ} from "../../redux/reducers/eq-reducer";
import {getFirm, getFirmError, getFirmLoader, setFirm, setFirmLoader, setFirmError, loadFirm} from "../../redux/reducers/firm-reducer";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import {SearchBar} from "../SearchBar/SearchBar";
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {getLoggedIn, getTariff} from "../../redux/reducers/login-reducer";
import {useDialog} from "../../hooks/useDialog.js";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";  
import  Loader  from '../Loader/Loader';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {API_SERVER_VAL} from "../../config";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import {RuIcon} from '../RuIcon/RuIcon';
import Lightbox  from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Bookmark from "../Bookmark/Bookmark"
import Compare from "../Compare/Compare"
import { AnalogMenu } from "../AnalogMenu/AnalogMenu";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import {getProfile} from "../../redux/reducers/user-profile-personal-reducer";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Helmet} from "react-helmet";

const baseUrl = `${API_SERVER_VAL}/api/eqp`

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px',
        width: '100%',
    },
    carousel: {
        margin:'auto',
        cursor:'pointer'
    },
    headitemname : {
        display: 'flex', 
        paddingTop: '10px',
        paddingLeft: '5px',
        fontSize:'16px',
        
        color: '#929292'
    },
    headitemvalue : {
        display: 'flex', 
        fontSize:'15px',
        paddingLeft: '5px',
    },
    headitemvalueFirmNotActive: {
        marginLeft: '5px',
        cursor:'pointer', 
        color:'darkred', 
        display:'block',
        width: 'fit-content',
        textDecoration: 'underline', 
        textDecorationStyle: 'dashed', 
        textDecorationThickness:'1px', 
        paddingTop:'3px', 
        lineHeight:'20px'
    },

    headitemvalueFirm: {
        marginLeft: '5px',
        cursor:'pointer', 
        color:'#054C74', 
        display:'block',
        width: 'fit-content',
        textDecoration: 'underline', 
        textDecorationStyle: 'dashed', 
        textDecorationThickness:'1px', 
        paddingTop:'3px', 
        lineHeight:'20px'
    },
    pic: {
        minWidth:'300px',
        height: '300px',
        display:'flex',
        float: 'left',
        paddingRight: '10px',
        ['@media (max-width:520px)']: { 
            width: '-webkit-fill-available',
            paddingRight: '0',
            paddingBottom:'20px',
            height:'auto'
        },
        "& .carousel-root": {
            margin: "auto",
          },
        "& img, & div": {
            maxHeight: "300px",
            maxWidth: "300px",
          }, 
    },
    eqName: {
        fontSize: '25px',
        
        verticalAlign: 'middle',
        display: 'table-cell',
    },

    EQMain: {paddingTop: '70px', paddingBottom: '0px', width:'1024px', 
    ['@media (max-width:860px)']: { 
        width: '-webkit-fill-available',
        margin: '0 5px'
    },   
    ['@media (max-width:500px)']: { 
        width: '-webkit-fill-available',
    }
    },    
    Breadcrumbs: {
        "& li.MuiBreadcrumbs-separator": {
            marginLeft: '0',
            marginRight: '0',
        },   
        ['@media (max-width:500px)']: { 
            display: 'none',
        }        
    },
    
    customBadge: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '10px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },
      customBadge2: {
        "& .MuiBadge-badge": {
            top: '4px',
            right: '4px',
            color: "white",
            backgroundColor: "#054C74",
            fontSize: '0.9rem'
          }        
      },

      button: {
        color: '#054C74',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#eeeeee',
            color: '#054C74',
        },        
    },

}))



export const EQ = (av) => {
    const {id} = useParams()
    const classes = useStyles()
    const dispatch = useDispatch();
    const isTariff = useSelector(getTariff)
    const loadBm = useSelector(getLoadBmEq)
    const loadCm = useSelector(getLoadCmEq)


    const [showFullScreenImage, setShowFullScreenImage] = useState({open:false, images:null, name:null});
    const [actBookmark, setActBookmark] =  useState(null);
    const [actCompare, setActCompare] =  useState(null);
    const [firmDetails, setFirmDetails] =  useState([]);
    const [eqpMeta, setEqpMeta] = useState({title:'', description:'', keywords: 'каталог нефтегазового оборудования, каталог, нефтегазового, оборудования, нефтегазового оборудования, нефтегазовое оборудование, оборудование, промысловое оборудование, Нефть, Газ, нефтегазопромысловое оборудование, нефть и газ, оборудование для добычи, оборудование для бурения, буровое оборудование, производители нефтегазового оборудования, поиск аналогов, производители, производители нефтегазового оборудования'});
//console.log(firmDetails)
    

    const eqItem = useSelector(getEQ)
    const firm = useSelector(getFirm)  
    const firmLoader = useSelector(getFirmLoader)  
    let {user} = useSelector(getProfile)


    useEffect(() => {
        if (eqItem != null) {
             setFirmDetails(
                eqItem?.firms?.map(firmItem=>({"id":firmItem.ID, "content":null}))
            )
        if (eqpMeta.title == '')
            setEqpMeta({...eqpMeta, title: eqItem?.eq?.NAME+' '+eqItem?.eq?.SHIFR, description: eqItem?.eq?.COMMENT, keywords:eqItem?.eq?.NAME+', '+eqpMeta.keywords} );
        }
    }, [eqItem]);

    useEffect(() => {


        if(firm != null)
        setFirmDetails(firmDetails.map((o, i) => {
            if (o.id === firm.id) {
                o.content = firm 
                return o; // stop searching
            }
            else return o
        }))
    },[firm])


    useEffect(() => {
        if (id)
        dispatch(loadEQ(id));
    }, []);
 
    useEffect(() => {
        if (loadBm) {
            
            av?.av?.ssetAv(!av?.av?.av);
            dispatch(setLoadBmEq(false))
            
            setActBookmark(null)
        }
  },[loadBm])

  useEffect(() => {
    if (loadCm) {
        
        av?.av?.ssetAv(!av?.av?.av);
        dispatch(setLoadCmEq(false))
        
        setActCompare(null)
    }
},[loadCm])  
      
    useEffect(() => {
        if (actBookmark) {

            dispatch(setBookmarkStatusEq(actBookmark))
            setActBookmark(null)
        }
      },[actBookmark])

      useEffect(() => {
        if (actCompare) {

            dispatch(setCompareStatusEq(actCompare))
            setActCompare(null)
        }
      },[actCompare])      
      
    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()




    const genBreadcrumb = () => {

        let content = []

        if(eqItem?.crumbs?.length > 0)
        for(let i = eqItem?.crumbs?.length-1; i >= 0; i--) {
            //if(i==eqItem?.crumbs?.length-1)
            //content.push(<span>{eqItem.crumbs[i].NAME}</span>)
            //else
            content.push(<Link target="_blank" to={"/eqpsearch/?ok="+eqItem.crumbs[i].ID+(i==eqItem?.crumbs?.length-1?"&chap="+eqItem.crumbs[i].ID:"")+"&okname="+eqItem.crumbs[i].NAME} className="link breadcrumbslink">{eqItem.crumbs[i].NAME}</Link>)


            //content.push(<Link target="_blank" to={"/eqpsearch/?ok="+eqItem.crumbs[i].ID+(i==eqItem?.crumbs?.length-1?"&chap="+eqItem.crumbs[i].ID:"")+"&okname="+eqItem.crumbs[i].NAME} className="link breadcrumbslink">{eqItem.crumbs[i].NAME}</Link>)
        }
        return content

    }

    const ok = () => {
        let content = []

        if(eqItem?.ok?.length > 0)
        eqItem?.ok?.forEach( function callback (okItem) {
            content.push(<div className={classes.headitemvalue} style={{paddingTop:'3px'}}><Link target="_blank" to={"/eqpsearch/?ok="+okItem.ID+"&okname="+okItem.NAME} className="link breadcrumbslink">{okItem.NAME}</Link></div>)
        })

        return content
    }

    const descriptions = () => {
        let content = []
        eqItem?.eq?.DESCRIPTION.split('\r\n')?.forEach( function callback (descriptionsItem) {
            //descriptionsItem = descriptionsItem?.toString().replace('    ', '   ').replace('    ', '   ').replace('    ', '   ').replace('    ', '   ').replace('    ', '   ')
            content.push(<div className={classes.headitemvalue} style={{paddingTop:'3px'}}>
                        {descriptionsItem}
                         </div>)

        })

        return content
    }

    const firms = () => {
        let content = []

        if(!isTariff)
        content.push(<Link to="#" className="link headerlink" style={{ padding: '10px 0 0 5px', display: 'flex', height: '20px'}} onClick={()=>{pleaseRegisterDialogHandleOpen()}}>Доступно пользователям с оплаченным тарифом</Link>)
        else
        if(eqItem?.firms?.length > 0)
        {
            if (eqItem.allfirms == true)
            content = eqItem?.firms?.map(firmItem=><div><div onClick={()=>onFirmClick(firmItem.ID)} className={(firmItem.STATUS == 'ACTIVE')||(firmItem.STATUS == 'UNKNOWN')?classes.headitemvalueFirm:classes.headitemvalueFirmNotActive}>{firmItem.NAME}</div><div>{renderFirmDetail(firmItem.ID)}</div></div>)
            else
            content = eqItem?.firms?.filter(firmItem=>firmItem.STATUS=='ACTIVE'||firmItem.STATUS=='UNKNOWN')?.map(firmItem=><div><div onClick={()=>onFirmClick(firmItem.ID)} className={classes.headitemvalueFirm}>{firmItem.NAME}</div><div>{renderFirmDetail(firmItem.ID)}</div></div>)
        }
        
        {if ((eqItem?.firms?.filter(firmItem=>(firmItem.STATUS!='ACTIVE')&&(firmItem.STATUS!='UNKNOWN'))?.length>0))
            content.push(<div><span style={{cursor:'pointer', color:'#054C74', display:'block'}} onClick={()=>{
                dispatch(setEQ({... eqItem, allfirms:!eqItem.allfirms}))

            }}>
                {(isTariff)?
                <div>
                    <span style={{verticalAlign: 'middle'}}>{eqItem.allfirms?<KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}</span>
                    <span style={{borderBottom: '1px dashed'}}>{eqItem.allfirms?'Скрыть в банкротстве и ликвидированные' : 'Показать в банкротстве и ликвидированные'}</span>
                    </div>
                :null}</span></div>
                )
        }
        return <div>{content}</div>
    }    

    const renderFirmDetail = (firmId) => {
        var firmItem = firmDetails?.find(o => parseInt(o.id) == parseInt(firmId))
        if (firmItem?.content == 'loader')
            return <Loader type="linear"/>
        else
        if (firmItem?.content == null)
        return <div></div>
        else
        return <div style={{paddingLeft:'5px'}}>
            {(firmItem?.content?.status != '')&&(firmItem?.content?.status)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Статус:</span> <span style={(firmItem?.content?.status == "Действует")?{color:"darkgreen"}:(firmItem?.content?.status == "Ликвидирована")?{color:"darkred"}:null}>{firmItem?.content?.status}</span></div>:null}
            {(firmItem?.content?.date_update != '')&&(firmItem?.content?.date_update)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Дата обновления:</span> {firmItem?.content?.date_update}</div>:null}
            {(firmItem?.content?.innkpp != '')&&(firmItem?.content?.innkpp)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>ИНН/КПП:</span> {firmItem?.content?.innkpp}</div>:null}
            {(firmItem?.content?.ogrn != '')&&(firmItem?.content?.ogrn)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>ОГРН:</span> {firmItem?.content?.ogrn}</div>:null}
            {(firmItem?.content?.opfname != '')&&(firmItem?.content?.opfname)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Организационно-правовая форма:</span> {firmItem?.content?.opfname}</div>:null}
            {(firmItem?.content?.full != '')&&(firmItem?.content?.full)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Полное наименование:</span> {firmItem?.content?.full}</div>:null}
            {(firmItem?.content?.manager != '')&&(firmItem?.content?.manager)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Руководитель:</span> {firmItem?.content?.manager}</div>:null}
            {(firmItem?.content?.region != '')&&(firmItem?.content?.region)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Регион:</span> {firmItem?.content?.region}</div>:null}
            {(firmItem?.content?.addressline != '')&&(firmItem?.content?.addressline)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Адрес местонахождения:</span> {firmItem?.content?.addressline}</div>:null}
            {(firmItem?.content?.site != '')&&(firmItem?.content?.site)?<div style={{marginTop:"5px"}}><span className={classes.headitemname} style={{display:"contents"}}>Сайт:</span> <span className="breadcrumbslink" style={{cursor:'pointer'}} onClick={()=>window.open(firmItem?.content?.site,"_blank")}>{firmItem?.content?.site}</span></div>:null}
            </div>
    } 

    const loadSelectedFirm = (id) => {
        dispatch(loadFirm(id))
    }

    const onFirmClick = (id) => {

        
        setFirmDetails(firmDetails.map((o, i) => {
            if (o.id === id) {

                if ((o.content == null) && (firmLoader==false)) {
                    o.content = 'loader' 
                    loadSelectedFirm(id)
                }
                return o; // stop searching
            }
            else return o
        }))

        

    }
    
    const regions = () => {
        let content = []

        if(!isTariff)
        content.push(<Link to="#" className="link headerlink" style={{ padding: '10px 0 0 5px', display: 'flex', height: '20px'}} onClick={()=>{pleaseRegisterDialogHandleOpen()}}>Доступно пользователям с оплаченным тарифом</Link>)
        else
        if(eqItem?.regions?.length > 0)
            eqItem?.regions?.forEach( function callback (regionItem) {
                content.push(<div className={classes.headitemvalue} style={{paddingTop:'3px'}}>{regionItem.NAME}</div>)
            })

        return content
    }    

    let images = []
    let imagesView = []
    
    if (eqItem?.old > 0)
    for(let i = 0; i <= eqItem.old-1; i++) {
        images.push(<div><img src={baseUrl+'/pic/?eid='+eqItem?.eq?.ID+'&count='+i} /></div>)
        imagesView.push(baseUrl+'/pic/?eid='+eqItem?.eq?.ID+'&count='+i)
    }
    else
    if ((eqItem?.pics?.length > 0) && (imagesView.length == 0))
        eqItem.pics.forEach( function callback (picsItem) { 
            images.push(<div><img src={baseUrl+'/pic/?id='+picsItem} /></div>)
            imagesView.push(baseUrl+'/pic/?id='+picsItem)
        })

        else {
        images.push(<div><img src={baseUrl+'/pic/?eid='+eqItem?.eq?.ID} /></div>)
        imagesView.push(baseUrl+'/pic/?eid='+eqItem?.eq?.ID)
        }

        const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);
        
        const gotoNext = () =>
        currentImageIndex + 1 < showFullScreenImage.images.length &&
        setCurrentIndex(currentImageIndex + 1);
        
        const [currentImageIndex, setCurrentIndex] = useState(0);

        function scrollElement(id) {
            var element = document.getElementById(id);
            element.scrollIntoView();
           }        
           const firmsList = useRef(null)
           const regionsList = useRef(null)
           
        const setBookmark = (eqItem) => {
        
            setActBookmark(eqItem)
        }
        
        const setCompare = (eqItem) => {
        
            setActCompare(eqItem)
        }        
 
        

// Тут правим        
return  <div className={classes.paper}>
    <Helmet>
        <title>{eqpMeta.title}</title>
        <meta name="description" content={eqpMeta.description}/>
        <meta name="keywords" content={eqpMeta.keywords}/>
        <link href={"https://importzamena.ru/eq/"+id} rel="canonical"></link>
    </Helmet>
               {(showFullScreenImage.images != null)? <Lightbox

                    mainSrc={showFullScreenImage.images[currentImageIndex]}
                    nextSrc={showFullScreenImage.images[(currentImageIndex + 1) % showFullScreenImage.images.length]}
                    prevSrc={showFullScreenImage.images[(currentImageIndex + showFullScreenImage.images.length - 1) % showFullScreenImage.images.length]}
                    onCloseRequest={() => {setShowFullScreenImage({open:false, images:null, name:null}); setCurrentIndex(0)}}
                    isOpen={showFullScreenImage.open}
                    onMovePrevRequest={gotoPrevious}
                    onMoveNextRequest={gotoNext}
                    images={showFullScreenImage.images}
                    currentIndex={currentImageIndex}

                    />:null}
            <PleaseRegisterDialog
                open={pleaseRegisterDialogOpen}
                onClose={pleaseRegisterDialogHandleClose}
            />      
        <SearchBar/>
        {(eqItem)?        
        <div className={classes.EQMain}>
            <Breadcrumbs className={classes.Breadcrumbs} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {genBreadcrumb()}
            </Breadcrumbs>
        <div style={{display:'inline-table'}}>
        <div className={classes.eqName}>
            {
                (user?.role == 'operator')?
                <IconButton style={{padding:'0', top:'-2px'}} onClick={()=>window.open('/eqedit/'+eqItem?.eq?.ID,"_blank")}>
                <DesignServicesIcon style={{color:'green'}} />
                </IconButton>
                :
                null
            }

            </div>
            <div style={{ padding: '10px 10px 10px 0', float:'left', top: '0', right:'0', width:'23px'}}>
            <Bookmark onClick={()=>setBookmark(eqItem)}  status={eqItem.bookmark} action={pleaseRegisterDialogHandleOpen}/>
            <Compare onClick={()=>setCompare(eqItem)}  status={eqItem.compare} action={pleaseRegisterDialogHandleOpen}/>

                
            </div>

            <div className={classes.eqName}>{eqItem.eq.NAME} {eqItem.eq.SHIFR}</div>
        </div>
        <div>
            <div className={classes.pic}>
            <Carousel className={classes.carousel} showThumbs={false} dynamicHeight={false} centerMode={true} showStatus={false} onClickItem={()=>{setShowFullScreenImage({open:true, images:imagesView, name:eqItem.eq.NAME+' '+eqItem.eq.SHIFR})}}>
                    {images}

                </Carousel>
            </div>
            <div>
                <div style={{display: 'inline-block'}}>

                    <AnalogMenu searchResultOutItem={eqItem} type="all" isTariff={isTariff} gridFormat={false} clickAction={()=>{if(!isTariff)pleaseRegisterDialogHandleOpen()}}/>
                    {eqItem.ruanalogs != -1 ? <AnalogMenu searchResultOutItem={eqItem} type="ru" isTariff={isTariff} gridFormat={false} clickAction={()=>{if(!isTariff)pleaseRegisterDialogHandleOpen()}}/>:''}
                    
                    

                </div>
                <div style={{display: 'flex', }}><Link to="#firms" className="link headerlink" style={{ padding: '10px 0 0 5px', display: 'flex', height: '20px'}} onClick={()=>{if(!isTariff)pleaseRegisterDialogHandleOpen(); else firmsList.current.scrollIntoView()}}>Изготовители: {(eqItem?.firms?.length)?eqItem?.firms?.length:'0'}</Link></div>
                <div style={{display: 'flex', }}><Link to="#regions" className="link headerlink" style={{ padding: '0 0 0px 5px', display: 'flex', height: '20px'}} onClick={()=>{if(!isTariff)pleaseRegisterDialogHandleOpen(); else regionsList.current.scrollIntoView()}}>Регионы: {(eqItem?.regions?.length)?eqItem?.regions?.length:'0'}</Link></div>
                <div className={classes.headitemname}>Шифр</div>
                <div className={classes.headitemvalue}>{eqItem.eq?.SHIFR} </div>
                <div className={classes.headitemname}>Наименование</div>
                <div className={classes.headitemvalue}>{eqItem.eq?.NAME}</div>
                <div>
                <div className={classes.headitemname}>Отраслевой классификатор</div>
                {ok()}
                <div className={classes.headitemname} style={{clear:'both'}}>Технические характеристики</div>
                {descriptions()}
                {(eqItem.eq?.COMMENT != null)?
                <div>
                    <div className={classes.headitemname}>Описание</div>
                    <div className={classes.headitemvalue} style={{whiteSpace:'pre-wrap'}}>{eqItem.eq?.COMMENT?.toString()?.trim()} </div>
                </div>                    
                :null}
                <div className={classes.headitemname} ref={firmsList}>Изготовители - {(eqItem?.firms?.length)?eqItem?.firms?.length:'0'}</div>
                {firms()}
                <div className={classes.headitemname} ref={regionsList}>Регионы - {(eqItem?.regions?.length)?eqItem?.regions?.length:'0'}</div>
                {regions()}
                <div style={{height:'50px'}}></div>
                </div>
            </div>
        </div>
        </div>:
        <div style={{padding:'100px'}}><Loader/></div> }

        </div>

}