import { KEEPERAPI } from "../../api/api";
import { setLoader } from "./user-profile-personal-reducer";

const ORDER = "keeperReducer/ORDER";
const KEEPER_LOAD = "keeperReducer/REGIONS_LOAD";
const ERROR_LOAD = "keeperReducer/ERROR_LOAD";


const initialState = {
    order: null,
    loading: true,
    error: null,
  };


  export const keeperReducer = (state = initialState, action) => {
    switch (action.type) {
        case KEEPER_LOAD:
        return {
          ...state,
          loading: action.loading,
        };
        case ORDER:
        return {
          ...state,
          order: action.order,
        };
        case ERROR_LOAD:
        return {
            ...state,
            loading: false,
            error: "Что-то пошло не так... Попробуйте перезагрузить страницу",
        };        
        default:
            return state;
    }
};


export const getOrder = (state) => state.keeperReducer.order; 
export const getOrderLoader = (state) => state.keeperReducer.loading; 
export const errorLoad = () => ({ type: ERROR_LOAD });  

export const setOrder = (order) => ({ type: ORDER, order});  
export const setOrderLoader = (loading) => ({ type: KEEPER_LOAD, loading});  

export const loadOrder = (id, tariff = null) => async (dispatch) => {

    let response = null
    if (id == 'new')
      response = await KEEPERAPI.createOrder(tariff);
    else
      response = await KEEPERAPI.loadOrder(id);

    if (response.status === 200 && response.data.length !== 0) {

      const new_data = response.data;
      dispatch(setOrder(new_data));
      dispatch(setOrderLoader(false))

    } else {
      dispatch(errorLoad());
    }

  };

