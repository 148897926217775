import {RouteNames} from "./consts";
import {Navigate} from "react-router-dom";
import {LoginPage} from "../pages/LoginPage";
import {RegistrationPage} from "../pages/RegistrationPage";
import {HomePage} from "../pages/HomePage";
import {NotFoundPage} from "../pages/NotFoundPage";
import {AboutPage} from "../pages/AboutPage";
import {ContactsPage} from "../pages/ContactsPage";
import {PolicyPage} from "../pages/PolicyPage";
import {AdvancedSearchPage} from "../pages/AdvancedSearchPage";
import {RecoveryPage} from "../pages/RecoveryPage";
import {UserProfilePage} from "../pages/UserProfilePage";
import {FavoritePage} from "../pages/FavoritePage";
import {ComparePage} from "../pages/ComparePage";
import {ActivationPage} from "../pages/ActivationPage";
import {SearchPage} from "../pages/SearchPage";
import {EQPage} from "../pages/EQPage";
import {EQPEditPage} from "../pages/EQPEditPage";
import {OperatorPage} from "../pages/OperatorPage";
import { FirmEditPage } from "../pages/FirmEditPage";
import { TariffsPage } from "../pages/TariffsPage";
import { PayOrderPage } from "../pages/PayOrderPage";
import { ParseStudyPage } from "../pages/ParseStudyPage";
import { OfertaPage } from "../pages/OfertaPage";
import { PMPage } from "../pages/PMPage";
import { HelpPage } from "../pages/HelpPage";

export const publicRoutes = [
    {element: <LoginPage/>, path: RouteNames.LOGIN, exact: true},
    {element: <RegistrationPage/>, path: RouteNames.REGISTRATION, exact: true},
    {element: <HomePage/>, path: RouteNames.HOME, exact: true},
    {element: <AboutPage/>, path: RouteNames.ABOUT, exact: true},
    {element: <ContactsPage/>, path: RouteNames.CONTACTS, exact: true},
    {element: <PolicyPage/>, path: RouteNames.POLICY, exact: true},
    {element: <AdvancedSearchPage/>, path: RouteNames.ADVANCED, exact: true},
    {element: <RecoveryPage/>, path: RouteNames.RECOVERY, exact: true},
    {element: <ActivationPage/>, path: RouteNames.ACTIVATE, exact: true},
    {element: <SearchPage/>, path: RouteNames.SEARCH, exact: true},
    {element: <SearchPage/>, path: RouteNames.OSEARCH, exact: true},
    {element: <EQPage/>, path: RouteNames.EQ, exact: true},
    {element: <TariffsPage/>, path: RouteNames.TARIFFS, exact: true},
    {element: <OfertaPage/>, path: RouteNames.OFERTA, exact: true},
    {element: <PMPage/>, path: RouteNames.PM, exact: true},
    {element: <HelpPage/>, path: RouteNames.HELP, exact: true},
    
    
//    {element: <Navigate to={'/'}/>, path: RouteNames.USERPROFILE, exact: true},

]
export const privateRoutes = [ 
    {element: <UserProfilePage/>, path: RouteNames.USERPROFILE, exact: true},
    {element: <FavoritePage/>, path: RouteNames.FAVORITE, exact: true},
    {element: <ComparePage/>, path: RouteNames.COMPARE, exact: true},        
    {element: <ActivationPage/>, path: RouteNames.ACTIVATE, exact: true},            
    {element: <PayOrderPage/>, path: RouteNames.PAYORDER, exact: true},            
    
]

export const adminRoutes = [
    {element: <OperatorPage/>, path: RouteNames.OPERATOR, exact: true},
    {element: <EQPEditPage/>, path: RouteNames.EQEDIT, exact: true},
    {element: <FirmEditPage/>, path: RouteNames.FIRMEDIT, exact: true},
    {element: <ParseStudyPage/>, path: RouteNames.PARSESTUDY, exact: true},

]

export const notFoundRoute = {
    element: <NotFoundPage/>, path: RouteNames.NOT_FOUND, exact: true
}
 
