import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";  

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { getOk, loadOk } from "../../redux/reducers/ok-reducer";
import {OkPanel} from "../../containers/OkPanel/OkPanel";
import {RegionsPanel} from "../../containers/RegionsPanel/RegionsPanel";
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import {useDialog} from "../../hooks/useDialog.js";
import Grid from "@material-ui/core/Grid";
import {loadManufactures, getManufactures, getRegions, setManufactures, setRegions, loadTechSpec, 
    getTechSpec, setTechSpec, getSearchResult, setSearchResult, operatorEQPSearch, getLoader, setLoader} from "../../redux/reducers/advancedsearch-reducer";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "../Loader/Loader.jsx";
import { CompareTable } from "../CompareTable/CompareTable";
import {GridCellExpand} from "../../components/GridCellExpand/GridCellExpand.jsx";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({


    SearchManufacture: {
        marginTop: '20px',
        border: '1px solid #868686',
        
        width: '250px',
        height: '40px',
        borderRadius: '5px 5px 5px 5px',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        paddingLeft: '10px',
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '350px',
              borderRight: '2px solid #868686',
              borderRadius: '5px',
          },
          ['@media (max-width:360px)']: { 
            width: '300px',
          }
      },
    
    manufactureItem: { 
        cursor:'pointer', 
        color:'#054C74',
        borderBottom: '1px dashed', 
        lineHeight: '25px',
    },  

    mainDialog: {
        "& div.MuiPaper-root": {
            margin: '5px',
            //height: 'calc(100% - 5px)',
            minWidth: '280px'
            
          },
        "& .MuiTypography-root": {
          padding: '5px',
          margin: 'auto'      
        },
        "& .MuiDialogContent-root": {
            padding: '0',
            
        },
        "& .MuiTreeItem-label": {
            color: '#1A1A1A'
        }

    },
    orderMenu: { minWidth:'100px',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    }, 
    AdvancedSearchArea: {
        paddingTop: '10px',
        maxWidth: '1280px',
        display: 'block',
        ['@media (max-width:900px)']: { 
            width: 'auto',
          } ,
        ['@media (max-width:520px)']: { 
              width: '100%',
              borderRadius: '5px',
          }       
    },

    advancedSearchValue: {
        marginBottom:'10px', 
        display: 'flow-root',
['@media (max-width:767px)']: { 
    width: '350px',
  } ,
['@media (max-width:500px)']: { 
      width: '100%',

  }        
    },

    advancedSearchName: {
        marginRight: '10px', 
        float: 'left', 
        lineHeight: '40px',
        width: '250px',
        color: '#676767'
    },


    advancedSearchGroup: {
        padding: '5px 5px 0 5px'
    },
    advancedSearchTerms: {

        width: '-webkit-fill-available',
        height: '40px',
        borderRadius: '5px 5px 5px 5px',
        border: '1px solid #868686',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '100%',

          }  
      },



}))


export default function Operator() {
    const classes = useStyles()
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getLoggedIn)

    const [searchTerms, setSearchTerms] = useState({
        shifr: '',
        name: '',
        owner: 1,
        shifrDublicates: false,
        nameDublicates: false,
        noManufactures: false,
        noClassifier: false,
        noTechSpec: false,
        region: {name:'Любой', id:'any'},
        manufacture: {name:'Любой', id:'any'},
        techSpec: {name:'Любая', id:'any', value:null, operator:null, ei_id:null, ei_name:null},
        classifier: {name:'Любой', id:'any'},
    });

    const techSpecDefault = {name:'Любая', id:'any', value:null, operator:null, ei_id:null, ei_name:null}
    const [techSpecTmp, setTechSpecTmp] = useState({name:'Любая', id:'any', value:null, operator:null, ei_id:null, ei_name:null})

    const setShifr = (val) => {
        setSearchTerms ( {...searchTerms, shifr: val})
    }
    const setName = (val) => {
        setSearchTerms ( {...searchTerms, name: val})
    }    

//    const setSearchManufacture = (val) => {
//        setSearchTerms ( {...searchTerms, searchManufacture: val})
//    }    
//    const [shifr, setShifr] = useState('');
//    const [name, setName] = useState('');
//    const [owner, setOwner] = useState(1);
    const [searchManufacture, setSearchManufacture] = useState('')
    const setOwnerItem = (event, value) => {
        setSearchTerms ( {...searchTerms, owner: value.props.value})
        
    }


   // const [region, setRegion] = useState({name:'Любой', id:'any'});
    const setRegionItem = (id, name) => {
        setSearchTerms ( {...searchTerms, region: {name:name, id:id}})
        сloseDialogClassifier()
    }

//    const [manufacture, setManufacture] = useState({name:'Любой', id:'any'});
    const manufactures = useSelector(getManufactures)
    const techSpec = useSelector(getTechSpec)
    const searchResult = useSelector(getSearchResult)
    const LoaderSearchResult = useSelector(getLoader)
    

    useEffect(() => {
        if (manufactures == null)
        dispatch(loadManufactures());
    }, []);

    useEffect(() => {
        if (searchTerms.classifier.id != 'any')
        dispatch(loadTechSpec(searchTerms.classifier.id));
    }, [searchTerms.classifier]);

    useEffect(() => {
        if (techSpec != null) {
            
            setTechSpecTmp ( {...techSpecTmp, name:techSpec[0].btp_name, id:techSpec[0].btp_id, ei_id:techSpec[0].ei[0].ID, ei_name:techSpec[0].ei[0].NAME})  
        }
    }, [techSpec]);


    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()
 
    //const [classifier, setClassifier] = useState({name:'Любой', id:'any'});

    const setClassifierItem = (id, name) => {
        
        setSearchTerms ( {...searchTerms, classifier: {name:name, id:id}, techSpec: techSpecDefault})

        сloseDialogClassifier()
    }
    const [dialogClassifier, setDialogClassifier] = useState(null);
    const сloseDialogClassifier = () => {
        setDialogClassifier(null);
      };
    
    const ManufacturesPanel = (props) => {
        return (manufactures)? 
            manufactures.filter(el => el.NAME.toUpperCase().indexOf(searchManufacture.toUpperCase().trim()) != -1).map((option) => (
            <div style={{paddingLeft: '5px'}} onClick={()=>{setSearchTerms( {...searchTerms, manufacture: {id:option.ID, name:option.NAME}});сloseDialogClassifier()}}> <span className={classes.manufactureItem}>{option.NAME}</span></div>
            ))
            :null
        
    }

    const setTechSpec =  (event, value) => {
        setTechSpecTmp ({...techSpecTmp, name:value.props.children, id:value.props.value})  
    }

    const setOperator =  (event, value) => {
        setTechSpecTmp ({...techSpecTmp, operator:value.props.value})  
    }    

    const setEi =  (event, value) => {
        setTechSpecTmp ({...techSpecTmp, ei_id:value.props.value, ei_name:value.props.children})  
    }    

    const setValue =  (event, value) => {
        setTechSpecTmp ({...techSpecTmp, value:event.target.value})  
    }     

    const techSpecOk =  () => {
        setSearchTerms ( {...searchTerms, techSpec: techSpecTmp})
        setDialogClassifier(null)
    }     

    const TechSpecPanel = (props) => {

        const [tsValue, setTsValue] = useState(techSpecTmp.value)

        return (techSpec)? 
        
            
            <div style={{paddingLeft: '5px', width: '550px'}} 
            //onClick={()=>{setSearchTerms( {...searchTerms, manufacture: {id:option.ID, name:option.NAME}});сloseDialogClassifier()}}
            > 
            <div style={{ display:'flex'}}>
            <TextField
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-techSpec"
                    select
                    label="Техническая характеристика"
                    defaultValue={((techSpecTmp.id == 'any') && (techSpec != null))? techSpec[0].btp_id:techSpecTmp.id}
                    value={techSpecTmp.id}
                    onChange={setTechSpec}
                    >
                    {techSpec?.map((option) => (
                        <MenuItem key={option.btp_id} value={option.btp_id}>
                        {option.btp_name}
                        </MenuItem>
                    ))}
                    </TextField>

                    <TextField
                    style={{paddingLeft:'10px', width:'80px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-Operator"
                    select
                    label="Оператор"
                    defaultValue={(techSpecTmp.operator == null)?'=':techSpecTmp.operator}
                    value={techSpecTmp.operator}
                    onChange={setOperator}
                    >
                        <MenuItem key='equal' value="=">=</MenuItem>
                        <MenuItem key='less' value="<">&lt;</MenuItem>
                        <MenuItem key='more' value=">">&gt;</MenuItem>
                    </TextField>       

                    <TextField
                    style={{paddingLeft:'10px', width:'80px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    key={"standard-techSpec-value"}
                    label="Значение"
                    value={tsValue}
                    onChange={(e)=>setTsValue(e.target.value)}
                    onBlur={(e)=> setTechSpecTmp ({...techSpecTmp, value:e.target.value})  }
                    onKeyDown={(e) => {
                    }}
                    >
                    </TextField>      


                    <TextField
                    style={{paddingLeft:'10px', width:'120px'}}
                    size="small"
                    //className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-Ei"
                    select
                    label="Единица измерения"
                    defaultValue={techSpecTmp.ei_id}
                    value={techSpecTmp.ei_id}
                    onChange={setEi}
                    >
                    {techSpec?.find(element => element.btp_id == techSpecTmp.id)?.ei.map((option) => (
                        <MenuItem key={option.ID} value={option.ID}>
                        {option.NAME}
                        </MenuItem>
                    ))}
                    </TextField>           
                    </div>
                    <Button 
                                variant="contained" 
                                
                                //disabled={codeLoader}
                                onClick={techSpecOk}
                                size={'large'}
                                style={{margin: '20px 0 0 10px'}}
                                //className={classes.button}
                                >
                                    Ok
                            </Button>                                        
                            
            </div>
            
            :null
        
    }

    const submit = () => {
        dispatch(setLoader(true))
        dispatch(operatorEQPSearch(searchTerms));
        //window.open('/eqpsearch/advanced/?aname='+searchTerms.name+'&ashifr='+searchTerms.shifr+'&aok='+searchTerms.classifier.id+'&acompany='+searchTerms.manufacture.id+'&aregion='+searchTerms.region.id,"_self");
    }

    const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              href = {'/eqedit/'+params.id}
          />
        )
      }
    
    const genColumns = () => {
        let compareColumns = [
            { 
            field: "id", 
            headerName: "ID", 
            minWidth: 180, flex: 1, 
//            renderCell: RenderDescription
            }, 
            { 
            field: "eqname", 
            headerName: "Наименование", 
            minWidth: 180, flex: 1, 
            renderCell: RenderDescription
            }, { 
            field: "shifr", 
            headerName: "Шифр", 
            width: 150, flex: 1, 
            renderCell:RenderDescription, 
            }]

        return compareColumns
    }

    const genRows = () => {
        let rowData = []
        searchResult?.forEach( function callback (searchResultOutItem) {
            let tmpRow = {id:searchResultOutItem.ID,eqname:searchResultOutItem.NAME,shifr:searchResultOutItem.SHIFR}
            rowData.push(tmpRow)
        })
        return rowData

    }

    return (
        <div className={classes.AdvancedSearchArea}>
            <PleaseRegisterDialog
                open={pleaseRegisterDialogOpen}
                onClose={pleaseRegisterDialogHandleClose}
            />      
            
            <Dialog
            className={classes.mainDialog}
        open={(dialogClassifier == null)?false:true}
        onClose={сloseDialogClassifier}
        handleClose={сloseDialogClassifier}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
      >
        <DialogTitle id="scroll-dialog-title">
        
        {(dialogClassifier == 'ok')?
        <TextField
                    size="small"
                    className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-sort"
                    select
                    label="Тип оборудования"
                    defaultValue="1"
                    value={searchTerms.owner}
                    onChange={setOwnerItem}
                    >
                    
                    <MenuItem key="production" value="1">Оборудование для добычи нефти и газа</MenuItem>
                    <MenuItem key="drilling" value="2">Буровое оборудование</MenuItem>
        </TextField>
        :(dialogClassifier == 'manufacture')?
        <input 
            type="text" 
            //onKeyDown={(e) => { if (e.key === 'Enter') search()}} 
            autocomplete="off" 
            onChange={e=>setSearchManufacture(e.target.value)} 
            className={classes.SearchManufacture} 
            id="search-manufacture" 
            placeholder="Найти производителя" 
            value={searchTerms.searchManufacture}
        />
        :(dialogClassifier == 'region')?
        <h2>Регионы</h2>
        :(dialogClassifier == 'techSpec')?
        <h2>Техническая характеристика</h2>
 
        :null
        }
        </DialogTitle>
        <DialogContent dividers="paper">
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          >

            {(dialogClassifier == 'ok')?
            <OkPanel owner={searchTerms.owner} action={setClassifierItem}/>
            :(dialogClassifier == 'manufacture')?
            <ManufacturesPanel manufactures={manufactures}/>
            :(dialogClassifier == 'region')?
            <RegionsPanel  action={setRegionItem}/>
            :(dialogClassifier == 'techSpec')?
            <TechSpecPanel />
            :null
            }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={сloseDialogClassifier}>Закрыть</Button>
        </DialogActions>
      </Dialog>            
            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName}>Шифр оборудования</div>
                <div className={classes.advancedSearchValue}>
                    <div style={{display:"flex"}}>
                        <input 
                        type="text" 
                        autocomplete="off" 
                        onChange={(e)=>setShifr(e.target.value)} 
                        //onKeyDown={(e) => { if (e.key === 'Enter') search()}}  
                        className={classes.advancedSearchTerms} 
                        //id="search-terms" 
                        //placeholder="Наименование или шифр оборудования" 
                        value={searchTerms.shifr}
                        />
                        <div  style={{width:"-webkit-fill-available"}} >
                            <Checkbox checked={searchTerms.shifrDublicates} onClick={() =>setSearchTerms ( {...searchTerms, shifrDublicates: !searchTerms.shifrDublicates})} color="#054C74"/>Дубли по шифру
                        </div>
                    </div>
                </div>
                
            </div>

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName}>Наименование оборудования</div>
                <div className={classes.advancedSearchValue}>
                    <div style={{display:"flex"}}>
                        <input 
                            type="text" 
                            autocomplete="off" 
                            onChange={(e)=>setName(e.target.value)} 
                            //onKeyDown={(e) => { if (e.key === 'Enter') search()}}  
                            className={classes.advancedSearchTerms} 
                            //id="search-terms" 
                            //placeholder="Наименование или шифр оборудования" 
                            value={searchTerms.name}
                        />
                        <div  style={{width:"-webkit-fill-available"}} >
                            <Checkbox checked={searchTerms.nameDublicates} onClick={() =>setSearchTerms ( {...searchTerms, nameDublicates: !searchTerms.nameDublicates})} color="#054C74"/>Дубли по наименованию
                        </div>
                    </div>

                </div>
                
            </div>

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Тип оборудования</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>
                        <div >{searchTerms.classifier.name} {searchTerms.classifier.id != 'any'? 
                                    <IconButton style={{padding:'0'}} onClick={()=>        setSearchTerms ( {...searchTerms, classifier: {name:'Любой', id:'any'}})}>
                                    <CloseIcon />
                                </IconButton> 
                                :null   
                            }</div> 
                        <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline'}} onClick={() => setDialogClassifier('ok')}>Указать тип оборудования</div>
                    </div>                
            </div>

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Изготовитель</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>
                        <div >{searchTerms.manufacture.name} {searchTerms.manufacture.id != 'any'? 
                                    <IconButton style={{padding:'0'}} onClick={()=>setSearchTerms( {...searchTerms, manufacture: {name:'Любой', id:'any'}})}>
                                    <CloseIcon />
                                </IconButton> 
                                :null   
                            }</div> 
                        <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline'}} onClick={() => (!isLoggedIn)? pleaseRegisterDialogHandleOpen():setDialogClassifier('manufacture')}>Указать изготовителя</div>
                    </div>                
            </div>            

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Регион</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>
                        <div >{searchTerms.region.name} {searchTerms.region.id != 'any'? 
                                    <IconButton style={{padding:'0'}} onClick={()=> setSearchTerms ( {...searchTerms, region: {name:'Любой', id:'any'}})}>
                                    <CloseIcon />
                                </IconButton> 
                                :null   
                            }</div> 
                        <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline'}} onClick={() => (!isLoggedIn)? pleaseRegisterDialogHandleOpen():setDialogClassifier('region')}>Указать регион</div>
                    </div>                
            </div>

            <div style={(searchTerms.classifier.id=='any')?{display:'none'}:null} className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Техническая характеристика</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>
                    

                        <div >{(searchTerms.techSpec.id != 'any')?searchTerms.techSpec.name+' '+searchTerms.techSpec.operator+' '+searchTerms.techSpec.value+' '+searchTerms.techSpec.ei_name:searchTerms.techSpec.name}  {searchTerms.techSpec.id != 'any'? 
                                    <IconButton style={{padding:'0'}} onClick={()=>setSearchTerms( {...searchTerms, techSpec: {name:'Любая', id:'any'}})}>
                                    <CloseIcon />
                                </IconButton> 
                                :null   
                            }</div> 
                        <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline'}} onClick={() => (!isLoggedIn)? pleaseRegisterDialogHandleOpen():setDialogClassifier('techSpec')}>Указать техническую характеристику</div>
                    </div>                
            </div>      

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Нет изготовителя</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>

                        <Checkbox checked={searchTerms.noManufactures} onClick={() =>setSearchTerms ( {...searchTerms, noManufactures: !searchTerms.noManufactures})} color="#054C74"/>
                    </div>                
            </div>     
            <div style={(searchTerms.classifier.id!='any')?{display:'none'}:null} className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Не указан тип оборудования</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>

                        <Checkbox checked={searchTerms.noClassifier} onClick={() =>setSearchTerms ( {...searchTerms, noClassifier: !searchTerms.noClassifier})}color="#054C74"/>
                    </div>                
            </div>
            <div className={classes.advancedSearchGroup} style={(searchTerms.classifier.id!='any')?null:{display:'none'}}>
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Нет основных тех. характеристик</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>

                        <Checkbox checked={searchTerms.noTechSpec} onClick={() =>setSearchTerms ( {...searchTerms, noTechSpec: !searchTerms.noTechSpec})} color="#054C74"/>
                    </div>                
            </div>                                                  
            <Grid container spacing={2} justifyContent="center">
                                        <Grid item>
                                            <Button variant="contained" 
                                            startIcon={<SearchIcon />}
                                                //disabled={submitLoader} 
                                                onClick={submit}  
                                                style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                                                size={'large'} 
                                                >
                                                Найти
                                            </Button>
                                            
                                        </Grid>                                        <Grid item>
                                            <Button variant="contained" 
                                                startIcon={<AddIcon />}
                                                //disabled={submitLoader} 
                                                onClick={()=>window.open('/eqedit/new/',"_blank")}
                                                style={{color: '#054C74', backgroundColor: '#fff', marginBottom:'30px'}}
                                                size={'large'} 
                                                >
                                                Добавить
                                            </Button>
                                            
                                        </Grid>
                                    </Grid>           

            {(LoaderSearchResult)?
            <div style={{margin: '40px auto 40px auto', display: 'table'}}><Loader/></div>
            :(searchResult?.length == 0)?
            <div>Ничего не найдено</div>
            :(searchResult)?
            <CompareTable  style={{height:'auto'}} data={genRows()} columns={genColumns()}/>
            :null}

        </div>
        
    )

}