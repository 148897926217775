import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import  Loader  from '../Loader/Loader.jsx';
import Container from '@mui/material/Container';
import Paper from "@material-ui/core/Paper";
import {useQuery} from "../../hooks/useQuery.js";
import {useRedirect} from "../../hooks/useRedirect.js";
import DatePicker from "../DatePicker/DatePicker";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Users from "../Users/Users.jsx";
import {getError, getRegistrationStat,setRegistrationStat, getStatLoader, loadRegistrationStat} from "../../redux/reducers/stat-reducer";
import { PieChart, Pie, Sector, Cell} from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const useStyles = makeStyles((theme) => ({
  customTooltip:{
    backgroundColor:'white',
    padding: '5px',
    border: 'solid 1px #969696'
  },
  PieChartStyle:{
    float:'left',display:'flex', height:'200px', alignItems:'center'
  },
  PieChartLegend: {
    fontSize:'40px', lineHeight:'0px', fontWeight:'bold'
  },
  datePickerPanel: {
    margin: "10px 0 0 10px",
  },
  datePicker: {
    width:'250px', 
    lineHeight:'40px',
    float:'left'
  },

  button: {
    color: '#054C74',
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: '#eeeeee',
        color: '#054C74',
    },        
},
}))


export default function StatisticsUser() {

    const query = useQuery()
    const {replace} = useRedirect()
    const classes = useStyles()
    const dispatch = useDispatch();

    const registrasion_stat  = useSelector(getRegistrationStat);    
    const error  = useSelector(getError);    
    const statLoader  = useSelector(getStatLoader);    
    

    const [refresh, setRefresh] = useState(false)

    const [filter, setFilter] = useState({startTime:null, endTime:null, details:'dayly'});
    const [userStatDiagramData, setUserStatDiagramData] = useState({pieData:null, barData:null})
    const COLORS = ['#0084AE', '#AE0054'];
    const rMonth = ['','Янв','Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек']

    const setFilterValue = ( key, value) => {
      const nextFilter = {...filter};
      nextFilter[key] = value
      
      setFilter(nextFilter)
    }  

    useEffect(() => {
      if (registrasion_stat == null)
        dispatch(loadRegistrationStat(filter))

  }, []);      

  useEffect(() => {
    if (registrasion_stat != null)
      genUserStatDiagramData()

}, [registrasion_stat]); 

  useEffect(() => {
    
     if (refresh == true) {
        dispatch(setRegistrationStat(null))
        dispatch(loadRegistrationStat(filter))
        setRefresh(false)
     }
     

}, [refresh]);        


const genUserStatDiagramData = () => {
  const pieData = [
      {
        name:"Физические лица",
        value: registrasion_stat?.registrations_pie?.individual_count
      },
      {
        name:"Юридические лица",
        value: registrasion_stat?.registrations_pie?.entity_count
      }
    ]
    let barData = []
    registrasion_stat?.registrations_count?.forEach( (barDataItem) => {
      barData.push({name:(filter.details=='dayly')?barDataItem?.created_day?.slice(0, 10):barDataItem?.created_day?.slice(5, 7)+'.'+barDataItem?.created_day?.slice(0, 4), entity:barDataItem?.entity_count, individual:barDataItem?.individual_count})
    })   

    setUserStatDiagramData({... userStatDiagramData, pieData:pieData, barData:barData})
}


 
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className={classes.customTooltip}>
            <p >
                {`${label}`} <br/> 
                Физические: {`${payload[0].value}`} <br/> 
                Юридические: {`${payload[1].value}`}
            </p>
          </div>
        );
      }

      return null;
    };   
   
    return (
      
      <div>
        <div className={classes.datePickerPanel}>
          <div className={classes.datePicker}>
            {"C "}
            <DatePicker
              time={filter.startTime}
              handleChange={(value) => setFilterValue('startTime', value) }
            />
          </div>
          <div className={classes.datePicker}>
            {"ПО "}
            <DatePicker 
              time={filter.endTime} 
              handleChange={(value) => setFilterValue('endTime', value) }
            />
          </div>
          <div className={classes.datePicker}>
            <Button variant="contained" 
              //disabled={stepLoader}  
              size={'sdmall'} 
              onClick={()=>setRefresh(true)}  
              className={classes.button}>
              Построить отчет
            </Button>
          </div>
          <div className={classes.datePicker} style={{float:'right'}}>
            <TextField
              size="small"
              sx={{width:'200px'}}
              className={classes.orderMenu}
              margin="dense"
              padding="none"
              id="standard-select-details"
              select
              label="Детализация"
              
              value={filter.details}
              onChange={(e)=>{setFilterValue('details', e.target.value)}}
            >
              <MenuItem key="dayly" value="dayly">По дням</MenuItem>
              <MenuItem key="mountly" value="mountly">По месяцам</MenuItem>
            </TextField>
          </div>
        </div>
        {(registrasion_stat)?
        <div style={{clear:'both', width:'100%', height:'300px', display:'flex'}}>
          <div>          
            <div style={{float:'left', display:'flex'}}>

                <div className={classes.PieChartStyle}><div><p className={classes.PieChartLegend} style={{color:COLORS[0]}}>{(parseInt(registrasion_stat?.registrations_pie?.individual_count)>0)?(parseInt(registrasion_stat?.registrations_pie?.individual_count)*100/(parseInt(registrasion_stat?.registrations_pie?.individual_count)+parseInt(registrasion_stat?.registrations_pie?.entity_count))).toFixed():0}%</p><p>Физические</p></div></div>
                <PieChart width={200} height={200} >
                    <Pie
                      data={userStatDiagramData?.pieData}
                      cx={100}
                      cy={100}
                      innerRadius={50}
                      outerRadius={90}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {userStatDiagramData?.pieData?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                      
                    </Pie>
                </PieChart>
                <div className={classes.PieChartStyle}><div style={{textAlign:'right'}}><p className={classes.PieChartLegend} style={{color:COLORS[1]}}>{(parseInt(registrasion_stat?.registrations_pie?.entity_count)>0)?(parseInt(registrasion_stat?.registrations_pie?.entity_count)*100/(parseInt(registrasion_stat?.registrations_pie?.individual_count)+parseInt(registrasion_stat?.registrations_pie?.entity_count))).toFixed():0}%</p><p>Юридические</p></div></div>
            </div>
            <div>
              <div style={{width:'50%', float:'left', textAlign:'center', color:COLORS[0], fontSize:'40px', fontWeight:'bold'}}>{parseInt(registrasion_stat?.registrations_pie?.individual_count)}</div>
              <div style={{width:'50%', float:'left', textAlign:'center', color:COLORS[1], fontSize:'40px', fontWeight:'bold'}}>{parseInt(registrasion_stat?.registrations_pie?.entity_count)}</div>
              <div style={{width:'100%', clear:'both', textAlign:'center', fontSize:'60px',  fontWeight:'bold'}}>{parseInt(registrasion_stat?.registrations_pie?.entity_count)+parseInt(registrasion_stat?.registrations_pie?.individual_count)}</div>
            </div>
          </div>
          <div style={{float:'left'}}>
            <BarChart
              width={1000}
              height={300}
              data={userStatDiagramData?.barData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                payload={
                  [
                    {id: 1, type: "square", value:'Физические лица', color: COLORS[0]},
                    {id: 0, type: "square", value:'Юридические лица', color: COLORS[1]}
                    
                  ]
                }
              />
              
              <Bar dataKey="individual" stackId="a" fill="#0084AE" />
              <Bar dataKey="entity" stackId="a" fill="#AE0054" />
            </BarChart>
          </div>
        </div>
        :null}
        <div style={{clear:'both'}}>
          {!refresh?
          <Users noAddUser={true}/>
          :
          <Users noAddUser={true} startTime={filter.startTime} endTime={filter.endTime} refresh={refresh} setRefresh={setRefresh}/>
        }
          
        </div>
      </div>
    )

}