import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({

    searchSkeleton: { width:'860px', 
    ['@media (max-width:860px)']: { 
        width: '-webkit-fill-available',
        display: 'inline-block',

    },   
    ['@media (max-width:500px)']: { 
        width: '-webkit-fill-available',
        display: 'inline-block',
    }
    },

    OkPanelSkeleton: { width:'400px', 
    ['@media (max-width:860px)']: { 
        width: '-webkit-fill-available',
        display: 'inline-block',

    },   
    ['@media (max-width:500px)']: { 
        width: '300px',
        display: 'inline-block',
    }
    },

}))

export function SearchSkeleton() {
    const classes = useStyles()

    const getSkeleton = () => {
        let content = []
        for (let i = 1; i < 9; i++) 
            content.push(
            <div className={classes.searchSkeleton} style={{float:'none',  minHeight:'185px',marginBottom: '20px', border: '1px solid #868686', borderRadius: '5px', margin:'10px 5px',}}>
                <div style={{float: 'left',paddingTop:'30px', paddingLeft:'20px', paddingRight:'20px', display:'flex', width: '80%'}}>
                    <Skeleton variant="rounded" width={160} height={125}/>
                    <div style={{float: 'left', width: 'inherit'}}>
                    <Skeleton variant="rounded" width="80%" height={30} style={{ marginLeft: '20px'}}/>
                    <Skeleton variant="rounded" width="70%" height={15} style={{marginTop: '20px', marginLeft: '20px'}}/>
                    <Skeleton variant="rounded" width="50%" height={15} style={{marginTop: '10px', marginLeft: '20px'}}/>
                    <Skeleton variant="rounded" width="30%" height={15} style={{marginTop: '10px', marginLeft: '20px'}}/>

                    </div>
                </div>
            </div>

            )
            return content;
        }

    return (
        <div>{getSkeleton()}</div>
    )
}

export function OkSkeleton() {
    const classes = useStyles()
    return (
        <div className={classes.searchSkeleton} style={{padding: '0 10px', margin:'auto'}}>
            <Skeleton variant="rectangular" width="50%" height={30} style={{marginTop: '10px'}}/>
            <Skeleton variant="rectangular" width="80%" height={40} style={{marginTop: '10px'}}/>
            <Skeleton variant="rectangular" width="90%" height={30} style={{marginTop: '10px'}}/>
        </div>
    )
}
export function OkPanelSkeleton() {
        const classes = useStyles()
        return (
            <div className={classes.OkPanelSkeleton} style={{padding: '0 10px 20px 10px', margin:'auto'}}>
                <Skeleton variant="rectangular" width="90%" height={25} style={{marginTop: '10px'}}/>
                <Skeleton variant="rectangular" width="94%" height={25} style={{marginTop: '10px'}}/>
                <Skeleton variant="rectangular" width="85%" height={25} style={{marginTop: '10px'}}/>
                <Skeleton variant="rectangular" width="82%" height={25} style={{marginTop: '10px'}}/>
                <Skeleton variant="rectangular" width="91%" height={25} style={{marginTop: '10px'}}/>
                <Skeleton variant="rectangular" width="93%" height={25} style={{marginTop: '10px'}}/>

                
            </div>
        )    
}