import React from 'react';
import {Notification} from "../../components/Notification/Notification";
import {useDispatch, useSelector} from "react-redux";
import {closeNotification, getNotification} from "../../redux/reducers/notification-reducer";

export const NotificationContainer = () => {
    const {
        open,
        message,
        severity
    } = useSelector(getNotification)

    const dispatch = useDispatch()

    const handleCloseNotification = () => {
        dispatch(closeNotification())
    }

    return <Notification open={open}
                         message={message}
                         severity={severity}
                         onClose={handleCloseNotification}/>
}