import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { deletePic, getError, getPic, getPicLoader, getPics, loadPic, loadPics, newPic, setError, setPic, setPics, updatePic } from "../../redux/reducers/pic-reducer";
import {useDispatch, useSelector} from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ConstructionIcon  from '@mui/icons-material/Construction';
import {OkPanelSkeleton} from "../../components/SearchSkeleton/SearchSkeleton.jsx";
import {GridCellExpand} from "../../components/GridCellExpand/GridCellExpand.jsx";
import { PicsTable } from "../../components/PicsTable/PicsTable";
import  Loader  from '../../components/Loader/Loader';
import {API_SERVER_VAL} from "../../config";
import {useQuery} from "../../hooks/useQuery";
import TextField from '@mui/material/TextField';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useRedirect} from "../../hooks/useRedirect";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const useStyles = makeStyles((theme) => ({
    paper: {
      flexDirection: 'column',

                alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
    orderMenu: { minWidth:'100px',
    width:'100%',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    },     
    headitemname : {
      display: 'flex', 
      paddingTop: '10px',
      paddingLeft: '5px',
      fontSize:'16px',
      
      color: '#929292'
  },
  avatar: {
    margin: '0',
    width: '150px',
    height: '150px',
    backgroundColor: '#fff',
    border: '1px solid #868686',
    padding:'25px',
    fontSize: 24,
    color: '#868686',        
    "&:hover": {
        cursor: 'pointer'
    },
  },
  
  formAreaAvatar: {
    float:'left',
    marginTop:'auto', 
    marginBottom:'auto'   , 
    ['@media (max-width:767px)']: { 
      float: 'none',
      marginTop:'unset', 
      marginBottom:'unset',
      display: 'table',
      margin: 'auto',
      }   
  },  

}))

const baseUrl = `${API_SERVER_VAL}/api/eqp`

export const PicEdit = (props) => {

    const query = useQuery()
    const id = query.get('id')
 
    const classes = useStyles()
    const { pics } = useSelector(getPics);    
    const picItem = useSelector(getPic)
    const picLoader = useSelector(getPicLoader)

    const error = useSelector(getError)
    const {replace} = useRedirect()

    const [dialogClassifier, setDialogClassifier] = useState(null);


    const dispatch = useDispatch();
    useEffect(() => {
        if((pics == null)&&(!picLoader))
        dispatch(loadPics());
      }, [pics]);        

      if ((id) &&(!picItem)) {
        dispatch(loadPic(id))
    }
    useEffect(() => {
    if ((id != null) &&(id != 'new'))
    if (id == null)
      dispatch(setPic(null))
    }, [id]);        

    useEffect(() => {
      if (picItem?.ID != null)
          replace('/operator/?tab=Reference&component=pics&id='+picItem?.ID)
      }, [picItem]);        
  
  
    const setPicValue = (name, value) => {
        let picItemCopy = {... picItem}
        picItemCopy[name] = value
        dispatch(setPic(picItemCopy))
    }      
    const сloseDialogClassifier = () => {
      setDialogClassifier(null);
    };
      const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              link = {'/operator/?tab=Reference&component=pics&id='+params.id}
          />
        )
      }      

      const genColumns = () => {
        let compareColumns = [
            { 
            field: "id", 
            headerName: "ID", 
            minWidth: 15, 
            
//            renderCell: RenderDescription
            }, 
            { 
            field: "picname", 
            headerName: "Наименование", 
            minWidth: 180, flex: 1, 
            renderCell: RenderDescription
            }, 
            { 
              field: "dt", 
              headerName: "Дата изменения", 
              width: 150, flex: 1, 
              renderCell:RenderDescription, 
            }, 
            { 
                field: "pict", 
                headerName: "Изображение", 
                width: 150, flex: 1, 
                renderCell: (params) => <img src={params.value} style={{maxWidth:'100px',maxHeight:'70px'}} />
                }]

        return compareColumns
    }

    const genRows = () => {
        let rowData = []
        pics?.forEach( function callback (picItem) {
            let tmpRow = {id:picItem.ID,picname:picItem.NAME,dt:picItem.DATATIME,pict:baseUrl+'/pic/?id='+picItem.ID} 
            rowData.push(tmpRow)
        })
        return rowData

    }      
      
    const handleChange = function loadFile(event) {
      if (event.target.files.length > 0) {
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]); 
          reader.onloadend = function() {
            var base64data = reader.result;                
            setPicValue('PIC', base64data)
          }        
          
      }
  };
 
  const closePicEdit = () => {
    dispatch(setPic(null))
    dispatch(setPics(null))
    replace('/operator/?tab=Reference&component=pics')
  }

  const deletePicEdit = () => {
    dispatch(setPic(null))
    dispatch(setPics(null))
    dispatch(deletePic(picItem))
    replace('/operator/?tab=Reference&component=pics')
  }


    return <div className={classes.paper}>  
        <Dialog
            className={(dialogClassifier != 'delete')?classes.mainDialog:null}
        open={(dialogClassifier == null)?false:true}
        onClose={сloseDialogClassifier}
        handleClose={сloseDialogClassifier}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
      >
        <DialogTitle id="scroll-dialog-title">
        
        {(dialogClassifier == 'delete')?
        <DialogContentText
          id="scroll-dialog-description"
          //ref={descriptionElementRef}
          tabIndex={-1}
        >
        Вы действительно хотите удалить «{picItem.NAME}»?

        </DialogContentText>
        :null
        }
        </DialogTitle>
        <DialogContent dividers="paper">
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          >
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button 
          onClick={сloseDialogClassifier}
          >Закрыть</Button>
        {dialogClassifier == 'delete'?
        <Button style={{color:'red'}} onClick={()=>{сloseDialogClassifier(); deletePicEdit()}}>Удалить</Button>
        :null}          
        </DialogActions>
        </Dialog>      

          {
          ((id)&&(picItem)&&(!picLoader))?<div style={{minWidth:'380px',maxWidth:'680px', margin:'auto'}}>
          {
          (picItem?.DATATIME)?
            <div>     
                <div className={classes.headitemname} style={{paddingBottom:'10px'}}>Изменен: {picItem?.DATATIME}</div>
            </div>          
          :<div>     
                <div className={classes.headitemname} style={{paddingBottom:'10px'}}>Новое изображение</div>
            </div>          

          }
          <div>
               <TextField
                       size="small"
                       className={classes.orderMenu}
                       style={{ minWidth:'380px'}}
                       margin="dense"
                       padding="none"
                       id="standard-shifr"
                       error={!!error?.picNAME}
                       helperText={error?.picNAME?.text}                       
                       label="Наименование"
                       value={picItem.NAME}
                       onChange={(e)=> setPicValue('NAME', e.target.value)}
                       >
                </TextField>
            </div> 
            <div className={classes.formAreaAvatar} style={{}}>
            <input type="file" 
            onChange={handleChange} 
            id="upload" accept="image/*" style={{display: "none"}}/>
            <label htmlFor="upload">
              <IconButton color="primary" aria-label="загрузка аватара" component="span">
                {(picItem?.PIC)?
                <img src={picItem?.PIC} style={{maxWidth:'300px', maxHeight:'300px'}}/>
                :
                <AddAPhotoIcon id="avatar"
                 style={{ fontSize: 150 }}
                 src={picItem?.PIC}
                  className={classes.avatar}/>
                }
              </IconButton>
            </label>
            <label htmlFor="avatar"/>
           </div>
           <Grid container spacing={2} justifyContent="center" style={{padding:"20px 0"}}>
                    <Grid item>
                        <Button variant="contained" 
                            //disabled={submitLoader} ]
                            onClick={() => {


                                //setFirmLoaded(false)
                                //dispatch(setGeo(null))                        
                                
                                if (id == 'new') {
                                    dispatch(newPic(picItem))
                                    
                                }
                                else if (id != null){
                                    
                                    dispatch(updatePic(picItem))
                                }
                                    
                            }}  
                            style={{color: '#fff', backgroundColor: 'darkgreen', marginBottom:'30px'}}
                            size={'large'} 
                        >
                            Сохранить
                        </Button>
                    </Grid>

                    <Grid item>
                        {(picItem?.ID)?<Button 
                        style={{color: '#fff', backgroundColor: 'darkred', marginBottom:'30px'}}
                        size={'large'}
                        onClick={()=>setDialogClassifier('delete')}
                        >Удалить</Button>:null}
                    </Grid>
                    <Grid item>
                        <Button 
                        style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                        size={'large'} 
                        onClick={()=>closePicEdit()}
                        >Закрыть</Button>                    
                    </Grid>                    
            </Grid>              

          </div>  
          :((pics)&&(!id)&&(!picLoader))?<div >
          <PicsTable style={{height:'auto'}} add={true} data={genRows()} columns={genColumns()} action={props.action}/>
            </div>
          :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table'}}><Loader/></div>} 
          </div>
}