import React, { createRef, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getPics, loadPics } from "../../redux/reducers/pic-reducer";
import {useDispatch, useSelector} from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ConstructionIcon  from '@mui/icons-material/Construction';
import {OkPanelSkeleton} from "../../components/SearchSkeleton/SearchSkeleton.jsx";
import {GridCellExpand} from "../../components/GridCellExpand/GridCellExpand.jsx";
import { PicsTable } from "../../components/PicsTable/PicsTable";
import  Loader  from '../../components/Loader/Loader';
import {API_SERVER_VAL} from "../../config";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
}))

const baseUrl = `${API_SERVER_VAL}/api/eqp`

export const PicPanel = (props) => {

    const classes = useStyles()
    const { pics } = useSelector(getPics);    


    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(loadPics());
      }, []);        


      const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              href={params.value ? params.value.toString() : ''}
              clickHandle = {props.action}
          />
        )
      }      

      const genColumns = () => {
        let compareColumns = [
            { 
            field: "id", 
            headerName: "ID", 
            minWidth: 15, 
            
//            renderCell: RenderDescription
            }, 
            { 
            field: "picname", 
            headerName: "Наименование", 
            minWidth: 180, flex: 1, 
            renderCell: RenderDescription
            }, 
            { 
              field: "dt", 
              headerName: "Дата изменения", 
              width: 150, flex: 1, 
              renderCell:RenderDescription, 
            }, 
            { 
                field: "pict", 
                headerName: "Изображение", 
                width: 150, flex: 1, 
                renderCell: (params) => <img src={params.value} style={{maxWidth:'100px',maxHeight:'70px'}} />
                }]

        return compareColumns
    }

    const genRows = () => {
        let rowData = []
        pics?.forEach( function callback (picItem) {
            let tmpRow = {id:picItem.ID,picname:picItem.NAME,dt:picItem.DATATIME,pict:baseUrl+'/pic/?id='+picItem.ID} 
            rowData.push(tmpRow)
        })
        return rowData

    }      
      
    return  (pics)?<div >
          <PicsTable style={{height:'auto'}} data={genRows()} columns={genColumns()} action={props.action}/>
            </div>
          :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table'}}><Loader/></div> 
}