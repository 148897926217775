import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function Loader({type}) {

  if (!type || type == "circle")
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
    else
    return (
      <Box sx={{ width: '20%', padding:'10px' }}>
        <LinearProgress />
      </Box>
    );

  }