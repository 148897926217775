import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import {getFirms, setFirms, loadAllFirms} from "../../redux/reducers/firm-reducer";
import  Loader  from '../../components/Loader/Loader';
import { FirmsTable } from "../../components/FirmsTable/FirmsTable";
import {GridCellExpand} from "../../components/GridCellExpand/GridCellExpand.jsx";


const useStyles = makeStyles((theme) => ({

  
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },

}))


export default function Firms() {
    const classes = useStyles()
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getLoggedIn)
    const  firms  = useSelector(getFirms);    

    useEffect(() => {

        dispatch(loadAllFirms());
      }, []);        

      const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              href = {'/firmedit/'+params.id}
             // clickHandle = {props.action}
          />
        )
      }        

      const genColumns = () => {
        let compareColumns = [
            { 
            field: "id", 
            headerName: "ID", 
            minWidth: 15, 
            
//            renderCell: RenderDescription
            }, 
            { 
            field: "NAME", 
            headerName: "Наименование", 
            minWidth: 300, flex: 1, 
            renderCell: RenderDescription
            }, 
            { 
              field: "inn", 
              headerName: "ИНН", 
              width: 100, flex: 1, 
              renderCell:RenderDescription, 
            }, 
            { 
                field: "kpp", 
                headerName: "КПП", 
                width: 100, flex: 1, 
                renderCell:RenderDescription, 
              }, 
              { 
                field: "status", 
                headerName: "Статус", 
                width: 100, flex: 1, 
                renderCell:RenderDescription, 
              },, 
              { 
                field: "parsesettings", 
                headerName: "Парсинг сайта", 
                width: 100, flex: 1, 
                renderCell:RenderDescription, 
              },                  
            ]

        return compareColumns
    }      

    const genRows = () => {
        let rowData = []
        firms?.forEach( function callback (firmItem) {
            let tmpRow = {id:firmItem.ID,NAME:firmItem.NAME,inn:firmItem.inn,kpp:firmItem.kpp,status:
            (firmItem.status == "ACTIVE")? "Действует"
            :(firmItem.status == "LIQUIDATING")?"Ликвидируется"
            :(firmItem.status == "LIQUIDATED")?"Ликвидирована"
            :(firmItem.status == "BANKRUPT")?"Банкротство"
            :(firmItem.status == "REORGANIZING")?"Реорганизация"
            :"Не известно", parsesettings:(firmItem.parsesettings!="")?"Настроен":"Не настроен"}
            rowData.push(tmpRow)
        })
        return rowData

    }  
        
    return (
        (firms)?<div >
            
            <FirmsTable style={{height:'auto'}} data={genRows()} columns={genColumns()} 
            //action={props.action}
            />

        </div>
        :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table'}}><Loader/></div>         
    )

}