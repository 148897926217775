import React from "react";

import MuiContainer from "@material-ui/core/Container";
import { NotificationContainer } from "../../containers/NotificationContainer/NotificationContainer";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
//          marginTop: theme.mixins.toolbar.minHeight,
//        marginLeft: theme.spacing(7) + 1,
//        padding: theme.spacing(3),
//        height: "calc(100vh - 64px)",
paddingTop: '40px',

    },
    container: {
        display: 'flex',
        height: "100%",
    },
    chartContainer: {
        paddingRight: "10px",
        minWidth: "100%",
    },
}));

export const Container = ({ children, ...otherProps }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <MuiContainer
                className={
                    otherProps.chart ? classes.chartContainer : classes.container
                }
                component={"main"}
                {...otherProps}
            >
                {children}
            </MuiContainer>
            <NotificationContainer />
        </div>
    );
};