import React, {useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {updatePassword, getError, getLoader, setLoader, getMessage} from "../../redux/reducers/user-profile-personal-reducer";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loader from "../Loader/Loader.jsx";


const useStyles = makeStyles((theme) => ({
  changepasswordArea: {
      paddingTop: '10px',
  },
  formlineLeftItem: {
    float:'left', 
    minWidth:'150px', 
    textAlign:'right', 
    paddingRight: '10px', 
    lineHeight:'85px'
  },
  formlineRightItem: {
    float:"left", 
    width: "350px"
  },
  formArea: {
    float:'left',
    ['@media (max-width:767px)']: { 
      float: 'none',
      }   
  },
  personalArea: {
      paddingTop: '10px',
      display: 'flex',
      ['@media (max-width:767px)']: { 
        display: 'block',
        }         
  },
  button: {
    color: '#fff',
    backgroundColor: '#054C74',
    '&:hover': {
        backgroundColor: '#E6E6E6',
        color: '#1A1A1A',
    },        
  },     

}))

export default function ChangepasswordProfile() {

  const dispatch = useDispatch()
  const classes = useStyles()

  const error = useSelector(getError)
  const saveLoader = useSelector(getLoader)
  const message = useSelector(getMessage)

  const [old_password, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [buttonVisible, setButtonVisible] = useState(true);


  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);

  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);

  const onOldPasswordChange = e => {
    setOldPassword(e.target.value)
  }

  const onPasswordChange = e => {
    setPassword(e.target.value)
  }  

  const changePassword = () => {
    dispatch(setLoader(true))
    setButtonVisible(false)
    dispatch(updatePassword({old_password:old_password, password:password, password2:password}))
  
  }
  

    return (
      <div className={classes.personalArea}>
          <div className={classes.formArea}>
            <div style={{display:'flex'}}>
                <div className={classes.formlineLeftItem}>Текущий пароль</div>
                <div className={classes.formlineRightItem}>
                    <TextField
                    id={'outlined-basic'}
                    label={'Текущий пароль'}
                    fullWidth={true}
                    margin={'normal'}
                    autoComplete={'password'}
                    variant={'outlined'}
                    type={showOldPassword ? "text" : "password"}
                    error={!!error?.old_password}
                    helperText={error?.old_password?.text}
                    
                    onChange={onOldPasswordChange}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              onMouseDown={handleMouseDownOldPassword}
                            >
                              {showOldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}                                  
                    />   
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div className={classes.formlineLeftItem}>Новый пароль</div>
                <div className={classes.formlineRightItem}>
                    <TextField
                    id={'outlined-basic'}
                    label={'Новый пароль'}
                    fullWidth={true}
                    margin={'normal'}
                    autoComplete={'username'}
                    variant={'outlined'}
                    type={showPassword ? "text" : "password"}
                    error={!!error?.password}
                    helperText={error?.password?.text}
                    
                    onChange={onPasswordChange}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}                                       
                    />   
                </div>
              </div>   
              <Grid container spacing={2} justifyContent="center">
                                        <Grid item>
                            <div className={!saveLoader?'':'hidden'} style={{width:'100%'}}>
                            <Button variant="contained"  size={'large'} onClick={changePassword}  className={classes.button}>
                                Сохранить изменения
                            </Button>
                            </div>
                            <span className={saveLoader?'':'hidden'}><Loader/></span>
                        </Grid>
                    </Grid>                                     
                    <div style={{textAlign:'center', paddingTop:'20px'}}>{message}
            </div>
          </div>

      </div>
    );
  }