import React, {memo, useEffect, useRef, useState} from "react";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles.js";
import {    Link  } from "react-router-dom";

const useStyles = makeStyles(() => ({
    cellExpand: {
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
}))

function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

export const GridCellExpand = memo(function GridCellExpand(props) {
    const {width, value, eqid, href, link, clickHandle} = props

    const wrapper = useRef(null)
    const cellDiv = useRef(null)
    const cellValue = useRef(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles()
    const [showFullCell, setShowFullCell] = useState(false)
    const [showPopper, setShowPopper] = useState(false)

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current)
        setShowPopper(isCurrentlyOverflown)
        setAnchorEl(cellDiv.current)
        setShowFullCell(true)
    }

    const handleMouseLeave = () => {
        setShowFullCell(false)
    }

    useEffect(() => {
        if (!showFullCell) {
            return undefined
        }

        function handleKeyDown(nativeEvent) {
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false)
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [setShowFullCell, showFullCell])

    return (
        <div
            ref={wrapper}
            className={classes.cellExpand}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {(clickHandle)?
                <span className="headerlinkImageText" onClick={()=>clickHandle(eqid, value)} style={{cursor:'pointer'}}>{value}</span> 
                :(href)?
                <a className="headerlinkImageText" target="_blank" href={href}>{value}</a>
                :(link)?
                <Link to={link} className="link headerlink" Style={'padding:0px'}>{value}</Link>
                :
                <a className="headerlinkImageText" target="_blank" href={"/eq/"+eqid}>{value}</a>
                }
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{
                        width, marginLeft: -17, zIndex: 9999
                    }}
                >
                    <Paper
                        elevation={1}
                        style={{minHeight: wrapper.current.offsetHeight - 3}}
                    >
                        <Typography variant="body2" style={{padding: 8, whiteSpace:"break-spaces"}}>
                        {(clickHandle)?
                        <span onClick={()=>clickHandle(eqid)} className="headerlinkImageText" style={{cursor:'pointer'}}>{value}</span> 
                        :(href)?
                        <a className="headerlinkImageText" target="_blank" style={{whiteSpace: 'pre-line'}} href={href}>{value}</a>
                        :(link)?
                        <Link to={link} className="link headerlink" Style={'padding:0px'}>{value}</Link>                        
                        :
                        <a className="headerlinkImageText" target="_blank" style={{whiteSpace: 'pre-line'}} href={"/eq/"+eqid}>{value}</a>
                        }                            
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    )
})