import React from 'react';
import { HomePublicLayout } from '../layouts/HomePublicLayout';

import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    centeredDiv: {
        width: '100%',
        height: '100%',
    },
    smiley: {
        fontSize: 45
    }
}))


export const NotFoundPage = () => {
    const classes = useStyles()

    return  <HomePublicLayout>
    <div><p>Страница не найдена или доступна только авторизованным пользователям</p></div>
    </HomePublicLayout>

}