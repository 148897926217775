import { EQPAPI } from "../../api/api";

const SET_FIRM = 'firmReducer/SET_FIRM';
const SET_FIRMS = 'firmReducer/SET_FIRMS';
const SET_EQ = 'firmReducer/SET_EQ';
const SET_LOADER = 'firmReducer/SET_LOADER';
const SET_ERROR = "firmReducer/ERROR";
const SET_GEO = "firmReducer/SET_GEO";

const initialState = {
    firm: null,
    firms: null,
    geo: null,
    error: null,
    eq:null,
    loader: false,
}

export const firmReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FIRM:
            return {
                ...state,
                firm: action.firm
            }
        case SET_FIRMS:
            return {
                ...state,
                firms: action.firms
            }            
        case SET_EQ:
            return {
                ...state,
                eq: action.eq
            }
        case SET_GEO:
            return {
                ...state,
                geo: action.geo
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_LOADER:
            return {
                ...state,
                loader: action.loader
            }              
        default:
            return state;
    }
}


export const getFirm = state => state.firmReducer.firm
export const getFirms = state => state.firmReducer.firms
export const getGeo = state => state.firmReducer.geo
export const getEqFirm = state => state.firmReducer.eq
export const getFirmError = state => state.firmReducer.error
export const getFirmLoader = state => state.firmReducer.loader

export const setFirm = (firm) => ({type: SET_FIRM, firm})
export const setGeo = (geo) => ({type: SET_GEO, geo})
export const setFirms = (firms) => ({type: SET_FIRMS, firms})
export const setEqFirm= (eq) => ({type: SET_EQ, eq})
export const setFirmLoader = (loader) => ({type: SET_LOADER, loader})
export const setFirmError = (error) => ({type: SET_ERROR, error})


export const loadFirm = (id) => async dispatch => {
    dispatch(setFirmLoader(true))
    try {
        const response = await EQPAPI.getFirm(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setFirm(result))
            dispatch(setFirmLoader(false))
        }
    }
    catch (error) {
        dispatch(setFirmLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const loadFirmSync = (firm) => async dispatch => {
    dispatch(setFirmLoader(true))
    try {
        const response = await EQPAPI.getFirmSync(firm)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setFirm(result))
            dispatch(setFirmLoader(false))
        }
    }
    catch (error) {
        dispatch(setFirmLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const newFirm = (firm) => async dispatch => {
    dispatch(setFirmLoader(true))
    if (firm.NAME =='')
        firm.NAME ='Без названия'
    try {
        const response = await EQPAPI.newFirm(firm)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setFirm(result))
            dispatch(setFirmLoader(false))
        }
    }
    catch (error) {
        dispatch(setFirmLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const updateFirm = (id, firm) => async dispatch => {
    dispatch(setFirmLoader(true))
    try {
        const response = await EQPAPI.updateFirm(id, firm)
        
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setFirm(result))
            dispatch(setFirmLoader(false))
        }
    }
    catch (error) {
        dispatch(setFirmLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}


export const loadGeo = (id) => async dispatch => {
    dispatch(setFirmLoader(true))
    try {
        const response = await EQPAPI.getGeo(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setGeo(result))
            dispatch(setFirmLoader(false))
        }
    }
    catch (error) {
        dispatch(setFirmLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}
export const loadFirmEdit = (id) => async dispatch => {
    dispatch(setFirmLoader(true))
    try {
        const response = await EQPAPI.getFirmEdit(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setFirm(result))
            dispatch(setFirmLoader(false))
        }
    }
    catch (error) {
        dispatch(setFirmLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const loadAllFirms = (id) => async dispatch => {
    dispatch(setFirmLoader(true))
    try {
        const response = await EQPAPI.getAllFirms()
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setFirms(result))
            dispatch(setFirmLoader(false))
        }
    }
    catch (error) {
        dispatch(setFirmLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}