import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
      promoPanelCountNum: {  
        fontSize: '32px',
        fontWeight:'bold',
        ['@media (max-width:767px)']: { 
            fontSize: '24px',
        }
      }    
}))


export default function Counter({ val=10000, time=0.1 }) {
    const classes = useStyles()
    const [ currVal, setCurrVal ] = useState(0);
  
    useEffect(() => {
      currVal !== val && setTimeout(setCurrVal, 50, currVal + Math.floor(Math.random() * (100 - 1) + 1));
    }, [ currVal ]);
  
    return <span className={classes.promoPanelCountNum}>{currVal}</span>;
  }    