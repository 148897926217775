import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";  

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { getOk, loadOk } from "../../redux/reducers/ok-reducer";
import {OkPanel} from "../../containers/OkPanel/OkPanel";
import {RegionsPanel} from "../../containers/RegionsPanel/RegionsPanel";
import {getLoggedIn, getTariff} from "../../redux/reducers/login-reducer";
import {useDialog} from "../../hooks/useDialog.js";
import Grid from "@material-ui/core/Grid";
import {loadManufactures, getManufactures, getRegions, setManufactures, setRegions} from "../../redux/reducers/advancedsearch-reducer";

const useStyles = makeStyles((theme) => ({


    SearchManufacture: {
        marginTop: '20px',
        border: '1px solid #868686',
        
        width: '250px',
        height: '40px',
        borderRadius: '5px 5px 5px 5px',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        paddingLeft: '10px',
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '350px',
              borderRight: '2px solid #868686',
              borderRadius: '5px',
          },
          ['@media (max-width:360px)']: { 
            width: '300px',
          }
      },
    
    manufactureItem: { 
        cursor:'pointer', 
        color:'#054C74',
        borderBottom: '1px dashed', 
        lineHeight: '25px',
    },  

    mainDialog: {
        "& div.MuiPaper-root": {
            margin: '5px',
            height: 'calc(100% - 5px)',
            minWidth: '280px'
            
          },
        "& .MuiTypography-root": {
          padding: '5px',
          margin: 'auto'      
        },
        "& .MuiDialogContent-root": {
            padding: '0',
            
        },
        "& .MuiTreeItem-label": {
            color: '#1A1A1A'
        }

    },
    orderMenu: { minWidth:'100px',
    ['@media (max-width:400px)']: { 

        maxWidth:'300px'
    }
    }, 
    AdvancedSearchArea: {
        paddingTop: '10px',
        width: '800px',
        display: 'block',
        ['@media (max-width:900px)']: { 
            width: 'auto',
          } ,
        ['@media (max-width:520px)']: { 
              width: '100%',
              borderRadius: '5px',
          }       
    },

    advancedSearchValue: {
        marginBottom:'10px', 
        display: 'flow-root',
['@media (max-width:767px)']: { 
    width: '350px',
  } ,
['@media (max-width:500px)']: { 
      width: '100%',

  }        
    },

    advancedSearchName: {
        marginRight: '10px', 
        float: 'left', 
        lineHeight: '40px',
        width: '250px',
        color: '#676767'
    },


    advancedSearchGroup: {
        padding: '5px 5px 0 5px'
    },
    advancedSearchTerms: {

        width: '-webkit-fill-available',
        height: '40px',
        borderRadius: '5px 5px 5px 5px',
        border: '1px solid #868686',
        boxSizing: 'border-box',
        lineHeight: '18px',
        outline: 'none',
        
        ['@media (max-width:767px)']: { 
            width: '350px',
          } ,
        ['@media (max-width:500px)']: { 
              width: '100%',

          }  
      },



}))


export default function AdvancedSearch() {
    
    const classes = useStyles()
    const dispatch = useDispatch();
    const isTariff = useSelector(getTariff)
    

    const [shifr, setShifr] = useState('');
    const [name, setName] = useState('');
    const [owner, setOwner] = useState(1);
    const [searchManufacture, setSearchManufacture] = useState('')
    const setOwnerItem = (event, value) => {
        setOwner(value.props.value)
    }


    const [region, setRegion] = useState({name:'Любой', id:'any'});
    const setRegionItem = (id, name) => {
        setRegion({name:name, id:id})
        сloseDialogClassifier()
    }

    const [manufacture, setManufacture] = useState({name:'Любой', id:'any'});
    const manufactures = useSelector(getManufactures)

    useEffect(() => {
        if (manufactures == null)
        dispatch(loadManufactures());
    }, []);


    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()
 
    const [classifier, setClassifier] = useState({name:'Любой', id:'any'});

    const setClassifierItem = (id, name) => {
        setClassifier({name:name, id:id})
        сloseDialogClassifier()
    }
    const [dialogClassifier, setDialogClassifier] = useState(null);
    const сloseDialogClassifier = () => {
        setDialogClassifier(null);
      };
    
    const ManufacturesPanel = (props) => {
        return (manufactures)? 
            manufactures.filter(el => el.NAME.toUpperCase().indexOf(searchManufacture.toUpperCase().trim()) != -1).map((option) => (
            <div style={{paddingLeft: '5px'}} onClick={()=>{setManufacture({id:option.ID, name:option.NAME});сloseDialogClassifier()}}> <span className={classes.manufactureItem}>{option.NAME}</span></div>
            ))
            :null
        
    }

    const submit = () => {
        
        window.open('/eqpsearch/advanced/?aname='+name+'&ashifr='+shifr+'&aok='+classifier.id+'&aokname='+classifier.name+'&acompany='+manufacture.id+'&aregion='+region.id,"_self");
    }
    
    return (
        <div className={classes.AdvancedSearchArea}>
            <PleaseRegisterDialog
                open={pleaseRegisterDialogOpen}
                onClose={pleaseRegisterDialogHandleClose}
            />      
            
            <Dialog
            className={classes.mainDialog}
        open={(dialogClassifier == null)?false:true}
        onClose={сloseDialogClassifier}
        handleClose={сloseDialogClassifier}
        //scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        
      >
        <DialogTitle id="scroll-dialog-title">
        
        {(dialogClassifier == 'ok')?
        <TextField
                    size="small"
                    className={classes.orderMenu}
                     margin="dense"
                     padding="none"
                    id="standard-select-sort"
                    select
                    label="Тип оборудования"
                    defaultValue="1"
                    value={owner}
                    onChange={setOwnerItem}
                    >
                    
                    <MenuItem key="production" value="1">Оборудование для добычи нефти и газа</MenuItem>
                    <MenuItem key="drilling" value="2">Буровое оборудование</MenuItem>
        </TextField>
        :(dialogClassifier == 'manufacture')?
        <input 
            type="text" 
            //onKeyDown={(e) => { if (e.key === 'Enter') search()}} 
            autocomplete="off" 
            onChange={e=>setSearchManufacture(e.target.value)} 
            className={classes.SearchManufacture} 
            id="search-manufacture" 
            placeholder="Найти производителя" 
            value={searchManufacture}
        />
        :(dialogClassifier == 'region')?
        <h2>Регионы</h2>
        :null
        }
        </DialogTitle>
        <DialogContent dividers="paper">
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          >

            {(dialogClassifier == 'ok')?
            <OkPanel owner={owner} action={setClassifierItem}/>
            :(dialogClassifier == 'manufacture')?
            <ManufacturesPanel manufactures={manufactures}/>
            :(dialogClassifier == 'region')?
            <RegionsPanel  action={setRegionItem}/>
            :null
            }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={сloseDialogClassifier}>Закрыть</Button>
        </DialogActions>
      </Dialog>            
            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName}>Шифр оборудования</div>
                <div className={classes.advancedSearchValue}>
                    <input 
                        type="text" 
                        autocomplete="off" 
                        onChange={(e)=>setShifr(e.target.value)} 
                        //onKeyDown={(e) => { if (e.key === 'Enter') search()}}  
                        className={classes.advancedSearchTerms} 
                        //id="search-terms" 
                        //placeholder="Наименование или шифр оборудования" 
                        value={shifr}
                    />
                    <div style={{height: '30px', lineHeight: '30px'}}>
                        <span style={{color:'#676767'}}>например </span> 
                        <span style={{cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px'}} onClick={() => setShifr('ЗКЛ 160')}>ЗКЛ 160</span>
                    </div>
                </div>
                
            </div>

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName}>Наименование оборудования</div>
                <div className={classes.advancedSearchValue}>
                    <input 
                        type="text" 
                        autocomplete="off" 
                        onChange={(e)=>setName(e.target.value)} 
                        //onKeyDown={(e) => { if (e.key === 'Enter') search()}}  
                        className={classes.advancedSearchTerms} 
                        //id="search-terms" 
                        //placeholder="Наименование или шифр оборудования" 
                        value={name}
                    />
                    <div style={{height: '30px', lineHeight: '30px'}}>
                        <span style={{color:'#676767'}}>например </span> 
                        <span style={{cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px'}} onClick={() => setName('штанговый насос')}>штанговый насос</span>
                    </div>
                </div>
                
            </div>

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Тип оборудования</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>
                        <div >{classifier.name} {classifier.id != 'any'? 
                                    <IconButton style={{padding:'0'}} onClick={()=>setClassifier({name:'Любой', id:'any'})}>
                                    <CloseIcon />
                                </IconButton> 
                                :null   
                            }</div> 
                        <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline'}} onClick={() => setDialogClassifier('ok')}>Указать тип оборудования</div>
                    </div>                
            </div>

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Изготовитель</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>
                        <div >{manufacture.name} {manufacture.id != 'any'? 
                                    <IconButton style={{padding:'0'}} onClick={()=>setManufacture({name:'Любой', id:'any'})}>
                                    <CloseIcon />
                                </IconButton> 
                                :null   
                            }</div> 
                        <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline'}} onClick={() => !isTariff? pleaseRegisterDialogHandleOpen():setDialogClassifier('manufacture')}>Указать изготовителя</div>
                    </div>                
            </div>            

            <div className={classes.advancedSearchGroup} >
                <div className={classes.advancedSearchName} style={{marginBottom:'0'}}>Регион</div>
                    <div style={{  marginRight: '10px', marginBottom: '10px',  display:'flow-root', minWidth:'200px'}}>
                        <div >{region.name} {region.id != 'any'? 
                                    <IconButton style={{padding:'0'}} onClick={()=>setRegion({name:'Любой', id:'any'})}>
                                    <CloseIcon />
                                </IconButton> 
                                :null   
                            }</div> 
                        <div style={{ cursor:'pointer', color:'#054C74',borderBottom: '1px dashed', paddingTop:'3px', height: '30px', lineHeight: '30px', display:'inline'}} onClick={() => (!isTariff)? pleaseRegisterDialogHandleOpen():setDialogClassifier('region')}>Указать регион</div>
                    </div>                
            </div>   
            <Grid container spacing={2} justifyContent="center">
                                        <Grid item>
                                            <Button variant="contained" 
                                                //disabled={submitLoader} 
                                                onClick={submit}  
                                                style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                                                size={'large'} 
                                                >
                                                Найти
                                            </Button>
                                        </Grid>
                                    </Grid>                                
        </div>
        
    )

}