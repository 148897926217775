import { EQPAPI } from "../../api/api";

const SET_EQ = 'EQReducer/SET_EQ';
const SET_ERROR = "EQReducer/ERROR";
const SET_LOAD_BM = "EQReducer/SET_LOAD_BM";
const SET_LOAD_CM = "EQReducer/SET_LOAD_CM";
const SET_LOAD_URL = "EQReducer/SET_LOAD_URL";
const SET_EILIST = "EQReducer/SET_EILIST";
const SET_MANUFACTURES = 'EQReducer/SET_MANUFACTURES';
const SET_BTP = "EQReducer/SET_BTP";
const SET_BTP_REFRESH = "EQReducer/SET_BTP_REFRESH";
const SET_SAVE = "EQReducer/SET_SAVE";

const initialState = {
    manufactures: null,
    eq: null,
    eqsave: null,    
    btp: null,
    btp_refresh: null,
    eilist:null,
    load_bm:false,
    load_cm:false,
    load_url:false,
    error: null
}

export const EQReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EQ:
            return {
                ...state,
                eq: action.eq
            }
        case SET_SAVE:
            return {
                ...state,
                eqsave: action.eqsave
            }

        case SET_MANUFACTURES:
        return {
          ...state,
          manufactures: action.manufactures,
        }            
        case SET_BTP_REFRESH:
            return {
                ...state,
                btp_refresh: action.btp_refresh
            }            
        case SET_BTP:
            return {
                ...state,
                btp: action.btp
            }            
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_LOAD_BM:
            return {
              ...state,
              load_bm: action.load_bm
            }   
        case SET_LOAD_URL:
            return {
              ...state,
              load_url: action.load_url
            }               
        case SET_EILIST:
            return {
              ...state,
              eilist: action.eilist
            }                       
        case SET_LOAD_CM:
            return {
              ...state,
              load_cm: action.load_cm
            }               
        
            default:
                return state;
        }

    }


    export const getEQ = state => state.EQReducer.eq
    export const getEQSave = state => state.EQReducer.eqsave
    export const getBTP = state => state.EQReducer.btp
    export const getBTPRefresh = state => state.EQReducer.btp_refresh
    export const getEIList = state => state.EQReducer.eilist
    export const getLoadUrlEq = state => state.EQReducer.load_url
    export const getLoadBmEq = state => state.EQReducer.load_bm
    export const getLoadCmEq = state => state.EQReducer.load_cm
    export const getManufactures = (state) => state.EQReducer.manufactures; 

    export const setEQ = (eq) => ({type: SET_EQ, eq})
    export const setEQSave = (eqsave) => ({type: SET_SAVE, eqsave})    
    export const setBTP = (btp) => ({type: SET_BTP, btp})
    export const setBTPRefresh = (btp_refresh) => ({type: SET_BTP_REFRESH, btp_refresh})
    export const setEIList = (eilist) => ({type: SET_EILIST, eilist})
    export const setLoadBmEq = (load_bm) => ({type: SET_LOAD_BM, load_bm})
    export const setLoadUrlEq = (load_url) => ({type: SET_LOAD_URL, load_url})
    export const setLoadCmEq = (load_cm) => ({type: SET_LOAD_CM, load_cm})
    export const setManufactures = (manufactures) => ({type: SET_MANUFACTURES, manufactures})

    export const loadEQEdit = (id) => async dispatch => {

        const response = await EQPAPI.getEQEdit(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data;
            dispatch(setEQ(result));
        }

    }

    export const loadFromUrl = (url) => async dispatch => {

        const response = await EQPAPI.getEQFromUrl({url:url})
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data;
            dispatch(setEQ(result));
            dispatch(setLoadUrlEq(false))
        }

    }    

    export const saveEQEdit = (id, values) => async dispatch => {

        if (values.eq.NAME =='')
        values.eq.NAME ='Без названия'

        const response = await EQPAPI.setEQEdit(id, values)
        if (response.status === 200 ) {
            const result = response.data;
            dispatch(setEQSave(result));
        }

    }

    export const loadManufactures = () => async dispatch => {

        const response = await EQPAPI.getFirms()
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data;
            dispatch(setManufactures(result));
        }
    
    }
        
    export const loadEQ = (id) => async dispatch => {

        const response = await EQPAPI.getEQ(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data;
            dispatch(setEQ(result));
        }

    }

    export const loadBTP = (id) => async dispatch => {

        const response = await EQPAPI.getOkBTP(id)
        if (response.status === 200 ) {
            const result = response.data;
            dispatch(setBTP(result));
        }

    }

    export const loadBTPRefresh = (id) => async dispatch => {

        const response = await EQPAPI.getOkBTP(id)
        if (response.status === 200 ) {
            const result = response.data;
            dispatch(setBTPRefresh(result));
        }

    }
    
    export const loadEIList = () => async dispatch => {

        const response = await EQPAPI.getEIList()
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data;
            dispatch(setEIList(result));
        }

    }
    export const setBookmarkStatusEq = (eqItem) => async (dispatch) => {
        eqItem.bookmark = !eqItem.bookmark
        dispatch(setEQ(eqItem));

        const response = await EQPAPI.setBookmark(eqItem.eq.ID);
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data;
            dispatch(setLoadBmEq(true))
        }
      
      }
      
      export const setCompareStatusEq = (eqItem) => async (dispatch) => {
        eqItem.compare = !eqItem.compare
        dispatch(setEQ(eqItem));

        const response = await EQPAPI.setCompare(eqItem.eq.ID);
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data;
            dispatch(setLoadCmEq(true))
        }
      
      }      