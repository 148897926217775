import React from 'react';
import { Link } from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Menu from '@material-ui/icons/Menu';
import Bookmark from '@material-ui/icons/BookmarksOutlined';
import Star from '@material-ui/icons/StarOutline';
import {useDialog} from "../../hooks/useDialog.js";
import PleaseRegisterDialog from "../PleaseRegisterDialog/PleaseRegisterDialog.jsx";


const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: 40,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderBottom: '2px solid #054C74',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        overflow: 'hidden',
        padding: '0',
        margin: '0'
    },
    grow: {
        width: '40px',
        flexGrow: 1
    },


}))



export const FooterPublic = ({children}) => {

    const {
        open: pleaseRegisterDialogOpen,
        handleOpen: pleaseRegisterDialogHandleOpen,
        handleClose: pleaseRegisterDialogHandleClose
    } = useDialog()

    const classes = useStyles()
    return   <footer className="footer">
                <div className="App-footer">
                    <div className="App-footer-box">
                        <div className="App-footer-box-navigation">
                            <div className="App-footer-box-navigation-col">
                                <p style={{fontSize: '24px', fontWeight: '800'}}>Каталоги</p>
                                <p><Link to="/advanced" className="link footerlink">Расширенный поиск оборудования</Link></p>
                                <p><span onClick={() => window.open('/eqpsearch/?ok=1&chap=1&okname=Оборудование для добычи нефти и газа',"_self")} className="link footerlink">Оборудование для добычи нефти и газа</span></p>
                                <p><span onClick={() => window.open('/eqpsearch/?ok=2&chap=2&okname=Буровое оборудование',"_self")} className="link footerlink">Буровое оборудование</span></p>
                                
                            </div>
                            <div className="App-footer-box-navigation-col">
                            <p style={{paddingTop:'20px'}}><Link to="/about" onClick={()=>window.scrollTo(0, 0)} className="link footerlink">О нас</Link></p>
                            <p ><Link to="/tariffs" onClick={()=>window.scrollTo(0, 0)} className="link footerlink">Тарифы</Link></p>
                            <p ><Link to="/help" onClick={()=>window.scrollTo(0, 0)} className="link footerlink">Помощь</Link></p>
                            <p><Link to="/contacts" onClick={()=>window.scrollTo(0, 0)} className="link footerlink">Контакты</Link></p>
                            <p><Link to="/policy" onClick={()=>window.scrollTo(0, 0)} className="link footerlink">Политика конфиденциальности</Link></p>
                            <p><Link to="/oferta" onClick={()=>window.scrollTo(0, 0)} className="link footerlink">Договор оферта</Link></p>
                            <p><Link to="/payment-methods" onClick={()=>window.scrollTo(0, 0)} className="link footerlink">Способы и правила оплаты</Link></p>
                            <div style={{backgroundColor:'#fff', width:'292px', padding:'5px'}}><img src="/images/logo1h.png"/> </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
}